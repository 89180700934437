import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { isEmpty } from 'lodash';

import DropdownItem from './item';
import Button from '../../Button';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderOptions = this.renderOptions.bind(this);

    this.state = {
      values: {},
      name: {},
      time: {},
      period: {},
      criteriaOpen: null,
      amountOpen: null,
      names: props.choices,
      selectedLocale: props.selectedLanguage,
      invalid: true,
    };
  }

  componentWillMount() {
    const { choices, value, onChange } = this.props;

    let nameState = {};
    let valueState = {};

    choices.data.forEach(choice => {
      let key = Object.entries(choice);
      let name = key[0][0];
      let choicesContainValue = value[name]
        ? key[0][1].values.filter(val => Object.entries(val)[0][0] === value[name].name)
        : [];

      nameState[name] = value[name] ? value[name].name : '';
      valueState[name] = value[name] && !isEmpty(choicesContainValue) ? value[name] : {};
    });

    onChange([valueState]);

    this.setState({ name: nameState });
    this.setState({ values: valueState });

    this.validateForm(valueState);
  }

  handleTextChange = (e, name, locale) => {
    const { value } = e.target;
    const { values } = this.state;

    if (value === '') {
      this.removeOtherTextOnEmpty(name, locale);
    } else {
      const otherValues = values[name].other_text ? values[name].other_text[0] : {};

      otherValues[locale] = value;

      this.mergeValues(name, 'other_text', [otherValues]);
    }
  };

  toggleCriteriaDropdown = name => {
    this.setState({
      criteriaOpen: this.state.criteriaOpen === name ? null : name,
    });
  };

  handleChange(e, type, name) {
    const { value } = e.target.dataset;

    let valueToSend = value ? value : e.target.value;

    let newObj = update(this.state[type], {
      [name]: {
        $set: valueToSend,
      },
    });
    this.setState({ [type]: newObj }, () => {
      this.mergeValues(name, type, valueToSend);
    });
  }

  removeOtherTextOnEmpty = (name, locale) => {
    const { values } = this.state;

    delete values[name]['other_text'][0][locale];

    this.setState({ values: values });

    this.validateForm(values);
  };

  mergeValues(name, type, value) {
    const { values } = this.state;

    let newObj;

    if (value === 'deletion' || value === 'other') {
      newObj = update(values, {
        [name]: {
          [type]: {
            $set: value,
          },
          period: {
            $set: undefined,
          },
          time: {
            $set: undefined,
          },
        },
      });
    } else {
      newObj = update(values, {
        [name]: {
          [type]: {
            $set: value,
          },
        },
      });
    }

    this.setState({ values: newObj }, () => {
      this.sendValues();
    });

    this.validateForm(newObj);
  }

  sendValues() {
    const { onChange } = this.props;
    const { values } = this.state;

    onChange([values]);
  }

  onSelectLocale = locale => {
    this.setState({ selectedLocale: locale });
  };

  validateForm = values => {
    const { locales } = this.props;

    const valueArr = Object.values(values);

    let invalid = false;

    valueArr.map(obj => {
      if (
        obj.name !== 'deletion' && obj.name !== 'other' &&
        (typeof obj.name === 'undefined' ||
          typeof obj.period === 'undefined' ||
          typeof obj.time === 'undefined' ||
          isNaN(obj.time) ||
          obj.time === '' ||
          obj.time < 1)
      ) {
        invalid = true;
      } else if (obj.name === 'other') {
        if (typeof obj.other_text === 'undefined') {
          invalid = true;
        } else {
          locales.map(locale => {
            if (!obj.other_text[0].hasOwnProperty(locale)) {
              invalid = true;
            }
          });
        }
      }
    });

    this.setState({ invalid: invalid });
  };

  renderLocale = () => {
    const { locales } = this.props;
    const { selectedLocale } = this.state;
    return (
      <ul className='form_locales__list'>
        {locales.map(locale => (
          <li
            key={locale}
            className={`form_locales__item ${selectedLocale === locale ? 'active' : ''}`}
            onClick={() => this.onSelectLocale(locale)}
          >
            {locale.toUpperCase()}
          </li>
        ))}
      </ul>
    );
  };

  renderOptions(option) {
    return (
      <option key={option.id} value={option.value}>
        {option.label}
      </option>
    );
  }

  renderDropdown(options, i) {
    const { criteriaOpen } = this.state;
    const name = options[0].name;
    const value = this.state.name[name];
    const { t } = this.props;

    const valueObj = options.filter(option => option.value === value);
    const valueToShow = valueObj.length > 0 ? valueObj[0].label : t('.choose_criteria');

    return (
      <DropdownItem
        handleChange={this.handleChange}
        handleTextChange={this.handleTextChange}
        toggleCriteriaDropdown={this.toggleCriteriaDropdown}
        options={options}
        name={name}
        values={this.state.values}
        locales={this.props.locales}
        criteriaValue={valueToShow}
        isOpen={criteriaOpen === name}
        value={value}
        t={t}
        selectedLocale={this.state.selectedLocale}
      />
    );
  }

  render() {
    const { options, goBack, handleSubmit, t } = this.props;
    const { names } = this.state;

    return (
      <div>
        {options.map((opt, i) => {
          const objectKey = Object.keys(names.data[i]);
          const obj = names.data[i];
          return (
            <div key={i}>
              <h5> {obj[objectKey].name} </h5>
              {this.renderDropdown(opt, i)}
            </div>
          );
        })}

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
};

export default DropDown;
