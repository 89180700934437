import React from 'react';

import logo from '../../assets/img/admeet_logo-quote.svg';

const divStyle = {
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  'margin-top': '15%',
};

const IeMessage = () => (
  <div style={divStyle}>
    <img src={logo} alt='logo_admeet' style={{ 'margin-bottom': '32px' }} />
    <p> Bienvenu sur Admeet. </p>
    <p>
      {' '}
      Merci d’utiliser les dernières versions des navigateurs : Google Chrome, Microsoft Edge,
      Mozilla Firefox, Safari ou Opera.
    </p>
  </div>
);

export default IeMessage;
