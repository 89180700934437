import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import client from '../../config/apollo_client';
import AuthWrapper from '../../components/Auth';
import EditForm from './form';
import GraphqlCalls from './GraphQl';
import { withTranslation } from '../../providers/I18n';
import Loading from '../../components/Loading';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';
import gql from '../ProductPrivacyPolicies';
import Select from 'react-select';
import Button from 'src/components/Button';

const PrivacyPolicyNew = ({
  match: {
    params: { id },
    url,
  },
  t,
  history,
  userId,
  isSuperAdmin
}) => {
  const isCookiePolicy = () => {
    return url.includes('cookie-policies');
  };

  const policyType = isCookiePolicy() ? 'cookie_policy' : 'privacy_policy';

  const [cookiePolicyProducts, setCookiePolicyProducts] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);

  const [selectedLicenseID, setSelectedLicenseID] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [productTitle, setProductTitle] = useState(null);
  const [domainName, setDomainName] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableLegislation, setAvailableLegislation] = useState([]);
  const [packDetails, setpackDetails] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLicenses = () => {
      client
        .query({
          query: GraphqlCalls.GET_LICENSES_QUERY,
          fetchPolicy: 'network-only',
          variables: { productType: policyType, userId },
        })
        .then(({ data }) => {
          const unusedLicenses = data.licenses.reverse().filter(license => license.product == null);
          setLicenses(unusedLicenses);

          const licenseOpts = data.licenses
            .filter(license => license.product == null && !license?.isExpired)
            .map(({ id: value, name: label }) => {
              return { value, label };
            });
          setLicenseOptions(licenseOpts);
        });
    };
    fetchLicenses();
  }, []);

  useEffect(() => {
    const fetchProducts = () => {
      client
        .query({
          query: GraphqlCalls.GET_PRODUCT_POLICIES_QUERY,
          fetchPolicy: 'network-only',
          variables: { productType: 'cookie_policy', userId },
        })
        .then(({ data }) => {
          setCookiePolicyProducts(data.products);
          setLoading(false);
        });
    };
    fetchProducts();
  }, []);

  const handleChangeSelectedLicense = e => {
    const val = e.value;
    setSelectedLicenseID(val);

    const licenceArr = licenses.filter(obj => {
      return obj.id === parseInt(val);
    });

    const selectedLicence = licenceArr[0];
    setSelectedLicense(selectedLicence);
    setProductTitle(selectedLicence.name);
    setDomainName(selectedLicence.domainName);

    const legislation = selectedLicence.features.reduce(
      (arr, item) => (item.groupCode === 'legislation' && arr.push(item['code']), arr),
      [],
    );
    setAvailableLegislation(legislation);

    const languages = selectedLicence.features.reduce(
      (arr, item) => (item.groupCode === 'language' && arr.push(item['code']), arr),
      [],
    );
    setAvailableLanguages(languages);
    setpackDetails(selectedLicence);
  };

  return (
    <AuthWrapper>
      <TitleComponent title={t('.new_policy')} />
      <div className='row'>
        <div className='columns'>
          <div className='row button-buy-extra'>
            <div className='column'>
              <h1>{t('.new_policy')}</h1>
            </div>
            {/* {isSuperAdmin ? (
              <Button className='btn btn_pill is-blue' onClick={() => {
                localStorage.setItem('buyExtraState', "")

                history.push('/buylicenses')
              }}>
                <i className='fa fa-plus-circle margin-right-10' />
                {t('.buyextra')}
              </Button>
            ) : null} */}

          </div>
          {loading ? (
            <Loading />
          ) : (
            <Fragment>
              <Select
                options={licenseOptions}
                onChange={handleChangeSelectedLicense}
                placeholder='Select license...'
                className='select-padded'
              />
              {selectedLicenseID !== null && selectedLicenseID !== '0' && (
                <EditForm
                  productType={policyType}
                  allCookiePolicyProducts={cookiePolicyProducts}
                  selectedLicense={selectedLicense}
                  title={productTitle}
                  domainName={domainName}
                  availableLanguages={availableLanguages}
                  availableLegislation={availableLegislation}
                  formType={'new'}
                  linkPolicyMutation={GraphqlCalls.LINK_PRODUCT_POLICIES_MUTATION}
                  graphqlMutation={GraphqlCalls.CREATE_PRODUCT_POLICY_MUTATION}
                  packageData={packDetails}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </AuthWrapper>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userId, isSuperAdmin } = auth
  return { userId, isSuperAdmin }
}

export default compose(connect(mapStateToProps), withRouter, withTranslation('privacyPolicyEdit'))(PrivacyPolicyNew);
