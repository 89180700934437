import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import withTranslation from '../../../providers/I18n/HoC/t'
import { hideModal } from '../../../redux/modal/action'
import { withNotifications, NotificationModel } from '../../../providers/notifications'
import gql from 'graphql-tag'
import Button from 'src/components/Button'

const UserCard = ({ userEmail, id, client, notificationsProvider, closeExampleModal, t, isConfirmed, licenseId }) => {
  const deleteUser = () => {
    if (window.confirm(t('.revoke_user_access'))) {
      client
        .mutate({
          mutation: REVOKE_USER_ACCESS,
          variables: { id },
        })
        .then(() => {
          const notification = new NotificationModel({
            title: 'Success:',
            message: t('.remove_user_message'),
            type: 'success',
          })

          notificationsProvider.add(notification)

          closeExampleModal()
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors[0].extensions.errors[0].detail)
        })
    }
  }
  const resendEmail = () => {
    client
      .mutate({
        mutation: RESEND_EMAIL,
        variables: { licenseId, userEmail },
      })
      .then(() => {
        const notification = new NotificationModel({
          title: '',
          message: t('.resend_email'),
          type: 'success',
        })

        notificationsProvider.add(notification)
        closeExampleModal()
      })
      .catch(({ graphQLErrors }) => {
        const notification = new NotificationModel({
          title: '',
          message: t('.server_error'),
          type: 'alert',
        })

        notificationsProvider.add(notification)
      })
  }

  return (
    <div id={id} className='user-card'>
      <p>{userEmail}
        <span className='margin-left-10'>
          {isConfirmed ? (
            <i className="fa fa-check-circle checktrue"></i>
          ) : (
            <Button className='btn btn_pill is-blue activation-button-font' onClick={resendEmail}>
              {t('.activation_button')}
              <i className="fa fas fa-arrow-right margin-left-10"></i>
            </Button>
          )}
        </span>
      </p>
      <i className='fas fa-user-times margin-left-20 deleteicon' onClick={deleteUser}></i>

    </div>
  )
}

const REVOKE_USER_ACCESS = gql`
  mutation DeleteUserAccess($id: ID!) {
    deleteUserAccess(id: $id) {
      id
    }
  }
`
const RESEND_EMAIL = gql`
  mutation ResendConfirmation($licenseId: ID!, $userEmail: String!) {
    resendConfirmation(licenseId: $licenseId, userEmail: $userEmail) {
        id
    }
  }
`

const mapDispatchToProps = {
  closeExampleModal: hideModal,
}

export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withNotifications(),
  withTranslation('dashboard'),
)(UserCard)
