/* eslint react/jsx-indent-props: off */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'src/providers/I18n';

import { renderInputField } from '../../../components/Form/TextFieldGroup/fieldInput';
import validate from './validate';
import DropdownItem from './dropdown';
import { plan_list } from './onboarding_data';
import Select from 'react-select';
import CountryDropdownItem from './countrydropdown';
import countriesList from 'src/helpers/Countries/countries-list';

const CompanyForm = ({
    handleSubmit,
    handleChange,
    initialValues,
    countryhandleChange,
    t,
}) => {
    function listOfCountries() {
        var currentLanguage = localStorage.getItem('locale')
        let listOfCountries = countriesList[currentLanguage ? currentLanguage : 'fr'];
        var coutrydata = ["at", "be", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hu", "ie", "it", "lv", "lt", "lu", "mt", "nl", "pl", "pt", "ro", "sk", "si", "es", "se"]
        var newList = [{
            name: '',
            label: t('.please_select_country'),
            value: ''
        }];
        listOfCountries.forEach(item => {
            if (coutrydata.includes(item.value)) {
                newList.push({
                    name: item.label,
                    label: item.label,
                    value: item.value
                })
            }

        })
        newList = newList.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
        return newList;
    }
    return (
        <div className='onboarding_form'>
            <form className='text-form auth_form' onSubmit={handleSubmit}>
                <div className='d-flex-same'>
                    <div className='flex-div'>
                        <Field
                            name='email'
                            type='text'
                            label={t('.email_address')}
                            onChange={handleChange}
                            mandatory
                            component={renderInputField}
                            t={t}
                        />
                    </div>
                    <div className='flex-div'>
                        <CountryDropdownItem
                            label={t('.country')}
                            name='companycountryname'
                            handleChange={countryhandleChange}
                            options={listOfCountries()}
                            value={initialValues?.companycountryname}
                            mandatory
                            arraowShow={true}
                        />
                    </div>
                </div>
                <div className='d-flex-same'>
                    <div className='flex-div'>
                        <Field
                            name='company_name'
                            type='text'
                            label={t('.company_name')}
                            onChange={handleChange}
                            mandatory
                            component={renderInputField}
                            t={t}

                        />
                    </div>
                    <div className='flex-div subdomain '>
                        <Field
                            name='sub_domain'
                            type='text'
                            label={t('.choose_sub_domain')}
                            onChange={handleChange}
                            mandatory
                            component={renderInputField}
                            t={t}
                            className='txt-field width-100'
                        />
                        <div className="input-group-addon">
                            <span>.admeet.eu</span>
                        </div>
                    </div>
                </div>
                <div className='d-flex-same'>
                    <div className='flex-div'>
                        <Field
                            name='userPassword'
                            type='password'
                            label={t('.password')}
                            onChange={handleChange}
                            mandatory
                            component={renderInputField}
                            t={t}
                        />
                    </div>
                    <div className='flex-div'>
                        <Field
                            name='userRePassword'
                            type='password'
                            label={t('.re-password')}
                            onChange={handleChange}
                            mandatory
                            component={renderInputField}
                            t={t}
                            className='txt-field'
                        />
                    </div>

                </div>
                {/* <div className='d-flex-same'>
                    {values}========
                    <div className='flex-div'>
                        <DropdownItem
                            label={"Payment mode"}
                            name='paymentMode'
                            placeholder="Payment mode"
                            handleChange={handleChange}
                            options={plan_list}
                            value={values}
                            t={t}
                        />
                    </div>
                    <div className='flex-div'>

                    </div>

                </div> */}


            </form>
        </div>
    );
};

CompanyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
};
function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            sub_domain: ownProps.initialValues.sub_domain
        }
    }
}
export default compose(
    reduxForm({
        form: 'onboarding_form',
        enableReinitialize: true,
        validate
    }, mapStateToProps),
    withTranslation('account_onboarding'),
    withRouter,
)(CompanyForm);