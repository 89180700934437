import * as React from 'react'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Link from 'react-router-dom/es/Link'

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput'
import UnAuthWrapper from '../../components/UnAuth'
import { submitSignIn } from './submit_validation'
import { validateSignIn as validate } from './validate'
import { withTranslation } from '../../providers/I18n'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

class SignIn extends React.Component {
  render() {
    const { submitting, handleSubmit, error, t } = this.props
    return (
      <UnAuthWrapper title={t('.connection')}>
        <TitleComponent title={t('.connection')} />
        <form className='text-form auth_form' onSubmit={handleSubmit}>
          <Field
            name='email'
            type='text'
            label={t('.email')}
            placeholder='example@test.com'
            component={renderInputField}
            t={t}
          />
          <Field
            name='password'
            type='password'
            label={t('.password')}
            placeholder='********'
            component={renderInputField}
            noTooltip
            t={t}
          />
          <div className='auth_form-util'>
            <Link to='./reset-password'> {t('.password_forgotten')} </Link>
          </div>
          {error && <p className='validation_error'>{t(error)}</p>}
          <button type='submit' disabled={submitting} className='btn_pill is-blue'>
            {submitting ? t('.waiting') : t('.sign_me_in')}
          </button>
        </form>
      </UnAuthWrapper>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { userEmail } = auth
  return {
    initialValues: userEmail,
  }
}



export default compose(
  connect(mapStateToProps),
  reduxForm({ form: 'sign_in_form', validate, enableReinitialize: true, onSubmit: submitSignIn }),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(SignIn)
