import { loader } from 'graphql.macro';

const GraphqlCalls = {
  GET_PRODUCT_POLICIES_QUERY: loader('./get_product_policies_query.graphql'),
  GET_PRODUCT_POLICY_QUERY: loader('./get_product_policy_query.graphql'),
  GET_LICENSES_QUERY: loader('./get_licenses_query.graphql'),
  GET_PRIVACY_POLICY_QUERY: loader('./get_policy_query.graphql'),
  CREATE_PRODUCT_POLICY_MUTATION: loader('./create_product_policy_mutation.graphql'),
  LINK_PRODUCT_POLICIES_MUTATION: loader('./link_product_policies_mutation.graphql'),
  UPDATE_PRODUCT_POLICY_MUTATION: loader('./update_product_policy_mutation.graphql'),
  CREATE_PRIVACY_POLICY_MUTATION: loader('./create_policy_mutation.graphql'),
  LINK_POLICIES_MUTATION: loader('./link_policies_mutation.graphql'),
  UPDATE_PRIVACY_POLICY_MUTATION: loader('./update_policy_mutation.graphql'),
  ADD_USER_TO_PRODUCT: loader('./add_user_to_product.graphql')
};

export default GraphqlCalls;
