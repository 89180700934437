import Constant from './constant';

export const loadModal = payload => {
  return {
    type: Constant.SHOW,
    payload,
  };
};

export const hideModal = () => {
  return {
    type: Constant.HIDE,
  };
};
