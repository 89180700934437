import React from 'react';
import { compose } from 'recompose';
import withApollo from 'react-apollo/withApollo';
import { withRouter } from 'react-router-dom';

import PrivacyPolicyCategory from '../../components/PrivacyPolicyCategory';
import withTranslation from '../../providers/I18n/HoC/t';

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesStatus: 'completed',
    };
  }

  componentWillMount() {
    const { categories, ppStatus } = this.props;

    categories.forEach(cat => {
      if (cat.status.key === 'in_progress') {
        this.setState({ categoriesStatus: 'uncompleted' });
      }
    });

    if (ppStatus === 'published') this.setState({ categoriesStatus: 'published' });
  }

  render() {
    const { categories, ppID, ppStatus, publishedAt, policyType, t } = this.props;
    return (
      <div className='categories align-center'>
        {categories &&
          categories.map(category => (
            <PrivacyPolicyCategory
              key={category.id}
              id={category.id}
              ppId={ppID}
              avatar={category.avatar}
              name={category.name}
              status={category.status.key}
              position={category.position}
              ppStatus={ppStatus}
              date={publishedAt}
              policyType={policyType}
              t={t}
              category={category}
            />
          ))}
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(Categories);
