import * as React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import { renderCheckboxField } from '../../components/Form/Checkbox';
import UnAuthWrapper from '../../components/UnAuth';
import { submitPassword } from './submit_validation';
import validate from './validate';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class ChangePassword extends React.Component {
  componentDidMount() {
    const { history } = this.props;

    let urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('token')) {
      history.push('/');
    }
  }

  render() {
    const { submitting, handleSubmit, error, t } = this.props;

    return (
      <UnAuthWrapper title={t('.choose_new_password')}>
        <TitleComponent title={t('.choose_new_password')} />
        <form className='text-form auth_form' onSubmit={handleSubmit(submitPassword)}>
          <Field
            name='password'
            type='password'
            label={t('.password')}
            component={renderInputField}
            t={t}
          />
          <Field
            name='password_confirmation'
            type='password'
            label={t('.password_confirmation')}
            component={renderInputField}
            t={t}
          />
          {error && <p className='validation_error'>{t(error)}</p>}
          <button type='submit' className='btn btn_pill is-blue' disabled={submitting}>
            {submitting ? t('.waiting') : t('.confirm_my_password')}
          </button>
        </form>
      </UnAuthWrapper>
    );
  }
}

export default compose(
  reduxForm({ form: 'new_password_form', validate }),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(ChangePassword);
