import React, { useState } from 'react';
import { Field } from 'redux-form';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import CountryDropdownItem from './countrydropdown';
import withApollo from 'react-apollo/withApollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { withNotifications } from 'src/providers/notifications';

const languages = [
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'en',
    label: 'English',
  },
];
const AccountForm = ({ handleChange, handleSubmit, t, initialValues, client }) => {
  const [mailLanguage, setmailLanguage] = useState(initialValues?.mailLanguage);
  const [isReminderToUpdatePolicies, setIsReminderToUpdatePolicies] = useState(initialValues?.isReminderToUpdatePolicies);
  const reminderHandleChange = () => {
    client
      .mutate({
        mutation: UPDATE_PREFERENCES,
        variables: {
          isReminderToUpdatePolicies: !isReminderToUpdatePolicies
        }
      })
      .then(({ data }) => {
        const { updatePreferences } = data;
        if (updatePreferences) {
          setIsReminderToUpdatePolicies(!isReminderToUpdatePolicies)
        }

      }).catch(e => {
        console.log("======", e);
      })
  }
  return (
    <div>
      <h4> {t('.organization')} </h4>
      <Field
        name='organization_name'
        type='text'
        label={t('.organization_name')}
        onChange={handleChange}
        component={renderInputField}
        readOnly={true}
      />
      <h4>{t('.account_tenant')}</h4>
      <Field
        name='name'
        type='text'
        label={t('.name')}
        onChange={handleChange}
        component={renderInputField}
      />
      <Field
        name='phone'
        type='text'
        label={t('.phone_number')}
        onChange={handleChange}
        component={renderInputField}
      />
      <h4>{t('.connection_information')}</h4>
      <Field
        name='email_address'
        type='text'
        label={t('.email_address')}
        onChange={handleChange}
        component={renderInputField}
        readOnly={true}
      />
      <h4> {t('.email_preferences')} </h4>
      {languages && languages.length > 0 &&
        <CountryDropdownItem
          label={t('.language')}
          name='mailLanguage'
          handleChange={(e) => {
            setmailLanguage(e.target.value)
            handleChange(e)
          }}
          options={languages}
          value={mailLanguage}
        />
      }
      <div className='high-contrast'>
        <div className='high-contrast-content'>
          <h5>{t('.allow_reminder_update')}
          </h5>
          <div className='features-set'>
            <div className='checkbox-wrapper'>
              <input
                name={"isReminderToUpdatePolicies"}
                type='checkbox'
                id={"isReminderToUpdatePolicies"}
                checked={isReminderToUpdatePolicies}
                onChange={reminderHandleChange}
              />
              <label htmlFor={"isReminderToUpdatePolicies"}>Toggle</label>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const UPDATE_PREFERENCES = gql`
 mutation UpdatePreferences($isReminderToUpdatePolicies: Boolean!) {
    updatePreferences(is_reminder_to_update_policies: $isReminderToUpdatePolicies) {
        id
    }
}
`

export default compose(withApollo, withNotifications())(AccountForm);
