import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNotifications } from 'src/providers/notifications';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'src/providers/I18n';
import { IoIosCloseCircleOutline, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import './card.css'

class CardPackageList extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { activePackage, packDetails, t, paymentMode, onClickPackage, currentLan } = this.props;


        return (
            <>
                {activePackage && activePackage.length > 0 ? (
                    <>
                        {
                            activePackage.map(item => {
                                return (
                                    <div className={`columns small-12 medium-6 large-3  margin-bottom-40`} onClick={() => {
                                        onClickPackage(item)
                                    }}>
                                        <div className={`card ${packDetails?.uid === item.uid ? 'active' : ''}`}>
                                            <h4>{currentLan === 'en' ? item.name[0].en : item.name[1].fr} </h4>
                                            <span className="package-name">{currentLan === 'en' ? item.description[0].en : item.description[1].fr} </span>

                                            <div>

                                                {paymentMode === 'monthly' ? <p>€{item.monthlyPrice} {t('.per_month_per_website')} </p> : <p>€{item.yearlyPrice} {t('.per_year_per_website')} </p>}
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                ) : null}
            </>
        );
    }
}


export default compose(connect(null, null), withApollo, withRouter, withTranslation('account_onboarding'), withNotifications())(CardPackageList);