import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';

import countriesList from '../../../helpers/Countries/countries-list.js';
import eu_countriesAlpha from '../../../helpers/Countries/EU_countries-alpha.json';
import Button from '../../Button';
import {
  validatePhone,
  validateEmail,
  validateUrl,
  validateHTTPUrl,
} from '../../../containers/Questions/validateFormats';

const renderInput = ({
  option,
  input,
  label,
  type,
  example,
  readOnly,
  mandatory,
  t,
  meta: { touched, error },
}) => {
  if (option.name !== 'phone_number') option.mandatory = true;

  return (
    <div className={`text_input ${touched && error ? 'error' : ''}`}>
      <input
        {...input}
        type={type}
        placeholder={label}
        readOnly={readOnly}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      />
      {label && (
        <div className='text__label'>
          <label>
            {label}
            {option.mandatory && <span style={{ color: 'red' }}> * </span>}
          </label>
        </div>
      )}
      {touched && error && <div className='input_error'>{t(error)}</div>}
      {option.example && <p className='input_example'> {option.example} </p>}
    </div>
  );
};

class TextFieldGroup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderField = this.renderField.bind(this);
    this.state = {
      values: props.options.map(opt => {
        if (opt instanceof Array) {
          return opt.map(({ name, value }) => {
            return value[name] ? { [name]: value[name] } : { [name]: '' };
          });
        } else {
          const { name, value } = opt;
          return value[name] ? { [name]: value[name] } : { [name]: '' };
        }
      }),
      countryAlert: false,
      invalid: true,
    };

    this.props.onChange(this.state.values);
  }

  componentWillMount() {
    this.validateInput(this.state.values, 'init');
  }

  handleChange(event) {
    let newArr = this.state.values.map(item => {
      let keys = Object.keys(item);
      if (event.target.name === keys[0]) {
        return { [keys]: event.target.value };
      } else {
        if (item instanceof Array) {
          return item.map(it => {
            let arrKeys = Object.keys(it);
            if (event.target.name === arrKeys[0]) {
              return { [arrKeys]: event.target.value };
            } else {
              return it;
            }
          });
        }
        return item;
      }
    });

    this.validateInput(newArr, 'edit');

    this.setState({
      values: newArr,
    });

    this.props.onChange(newArr);
  }

  validateInput = (values, state) => {
    const keysUrl = ['url', 'link'];
    const keysHttpUrl = ['data'];
    const keysEmail = ['email'];
    const keysPhone = ['phone_number'];

    let { mandatoryChoices, referenceNumber } = this.props;

    if (referenceNumber === 'F.15') {
      mandatoryChoices = ['email', 'street', 'number', 'city', 'zip_code'];
    }

    if (referenceNumber === 'N.5') {
      mandatoryChoices = ['website', 'url'];
    }

    if (referenceNumber === 'N.6') {
      mandatoryChoices = ['name'];
    }

    if (referenceNumber === 'N.7') {
      mandatoryChoices = ['name'];
    }

    let invalid = false;
    let countryAlert = false;

    mandatoryChoices.map(choice => {
      values.forEach(item => {
        if (Array.isArray(item)) {
          item.forEach(subitem => {
            if (choice in subitem && subitem[choice] === '') {
              invalid = true;
            }
            if (choice === 'country' && subitem[choice] === '' && state === 'edit') {
              countryAlert = true;
            }
            for (const [key, value] of Object.entries(subitem)) {
              if (keysEmail.includes(key) && !validateEmail(value)) {
                invalid = true;
              }
              if (keysUrl.includes(key) && !validateUrl(value)) {
                invalid = true;
              }
              if (keysHttpUrl.includes(key) && !validateHTTPUrl(value)) {
                invalid = true;
              }
              if (keysPhone.includes(key) && !validatePhone(value)) {
                invalid = true;
              }
            }
          });
        } else {
          if (choice in item && item[choice] === '') {
            invalid = true;
          }
          for (const [key, value] of Object.entries(item)) {
            if (keysEmail.includes(key) && !validateEmail(value)) {
              invalid = true;
            }
            if (keysUrl.includes(key) && !validateUrl(value)) {
              invalid = true;
            }
            if (keysHttpUrl.includes(key) && !validateHTTPUrl(value)) {
              invalid = true;
            }
            if (keysPhone.includes(key) && !validatePhone(value)) {
              invalid = true;
            }
          }
        }
      });
    });

    this.setState({
      invalid: invalid,
      countryAlert: countryAlert,
    });
  };

  handleCountryChange = country => {
    this.handleChange({ target: { value: country.value, name: 'country' } });
  };

  renderField = (option, index, i) => {
    const { selectedLanguage, t, ...question } = this.props;
    const { values } = this.state;

    if (option.name === 'country') {
      let selectedCountry = {};
      let selectedIso = values[index][i] ? values[index][i] : values[index];
      countriesList[selectedLanguage].map(country => {
        if (selectedIso['country'] === country.value) selectedCountry = country;
      });

      const eu_isoCode = eu_countriesAlpha.map(({ alpha }) => alpha);
      const eu_countries = countriesList[selectedLanguage].filter(({ value }) =>
        eu_isoCode.includes(value),
      );

      const alertStyle = this.state.countryAlert
        ? {
          control: styles => ({ ...styles, borderColor: 'red !important' }),
        }
        : {
          control: styles => ({ ...styles, borderColor: '#adadad !important' }),
        };
      return (
        <div key={option.name} className='country__select'>
          <Select
            value={selectedCountry}
            classNamePrefix={'countries'}
            options={eu_countries}
            onChange={this.handleCountryChange}
            isDisabled={question.readOnly}
            styles={alertStyle}
          />
          <span> {t('.country')} </span>
        </div>
      );
    } else {
      return (
        <Field
          key={option.name}
          name={option.name}
          component={renderInput}
          type='text'
          placeholder={option.label}
          id={option.name}
          onChange={this.handleChange}
          label={option.label}
          option={option}
          readOnly={question.readOnly}
          t={t}
        />
      );
    }
  };

  render() {
    const { options, t, goBack, handleSubmit } = this.props;
    return (
      <div className='text-form'>
        {options.map((option, idx) => {
          return option instanceof Array
            ? option.map((opt, index) => this.renderField(opt, idx, index))
            : this.renderField(option, idx);
        })}
        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    );
  }
}

TextFieldGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
};

export default TextFieldGroup;
