import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const LocalesAndCountriesContext = React.createContext({
  availableCountries: [],
  availableLocales: [],
});

const ALL_AVAILABLES_LOCALES_QUERY = gql`
  query availableLocalesAndCountries {
    availableLocales {
      isoCode
    }
    availablePolicyLocales {
      isoCode
    }
    availableCountries {
      isoCode
    }
  }
`;

const LocalesAndCountriesProvider = ({ children }) => {
  return (
    <Query query={ALL_AVAILABLES_LOCALES_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;
        const { availableCountries, availableLocales, availablePolicyLocales } = data;
        return (
          <LocalesAndCountriesContext.Provider
            value={{
              availableCountries,
              availableLocales,
              availablePolicyLocales,
            }}
          >
            {children}
          </LocalesAndCountriesContext.Provider>
        );
      }}
    </Query>
  );
};

export const LocalesAndCountriesConsumer = LocalesAndCountriesContext.Consumer;

LocalesAndCountriesProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default LocalesAndCountriesProvider;
