/* eslint no-shadow: off */
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import logo from '../../assets/img/logo-white.png'
import logOutAction from './actions'
import logOut from '../../assets/img/log-out.svg'
import avatar from '../../assets/img/user.svg'
import database from '../../assets/img/database.svg'
import LanguageSelection from '../Form/LanguageSelection'
import { withTranslation } from '../../providers/I18n'
import { AiOutlineContacts, AiOutlineUser } from 'react-icons/ai'

class AuthenticateAccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    this.logOut = this.logOut.bind(this)
    this.visible = this.visible.bind(this)
  }

  logOut() {
    const { history, logOutAction } = this.props
    logOutAction()
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('isSuperAdmin')
    history.replace('/')
  }

  visible() {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    const { t, isSuperAdmin } = this.props
    return (
      <div className='row'>
        <div className='columns'>
          <div className='main-header'>
            <div className='menu-left'>
              <Link to='/'>
                <img src={logo} className='logo' alt='Logo Admeet' />
              </Link>
              <NavLink className='nav-link' activeClassName='is-active' to='/cookie-policies'>
                {t('.my_cookies')}
              </NavLink>
              <NavLink className='nav-link' activeClassName='is-active' to='/privacy-policies'>
                {t('.my_policies')}
              </NavLink>
            </div>
            <div className='menu-right'>
              <Link to={'/consent-database'}>
                <button
                  className='btn_pill profil_btn is-white is-outlined'
                  type='button'
                  onClick={this.visible}
                >
                  <AiOutlineContacts size='1.5rem' className='mr-05' />
                  {t('.consent_database')}
                </button>
              </Link>

              {isSuperAdmin && (
                <>
                  <Link to={'/account'}>
                    <button
                      className='btn_pill profil_btn is-white is-outlined'
                      type='button'
                      onClick={this.visible}
                    >
                      <AiOutlineUser size='1.5rem' className='mr-05' />
                      <span> {t('.account')} </span>
                    </button>
                  </Link>
                </>
              )}
              <div className='language_selection'>
                <LanguageSelection />
              </div>
              <button onClick={this.logOut}>
                <img src={logOut} alt='log_out' title={t('.log_out')} className='button_logout' />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AuthenticateAccess.defaultProps = {
  history: {
    replace: () => {
      return 'Redirection'
    },
  },
  logOutAction: () => {
    return 'Log Out'
  },
}

AuthenticateAccess.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  logOutAction: PropTypes.func,
}

// CONNECT

const mapDispatchToProps = {
  logOutAction,
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('header'),
)(AuthenticateAccess)
