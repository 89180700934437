import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';

import {
  Color,
  hexToRgb,
  default_colors,
  Solver,
  LightenDarkenColor,
} from '../../../helpers/colors';
import Button from '../../../components/Button';

class ColorField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colors: props.colors || { primary: '#00548A' },
    };
  }

  static propTypes = {
    color: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
  };

  handleChangeComplete = color => {
    const rgb = color ? hexToRgb(color.hex) : hexToRgb('#00548A');

    const colortest = new Color(rgb[0], rgb[1], rgb[2]);
    const solvertest = new Solver(colortest);
    const result = solvertest.solve();

    const colors = {
      primary: color.hex,
      secondary: LightenDarkenColor(color.hex, 60, 80),
      tertiary: LightenDarkenColor(color.hex, 120, 240),
      filter: result.filter,
    };

    this.props.handleChange('colors', colors);
    this.setState({ colors: colors });
  };

  render() {
    const { colors } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Button
          className='reset_button btn btn_pill is-borderless has-text-blue'
          onClick={() => this.handleChangeComplete({ hex: '#00548A' })}
        >
          <i className='fas fa-eraser margin-right-10' />
          {t('.default')}
        </Button>
        <div className='color__picker'>
          <TwitterPicker
            width={'406px'}
            color={this.state.colors.primary}
            colors={default_colors}
            onChangeComplete={this.handleChangeComplete}
          />
          <div
            style={{
              backgroundColor: `${colors.primary ? colors.primary : '#00548A'}`,
              width: '50px',
              height: '50px',
              marginLeft: '12px',
              borderRadius: '0.5em',
              boxShadow: '0px 2px 4px 0px rgba(80, 80, 80, 0.5)',
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default ColorField;
