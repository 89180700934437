// @ts-nocheck
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import Loading from '../../components/Loading';
import { withTranslation } from '../../providers/I18n';
import './invoice.css';
import DataTable from 'react-data-table-component';
import pdfIcon from '../../assets/img/pdf-icon.svg';
import { AiFillCloseCircle } from 'react-icons/ai';

import DataTableExtensions from 'react-data-table-component-extensions';
import Button from 'src/components/Button';

class Invoice extends React.Component {
  getDate = data => {
    let year = new Date(data).getFullYear();
    let month = new Date(data).getMonth() + 1; // Add 1 because getMonth() is zero-based
    let date = new Date(data).getDate();
    let setMonth = month.toString().length == 1 ? '0' + month : month; // Pad with 0 if month is a single digit
    let setDate = date.toString().length == 1 ? '0' + date : date; // Pad with 0 if date is a single digit
    let fullDate = year + '-' + setMonth + '-' + setDate;

    return fullDate;
  };
  render() {
    const { t, userId } = this.props;
    return (
      <Query query={ALL_LICENSES} fetchPolicy={'network-only'} variables={{ userId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return `Error! ${error.message}`;

          const { transactions } = data;
          const dataWithIndex =
            transactions &&
            transactions.map((item, index) => ({
              ...item,
              index: String(index + 1).padStart(2, '0'), // Adding 1 to make the index 1-based instead of 0-based
            }));
          const columns = [
            {
              name: '#',
              sortable: true,
              width: '100px',
              selector: row => row['index'],
              cell: invoices => {
                return (
                  <>
                    <div className='invoice-circle'>{invoices?.index}</div>
                  </>
                );
              },
            },
            {
              name: t('.license_name'),
              sortable: true,
              selector: invoices => invoices && invoices['license'] && invoices['license'][0] && invoices['license'][0].name ? invoices && invoices['license'] && invoices['license'][0] && invoices['license'][0].name : '',
              width: '500px',
              cell: invoices => {
                return (
                  <>
                    <div className='flex-items-col'>
                      {invoices?.license.length === 2 ? (
                        <>
                          <span>{invoices.license[0].name}</span>
                          <span>{invoices.license[1].name}</span>
                        </>
                      ) : invoices?.license.length === 1 ? (
                        <span>{invoices.license[0].name}</span>
                      ) : (
                        '-'
                      )}
                    </div>
                  </>
                );
              },
            },
            // {
            //   name: t('.status'),
            //   sortable: true,
            //   cell: invoices => {
            //     return (
            //       <>
            //         <div> {invoices?.status?.toUpperCase()}</div>
            //       </>
            //     );
            //   },
            // },
            {
              name: t('.date'),
              sortable: true,
              width: '180px',
              selector: row => row?.createdAt ? this.getDate(row?.createdAt) : '',
              cell: invoices => {
                return (
                  <>
                    <div>{invoices?.createdAt ? this.getDate(invoices?.createdAt) : '-'}</div>
                  </>
                );
              },
            },
            {
              name: t('.amount'),
              sortable: true,
              width: '200px',
              selector: row => row?.total,
              cell: invoices => {
                return (
                  <>
                    <div> {invoices?.total}€</div>
                  </>
                );
              },
            },
            {
              name: 'Action',
              width: 150,
              cell: invoices => {
                return (
                  <>
                    <div style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                      {invoices?.stripeInvoicePdf ? (
                        <>
                          <Button
                            className='btn btn_pill is-blue'
                            published
                            onClick={() => {
                              window.open(invoices?.stripeInvoicePdf, '_blank', 'noopener,noreferrer');
                            }}
                          >
                            {t('.view_invoice')}
                          </Button>
                          {/* <a href={invoices?.stripeInvoicePdf} target='_blank' rel='noreferrer'>
                            <img src={pdfIcon} style={{ width: '24px' }} alt='' />
                          </a> */}
                        </>
                      ) : (
                        <AiFillCloseCircle fontSize={24} />
                      )}
                    </div>
                  </>
                );
              },
            },
          ];
          const tableData = {
            columns,
            data: dataWithIndex.reverse(),
            exportHeaders: true,
            export: false,
            print: false,
            // filter: false
          };

          return (
            <DataTableExtensions {...tableData}>
              <DataTable
                columns={columns}
                data={dataWithIndex}
                className='dataTable-card'
                noHeader
                pagination
                highlightOnHover
                defaultSortAsc={true} // Set it to false for descending order
              />
            </DataTableExtensions>
          );
        }}
      </Query>
    );
  }
}

const ALL_LICENSES = gql`
  query Transactions {
    transactions {
      id
      status
      total
      license {
        id
        name
        validFrom
        validTo
        product {
          id
          title
        }
        productTypeId
      }
      stripeInvoicePdf
      createdAt
      updatedAt
    }
  }
`;

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin, userId } = auth;
  return {
    isSuperAdmin,
    userId,
  };
};

export default compose(connect(mapStateToProps), withRouter, withTranslation('licenses'))(Invoice);
