const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = '.required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = '.mail_not_valid';
  }

  if (!values.password) {
    errors.password = '.required';
  } else if (
    !/^(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@?€#$%^&+=\-_!*(){}\[\]<>?\/,;:'"~]).*$/i.test(
      values.password,
    )
  ) {
    errors.password = '.must_contain';
  }

  if (!values.password_confirmation) errors.password_confirmation = '.required';
  else if (values.password_confirmation !== values.password)
    errors.password_confirmation = '.same_password';

  return errors;
};

export const validateSignIn = values => {
  const errors = {};

  if (!values.email) {
    errors.email = '.required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = '.mail_not_valid';
  }

  if (!values.password) {
    errors.password = '.required';
  }

  return errors;
};

export default validate;
