/* eslint react/jsx-indent-props: off */
/* eslint no-console: off */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import QuestionGroup from './group';

class CategoryQuestionsList extends React.Component {
  componentDidMount() {
    const unansweredQuestions = document.querySelector('[data-answered="false"]');
    if (unansweredQuestions) unansweredQuestions.scrollIntoView();
  }

  render() {
    const { category, ppStatus, policyType, locales, pp } = this.props;
    return (
      <div>
        <ul>
          {category.questionGroups.map((group, index) => (
            <QuestionGroup
              ppStatus={ppStatus}
              {...group}
              index={index}
              key={index}
              policyType={policyType}
              locales={locales}
              pp={pp}
            />
          ))}
        </ul>
      </div>
    );
  }
}

CategoryQuestionsList.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(CategoryQuestionsList);
