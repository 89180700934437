import React, { useEffect, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    CardElement
} from "@stripe/react-stripe-js";
import { Field, reduxForm } from 'redux-form';

import { renderInputField } from "src/components/Form/TextFieldGroup/fieldInput";
import logo from '../../../assets/img/logo-white.png'
import { withTranslation } from "src/providers/I18n";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

function CheckoutForm({ paysuccess, productName, email, monthlyPrice, payButton, inprogressButton, t, cardShow, includeTaxShow, validateCompanyData }) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [nameofCard, setnameofCard] = useState(null);
    const [isPrimary, setisPrimary] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!nameofCard) {
            setMessage(t('.name_on_card_error'))
            setTimeout(() => {
                setMessage('');
            }, 3000)
            return
        }
        var card = elements.getElement(CardNumberElement)
        inprogressButton(true);
        const result = await stripe.createToken(card, { name: nameofCard });
        if (result.error) {
            inprogressButton(false);
            setMessage(result.error.message)
            setTimeout(() => {
                setMessage('');
            }, 3000)
        } else {
            paysuccess(result.token.id, "newCardAdd", isPrimary)
        }
    };
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="new-main-wrapper">
                <div className={`backgroud-card ${!cardShow ? 'addborder' : ''}`} >
                    <img className='logo-card' src={logo} alt='Logo Admeet' />
                    <div className="new-card-header" style={{ maxWidth: 150, textAlign: 'left' }}>
                        {/* <p>{productName}</p>
                        <h4>€{monthlyPrice}</h4> */}
                        <p><span className="payment-label">{t('.price_label')}:</span>    €{validateCompanyData.amount}</p>
                        <p><span className="payment-label">{t('.vat_label')}:</span>      €{validateCompanyData.vatAmount}</p>
                        <p><span className="payment-label">{t('.total_label')}:</span>    €{validateCompanyData.total}</p>
                        {/* {includeTaxShow ?
                            <span className="pricetext">{t('.price_includes')}</span>
                            : null} */}

                    </div>
                </div>
                {cardShow ? (
                    <div className="new-card-body">
                        {/* <label>Email</label>
                    <Field
                        name='email'
                        type='text'
                        mandatory={false}
                        defaultValue={email}
                        value={email}
                        component={renderInputField}
                        input={{ disabled: true, defaultValue: email }}
                    /> */}
                        <div className="m-20-card">
                            <div>
                                <label>{t('.card_info')}</label>
                                <div className="cardNumberClass">
                                    <CardNumberElement id="card-element" options={{
                                        showIcon: true,
                                    }} />
                                </div>
                            </div>
                            <div className="d-flex-card">
                                <div className="set-width-exp">
                                    <div className="cardExpClass">
                                        <CardExpiryElement id="card-exp" options={{
                                            placeholder: "MM / YY"
                                        }} />
                                    </div>
                                </div>
                                <div className="set-width-exp">
                                    <div className="cardCvcClass">
                                        <CardCvcElement id="card-cvc" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <label>{t('.name_on_card')}</label>
                        <Field
                            name='nameofcard'
                            type='text'
                            onChange={(e) => {
                                setnameofCard(e.target.value);
                            }}
                            mandatory={false}
                            component={renderInputField}
                        />
                        <div className="isprimary-card">

                            <Field
                                type='checkbox'
                                component="input"
                                name="isPrimary"
                                className='inline-checkbox'
                                value={isPrimary}
                                onChange={(e) => {
                                    setisPrimary(!isPrimary)
                                }}
                            />
                            <label>{t('.primary_card')}</label>
                        </div>
                        <div className="error-card-message">{message ? message : null}</div>

                        {!payButton ? <button className="card-button" type="submit">{t('.pay_button')}</button> : <button className="card-button">{t('.in_progress_button')}</button>}

                    </div>
                ) : null}


            </div>
        </form>
    );
}

export default compose(
    reduxForm({
        form: 'onboarding_form_Card',
        enableReinitialize: true,
    }),
    withTranslation('account_onboarding'),
    withRouter,
)(CheckoutForm);