import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

const withTranslation = key => WrappedComponent => {
  const Component = ({ intl, ...rest }) => {
    const t = (suffix, values = {}) => intl.formatMessage({ id: `${key}${suffix}`, values });

    return <WrappedComponent {...rest} t={t} />;
  };

  Component.propTypes = {
    intl: intlShape.isRequired,
  };

  return injectIntl(Component);
};

export default withTranslation;
