import React, { Fragment } from 'react';

import RadioGroup from '../../../../components/Form/RadioGroupSimple';
import { dark_colors, light_colors } from '../../../../helpers/colors';
import Button from '../../../../components/Button';

const BannerColors = ({
  handleChange,
  handleThemeChange,
  selectedColor,
  selectedTheme,
  openPreview,
  t,
}) => {
  const onThemeChange = value => {
    let theme = value[0];
    let color = theme === 'dark' ? '#fbc531' : '#00548a';
    handleThemeChange(theme, color);
  };

  const RADIO_OPTIONS = [
    {
      id: 0,
      name: 'theme',
      value: 'light',
      label: t('.light'),
    },
    {
      id: 1,
      name: 'theme',
      value: 'dark',
      label: t('.dark'),
    },
  ];

  let colors = selectedTheme === 'light' ? dark_colors : light_colors;

  return (
    <Fragment>
      <div className='customisation__theme left_radio'>
        <h5> {t('.theme')} </h5>
        <RadioGroup options={RADIO_OPTIONS} onChange={onThemeChange} value={[selectedTheme]} />
      </div>
      <div className='customisation__colors'>
        <h5> {t('.colors')} </h5>
        <div className='cst__colors'>
          {colors.map(color => (
            <div
              key={color}
              className={`cst__colors__item ${color === selectedColor ? 'selected' : ''}`}
              onClick={() => handleChange(color, 'color')}
            >
              <div style={{ backgroundColor: color }} />
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default BannerColors;
