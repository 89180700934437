/* eslint no-underscore-dangle: off */
import 'cross-fetch/polyfill';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';

const getHttpLink = () => {
  const location = window.location.href;
  const url = location
    .replace('https://', '')
    .replace('http://', '')
    .split(/[/?#]/)[0];
  const domain = url.split('.')[0];
  const api_link = process.env.REACT_APP_BASE_URL.replace('##PREFIX##', domain);

  return createHttpLink({ uri: `${api_link}/graphql` });
};

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const locale = localStorage.getItem('locale') || 'fr_BE';

  const authorizationHeader = token ? `Bearer ${token}` : null;
  const headers = {
    'Accept-Language': locale,
    authorization: authorizationHeader,
    contentType: 'application/json',
  };

  Object.keys(headers).forEach(key => {
    if (headers[key] === null) {
      delete headers[key];
    }
  });
  const contextHeaders = { headers };
  operation.setContext(contextHeaders);
  return forward(operation);
});

const httpLink = getHttpLink();

const httpLinkWithAuthToken = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache({addTypename: false}).restore(window.__APOLLO_STATE__),
});

export default client;
