import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { FlagIcon } from 'react-flag-kit'
import GraphQlCalls from '../../graphql/product_queries/'
import { withNotifications, NotificationModel } from '../../providers/notifications/'
import { compose } from 'recompose'
import connect from 'react-redux/es/connect/connect'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import { formattedDate } from '../../helpers/date'
import { withTranslation } from '../../providers/I18n'
import { loadModal } from '../../redux/modal/action'

const DashboardProfile = ({
  status,
  title,
  customization,
  countries,
  locales,
  policyType,
  categories,
  createdAt,
  updatedAt,
  publishedAt,
  t,
  client,
  loadModal,
  match: {
    params: { id },
  },
  deletePrivacyPolicy,
  userId,
  isSuperAdmin,
  history,
  notificationsProvider,
  ...privacyPolicy
}) => {
  const [linkedPolicy, setLinkedPolicy] = useState(null)
  const [hasLinkedPolicy, setHasLinkedPolicy] = useState(false)
  const [webVersion, setWebVersion] = useState(false)
  const [bannerScript, setBannerScript] = useState(false)
  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    const { features, maxNoOfUsers } = privacyPolicy.license
    setIsOwner(userId === privacyPolicy.owner.id)
    features.map(item => {
      if (item.code === 'iframe' || item.code === 'web_version') {
        setWebVersion(true)
      }
      if (item.code === 'script') {
        setBannerScript(true)
      }
    })

    if (privacyPolicy.cookiePolicy) {
      setLinkedPolicy(privacyPolicy.cookiePolicy)
      setHasLinkedPolicy(!!privacyPolicy?.cookiePolicy)
    } else if (privacyPolicy.parentId) {
      client
        .query({
          query: GraphQlCalls.GET_PRODUCT_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            id: privacyPolicy.parentId,
            userId,
          },
        })
        .then(({ data }) => {
          setLinkedPolicy(data.product)
          setHasLinkedPolicy(!!data.product?.linkedPolicy)
        })
    }
  }, [])

  const openUserModal = () => {
    const { id, licenseId } = privacyPolicy
    const { maxNoOfUsers } = privacyPolicy.license
    loadModal({
      modalType: 'all_users_modal',
      content: {
        licenseId,
        hasLinkedPolicy,
        id,
        maxNoOfUsers,
        selectedLicense: privacyPolicy?.license
      },
      title: t('.all_users_title'),
      t,
    })
  }

  return (
    <div className='dashboard_element el_left'>
      <div
        className='row collapse align-bottom align-justify margin-bottom-40'
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '500px',
        }}
      >
        <div>
          <h6> {t('.name')} </h6>
          <h3 className='policy_title'>{title}</h3>
        </div>
        {status !== 'published' && (
          <div className='dashboard_profil__actions'>
            <Link to={`/${policyType}-policies/${id}/edit`}>
              <i className='fas fa-pen btn_icon font_20' style={{ color: '#001230' }} />
            </Link>
          </div>
        )}
      </div>
      <div className='dashboard_info'>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-tint margin-right-10'></i>
            <p> {t('.colors')}: </p>
          </div>
          <div
            className='dashboard_color'
            style={{
              backgroundColor: `${customization.colors ? customization.colors.primary : '#00548a'}`,
            }}
          />
        </div>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-flag margin-right-10'></i>
            <p> {t('.countries')}: </p>
          </div>
          <div>
            {countries.map(country => (
              <FlagIcon key={country} code={country.toUpperCase()} size={25} className='flag' />
            ))}
          </div>
        </div>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-comment margin-right-10'></i>
            <p>{t('.languages')}: </p>
          </div>
          <div>
            {locales.map(locale => (
              <strong key={locale}> {t(`.${locale}`)} </strong>
            ))}
          </div>
        </div>
        {(isOwner || isSuperAdmin) && (
          <div className='row collapse align-middle align-justify margin-top-10'>
            <div className='dashboard_info-title'>
              <i className='fas fa-users margin-right-10'></i>
              <p>{t('.all_users')}: </p>
            </div>
            <div>
              <i className='fas fa-eye' onClick={openUserModal}></i>
            </div>
          </div>
        )}
        {linkedPolicy && (
          <div className='row collapse align-middle align-justify margin-top-10'>
            <div className='dashboard_info-title'>
              <i className='fas fa-clone margin-right-10'></i>
              <p>{policyType === 'cookie' ? t('.associated_pp') : t('.associated_cp')}: </p>
            </div>
            <div>
              <Link
                to={`/${policyType === 'cookie' ? 'privacy' : 'cookie'}-policies/${linkedPolicy.id
                  }`}
                style={{ textDecoration: 'underline' }}
              >
                {' '}
                {linkedPolicy.title}{' '}
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className='dashboard_section'>
        <div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='fas fa-file-excel'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.version')}: </p>
              <strong> {privacyPolicy.version} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='far fa-file'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.creation')}: </p>
              <strong>{formattedDate(createdAt)} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='far fa-file-alt'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.modification')}: </p>
              <strong>{formattedDate(updatedAt)} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='fas fa-book'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p>{t('.publication')}: </p>
              <strong>{publishedAt ? formattedDate(publishedAt) : '-'}</strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='fas fa-building'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p>{t('.product_owner')}: </p>
              <strong>{privacyPolicy.owner.organization.name}</strong>
            </div>
          </div>
        </div>
      </div>
      {privacyPolicy.data && webVersion && (
        <div className='dashboard_section'>
          <div className='row collapse align-middle margin-top-20'>
            <Link
              to={`/${policyType}-policies/${privacyPolicy.id}/integration_process`}
              style={{ textDecoration: 'underline' }}
            >
              {policyType === 'privacy' && t('.integration_pp_process')}
              {policyType === 'cookie' && bannerScript && t('.integration_cp_process')}
              {policyType === 'cookie' && !bannerScript && t('.integration_cp_process_no_banner')}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  loadModal,
}

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin, userId } = auth
  return {
    userId,
    isSuperAdmin
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withApollo,
  withNotifications(),
  withTranslation('dashboard'),
)(DashboardProfile)
