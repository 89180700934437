import { passwordValidation, subDomainValidation, validateEmail, validateHTTPUrl, vatValidation } from "./form_validation";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = '.required';
  }
  if (!values.firstname) {
    errors.firstname = '.required';
  }
  if (!values.organization_name) {
    errors.organization_name = '.required';
  }
  if (!values.street) {
    errors.street = '.required';
  }
  if (!values.country) {
    errors.country = '.required';
  }
  if (!values.city) {
    errors.city = '.required';
  }
  if (!values.zip_code) {
    errors.zip_code = '.required';
  }

  if (values.vat && values.vat.length <= 0) {
    errors.vat = '.required';
  }
  if (!values.email) {
    errors.email = '.required';
  } else if (!validateEmail(values.email) && values.email) {
    errors.email = '.mail_not_valid';
  }

  if (!values.companycountryname) {
    errors.companycountryname = '.required';
  }

  if (!values.company_name) {
    errors.company_name = '.required';
  }
  if (!values.sub_domain) {
    errors.sub_domain = '.required';
  } else if (!subDomainValidation(values.sub_domain) && values.sub_domain) {
    errors.sub_domain = '.subdomain_error';
  }
  if (!values.userPassword) {
    errors.userPassword = '.required';
  }

  if (!passwordValidation(values.userPassword)) {
    errors.userPassword = '.password_error'
  }
  if (!values.userRePassword) {
    errors.userRePassword = '.required';
  }

  if (!passwordValidation(values.userRePassword)) {
    errors.userRePassword = '.password_error'
  }
  if (!values.vat) {
    errors.vat = '.required';
  } else if (values.vat && values.vat.length > 0 && !vatValidation(values.vat)) {
    errors.vat = '.vat_number_validation_error_country'
  }

  return errors;
};

export default validate;
