import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';

import UnAuthenticateAccess from '../Header/unAuthenticateAccess';
import Onboarding from '../../containers/Onboarding/CompanyOnboarding/';

const Registration = ({ component: Component, ...props }) => {
  return (
    <Query query={ORGANIZATION_QUERY} fetchPolicy={'network-only'}>
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;
        return (
          !isEmpty(data) &&
          (!data.organization.billing ? (
            <Fragment>
              <header className='row expanded shadow-wrapper'>
                <div className='columns'>
                  <div className='main-header-wrapper'>
                    <UnAuthenticateAccess />
                  </div>
                </div>
              </header>
              <Onboarding />
            </Fragment>
          ) : !data?.organization?.billing?.isOnboardingDone ? (
            <Fragment>
              <header className='row expanded shadow-wrapper'>
                <div className='columns'>
                  <div className='main-header-wrapper'>
                    <UnAuthenticateAccess />
                  </div>
                </div>
              </header>
              <Onboarding />
            </Fragment>
          ) : (
            <Component {...props} />
          ))
        );
      }}
    </Query>
  );
};

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      id
      name
      countryCode
      subDomainName
      settings
      type
      createdAt
      acceptedAt
      billing {
        id
        managerName
        companyName
        lastName
        vat
        address
        industry
        mailLanguage
        isOnboardingDone
        size
      }
    }
  }
`;

export default Registration;