import React, { Fragment } from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import withApollo from 'react-apollo/withApollo';
import { Link, withRouter } from 'react-router-dom';

import OnBoardingForm from './onboarding_form';
import CompanyForm from './compnay_form';

import { getNameWithIsoCode } from '../../../helpers/Countries';
import { withTranslation } from '../../../providers/I18n';
import { TitleComponent } from '../../../providers/pageTitles/TitleComponent';
import UnAuthenticateAccess from 'src/components/Header/unAuthenticateAccess';
import Button from 'src/components/Button';
import { IoMdCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';

import { AiOutlineFileAdd } from 'react-icons/ai';
import { AiOutlineArrowLeft, AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import { validateEmail, validateTextInput, validateUrl, passwordValidation } from './form_validation';
import './App.css'
// import PaymentFlow from '../PaymentFlow';
import Styles from './Styles'
import { Form, Field } from 'react-final-form';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from './cardUtils'
import DropdownItem from './dropdown';
import { organization_employees, organization_sector, plan_list } from './onboarding_data';
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import NotificationModel from 'src/providers/notifications/models/notification.model';
import { values } from 'lodash';
import Loading from 'src/components/Loading';
import { hideModal, loadModal } from 'src/redux/modal/action.js';
import { connect } from 'react-redux';
import AuthWrapper from 'src/components/Auth'
import PaymentForm from '../../../containers/Payment/index.jsx'
import arrowup from '../../../assets/img/arrow-up.png';
import arrowdown from '../../../assets/img/arrow-down.svg';
import PackageList from 'src/containers/PackageList';
import CardPackageList from 'src/containers/CardPackageList';
class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      firstname: '',
      organization_name: '',
      street: '',
      vat: '',
      zip_code: '',
      city: '',
      country: '',
      phone_number: '',
      sector: '',
      employees: '',
      hasVat: null,
      vatInfo: null,
      initValue: {},
      step: 0,
      activePackage: [],
      currentLan: 'fr',
      email: '',
      companycountryname: '',
      company_name: '',
      sub_domain: '',
      userPassword: '',
      userRePassword: '',
      checboxTerm: false,
      packDetails: {},
      paymentMode: 'yearly',
      errorDisplay: '',
      cardcvc: '',
      cardexpiry: '',
      cardemail: '',
      namecard: '',
      cardnumber: '',
      isPrivacyPolicy: false,
      isunderstandPrivacyPolicy: false,
      setVatValue: '',
      isLoading: true,
      companynextButton: false,
      payButton: false,
      vatCountryCode: "",
      paymentReponse: {},
      displayTotalPay: 0,
      arrowHide: false,
      firstStepArrowHide: false,
      validateCompanyData: {},
      arrowHideForpackage: false,
      arrowHideForpackageStepFive: false

    };
  }
  async componentDidMount() {
    try {
      var dataSet = sessionStorage.getItem('stateData');
      if (dataSet) {
        var jsonData = JSON.parse(dataSet);
        this.setState({
          name: jsonData.name,
          firstname: jsonData.firstname,
          organization_name: jsonData.organization_name,
          street: jsonData.street,
          vat: jsonData.vat,
          zip_code: jsonData.zip_code,
          city: jsonData.city,
          country: jsonData.country,
          phone_number: jsonData.phone_number,
          sector: jsonData.sector,
          employees: jsonData.employees,
          hasVat: jsonData.hasVat,
          vatInfo: jsonData.vatInfo,
          initValue: jsonData.initValue,
          step: jsonData.step,
          currentLan: jsonData.currentLan,
          email: jsonData.email,
          companycountryname: jsonData.companycountryname,
          company_name: jsonData.company_name,
          sub_domain: jsonData.sub_domain,
          userPassword: jsonData.userPassword,
          userRePassword: jsonData.userRePassword,
          checboxTerm: jsonData.checboxTerm,
          paymentMode: jsonData.paymentMode,
          errorDisplay: jsonData.errorDisplay,
          cardcvc: jsonData.cardcvc,
          cardexpiry: jsonData.cardexpiry,
          cardemail: jsonData.cardemail,
          namecard: jsonData.namecard,
          cardnumber: jsonData.cardnumber,
          isPrivacyPolicy: jsonData.isPrivacyPolicy,
          isunderstandPrivacyPolicy: jsonData.isunderstandPrivacyPolicy,
          setVatValue: jsonData.setVatValue,
          companynextButton: jsonData.companynextButton,
          payButton: jsonData.payButton,
          vatCountryCode: jsonData.vatCountryCode,
          paymentReponse: jsonData.paymentReponse,
          packDetails: jsonData.packDetails,
          displayTotalPay: jsonData.displayTotalPay ? jsonData.displayTotalPay : 0,
          validateCompanyData: jsonData.validateCompanyData ? jsonData.validateCompanyData : {}
        })

      }
      await this.fetchPackages();
      await this.validationcheck();
      await this.setState({ isLoading: false })

      var lan = localStorage.getItem('locale') || 'en';
      if (lan && lan.length > 0) {
        this.setState({ currentLan: lan })
      }
    }
    catch (e) {
      this.setState({ isLoading: false })
      sessionStorage.setItem('stateData', JSON.stringify(this.state))

    }
  }
  apiCall = (token) => {
    this.setState({ payButton: true })
    const { client, history } = this.props;
    const { name, firstname, organization_name, vat, sector, employees, hasVat, email, userPassword, userRePassword, phone_number, sub_domain, country, packDetails, company_name, paymentMode, companycountryname } = this.state;

    const {

      notificationsProvider,
      t

    } = this.props
    let address = this.buildAddress();
    var values = {
      stripeToken: token,
      email,
      name: company_name,
      password: userPassword,
      subDomainName: sub_domain,
      managerName: `${name} ${firstname}`,
      companyName: organization_name,
      countryCode: (country.slice(0, 2)).toLowerCase(),
      type: 'company',
      settings: "",
      address: address,
      industry: sector,
      size: employees,
      vatExist: hasVat === 'yes',
      packageId: packDetails.id,
      packageUuid: packDetails.data.uid,
      paymentMode,
      vat: vat ? vat : ''
    }
    client
      .mutate({
        mutation: PURCHASE_PACKAGE,
        variables: values,
      })
      .then(({ data }) => {
        const { purchasePackage } = data;
        this.setState({ paymentReponse: purchasePackage })
        const notification = new NotificationModel({
          title: '',
          message: t('.payment_success_message_pop'),
          type: 'success',
        })

        notificationsProvider.add(notification)
        this.setState({ step: 5 })
        // history.push('/');
        this.setState({ payButton: false })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        sessionStorage.setItem('stateData', JSON.stringify(this.state))

      })
      .catch(({ graphQLErrors }) => {
        this.setState({ payButton: false })
        sessionStorage.setItem('stateData', JSON.stringify(this.state))

        var errorDisplay = '';
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
          graphQLErrors[0].extensions.errors.map(item => {
            errorDisplay += ' ' + item.detail
          })
          const notification = new NotificationModel({
            title: '',
            message: errorDisplay,
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        else {
          this.setState({ payButton: false })
          const notification = new NotificationModel({
            title: '',
            message: t('.server_error'),
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        // throw error;
      });

  }
  validationApiCall = (setp) => {
    const { t } = this.props;
    this.setState({ companynextButton: true });
    sessionStorage.setItem('stateData', JSON.stringify(this.state))

    const { email, userPassword, sub_domain, country, companycountryname, packDetails, company_name, paymentMode } = this.state;
    const { client, history, notificationsProvider } = this.props;
    var values = {
      email,
      password: userPassword,
      name: company_name,
      subDomainName: sub_domain,
      countryCode: (companycountryname.slice(0, 2)).toLowerCase(),
      packageId: packDetails.id,
      packageUuid: packDetails.data.uid,
      paymentMode
    }
    client
      .mutate({
        mutation: VALIDATION_PACKAGE_1,
        variables: values,
      })
      .then(() => {
        this.setState({ organization_name: company_name })
        this.setState({ country: companycountryname })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        this.setState({ step: 2 });
        this.setState({ companynextButton: false });
        sessionStorage.setItem('stateData', JSON.stringify(this.state))

      })
      .catch(({ graphQLErrors }) => {
        var errorDisplay = '';
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
          graphQLErrors[0].extensions.errors.map(item => {
            errorDisplay += ' ' + item.detail
          })
          const notification = new NotificationModel({
            title: '',
            message: errorDisplay,
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        else {
          const notification = new NotificationModel({
            title: '',
            message: t('.server_error'),
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        this.setState({ companynextButton: false });
        sessionStorage.setItem('stateData', JSON.stringify(this.state))

      });
    this.setState({ companynextButton: false });
    sessionStorage.setItem('stateData', JSON.stringify(this.state))


  }
  ValidateCreditCardNumber(ccNum) {

    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
    }

    return isValid;
  }
  fetchPackages = () => {
    const { client } = this.props;
    client
      .query({
        query: GET_PACKAGES,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        if (data.packages) {
          var activePackage = data.packages.filter(item => item.isActive)
          this.setState({ activePackage });

          if (activePackage && activePackage.length > 0) {
            const {
              match: {
                params: { uid },
              },
            } = this.props;
            if (uid) {
              var packDetails = activePackage.find(item => item.uid === uid)
              this.setState({ packDetails })
            }
          }
        }
      })
  }
  inprogressButton = (value) => {
    this.setState({ payButton: value })
  }

  handleChange = e => {
    const {
      target: { value, name },
    } = e;
    this.setState({ [name]: value });
    sessionStorage.setItem('stateData', JSON.stringify(this.state))

  };
  stephandleChange = async (e) => {
    const {
      target: { value, name },
    } = e;
    await this.setState({ [name]: value });
    await this.validationcheck();
    sessionStorage.setItem('stateData', JSON.stringify(this.state))
  }
  validationcheck = () => {
    const { email, companycountryname, company_name, sub_domain, userPassword, userRePassword, checboxTerm } = this.state;
    var invalid = false;
    if (!validateEmail(email) || !validateTextInput(companycountryname) || !validateTextInput(company_name) || !sub_domain || !passwordValidation(userPassword) || !passwordValidation(userRePassword)) {
      invalid = true;
    }
    return invalid
  }
  handleVATChange = e => {
    const {
      target: { value },
    } = e;
    this.setState({ setVatValue: value, vatInfo: false })
    sessionStorage.setItem('stateData', JSON.stringify(this.state))

  };
  vatVerfication = (e) => {


    e.preventDefault();
    const { client, notificationsProvider, t, loadExampleModal, hideModal } = this.props;
    const { setVatValue } = this.state;


    const vatNumber = setVatValue.replace(/[\. ]+/g, '');
    if (vatNumber) {
      loadExampleModal({
        modalType: 'cookie_scan',
        content: t('.vat_number_scan_message'),
        title: t('.vat_number_title'),
      });
      client
        .query({
          query: CHECK_VAT_VALIDATION_QUERY,
          variables: {
            number: vatNumber,
          },
        })
        .then(({ data }) => {
          hideModal();
          const { checkVat } = data;
          if (checkVat) {
            this.setState({
              vat: vatNumber,
              vatInfo: true,
            });
            this.createAddressWithVat(checkVat, vatNumber);
            sessionStorage.setItem('stateData', JSON.stringify(this.state))

          }
          else {
            loadExampleModal({
              modalType: 'vat_not_found_model',
              content: t('.vat_number_title'),
              title: t('.vat_number_title'),
            });
            this.setState({
              vatInfo: false,
            });
            sessionStorage.setItem('stateData', JSON.stringify(this.state))

          }
        })
        .catch(error => {
          this.setState({
            vatInfo: false,
          });
          sessionStorage.setItem('stateData', JSON.stringify(this.state))

        });
    }
  }

  toggleVAT = e => {
    const {
      target: { value },
    } = e;
    this.setState({
      hasVat: value,
    });
    this.setState({
      vat: '',
      vatInfo: false,
      country: ""

    });
    sessionStorage.setItem('stateData', JSON.stringify(this.state))

  };
  stepHandleSubmit = e => {
    e.preventDefault();
  }

  handleSubmit = e => {
    const { t } = this.props;

    e.preventDefault();
    const { client, history, notificationsProvider } = this.props;
    const { name, firstname, organization_name, vat, sector, employees, hasVat, packDetails, paymentMode } = this.state;

    let address = this.buildAddress();

    const values = {
      managerName: `${name} ${firstname}`,
      companyName: organization_name,
      address: address,
      industry: sector,
      size: employees,
      vatExist: hasVat === 'yes',
      packageId: packDetails.id,
      packageUuid: packDetails.data.uid,
      paymentMode
    };

    if (vat) values.vat = vat;


    // const { email, userPassword, sub_domain, country, companycountryname, packDetails, company_name, paymentMode } = this.state;
    // const { client, history, notificationsProvider } = this.props;
    client
      .mutate({
        mutation: VALIDATION_PACKAGE_2,
        variables: values,
      })
      .then(({ data }) => {
        const { validateCompanyData } = data
        this.setState({ step: 3 })
        this.setState({ displayTotalPay: validateCompanyData?.total })
        this.setState({ validateCompanyData: validateCompanyData })

        sessionStorage.setItem('stateData', JSON.stringify(this.state))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      })
      .catch(({ graphQLErrors }) => {
        var errorDisplay = '';
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
          graphQLErrors[0].extensions.errors.map(item => {
            errorDisplay += ' ' + item.detail
          })
          const notification = new NotificationModel({
            title: '',
            message: errorDisplay,
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        else {
          const notification = new NotificationModel({
            title: '',
            message: t('.server_error'),
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        // throw error;
      })
    // client
    //   .mutate({
    //     mutation: CREATE_BILLING_MUTATION,
    //     variables: values,
    //   })
    //   .then(() => {
    //     history.push('/');
    //   })
    //   .catch(error => {
    //     throw error;
    //   });

    e.preventDefault();
  };

  createAddressWithVat = (vatInfo, vat) => {
    if (vatInfo !== null) {
      let addressToReturn = {};
      addressToReturn.vat = vat;
      addressToReturn.organization_name = vatInfo.name;
      addressToReturn.country = getNameWithIsoCode(vatInfo.countryCode);

      let address = vatInfo.address.split('\n');
      addressToReturn.street = address[0]?.replace(',', '');

      let cityAndZip = address[1]?.split(' ');
      addressToReturn.zip_code = cityAndZip?.[0];
      addressToReturn.city = cityAndZip?.[1];

      this.setState({
        vat: addressToReturn.vat,
        vatCountryCode: vatInfo?.countryCode,
        organization_name: addressToReturn.organization_name,
        country: addressToReturn.country,
        street: addressToReturn.street,
        zip_code: addressToReturn.zip_code,
        city: addressToReturn.city,
        vatInfo: vatInfo ? vatInfo : null,
        initValue: addressToReturn,
      });
      sessionStorage.setItem('stateData', JSON.stringify(this.state))

    } else {
      this.setState({
        vat: vat,
        vatInfo: true,
      });
      sessionStorage.setItem('stateData', JSON.stringify(this.state))

    }
  };
  buildAddress = () => {
    const { street, zip_code, city, phone_number, country, vatCountryCode, companycountryname, hasVat } = this.state;

    let address = {
      street,
      zip_code,
      city,
      phone_number,
      country,
      country_code: (vatCountryCode && hasVat === 'yes') ? (vatCountryCode?.slice(0, 2).toLowerCase()) : (hasVat === 'no') ? (country?.slice(0, 2)).toLowerCase() : (companycountryname?.slice(0, 2)).toLowerCase(),
    };

    return address;
  };
  Totalpersonalisation = (item, code) => {

    var total = false;
    if (code === 'Personalisation') {
      item.cookieFeatures.forEach(element => {
        if (element.feature_group.code === 'personalisation') {
          total = true
        }
      });
      item.policyFeatures.forEach(element => {
        if (element.feature_group.code === 'personalisation') {
          total = true
        }
      });
    }
    else if (code === 'format_web_version') {
      var webCookieFormat = false;
      var webPrivacyFormat = false;
      item.cookieFeatures.forEach(element => {
        if (element.code === 'format_web_version') {
          webCookieFormat = true
        }
      });
      item.policyFeatures.forEach(element => {
        if (element.code === 'format_web_version') {
          webPrivacyFormat = true
        }
      });
      total = webCookieFormat || webPrivacyFormat
    }
    else if (code === 'format_pdf') {
      var pdfCookieFormat = false;
      var pdfPrivacyFormat = false;
      item.cookieFeatures.forEach(element => {
        if (element.code === 'format_pdf') {
          pdfCookieFormat = true
        }
      });
      item.policyFeatures.forEach(element => {
        if (element.code === 'format_pdf') {
          pdfPrivacyFormat = true
        }
      });
      total = pdfCookieFormat && pdfPrivacyFormat
    }
    else if (code === 'banner_script') {
      item.cookieFeatures.forEach(element => {
        if (element.code === 'banner_script') {
          total = true
        }
      });
    }
    else if (code === 'banner_consents_db') {
      item.cookieFeatures.forEach(element => {
        if (element.code === 'banner_consents_db') {
          total = true
        }
      });
    }
    else if (code === 'personalisation_banner') {
      item.cookieFeatures.forEach(element => {
        if (element.code === 'personalisation_banner') {
          total = true
        }
      });

    }
    else if (code === 'Graphic customisation') {
      var personalisationCookie = false;
      var personalisationPolicy = false;

      item.cookieFeatures.forEach(element => {
        if (element.code === 'personalisation_cookie_policy') {
          personalisationCookie = true
        }
      });
      item.policyFeatures.forEach(element => {
        if (element.code === 'personalisation_privacy_policy') {
          personalisationPolicy = true
        }
      });
      total = personalisationCookie && personalisationPolicy;

    }
    else if (code === "versioning") {
      if (item.isCookie && item.isPolicy) {
        if (item.cIsVersioning && item.pIsVersioning) {
          total = true;
        }
        else {
          total = false;
        }
      }
      else if (item.isCookie) {
        if (item.cIsVersioning) {
          total = true;
        }
        else {
          total = false;
        }
      }
      else if (item.isPolicy) {
        if (item.pIsVersioning) {
          total = true;
        }
        else {
          total = false;
        }
      }
      else {
        total = false
      }
    }

    return total;
  }
  pageViewCount = (item) => {
    var count = (item.isCookie && item.isPolicy) ? Math.min(item.cTrafficBucket, item.pTrafficBucket) : item.isCookie ? item.cTrafficBucket : item.isPolicy ? item.pTrafficBucket : '';
    if (count) {
      // count = new Intl.NumberFormat('fr-FR').format(count)
    }

    return count >= 10000 ? `${(count / 1000)}k` : count.toString();
  }
  languageCount = (item, value) => {
    const { t } = this.props;

    // console.log("---4050s---item", item)
    if (value === 'langage') {
      var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLang, item.pNoOfLang) : item.isCookie ? item.cNoOfLang : item.isPolicy ? item.pNoOfLang : '';
      count = count >= 2 ? `${t('.up_to')} ${(count)}` : count.toString();;
      return count;
    }
    if (value === 'legislations') {
      var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLegis, item.pNoOfLegis) : item.isCookie ? item.cNoOfLegis : item.isPolicy ? item.pNoOfLegis : '';
      return count;
    }
    if (value === "users") {
      var count;

      if (!item.cNoOfUsers && item.cNoOfUsers !== 0 && !item.pNoOfUsers && item.pNoOfUsers !== 0) {
        count = 'Unlimited'
      }
      else if (!item.cNoOfUsers && item.cNoOfUsers === 0 && !item.pNoOfUsers && item.pNoOfUsers === 0) {
        count = '1'
      }
      else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers !== item.cNoOfUsers)) {
        count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
      }
      else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers === item.cNoOfUsers)) {
        count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
      }
      else if (item.cNoOfUsers || item.pNoOfUsers || item.pNoOfUsers === 0 || item.cNoOfUsers === 0) {
        if (item.cNoOfUsers || item.cNoOfUsers === 0) {
          count = item.cNoOfUsers + 1
        }
        if (item.pNoOfUsers || item.pNoOfUsers === 0) {
          count = item.pNoOfUsers + 1
        }
      }
      else {
        count = "1"
      }



      return count;
    }
  }
  dateSetChange = (value) => {
    if (!value) {
      return '';
    }
    var currentLanguage = localStorage.getItem('locale');

    var date = new Date(value)
    return date.toLocaleString(currentLanguage, { dateStyle: 'full' })
  }
  subDomainUrl = (subDomainName) => {
    if (!subDomainName) {
      return '-'
    }
    return process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', subDomainName)
  }
  taxCount = (value, per) => {
    var taxcount = 0;
    if (value && per && (!this.state.vat || this.state.vatCountryCode === 'BE')) {
      taxcount = (Number(value) * Number(per)) / 100;
      return (Number(value) + taxcount).toFixed(2);
    }
    else {
      return (Number(value)).toFixed(2)
    }
  }
  loadPaymentModalWithAddCard = (token) => {
    const { loadExampleModal, t } = this.props;
    this.setState({ payButton: false })
    loadExampleModal({
      modalType: 'confirm_payment_model',
      content: {
        dataApiset: () => {
          this.apiCall(token);
        }
      },
      t,
      title: '',
    })
  }

  sectorFind = (sectorValue) => {
    const { t } = this.props;
    var valueFind = organization_sector.find(item => item.value === sectorValue);
    if (valueFind) {
      return t(`${valueFind.label}`);

    }
    else {
      return '-';
    }
  }
  employeeFind = (sectorValue) => {
    const { t } = this.props;
    var valueFind = organization_employees.find(item => item.value === sectorValue);
    if (valueFind) {
      return t(`${valueFind.label}`);

    }
    else {
      return '-';
    }
  }
  render() {
    const { arrowHide, firstStepArrowHide, displayTotalPay, validateCompanyData, email, sub_domain, paymentReponse, hasVat, isLoading, payButton, organization_name, country, initValue, companynextButton, name, sector, employees, vatInfo, phone_number, isPrivacyPolicy, isunderstandPrivacyPolicy, step, activePackage, currentLan, vat, companycountryname, arrowHideForpackageStepFive, company_name, city, zip_code, street, firstname, packDetails, paymentMode, userPassword, userRePassword, arrowHideForpackage } = this.state;
    const { t } = this.props;
    return (
      <>
        <AuthWrapper>
          <Fragment>
            <header className='row expanded shadow-wrapper'>
              <div className='columns'>
                <div className='main-header-wrapper'>
                  <UnAuthenticateAccess />
                </div>
              </div>
            </header>
            {isLoading ? <Loading /> : (
              <>
                <main>
                  <div className='main_content checkbox-border'>
                    {step === 0 ? (
                      <>
                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <div className='row'>
                            <div className='column'>
                              <h1>{t('.choose_your_desc_package')}</h1>
                            </div>
                          </div>

                        </div>
                        <div className="margin-bottom-40">
                          <div className="row package-box margin-bottom-40">
                            {activePackage && activePackage.length > 0 ? (
                              <>
                                <CardPackageList activePackage={activePackage} paymentMode={paymentMode} currentLan={currentLan} packDetails={packDetails} onClickPackage={(item) => {
                                  this.setState({ packDetails: item })
                                  sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                }} />
                              </>
                            ) : null}
                          </div>
                          <div className='row mb-10'>
                            <div className='dropdown-wrapper'>
                              <DropdownItem
                                label={t('.payment-mode')}
                                name='paymentMode'
                                placeholder={t('.payment-mode-placeholder')}
                                handleChange={this.stephandleChange}
                                options={plan_list}
                                value={paymentMode}
                                t={t}
                                arraowShow={true}

                              />
                            </div>
                          </div>
                          {activePackage && activePackage.length > 0 ? (
                            <div className="row package-box  package-box-inner-wrapp-section margin-bottom-20">
                              <div className='row margin-bottom-20' style={{ width: "100%" }}>
                                <div className='column arrowset' onClick={() => {
                                  this.setState({ firstStepArrowHide: !firstStepArrowHide })
                                }}>
                                  <h2>{t('.show_details_of_pack_feature')}</h2>
                                  <img src={firstStepArrowHide ? arrowdown : arrowup} />
                                </div>
                              </div>
                              {!firstStepArrowHide ? (
                                <div className='text-form auth_form table-new-user'>
                                  <table className='unstriped'>
                                    <PackageList activePackage={activePackage} />
                                  </table>


                                </div>
                              ) : null}

                            </div>
                          ) : null}


                        </div>





                        <div className='row'>
                          <div className='button-end'>
                            <Button
                              className='btn btn_pill is-blue right'
                              type='submit'
                              onClick={() => {
                                const {

                                  notificationsProvider,

                                } = this.props

                                if (!packDetails.id) {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.select_plan_error'),
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                  return
                                }
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                this.setState({ step: 1 })

                              }}
                            >
                              {t('.nextstep')}
                            </Button>
                          </div>
                        </div>


                      </>
                    ) : null}
                    {step === 1 ? (
                      <>

                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <TitleComponent title={t('.account_confirmation')} />
                          <div className='row'>
                            <div className='column'>
                              <h2>{t('.filldetail')}</h2>
                            </div>
                          </div>

                        </div>
                        <div className="margin-bottom-40">
                          <div className='row'>
                            <CompanyForm
                              handleSubmit={this.stepHandleSubmit}
                              handleChange={this.stephandleChange}
                              countryhandleChange={(e) => {
                                const {
                                  target: { value, name },
                                } = e;
                                this.setState({ [name]: value });
                                sessionStorage.setItem('stateData', JSON.stringify(this.state))

                              }}

                              initialValues={{
                                sub_domain: this.state.sub_domain,
                                company_name: this.state.company_name,
                                companycountryname: this.state.companycountryname,
                                email: this.state.email,
                                userPassword: this.state.userPassword,
                                userRePassword: this.state.userRePassword
                              }}
                              t={t}
                            />

                          </div>
                          <div className='row mb-10'>
                            <div>
                              <input
                                type='checkbox'
                                name="checbox-term"
                                className='inline-checkbox'
                                checked={isPrivacyPolicy}
                                onChange={() => {
                                  this.setState({ isPrivacyPolicy: !isPrivacyPolicy })
                                  sessionStorage.setItem('stateData', JSON.stringify(this.state))

                                }}
                              />
                            </div>
                            <div className='trems-wrapper' >
                              <p className='terms'>{t('.term-read')}</p>
                              <a target='_blank' href={currentLan === 'en' ? 'https://www.admeet.eu/en/terms-and-conditions/' : "https://www.admeet.eu/conditions-generales-admeet/"}> {t('.terms')} </a>
                              {/* <div className='a-tag' >
                                {t('.desc-term')}
                                <a target='_blank' href={currentLan === 'en' ? 'https://www.admeet.eu/en/admeet-privacy-policy/' : "https://www.admeet.eu/politique-de-vie-privee-admeet/"}>{t('.desc-term-privacy-policy')}</a>
                              </div>
                              <div className='a-tag' >
                                {t('.login-already')}
                                <a href='/account-verification'>{t('.click-here')} </a>
                              </div>
                              <div className='a-tag' >
                                {t('.vat_belgian_message')}
                              </div> */}
                            </div>

                          </div>
                          <div className='row mb-10'>
                            <div>
                              <input
                                type='checkbox'
                                name="checbox-policy"
                                className='inline-checkbox'
                                checked={isunderstandPrivacyPolicy}
                                onChange={() => {
                                  this.setState({ isunderstandPrivacyPolicy: !isunderstandPrivacyPolicy })
                                  sessionStorage.setItem('stateData', JSON.stringify(this.state))

                                }}
                              />
                            </div>
                            <div className='trems-wrapper' >
                              <p className='terms'>{t('.read_privacy_policy')}</p>
                              <a target='_blank' href={currentLan === 'en' ? 'https://www.admeet.eu/en/admeet-privacy-policy/' : "https://www.admeet.eu/politique-de-vie-privee-admeet/"}> {t('.click_here_link')}.</a>
                              {/* <div className='a-tag' >
                                {t('.desc-term')}
                                <a target='_blank' href={currentLan === 'en' ? 'https://www.admeet.eu/en/admeet-privacy-policy/' : "https://www.admeet.eu/politique-de-vie-privee-admeet/"}>{t('.desc-term-privacy-policy')}</a>
                              </div>
                              <div className='a-tag' >
                                {t('.login-already')}
                                <a href='/account-verification'>{t('.click-here')} </a>
                              </div>
                              <div className='a-tag' >
                                {t('.vat_belgian_message')}
                              </div> */}
                            </div>

                          </div>

                        </div>


                        <div className='bottom-wrapper'>

                          <div className='row'>
                            <Button
                              className='btn btn_pill'
                              type='button'
                              onClick={async () => {
                                await this.setState({ step: 0 })
                                // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                await sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                              }}
                            >
                              <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5 12H3.66998" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              </svg></span>
                              <span>{t('.back_to_previous_page')}</span>

                            </Button>
                            <Button
                              className='btn btn_pill is-blue'
                              type='submit'
                              disabled={this.validationcheck() || !isPrivacyPolicy || !isunderstandPrivacyPolicy || companynextButton}
                              onClick={() => {
                                const {

                                  notificationsProvider,

                                } = this.props
                                if (!isPrivacyPolicy || !isunderstandPrivacyPolicy) {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.term_condition_error'),
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                  return
                                }

                                if (userPassword !== userRePassword) {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.password_reset_not_match'),
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                  return
                                }
                                if (!packDetails.id) {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.select_plan_error'),
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                  return
                                }
                                if (!this.validationcheck() && packDetails && packDetails.id) {
                                  this.validationApiCall('2')

                                }
                              }}
                            >
                              {t('.nextstep')}
                            </Button>
                          </div>

                        </div>




                      </>
                    ) : null}
                    {step === 2 ? (
                      <>
                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <TitleComponent title={t('.account_confirmation')} />
                          <div className='row'>
                            <div className='column'>
                              <h2>{t('.company_details')}</h2>
                            </div>
                          </div>
                          <OnBoardingForm
                            initialValues={hasVat === 'yes' ? {
                              vat: this.state.vat,
                              organization_name: this.state.organization_name,
                              street: this.state.street,
                              zip_code: this.state.zip_code,
                              city: this.state.city,
                              country: this.state.initValue && this.state.initValue.country ? this.state.initValue.country : this.state.country,
                              phone_number: this.state.phone_number,
                              firstname: this.state.firstname,
                              name: this.state.name,
                            } : {
                              organization_name: this.state.organization_name,
                              street: this.state.street,
                              zip_code: this.state.zip_code,
                              city: this.state.city,
                              phone_number: this.state.phone_number,
                              firstname: this.state.firstname,
                              name: this.state.name,
                              country: this.state.country,
                            }}
                            vatVerfication={this.vatVerfication}
                            handleSubmit={this.handleSubmit}
                            handleChange={this.handleChange}
                            toggleVAT={this.toggleVAT}
                            handleVATChange={this.handleVATChange}
                            sector={sector}
                            employees={employees}
                            hasVat={hasVat}
                            isVatValid={vatInfo}
                            countryhandleChange={(e) => {
                              const {
                                target: { value, name },
                              } = e;
                              this.setState({ [name]: value });
                              sessionStorage.setItem('stateData', JSON.stringify(this.state))

                            }}
                            backToPreviousPage={async () => {
                              await this.setState({ step: 1 });

                              await sessionStorage.setItem('stateData', JSON.stringify(this.state))
                              await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            }}
                            t={t}
                          />
                        </div>
                        {/* {t('.back_to_selection')} */}

                        {/* <div>
                          <div className='row'>
                            <div className='button-end'>
                              <Button
                                className='btn btn_pill'
                                onClick={async () => {
                                  await this.setState({ step: 1 });

                                  await sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                  await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                }}
                              >
                                {t('.back_to_previous_page')}
                              </Button>
                            </div>
                          </div>

                        </div> */}
                      </>
                    ) : null}
                    {step === 3 ? (
                      <>
                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <TitleComponent title={t('.account_confirmation')} />
                          <div className='row'>
                            <div className='column'>
                              <h1 className='page-title'>{t('.below_details')}</h1>
                              <h2 className='page-sub-title'>{t('.company_details')}</h2>
                            </div>
                          </div>
                        </div>


                        <div className="detail-wrapper row">
                          <div className="detail-wrapper-left">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3 className='detail-section-title'>{t('.vat')}</h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.vat_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{vat ? vat : t('.no_vat_number')}</p></div>
                              </div>
                            </div>

                            <div className="margin-bottom-20 account-owner">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3 className='detail-section-title'>{t('.account_tenant')}</h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.firstname')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{firstname ? firstname : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.lastname')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{name ? name : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.sub_domain')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text subdomain'><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                  <g clip-path="url(#clip0_125_1763)">
                                    <path d="M10.1673 4.83484C9.72918 2.13666 8.69583 0.242188 7.49328 0.242188C6.29073 0.242188 5.25738 2.13666 4.81927 4.83484H10.1673ZM4.59266 7.73547C4.59266 8.40624 4.62891 9.04981 4.69237 9.66922H10.2912C10.3546 9.04981 10.3909 8.40624 10.3909 7.73547C10.3909 7.0647 10.3546 6.42112 10.2912 5.80172H4.69237C4.62891 6.42112 4.59266 7.0647 4.59266 7.73547ZM14.4034 4.83484C13.5393 2.78326 11.7898 1.19698 9.62947 0.556422C10.3667 1.57768 10.8743 3.11562 11.1402 4.83484H14.4034ZM5.35407 0.556422C3.19673 1.19698 1.44427 2.78326 0.583146 4.83484H3.84635C4.10922 3.11562 4.61683 1.57768 5.35407 0.556422ZM14.7267 5.80172H11.2611C11.3245 6.43623 11.3608 7.08585 11.3608 7.73547C11.3608 8.38509 11.3245 9.03471 11.2611 9.66922H14.7237C14.8899 9.04981 14.9835 8.40624 14.9835 7.73547C14.9835 7.0647 14.8899 6.42112 14.7267 5.80172ZM3.62578 7.73547C3.62578 7.08585 3.66204 6.43623 3.72549 5.80172H0.259848C0.0966875 6.42112 0 7.0647 0 7.73547C0 8.40624 0.0966875 9.04981 0.259848 9.66922H3.72247C3.66204 9.03471 3.62578 8.38509 3.62578 7.73547ZM4.81927 10.6361C5.25738 13.3343 6.29073 15.2288 7.49328 15.2288C8.69583 15.2288 9.72918 13.3343 10.1673 10.6361H4.81927ZM9.63249 14.9145C11.7898 14.274 13.5423 12.6877 14.4064 10.6361H11.1432C10.8773 12.3553 10.3697 13.8933 9.63249 14.9145ZM0.583146 10.6361C1.44729 12.6877 3.19673 14.274 5.35709 14.9145C4.61985 13.8933 4.11224 12.3553 3.84635 10.6361H0.583146Z" fill="#0028E6" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_125_1763">
                                      <rect width="14.9866" height="15.47" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg></span>
                                  <span>{this.subDomainUrl(sub_domain)}</span></p></div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.sector')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{this.sectorFind(sector)}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.employee_rate')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{this.employeeFind(employees)}</p></div>
                              </div>
                              {/* <div className="row">
                                <div className="columns small-2 large-6">{t('.email_address')}</div>
                                <div className="columns small-4 large-6">{email ? email : '-'}</div>
                              </div> */}
                              {/* <div className="row">
                      <div className="columns small-2 large-3">Last name</div>
                      <div className="columns small-4 large-3">{name}</div>
                    </div> */}

                            </div>
                          </div>
                          <div className="detail-wrapper-right">








                            <div className="margin-bottom-20 billing-address">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3 className='detail-section-title'>{t('.company_billing_info')}</h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.company_name')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{company_name ? company_name : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.street_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{street ? street : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.zip_code')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{zip_code ? zip_code : ''}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.city')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{city ? city : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.country')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{country ? country : "-"}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.phone_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{phone_number ? phone_number : "-"}</p></div>
                              </div>
                            </div>



                            {/* <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3 className='detail-section-title'>{t('.company_details')}</h3>
                                </div>
                              </div>
                              <div className="row mb-10">
                                <div className="columns small-2 large-6"><p className='field-label'>{t('.sector')}</p></div>
                                <div className="columns small-4 large-6"><p className='field-text'>{this.sectorFind(sector)}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6"><p className='field-label'>{t('.employee_rate')}</p></div>
                                <div className="columns small-4 large-6"><p className='field-text'>{this.employeeFind(employees)}</p></div>
                              </div>
                            </div> */}




                          </div>
                          <div >

                          </div>
                          {/* <div className="detail-wrapper-right">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.company_billing_info')}</h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6">{t('.company_name')}</div>
                                <div className="columns small-4 large-6">{company_name ? company_name : ''}</div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6">{t('.street_number')}</div>
                                <div className="columns small-4 large-6">{street ? street : '-'}</div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6">{t('.zip_code')}</div>
                                <div className="columns small-4 large-6">{zip_code ? zip_code : ''}</div>
                              </div>  <div className="row">
                                <div className="columns small-2 large-6">{t('.city')}</div>
                                <div className="columns small-4 large-6">{city ? city : '-'}</div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6">{t('.country')}</div>
                                <div className="columns small-4 large-6">{country ? country : "-"}</div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-6">{t('.phone_number')}</div>
                                <div className="columns small-4 large-6">{phone_number ? phone_number : "-"}</div>

                              </div>

                            </div>
                          </div> */}
                        </div>

                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <div className='row'>
                            <div className='column'>
                              <h1>{t('.pack_detail')}</h1>
                            </div>
                          </div>
                        </div>

                        <div className="detail-wrapper row ">
                          <div className="detail-wrapper-left">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-4">{t('.frequency_of_payments')}</div>
                                <div className="columns small-4 large-8">{paymentMode === 'monthly' ? t('.monthly_discount_price') : t('.yearly_discount_price')}</div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div id={"packpaymentdetails"} className='marginBottom-50' >
                          <div className='row'>
                            <div className=" package-box  medium-4  margin-bottom-20">
                              <div className="package-inner-sec-main" style={{ paddingBottom: "15px", justifyContent: "center" }}>
                                <div className="card-payment-section-inner-wrapper-new">
                                  <div className="card back-ground-blue">

                                    <h4 className='package-card-title'>{currentLan === 'en' ? packDetails.name[0].en : packDetails.name[1].fr} </h4>
                                    <span className="package-name">{currentLan === 'en' ? packDetails.description[0].en : packDetails.description[1].fr} </span>
                                    {/* <p className="font-color">€{validateCompanyData?.amount} {`+  €`}{validateCompanyData?.isTax ? validateCompanyData?.vatAmount : "0.00"}  {"VAT"}</p> */}

                                    {paymentMode === 'monthly' ? <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total).toFixed(2) : "0.00"} {t('.per_month_per_website')} </p> : <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total).toFixed(2) : "0.00"} {t('.per_year_per_website')} </p>}
                                    {/* {paymentMode === 'monthly' ? <p className="font-color">€{packDetails.monthlyPrice} </p> : <p className="font-color">€{packDetails.yearlyPrice} {t('.per_year_per_website')} </p>} */}
                                    {validateCompanyData?.isTax ? (
                                      <span className="pricetext">{t('.price_includes')}</span>
                                    ) : null}

                                    <span className="pricetext" style={{ textDecoration: "underline", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                                      this.setState({ arrowHideForpackage: !arrowHideForpackage })
                                    }}>
                                      <div style={{ marginRight: 5 }}> {t('.for_package_details')}</div>
                                      {arrowHideForpackage ? <AiFillCaretDown size={20} /> : <AiFillCaretUp size={20} />}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* ) : null} */}


                            </div>
                            {/* {arrowHideForpackage ? ( */}
                            <div className="columns medium-8 left-dark-sec  margin-bottom-20">
                              <div className='text-form '>
                                <table className='unstriped table-new-user'>
                                  <PackageList activePackage={[packDetails]} />
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* ) : null} */}




                          {/* 
                          <div>
                            <div className='row'>
                              <Button
                                className='btn btn_pill is-blue'
                                onClick={() => {
                                  this.setState({ step: 1 });
                                  sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                }}
                              >

                                <AiOutlineArrowLeft className='icon-class' />
                                {t('.back_to_company')}
                              </Button>
                            </div>

                          </div> */}
                        </div>

                        <div className='bottom-wrapper'>

                          <div className='row'>
                            <Button
                              className='btn btn_pill'
                              type='button'
                              onClick={async () => {
                                await this.setState({ step: 2 })
                                await sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                              }}
                            >
                              <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5 12H3.66998" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              </svg></span>
                              <span>{t('.back_to_previous_page')}</span>

                            </Button>
                            <Button
                              className='btn btn_pill is-blue'
                              type='submit'
                              disabled={this.validationcheck() || !isPrivacyPolicy || !isunderstandPrivacyPolicy || companynextButton}
                              onClick={async () => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                await this.setState({ step: 4 })
                                await sessionStorage.setItem('stateData', JSON.stringify(this.state))


                              }}
                            >
                              {t('.nextstep')}
                            </Button>
                          </div>

                        </div>




                        {/* <div className='row'>
                          <div className='button-end'>
                            <Button
                              className='btn btn_pill'
                              type='button'
                              onClick={async () => {
                                await this.setState({ step: 2 })
                                await sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                              }}
                            >
                              {t('.back_to_previous_page')}
                            </Button>
                          </div>
                        </div> */}

                      </>
                    ) : null}
                    {step === 4 ? (
                      <>
                        <div className='row collapse '>
                          <TitleComponent title={t('.account_confirmation')} />
                          <div className='row'>
                            <div className='column'>
                              <h1 className='page-title'>{t('.payment_order')}</h1>
                              <h2 className='page-sub-title'>{t('.payment_order_desc')}</h2>
                            </div>
                          </div>
                        </div>





                        <div className='row paymentclass payment-card-wrapper'>
                          <div className='payment-method'>
                            {/* <h2 className='payment-block-title'>{t('.select_card')}</h2> */}
                            <div className="payment-method-wrapper-new">
                              <div className='payment-method-left'>
                                <PaymentForm payButton={payButton} paysuccess={this.loadPaymentModalWithAddCard} productName={currentLan === 'en' ? packDetails?.name[0]?.en : packDetails?.name[1]?.fr} email={email} monthlyPrice={paymentMode === 'monthly' ? packDetails.monthlyPrice : packDetails.yearlyPrice} inprogressButton={this.inprogressButton} priceDisplay={(displayTotalPay || displayTotalPay === 0) ? (displayTotalPay).toFixed(2) : "0.00"} includeTaxShow={validateCompanyData?.isTax} validateCompanyData={validateCompanyData} />

                              </div>

                            </div>
                          </div>
                        </div>



                        <div className='bottom-wrapper'>

                          <div className='row'>
                            <Button
                              className='btn btn_pill'
                              type='button'
                              onClick={async () => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                await this.setState({ step: 3 })
                                await sessionStorage.setItem('stateData', JSON.stringify(this.state))

                              }}
                            >
                              <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5 12H3.66998" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              </svg></span>
                              <span>{t('.back_to_previous_page')}</span>

                            </Button>
                          </div>

                        </div>
                      </>
                    ) : null}
                    {step === 5 ? (
                      <>
                        <div className='payment-success'>
                          <div className='check-image'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                              <path d="M35.8477 8.45989C38.1238 6.51337 41.8514 6.51337 44.1606 8.45989L49.3726 12.9468C50.3622 13.8045 52.2095 14.4974 53.5291 14.4974H59.1369C62.6336 14.4974 65.5036 17.3677 65.5036 20.8648V26.4734C65.5036 27.7601 66.1963 29.6406 67.054 30.6303L71.5403 35.843C73.4866 38.1195 73.4866 41.8475 71.5403 44.157L67.054 49.3697C66.1963 50.3594 65.5036 52.207 65.5036 53.5266V59.1352C65.5036 62.6323 62.6336 65.5026 59.1369 65.5026H53.5291C52.2425 65.5026 50.3622 66.1954 49.3726 67.0532L44.1606 71.5401C41.8844 73.4866 38.1568 73.4866 35.8477 71.5401L30.6356 67.0532C29.646 66.1954 27.7987 65.5026 26.4792 65.5026H20.7723C17.2756 65.5026 14.4057 62.6323 14.4057 59.1352V53.4936C14.4057 52.207 13.713 50.3594 12.8883 49.3697L8.43496 44.124C6.52168 41.8475 6.52168 38.1525 8.43496 35.876L12.8883 30.6303C13.713 29.6406 14.4057 27.7931 14.4057 26.5064V20.8318C14.4057 17.3347 17.2756 14.4644 20.7723 14.4644H26.4792C27.7657 14.4644 29.646 13.7716 30.6356 12.9138L35.8477 8.45989Z" fill="#46C246" />
                              <path d="M26 40.4333L35.4333 49.8667L54.3333 31" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>
                          <div className='success-color'>
                            <p>{t('.payment_success_message')}</p>
                          </div>
                        </div>
                        <div className='row collapse align-bottom align-justify margin-bottom-20'>
                          <div className='column '>
                            <h1 className='payment-title'>{t('.payment_overview')}</h1>
                          </div>
                        </div>
                        <div className="margin-bottom-20  payment-overview">
                          {/* <div className="row margin-bottom-20">
                            <div className="columns small-3 large-4">
                              <h3>{t('.payment_via')}</h3>
                            </div>
                          </div> */}
                          <div className="row">
                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_status')}</p></div>
                            <div className=" small-4 large-4"><p className='field-text'>{t('.payment_via')}</p></div>
                          </div>
                          <div className="row">
                            <div className=" small-2 large-2"><p className='field-label'>{t('.amount_paid')}</p></div>
                            <div className=" small-4 large-4"><p className='field-text'>€{paymentReponse?.total}</p></div>
                          </div>
                          <div className="row">
                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_method')}</p></div>
                            <div className=" small-4 large-4">
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 5 }}>
                                  {paymentReponse?.paymentMethod === "Visa" ?

                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" fill="none">
                                      <path d="M1.92 0H35.52C36.5804 0 37.44 0.859632 37.44 1.92V22.08C37.44 23.1404 36.5804 24 35.52 24H1.92C0.859632 24 0 23.1404 0 22.08V1.92C0 0.859632 0.859632 0 1.92 0Z" fill="#0E4595" />
                                      <path d="M14.0736 16.739L15.6749 7.34249H18.2362L16.6337 16.739H14.0736ZM25.8869 7.54505C25.3795 7.35468 24.5843 7.15039 23.5914 7.15039C21.0606 7.15039 19.2779 8.42479 19.2628 10.2513C19.2484 11.6016 20.5354 12.3548 21.5069 12.8043C22.5039 13.2648 22.8389 13.5585 22.8342 13.9698C22.828 14.5997 22.0381 14.8874 21.302 14.8874C20.2768 14.8874 19.7322 14.745 18.8911 14.3942L18.561 14.2449L18.2016 16.3484C18.7998 16.6107 19.906 16.8379 21.0546 16.8497C23.7469 16.8497 25.4946 15.5898 25.5146 13.6393C25.5241 12.5704 24.8418 11.757 23.3641 11.0863C22.4689 10.6517 21.9207 10.3615 21.9265 9.92143C21.9265 9.53085 22.3905 9.11316 23.3932 9.11316C24.2308 9.1002 24.8375 9.28284 25.3102 9.47316L25.5397 9.58164L25.8869 7.54505ZM32.4777 7.34234H30.4986C29.8855 7.34234 29.4267 7.50972 29.1574 8.12157L25.3537 16.7328H28.0432C28.0432 16.7328 28.4829 15.5749 28.5823 15.3207C28.8763 15.3207 31.489 15.3247 31.8626 15.3247C31.9392 15.6537 32.1741 16.7328 32.1741 16.7328H34.5508L32.4777 7.34205V7.34234ZM29.3377 13.41C29.5494 12.8686 30.3581 10.7833 30.3581 10.7833C30.343 10.8084 30.5683 10.2393 30.6977 9.88649L30.8707 10.6967C30.8707 10.6967 31.3612 12.9397 31.4637 13.41H29.3377ZM11.8993 7.34234L9.39174 13.7503L9.12453 12.4481C8.65773 10.947 7.20333 9.32066 5.57742 8.50644L7.87019 16.724L10.5801 16.721L14.6123 7.34225H11.8993" fill="white" />
                                      <path d="M7.05218 7.34248H2.92221L2.88953 7.53798C6.10255 8.31577 8.22857 10.1954 9.11129 12.4537L8.21321 8.13558C8.05817 7.54062 7.60855 7.36307 7.05228 7.34229" fill="#F2AE14" />
                                    </svg> :
                                    <svg width="38" height="24" viewBox="0 0 39 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M36.5156 0.0357666H1.55564C0.719645 0.0357666 0.0356445 0.719767 0.0356445 1.55577V3.83577H38.0356V1.55577C38.0356 0.719767 37.3516 0.0357666 36.5156 0.0357666Z" fill="#AAB2BC" />
                                      <path d="M36.5156 1.55579H1.55564C0.719645 1.55579 0.0356445 2.23979 0.0356445 3.07579V3.88329H38.0356V3.07579C38.0356 2.23979 37.3516 1.55579 36.5156 1.55579Z" fill="#F2FAFF" />
                                      <path d="M38.0356 23.5958C38.0356 24.4318 37.3516 25.1158 36.5156 25.1158H1.55564C0.719645 25.1158 0.0356445 24.4318 0.0356445 23.5958V3.8358C0.0356445 2.9998 0.719645 2.3158 1.55564 2.3158H36.5156C37.3516 2.3158 38.0356 2.9998 38.0356 3.8358V23.5958Z" fill="#D8E0EA" />
                                      <path d="M23.8128 6.11584C22.0028 6.11584 20.3409 6.74893 19.0356 7.80523C20.7573 9.1985 21.8585 11.3284 21.8585 13.7158C21.8585 16.1033 20.7573 18.2332 19.0356 19.6265C20.3409 20.6828 22.0028 21.3158 23.8128 21.3158C28.0103 21.3158 31.4128 17.9133 31.4128 13.7158C31.4128 9.51836 28.0103 6.11584 23.8128 6.11584Z" fill="#F79F1A" />
                                      <path d="M16.2125 13.7158C16.2125 11.3284 17.3137 9.1985 19.0354 7.80523C17.7301 6.74893 16.0682 6.11584 14.2582 6.11584C10.0607 6.11584 6.6582 9.51836 6.6582 13.7158C6.6582 17.9133 10.0607 21.3158 14.2582 21.3158C16.0682 21.3158 17.7301 20.6828 19.0354 19.6265C17.3137 18.2332 16.2125 16.1033 16.2125 13.7158Z" fill="#EA0A1B" />
                                      <path d="M21.8585 13.7158C21.8585 11.3283 20.7574 9.19845 19.0357 7.80518C17.314 9.19845 16.2129 11.3283 16.2129 13.7158C16.2129 16.1032 17.314 18.2331 19.0357 19.6264C20.7574 18.2331 21.8585 16.1032 21.8585 13.7158Z" fill="#FF5F01" />
                                    </svg>
                                  }
                                </div>
                                <p className='field-text'>
                                  {paymentReponse?.paymentMethod} ending XXXX.{paymentReponse?.userStripeSource?.last4}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_time')}</p></div>
                            <div className=" small-4 large-4"><p className='field-text'>{this.dateSetChange(paymentReponse?.createdAt)}</p></div>
                          </div>
                        </div>
                        {/* <div className='row'>
                          <Button
                            className='btn btn_pill is-blue'
                            onClick={() => {
                              sessionStorage.setItem("stateData", "")
                              window.location = process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', sub_domain)
                            }}
                          >
                            {t('.continue_application')}
                          </Button>
                        </div> */}
                        <div className='row collapse align-bottom align-justify margin-bottom-40'>
                          <div className='row'>
                            <div className='column'>
                              <h1 className='order-summary-title'>{t('.summary_of_order')}</h1>
                              <h2 className='order-summary-sub-title'>{t('.your_details')}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="detail-wrapper row">
                          <div className="detail-wrapper-left">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.vat')}</h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.vat_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{vat ? vat : t('.no_vat_number')}</p></div>
                              </div>
                            </div>
                            <div className="margin-bottom-20 payment-success-overview-detail">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.account_detail')}</h3>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.firstname')}</p></div>
                                <div className="columns small-4 large-8 "><p className='field-text'>{firstname}</p></div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.lastname')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{name ? name : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.sub_domain')}</p></div>
                                <div className="columns small-4 large-8 break-word-set icon-subdomain"><p className='field-text'>
                                  <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                    <g clip-path="url(#clip0_125_1763)">
                                      <path d="M10.1673 4.83484C9.72918 2.13666 8.69583 0.242188 7.49328 0.242188C6.29073 0.242188 5.25738 2.13666 4.81927 4.83484H10.1673ZM4.59266 7.73547C4.59266 8.40624 4.62891 9.04981 4.69237 9.66922H10.2912C10.3546 9.04981 10.3909 8.40624 10.3909 7.73547C10.3909 7.0647 10.3546 6.42112 10.2912 5.80172H4.69237C4.62891 6.42112 4.59266 7.0647 4.59266 7.73547ZM14.4034 4.83484C13.5393 2.78326 11.7898 1.19698 9.62947 0.556422C10.3667 1.57768 10.8743 3.11562 11.1402 4.83484H14.4034ZM5.35407 0.556422C3.19673 1.19698 1.44427 2.78326 0.583146 4.83484H3.84635C4.10922 3.11562 4.61683 1.57768 5.35407 0.556422ZM14.7267 5.80172H11.2611C11.3245 6.43623 11.3608 7.08585 11.3608 7.73547C11.3608 8.38509 11.3245 9.03471 11.2611 9.66922H14.7237C14.8899 9.04981 14.9835 8.40624 14.9835 7.73547C14.9835 7.0647 14.8899 6.42112 14.7267 5.80172ZM3.62578 7.73547C3.62578 7.08585 3.66204 6.43623 3.72549 5.80172H0.259848C0.0966875 6.42112 0 7.0647 0 7.73547C0 8.40624 0.0966875 9.04981 0.259848 9.66922H3.72247C3.66204 9.03471 3.62578 8.38509 3.62578 7.73547ZM4.81927 10.6361C5.25738 13.3343 6.29073 15.2288 7.49328 15.2288C8.69583 15.2288 9.72918 13.3343 10.1673 10.6361H4.81927ZM9.63249 14.9145C11.7898 14.274 13.5423 12.6877 14.4064 10.6361H11.1432C10.8773 12.3553 10.3697 13.8933 9.63249 14.9145ZM0.583146 10.6361C1.44729 12.6877 3.19673 14.274 5.35709 14.9145C4.61985 13.8933 4.11224 12.3553 3.84635 10.6361H0.583146Z" fill="#0028E6" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_125_1763">
                                        <rect width="14.9866" height="15.47" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg></span>{this.subDomainUrl(sub_domain)}</p></div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.email_address')}</p></div>
                                <div className="columns small-4 large-8 break-word-set"><p className='field-text'>{email ? email : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.sector')}</p></div>
                                <div className="columns small-4 large-8 break-word-set"><p className='field-text'>{this.sectorFind(sector)}</p></div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.employee_rate')}</p></div>
                                <div className="columns small-4 large-8 break-word-set"><p className='field-text'>{this.employeeFind(employees)}</p></div>
                              </div>
                              {/* <div className="row">
                      <div className="columns small-2 large-3">Last name</div>
                      <div className="columns small-4 large-3">{name}</div>
                    </div> */}

                            </div>
                            {/* <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.company_details')}</h3>
                                </div>
                              </div>
                              <div className="row margin-bottom-20">
                                <div className="columns small-2 large-6">{t('.sector')}</div>
                                <div className="columns small-4 large-6 break-word-set">{this.sectorFind(sector)}</div>
                              </div>
                              <div className="row margin-bottom-20">
                                <div className="columns small-2 large-6">{t('.employee_rate')}</div>
                                <div className="columns small-4 large-6 break-word-set">{this.employeeFind(employees)}</div>
                              </div>
                            </div> */}
                          </div>
                          <div className="detail-wrapper-right">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.company_billing_info')}</h3>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.company_name')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{company_name ? company_name : ''}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.street_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{street ? street : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.zip_code')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{zip_code ? zip_code : ''}</p></div>
                              </div>  <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.city')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{city ? city : '-'}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.country')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{country ? country : "-"}</p></div>
                              </div>
                              <div className="row">
                                <div className="columns small-2 large-4"><p className='field-label'>{t('.phone_number')}</p></div>
                                <div className="columns small-4 large-8"><p className='field-text'>{phone_number ? phone_number : "-"}</p></div>

                              </div>

                            </div>
                          </div>
                        </div>




                        <div className='row collapse align-bottom align-justify margin-bottom-20 margin-top-40'>
                          <div className='row'>
                            <div className='column'>
                              <h1 className='page-title'>{t('.pack_detail')}</h1>
                            </div>
                          </div>
                        </div>
                        <div className="detail-wrapper row">
                          <div className="detail-wrapper-left">
                            <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-4">{t('.frequency_of_payments')}</div>
                                <div className="columns small-4 large-8">{paymentMode === 'monthly' ? t('.monthly_discount_price') : t('.yearly_discount_price')}</div>
                              </div>
                            </div>

                          </div>
                          <div className="detail-wrapper-right">

                          </div>
                        </div>

                        <div id={"packpaymentdetails"} className='marginBottom-50' >
                          <div className='row'>
                            <div className=" package-box  medium-4  margin-bottom-20">
                              <div className="package-inner-sec-main" style={{ paddingBottom: "15px", justifyContent: "center" }}>
                                <div className="card-payment-section-inner-wrapper-new">
                                  <div className="card back-ground-blue">

                                    <h4 className='package-card-title'>{currentLan === 'en' ? packDetails.name[0].en : packDetails.name[1].fr} </h4>
                                    <span className="package-name">{currentLan === 'en' ? packDetails.description[0].en : packDetails.description[1].fr} </span>
                                    {/* <p className="font-color">€{validateCompanyData?.amount} {`+  €`}{validateCompanyData?.isTax ? validateCompanyData?.vatAmount : "0.00"}  {"VAT"}</p> */}

                                    {paymentMode === 'monthly' ? <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total).toFixed(2) : "0.00"} {t('.per_month_per_website')} </p> : <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total)?.toFixed(2) : "0.00"} {t('.per_year_per_website')} </p>}
                                    {/* {paymentMode === 'monthly' ? <p className="font-color">€{packDetails.monthlyPrice} </p> : <p className="font-color">€{packDetails.yearlyPrice} {t('.per_year_per_website')} </p>} */}
                                    {validateCompanyData?.isTax ? (
                                      <span className="pricetext">{t('.price_includes')}</span>
                                    ) : null}

                                    <span className="pricetext" style={{ textDecoration: "underline", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                                      this.setState({ arrowHideForpackage: !arrowHideForpackage })
                                    }}>
                                      <div style={{ marginRight: 5 }}> {t('.for_package_details')}</div>
                                      {arrowHideForpackage ? <AiFillCaretDown size={20} /> : <AiFillCaretUp size={20} />}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* ) : null} */}


                            </div>
                            {/* {arrowHideForpackage ? ( */}
                            <div className="columns medium-8 left-dark-sec  margin-bottom-20">
                              <div className='text-form '>
                                <table className='unstriped table-new-user'>
                                  <PackageList activePackage={[packDetails]} />
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* ) : null} */}




                          {/* 
                          <div>
                            <div className='row'>
                              <Button
                                className='btn btn_pill is-blue'
                                onClick={() => {
                                  this.setState({ step: 1 });
                                  sessionStorage.setItem('stateData', JSON.stringify(this.state))
                                }}
                              >

                                <AiOutlineArrowLeft className='icon-class' />
                                {t('.back_to_company')}
                              </Button>
                            </div>

                          </div> */}
                        </div>


                        {/* <div id={"packpaymentdetails"}  >

                          <div className="row package-box    margin-bottom-20">

                        
                            <div className=" medium-3 package-inner-sec-main" style={{ paddingBottom: "15px", justifyContent: "center" }}>
                              <div className="card-payment-section-inner-wrapper-new">
                                <div className="card back-ground-blue">

                                  <h4>{currentLan === 'en' ? packDetails.name[0].en : packDetails.name[1].fr} </h4>
                                  <span className="package-name">{currentLan === 'en' ? packDetails.description[0].en : packDetails.description[1].fr} </span>
                                  <p className="font-color">€{validateCompanyData?.amount} {`+  €`}{validateCompanyData?.isTax ? validateCompanyData?.vatAmount : "0.00"}  {"VAT"}</p>

                                  {paymentMode === 'monthly' ? <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total).toFixed(2) : "0.00"} {t('.per_month_per_website')} </p> : <p className="font-color">€{validateCompanyData?.total ? parseFloat(validateCompanyData?.total).toFixed(2) : "0.00"} {t('.per_year_per_website')} </p>}
                                 
                                  {validateCompanyData?.isTax ? (
                                    <span className="pricetext">{t('.price_includes')}</span>
                                  ) : null}

                                  <span className="pricetext" style={{ textDecoration: "underline", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                                    this.setState({ arrowHideForpackageStepFive: !arrowHideForpackageStepFive })
                                  }}>
                                    <div style={{ marginRight: 5 }}>  {t('.for_package_details')}</div>
                                    {arrowHideForpackageStepFive ? <AiFillCaretDown size={20} /> : <AiFillCaretUp size={20} />}
                                  </span>
                                </div>
                              </div>
                            </div>

                         

                          </div>
                          <div className="row columns medium-4 left-dark-sec">
                            <div className='text-form '>
                              <table className='unstriped table-new-user'>
                                <PackageList activePackage={[packDetails]} />
                              </table>
                            </div>
                          </div>
                          
                        </div> */}


                        <div className='bottom-wrapper'>

                          <div className='row'>
                            <Button
                              className='btn btn_pill is-blue'
                              type='submit'
                              disabled={this.validationcheck() || !isPrivacyPolicy || !isunderstandPrivacyPolicy || companynextButton}
                              onClick={() => {
                                sessionStorage.setItem("stateData", "")
                                window.location = process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', sub_domain)
                              }}
                            >
                              {t('.continue_application')}
                            </Button>
                          </div>

                        </div>
                      </>
                    ) : null}
                  </div>
                </main>
              </>
            )}


          </Fragment >
        </AuthWrapper >


      </>
    );
  }
}

const CREATE_BILLING_MUTATION = gql`
  mutation createBilling(
    $managerName: String!
    $companyName: String!
    $vat: String
    $address: JSON!
    $industry: String
    $size: String
    $vatExist: Boolean
  ) {
    createBilling(
      managerName: $managerName
      companyName: $companyName
      vat: $vat
      address: $address
      industry: $industry
      size: $size
      vatExist: $vatExist
    ) {
      id
    }
  }
`;
const PURCHASE_PACKAGE = gql`
mutation PurchasePackage(
  $stripeToken: String!,
  $email: String!,
  $password: String!, 
  $name: String!, 
  $subDomainName: String!, 
  $countryCode: String!, 
  $type: String!, 
  $settings: JSON!, 
  $managerName: String!, 
  $companyName: String!, 
  $vat: String, 
  $address: JSON!, 
  $industry: String, 
  $size: String, 
  $vatExist: Boolean, 
  $packageId: ID!, 
  $packageUuid: String!, 
  $paymentMode: String!) {
    purchasePackage(
      stripeToken: $stripeToken, 
      email: $email, 
      password: $password, 
      name: $name, 
      subDomainName: $subDomainName, 
      countryCode: $countryCode, 
      type: $type, 
      settings: $settings, 
      managerName: $managerName, 
      companyName: $companyName, 
      vatExist: $vatExist, 
      vat: $vat, 
      address: $address, 
      industry: $industry, 
      size: $size, 
      packageId: $packageId, 
      packageUuid: $packageUuid, 
      paymentMode: $paymentMode) {
        id,
        total,
        paymentMethod,
        createdAt,
        userStripeSource{
          last4
        }
    }
}
`;
const VALIDATION_PACKAGE_1 = gql`
mutation ValidateUserData($email: String!, $password: String!, $name: String!, $subDomainName: String!, $countryCode: String!, $packageId: ID!, $packageUuid: String!, $paymentMode: String!) {
    validateUserData(email: $email, password: $password, name: $name, subDomainName: $subDomainName, countryCode: $countryCode, packageId: $packageId, packageUuid: $packageUuid, paymentMode: $paymentMode) {
        valid
    }
}
`;
const VALIDATION_PACKAGE_2 = gql`
mutation ValidateCompanyData($managerName: String!, $companyName: String!, $vat: String, $address: JSON!, $industry: String, $size: String, $vatExist: Boolean, $packageId: ID!, $packageUuid: String!, $paymentMode: String!) {
    validateCompanyData(managerName: $managerName, companyName: $companyName, vatExist: $vatExist, vat: $vat, address: $address, industry: $industry, size: $size, packageId: $packageId, packageUuid: $packageUuid, paymentMode: $paymentMode) {
        valid
        total
        amount
        isTax
        vatAmount
    }
}
`;

const CHECK_VAT_VALIDATION_QUERY = gql`
  query($number: String!) {
    checkVat(number: $number) {
      address
      countryCode
      id
      name
      vatNumber
    }
  }
`;
const GET_PACKAGES = gql`
  query packages{
    packages {
        id,
        uid,
        status,
        isActive,
        name,
        description,
        isShow,
        monthlyPrice,
        yearlyPrice,
        duration,
        data {
            uid
            cookieFeatures
        },
        isShow,
        isCookie,
        isPolicy,
        isShow,
        cTrafficBucket,
        cNoOfLang,
        cNoOfLegis,
        cNoOfUsers,
        support
        legalUpdate
        cIsVersioning,
        cIsExport,
        pTrafficBucket,
        pNoOfLang,
        pNoOfLegis,
        pNoOfUsers,
        pIsVersioning,
        cookieFeatureIds,
        policyFeatureIds,
        cookieFeatures,
        policyFeatures,
        createdAt,
        updatedAt,
        ifBeyondTheLimit,
        legalDesignWebPolicy,
        dedicatedCsm,    
        isBannerConsentInteractionStatistics
    }
}
`
const mapDispatchToProps = {
  loadExampleModal: loadModal,
  hideModal
};

export default compose(connect(null, mapDispatchToProps), withApollo, withRouter, withTranslation('account_onboarding'), withNotifications())(Onboarding);
