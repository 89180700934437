import { loader } from 'graphql.macro';

const GraphqlCalls = {
  GET_PRIVACY_POLICY_QUERY: loader('./get_policy_query.graphql'),
  UPDATE_PRIVACY_POLICY_MUTATION: loader('./update_policy_mutation.graphql'),
  CREATE_PRIVACY_POLICY_MUTATION: loader('./create_policy_mutation.graphql'),
  LINK_POLICIES_MUTATION: loader('./link_policies_mutation.graphql'),
};

export default GraphqlCalls;
