import * as React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import connect from 'react-redux/es/connect/connect';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import { renderCheckboxField } from '../../components/Form/Checkbox';
import UnAuthWrapper from '../../components/UnAuth';
import { submitNewPassword } from './submit_validation';
import validate from './validate';
import { loadModal } from '../../redux/modal/action';
import { withTranslation } from '../../providers/I18n';
import { Queries } from './submit_queries';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class CreatePassword extends React.Component {
  componentDidMount() {
    const { history, client, loadOutdatedModal, t } = this.props;

    let urlParams = new URLSearchParams(window.location.search);

    client
      .query({
        query: Queries.check_token,
        fetchPolicy: 'network-only',
        variables: {
          token: urlParams.get('token'),
        },
      })
      .then(({ data }) => {
        if (!data.checkToken) {
          loadOutdatedModal({ modalType: 'token_outdated', title: t('.outdated_token_title') });
        }
      });

    if (!urlParams.has('token')) {
      history.push('/');
    }
  }

  render() {
    const { submitting, handleSubmit, error, t } = this.props;

    return (
      <UnAuthWrapper title={t('.choose_password')}>
        <TitleComponent title={t('.choose_password')} />
        <form className='text-form auth_form' onSubmit={handleSubmit(submitNewPassword)}>
          <Field
            name='password'
            type='password'
            label={t('.password')}
            component={renderInputField}
            t={t}
          />
          <Field
            name='password_confirmation'
            type='password'
            label={t('.password_confirmation')}
            component={renderInputField}
            t={t}
          />
          <Field
            name='conditions'
            renderLabel={() => conditionsCheckboxComponent(t)}
            component={renderCheckboxField}
            t={t}
          />
          {error && <p className='validation_error'>{t(error)}</p>}
          <button type='submit' className='btn btn_pill is-blue' disabled={submitting}>
            {submitting ? t('.waiting') : t('.confirm_my_account')}
          </button>
        </form>
      </UnAuthWrapper>
    );
  }
}

const conditionsCheckboxComponent = t => {
  return (
    <div>
      <strong> {t('.condition')} </strong>
    </div>
  );
};

const mapDispatchToProps = {
  loadOutdatedModal: loadModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({ form: 'new_password_form', validate }),
  withRouter,
  withApollo,
  withTranslation('unAuthenticatedForm'),
)(CreatePassword);
