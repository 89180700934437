import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { modules, formats } from './quill_options'
import Button from '../../Button'

class TextAreaWithDefault extends React.Component {
  constructor(props) {
    super(props)
    this.renderField = this.renderField.bind(this)

    let defaultValues = {}

    if (props.options[0].defaultValue) {
      props.options[0].defaultValue.map(value => {
        let keys = Object.keys(value)
        defaultValues[`${keys[0]}`] = value[keys[0]]
      })
    }

    let language = props.locales.filter(locale => locale.isoCode === props.selectedLanguage)

    this.state = {
      values: [],
      selectedLocale: language[0] ? this.props.selectedLanguage : props.locales[0].isoCode,
      defaultValues: defaultValues,
      invalid: true,
    }
  }

  componentWillMount() {
    const { locales, options } = this.props
    const { defaultValues } = this.state

    const values = locales.map((locale, i) => {
      let iso = locale.isoCode
      return options[0].value[i]
        ? { [iso]: options[0].value[i][iso] }
        : { [iso]: defaultValues[iso] }
    })

    this.setState({ values: values, invalid: this.validateInput(values) }, () =>
      this.props.onChange(values),
    )
  }

  handleChange = (value, locale) => {
    const { selectedLocale, values } = this.state

    let valueToSend = values

    if (selectedLocale === locale) {
      valueToSend = values.map(val => {
        return selectedLocale in val ? { [selectedLocale]: value } : val
      })
    }

    this.setState({ values: valueToSend, invalid: this.validateInput(valueToSend) }, () =>
      this.props.onChange(this.state.values),
    )
  }

  removeTags = str => {
    if (typeof str === 'undefined' || str === null || str === '') return ''
    else str = str.toString()
    return str.replace(/(<([^>]+)>)/gi, '')
  }

  validateInput = values => {
    let invalid = false
    values.map(value => {
      if (this.removeTags(Object.values(value)[0]) === '') invalid = true
    })
    return invalid
  }

  onSelectLocale = locale => {
    this.setState({ selectedLocale: locale })
  }

  renderLocale = () => {
    const { locales } = this.props
    const { selectedLocale } = this.state
    return (
      <ul className='form_locales__list'>
        {locales.map(locale => (
          <li
            key={locale.isoCode}
            className={`form_locales__item ${selectedLocale === locale.isoCode ? 'active' : ''}`}
            onClick={() => this.onSelectLocale(locale.isoCode)}
          >
            {locale.isoCode.toUpperCase()}
          </li>
        ))}
      </ul>
    )
  }

  renderField = option => {
    const { locales, goBack, handleSubmit, t } = this.props
    const { selectedLocale, defaultValues } = this.state
    return (
      <div>
        {locales.map((locale, i) => (
          <div key={locale.isoCode}>
            <ReactQuill
              defaultValue={
                option.value[i] ? option.value[i][locale.isoCode] : defaultValues[locale.isoCode]
              }
              onChange={text => this.handleChange(text, locale.isoCode)}
              modules={modules}
              formats={formats}
              className={`quill-editor notranslate ${
                locale.isoCode === selectedLocale ? '' : 'hidden'
              }`}
            />
          </div>
        ))}

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { options, locales } = this.props

    return (
      <div className='text-form'>
        {this.renderLocale(locales)}
        {this.renderField(options[0])}
      </div>
    )
  }
}

TextAreaWithDefault.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
}

export default TextAreaWithDefault
