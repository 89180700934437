import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import { NotificationModel, withNotifications } from '../../../providers/notifications'
import Button from 'src/components/Button'
import gql from 'graphql-tag';


const DownGradePopupModel = ({
    title,
    closeExampleModal,
    content: {
        subscriptionPack,
        packDetails,
        licenseId
    },
    t,
    client,
    notificationsProvider,
    history,
}) => {
    const paymentApiCall = () => {

        var values = {
            "stripeToken": "",
            "userStripeSourceId": "",
            "isPrimary": false,
            licenseId: licenseId,
            oldPackageId: subscriptionPack?.packageId,
            oldPackageUuid: subscriptionPack?.package?.uid,
            newPackageId: packDetails?.id,
            newPackageUuid: packDetails?.data?.uid
        }
        client
            .mutate({
                mutation: CHANGE_SUBSCRIPTION,
                variables: values,
            })
            .then(() => {
                const notification = new NotificationModel({
                    title: '',
                    message: t('.downgrade_success_message'),
                    type: 'success',
                })

                notificationsProvider.add(notification)
                history.push('/');
            })
            .catch(({ graphQLErrors }) => {
                var errorDisplay = '';
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                    graphQLErrors[0].extensions.errors.map(item => {
                        errorDisplay += ' ' + item.detail
                    })
                    const notification = new NotificationModel({
                        title: '',
                        message: errorDisplay,
                        type: 'alert',
                    })

                    notificationsProvider.add(notification)
                }
                else {
                    const notification = new NotificationModel({
                        title: '',
                        message: t('.server_error'),
                        type: 'alert',
                    })

                    notificationsProvider.add(notification)
                }
            });
    }

    return (
        <Modal>
            <div className='modal_bar'>
                <h4 className='modal_title'>{title}</h4>
                <button type='button' className='close' onClick={closeExampleModal} />
            </div>
            <div className='modal_content'>
                {t('.downgrade_mesage')}
                <div className='list_buttons'>
                    <Button
                        className='btn btn_pill is-outlined is-blue'
                        published
                        onClick={closeExampleModal}
                    >
                        {t('.cancel')}
                    </Button>

                    <Button
                        className='btn btn_pill is-blue'
                        published
                        onClick={() => {
                            closeExampleModal()
                            paymentApiCall()
                        }}
                    >
                        {t('.yes_text')}
                    </Button>
                </div>
            </div>

        </Modal>
    )
}

DownGradePopupModel.propTypes = {
    title: PropTypes.string.isRequired,
    closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    closeExampleModal: hideModal,
}
const CHANGE_SUBSCRIPTION = gql`
  mutation SubscriptionPackageUpdate($licenseId: ID!, $oldPackageId: ID!, $oldPackageUuid: String!, $newPackageId: ID!, $newPackageUuid: String!, $stripeToken: String!, $userStripeSourceId: ID!, $isPrimary: Boolean) {
    subscriptionPackageUpdate(licenseId: $licenseId, oldPackageId: $oldPackageId, oldPackageUuid: $oldPackageUuid, newPackageId: $newPackageId, newPackageUuid: $newPackageUuid, stripeToken: $stripeToken, userStripeSourceId: $userStripeSourceId, isPrimary: $isPrimary) {
        id,
        total,
        paymentMethod,
        createdAt
    }
}
`
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('dashboard'),
)(DownGradePopupModel)
