const removeTags = str => {
  if (typeof str === 'undefined' || str === null || str === '') return ''
  else str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}

const validateInput = values => {
  let invalid = false
  values.map(value => {
    const answerIsEmpty = Object.values(value).some(answer => removeTags(answer) === '')
    if (answerIsEmpty) invalid = true
  })
  return invalid
}

const initDefaultValues = ({ defaultValues, locales }) => {
  let defaultValuesByLocale = defaultValues.filter(value => locales.includes(Object.keys(value)[0]))
  let formattedDefaultValues = [Object.assign({}, ...defaultValuesByLocale)]
  return formattedDefaultValues
}

const initQuestionAnswer = ({ answer, defaultValues, locales }) => {
  let questionAnswer
  questionAnswer = answer
    ? [Object.assign({}, ...answer.value)]
    : [Object.assign({}, ...defaultValues.filter(value => locales.includes(Object.keys(value)[0])))]

  locales.forEach(locale => {
    if (!Object.keys(questionAnswer[0]).includes(locale)) {
      questionAnswer[0][locale] = defaultValues[0][locale] || ''
    }
  })
  return questionAnswer
}

const generateDefaultValues = ({ locales }) => {
  let defaultValuesByLocale = {}
  locales.forEach(locale => (defaultValuesByLocale[locale] = ''))
  return [defaultValuesByLocale]
}

export { validateInput, initDefaultValues, initQuestionAnswer, generateDefaultValues }
