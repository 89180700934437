import React, { Fragment, useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderInputField } from '../TextFieldGroup/fieldInput';

const DurationForm = ({ handleChange, name, values, type, t }) => {
  const obj = values[name];
  const deletion = type === 'deletion';
  const selectedPeriod = obj.period ? obj.period : null;

  const [timeInvalid, setTimeInvalid] = useState(false);

  return (
    <Fragment>
      <div className='duration_select' style={{ opacity: deletion ? '0' : '1' }}>
        <div className='text-form'>
          <Field
            name={`${name}_time`}
            component={renderInputField}
            type='text'
            placeholder='0'
            id={`duration_${name}`}
            onChange={e => {
              handleChange(e, 'time', name);
              setTimeInvalid(isNaN(e.target.value) || e.target.value < 1);
            }}
            invalid={timeInvalid}
            label={t('.duration')}
          />
        </div>
      </div>
      <div className='period_checkbox' style={{ opacity: deletion ? '0' : '1' }}>
        <Field
          name={`period_${name}`}
          component={({ input }) => (
            <Fragment>
              <div className='radio_input'>
                <input
                  id={`${name}_month`}
                  type='radio'
                  {...input}
                  value='month'
                  name={name}
                  checked={selectedPeriod && selectedPeriod === 'month'}
                  onChange={e => handleChange(e, 'period', name)}
                />
                <label htmlFor={`${name}_month`}>{t('.month')}</label>
              </div>
              <div className='radio_input'>
                <input
                  id={`${name}_year`}
                  type='radio'
                  {...input}
                  value='year'
                  name={name}
                  checked={selectedPeriod && selectedPeriod === 'year'}
                  onChange={e => handleChange(e, 'period', name)}
                />
                <label htmlFor={`${name}_year`}>{t('.years')}</label>
              </div>
            </Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};

export default DurationForm;
