import React from 'react'
import { Field } from 'redux-form'

import { renderInputField } from '../Form/TextFieldGroup/fieldInput'
import DropdownItem from '../../containers/Onboarding/CompanyOnboarding/dropdown'
import TextArea from '../ProductForm/TextArea'
import Button from '../Button'
import { withTranslation } from '../../providers/I18n'
import withApollo from 'react-apollo/withApollo'
import Tooltip from '../Tooltip'

const category_list = [
  {
    value: 'necessary',
    label: '.necessary',
  },
  {
    value: 'functional',
    label: '.functional',
  },
  {
    value: 'statistic',
    label: '.statistic',
  },
  {
    value: 'marketing',
    label: '.marketing',
  },
]

const cookie_type_list = [
  {
    value: 'owner',
    label: '.cookie_owner',
  },
  {
    value: 'third_party',
    label: '.third_party_cookie',
  },
]

const technology_type_list = [
  {
    value: 'cookie',
    label: '.cookies',
  },
  {
    value: 'other',
    label: '.similar_technology',
  },
]

const expiration_date_format = [
  {
    value: 'minute',
    label: '.minute',
  },
  {
    value: 'day',
    label: '.day',
  },
  {
    value: 'month',
    label: '.month',
  },
  {
    value: 'session',
    label: '.session',
  },
]

class CookieFormItem extends React.Component {
  constructor(props) {
    super(props)

    let isSession = props.option.session ? 'session' : null

    this.state = {
      name: props.option.name,
      domain: props.option.domain || '',
      purpose_list: [],
      category: props.option.category || null,
      purpose: props.option.purpose || null,
      type: props.option.type || null,
      technology: props.option.technology || null,
      expDateFormat: props.option.expDateFormat || isSession,
      lifespanTime: props.option.lifespanTime || '',
      allSelected: false,
      lifespanTimeValid: true,
      nameValid: true,
      domainValid: true,
      missingCookieInfo: this.initializeCookieDatabase(props.allSystemCookies),
      filteredSystemCookies: [],
    }
  }

  componentWillMount() {
    const {
      option: { category },
      allSystemCookies,
    } = this.props
    category && this.categoryChange(category)
    let systemCookie = this.initializeCookieDatabase(allSystemCookies)
    if (systemCookie) {
      let purpose_list = this.initializePurposeList(systemCookie.category)
      let purpose = purpose_list.find(purpose => purpose.label === systemCookie.purpose)?.value
      this.setState({ purpose, purpose_list, category: systemCookie.category })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.allSystemCookies !== this.props.allSystemCookies ||
      prevProps.activeTab !== this.props.activeTab
    ) {
      this.setSystemCookie('', prevProps.activeTab, "")
    }
  }

  initializePurposeList = purpose => {
    let purposes = this.props.question.choices.data
    let purpose_list = purposes[purpose]?.map(prp => {
      let keys = Object.keys(prp)
      return {
        value: keys[0],
        label: prp[keys[0]],
      }
    })
    return purpose_list
  }

  setSystemCookie = (cookie, prevProps, selectvalue) => {
    let newSystemCookie

    if (!cookie) {
      newSystemCookie = this.props.allSystemCookies.find(cookie => cookie.name === this.state.name)
      let isSession = this.props?.option?.session ? 'session' : null
      if (newSystemCookie) {
        newSystemCookie = {
          ...newSystemCookie,
          name: this.props?.option?.name ? this.props?.option?.name : newSystemCookie.name,
          domain: this.props?.option?.domain ? this.props?.option?.domain : newSystemCookie.owner,
          expDateFormat: this.props?.option?.expDateFormat ? this.props?.option?.expDateFormat : isSession ? isSession : newSystemCookie?.lifespan?.toLowerCase(),//newSystemCookie?.lifespan?.toLowerCase(),
          lifespanTime: this.props?.option?.lifespanTime ? this.props?.option?.lifespanTime : newSystemCookie?.duration,
          technology: this.props?.option?.technology ? this.props?.option?.technology : newSystemCookie?.technologies,
          type: this.props?.option?.type ? this.props?.option?.type : newSystemCookie?.cookieType,
          category: this.props?.option?.category ? this.props?.option?.category : newSystemCookie?.category,
          purpose: this.props?.option?.purpose ? this.props?.option?.purpose : newSystemCookie?.purpose,
        }
      }

    } else {
      newSystemCookie = cookie
    }
    if (this.props.activeTab === 'validated' || prevProps === 'validated') {
      this.newData(newSystemCookie)
    }
    else {
      if (newSystemCookie && selectvalue === "") {
        if (
          isNaN(newSystemCookie?.duration) ||
          (newSystemCookie?.duration !== 0 && newSystemCookie?.lifespan === 'session')
        ) {
          this.setState({ lifespanTimeValid: false })
        } else {
          this.setState({ lifespanTimeValid: true })
        }
        if (newSystemCookie?.name === '') {
          this.setState({ nameValid: false })
        } else {
          this.setState({ nameValid: true })
        }
        if (newSystemCookie?.domain === '') {
          this.setState({ domainValid: false })
        } else {
          this.setState({ domainValid: true })
        }
        let purpose_list = this.initializePurposeList(newSystemCookie?.category)
        let purpose = purpose_list?.find(purpose => purpose.value === newSystemCookie?.purpose)?.value
        var checkOld = this.props.allSystemCookies.find(cookie => cookie.name === this.state.name)
        newSystemCookie.domain = (checkOld?.owner ? checkOld?.owner : newSystemCookie?.domain)
        this.setState({
          name: newSystemCookie?.name,
          domain: newSystemCookie?.domain,
          expDateFormat: newSystemCookie?.expDateFormat?.toLowerCase(),
          lifespanTime: newSystemCookie?.lifespanTime,
          technology: newSystemCookie?.technology,
          type: newSystemCookie?.type,
          category: newSystemCookie?.category,
          purpose,
          purpose_list,
          filteredSystemCookies: [],
          missingCookieInfo: newSystemCookie,
        })
        this.props.handleChange(this.props.option.id, 'name', newSystemCookie?.name)
        this.props.handleChange(this.props.option.id, 'allvalue', {
          name: newSystemCookie?.name,
          domain: newSystemCookie?.domain,
          expDateFormat: newSystemCookie?.expDateFormat?.toLowerCase(),
          lifespanTime: newSystemCookie?.lifespanTime,
          technology: newSystemCookie?.technology,
          type: newSystemCookie?.type,
          category: newSystemCookie?.category,
          purpose,
          purpose_list,
          filteredSystemCookies: [],
          missingCookieInfo: newSystemCookie,
        })
      }
      else if (newSystemCookie && selectvalue === "selectvalue") {
        if (
          isNaN(newSystemCookie.duration) ||
          (newSystemCookie.duration !== 0 && newSystemCookie.lifespan === 'session')
        ) {
          this.setState({ lifespanTimeValid: false })
        } else {
          this.setState({ lifespanTimeValid: true })
        }
        if (newSystemCookie.name === '') {
          this.setState({ nameValid: false })
        } else {
          this.setState({ nameValid: true })
        }
        if (newSystemCookie.owner === '') {
          this.setState({ domainValid: false })
        } else {
          this.setState({ domainValid: true })
        }
        let purpose_list = this.initializePurposeList(newSystemCookie?.category)
        let purpose = purpose_list.find(purpose => purpose.value === newSystemCookie?.purpose)?.value
        var checkOld = this.props.allSystemCookies.find(cookie => cookie.name === this.state.name)
        newSystemCookie.domain = (checkOld?.owner ? checkOld?.owner : newSystemCookie?.domain)
        this.setState({
          name: newSystemCookie?.name,
          domain: (checkOld?.owner ? checkOld?.owner : newSystemCookie?.domain),
          expDateFormat: newSystemCookie?.lifespan?.toLowerCase(),
          lifespanTime: newSystemCookie?.duration,
          technology: newSystemCookie?.technologies,
          type: newSystemCookie?.cookieType,
          category: newSystemCookie?.category,
          purpose,
          purpose_list,
          filteredSystemCookies: [],
          missingCookieInfo: newSystemCookie,
        })
        this.props.handleChange(this.props.option.id, 'name', newSystemCookie?.name)
        this.props.handleChange(this.props.option.id, 'allvalue', {
          name: newSystemCookie?.name,
          domain: newSystemCookie?.owner,
          expDateFormat: newSystemCookie?.lifespan?.toLowerCase(),
          lifespanTime: newSystemCookie?.duration,
          technology: newSystemCookie?.technologies,
          type: newSystemCookie?.cookieType,
          category: newSystemCookie?.category,
          purpose,
          purpose_list,
          filteredSystemCookies: [],
          missingCookieInfo: newSystemCookie,
        })
      }
    }
  }
  newData = (newSystemCookie) => {
    if (newSystemCookie) {
      if (
        isNaN(newSystemCookie?.duration) ||
        (newSystemCookie?.duration !== 0 && newSystemCookie?.lifespan === 'session')
      ) {
        this.setState({ lifespanTimeValid: false })
      } else {
        this.setState({ lifespanTimeValid: true })
      }
      if (newSystemCookie?.name === '') {
        this.setState({ nameValid: false })
      } else {
        this.setState({ nameValid: true })
      }
      if (newSystemCookie?.domain === '') {
        this.setState({ domainValid: false })
      } else {
        this.setState({ domainValid: true })
      }
      // newSystemCookie.li
      let allSystemData = this.props.allSystemCookies.find(cookie => cookie.name === newSystemCookie.name)
      let isSession = this.props?.option?.session ? 'session' : null
      newSystemCookie = {
        ...newSystemCookie,
        name: newSystemCookie?.name ? newSystemCookie?.name : allSystemData.name,
        domain: (newSystemCookie?.domain ? newSystemCookie?.domain : allSystemData?.owner),//newSystemCookie?.domain ? newSystemCookie?.domain : allSystemData.owner,
        expDateFormat: newSystemCookie?.expDateFormat ? newSystemCookie?.expDateFormat : isSession ? isSession : allSystemData?.lifespan?.toLowerCase(),//newSystemCookie?.lifespan?.toLowerCase(),
        lifespanTime: newSystemCookie?.lifespanTime ? newSystemCookie?.lifespanTime : allSystemData?.duration,
        technology: newSystemCookie?.technology ? newSystemCookie?.technology : allSystemData?.technologies,
        type: newSystemCookie?.type ? newSystemCookie?.type : allSystemData?.cookieType,
        category: newSystemCookie?.category ? newSystemCookie?.category : allSystemData?.category,
        purpose: newSystemCookie?.purpose ? newSystemCookie?.purpose : allSystemData?.purpose
      }
      let purpose_list = this.initializePurposeList(newSystemCookie?.category)
      let purpose = purpose_list?.find(purpose => purpose.value === newSystemCookie?.purpose)?.value

      this.setState({
        name: newSystemCookie?.name,
        domain: (newSystemCookie?.domain ? newSystemCookie?.domain : allSystemData?.owner),//newSystemCookie?.domain ? newSystemCookie?.domain : 
        expDateFormat: newSystemCookie?.expDateFormat?.toLowerCase(),
        lifespanTime: newSystemCookie?.lifespanTime,
        technology: newSystemCookie?.technology,
        type: newSystemCookie?.type,
        category: newSystemCookie?.category,
        purpose,
        purpose_list,
        filteredSystemCookies: [],
        missingCookieInfo: newSystemCookie,
      })
      this.props.handleChange(this.props.option.id, 'name', newSystemCookie?.name)

      this.props.handleChange(this.props.option.id, 'allvalue', {
        name: newSystemCookie?.name,
        domain: (newSystemCookie?.domain ? newSystemCookie?.domain : allSystemData?.owner),
        expDateFormat: newSystemCookie?.expDateFormat?.toLowerCase(),
        lifespanTime: newSystemCookie?.lifespanTime,
        technology: newSystemCookie?.technology,
        type: newSystemCookie?.type,
        category: newSystemCookie?.category,
        purpose,
        purpose_list,
        filteredSystemCookies: [],
        missingCookieInfo: newSystemCookie,
      })

      // if (isNaN(newSystemCookie?.lifespanTime) || (newSystemCookie?.lifespanTime < 1 && this.state.expDateFormat === 'session')) {
      //   this.setState({ lifespanTimeValid: false })
      // } else {
      //   this.setState({ lifespanTimeValid: true })
      // }
    }
  }

  initializeCookieDatabase = allSystemCookies =>
    allSystemCookies.find(cookie => cookie.name === this.props.option.name)

  categoryChange = cat => {
    this.setState({ purpose_list: this.initializePurposeList(cat) })
  }

  otherChange = value => {
    this.props.handleChange(this.props.index, 'other', value)
  }

  onChange = e => {
    const { purpose, category, type, technology, expDateFormat } = this.state
    const { name, value } = e.target

    name === 'category' && this.categoryChange(value)
    name === 'category' && this.setState({ purpose: null })

    this.props.handleChange(this.props.index, name, value)

    this.setState(
      { [name]: value },
      () =>
        purpose &&
        category &&
        type &&
        technology &&
        expDateFormat &&
        this.setState({ allSelected: true }),
    )
  }

  handleTextFieldChange(event, field) {
    let value = event.target.value
    if (field === 'lifespanTime') {
      if (isNaN(value) || (value < 1 && this.state.expDateFormat === 'session')) {
        this.setState({ lifespanTimeValid: false })
      } else {
        this.setState({ lifespanTimeValid: true })
      }
    }
    if (field === 'name') {
      const filteredSystemCookies = this.props.allSystemCookies.filter(cookie =>
        cookie.name.toLowerCase().includes(value.toLowerCase()),
      )
      if (value === '') {
        this.setState({ filteredSystemCookies: [] })
        this.setState({ nameValid: false })
      } else {
        this.setState({ filteredSystemCookies })

        this.setState({ nameValid: true })
      }
    }
    if (field === 'domain') {
      if (value === '') {
        this.setState({ domainValid: false })
      } else {
        this.setState({ domainValid: true })
      }
    }

    this.setState({ [field]: value })
  }

  render() {
    const { index, locales, question, option, handleChange, removeCookie, t } = this.props
    const {
      purpose,
      category,
      type,
      technology,
      purpose_list,
      expDateFormat,
      lifespanTime,
      lifespanTimeValid,
      nameValid,
      domainValid,
      domain,
      name,
      missingCookieInfo,
      filteredSystemCookies,
    } = this.state

    let allSelected =
      purpose &&
      category &&
      type &&
      technology &&
      lifespanTimeValid &&
      nameValid &&
      domainValid &&
      !(expDateFormat !== 'session' && lifespanTime === '')

    let other_options = []

    if (this.state.purpose === 'other') {
      if (this.props.option.other) {
        other_options = [{ value: this.props.option.other }]
        // validate locale values for other purpose
        this.props.option.other.forEach(item => {
          Object.values(item).forEach(value => {
            if (value === '') {
              allSelected = false
            }
          })
        })
      } else if (missingCookieInfo?.others?.length !== 0) {
        other_options = [{ value: missingCookieInfo?.others }]
        missingCookieInfo?.others?.forEach(item => {
          Object.values(item).forEach(value => {
            if (value === '') {
              allSelected = false
            }
          })
        })
      } else {
        let empty_options = locales.map(locale => {
          return { [locale]: '' }
        })
        other_options = [{ value: empty_options }]
        allSelected = false
      }
    }

    return (
      <div className='text-form cookie-form'>
        <div className='cookie-form__block'>
          {/* {(option?.infoShow && this.props.activeTab !== 'validated') ?
            <div className='display-icon-div'>
              <span
                className='element-with-data-title'
                key={Math.random() * 10}
                style={{ marginRight: '5px', width: '100%' }}
                data-title={t('.banner_same_cookie_info')}
              >
                <i className="fa fa-info-circle icon-color" style={{ cursor: "pointer" }}></i>
              </span>
            </div> : null} */}

          <div className='text_input cookie-form__elem form--50'>
            <input
              id='name'
              name={`${index}_name`}
              type='text'
              placeholder=''
              readOnly={option.validate}
              onChange={e => {
                handleChange(index, 'name', e.target.value)
                this.handleTextFieldChange(e, 'name')
              }}
              value={this.state.name}
              style={{
                border: this.state.nameValid ? '1px solid #adadad' : '1px solid #f00',
              }}
            />
            <div className='text__label'>
              <label>{t('.cookie_name')}</label>
            </div>
            {filteredSystemCookies.length !== 0 && (
              <div className='system_cookies_results'>
                {filteredSystemCookies.map(cookie => (
                  <div
                    className='system-cookie-result'
                    onClick={() => this.setSystemCookie(cookie, '', "selectvalue")}
                  >
                    <p>{cookie.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <DropdownItem
            label={t('.type')}
            className='cookie-form__elem form--50'
            name='type'
            placeholder='--'
            handleChange={this.onChange}
            options={cookie_type_list}
            value={this.state.type || missingCookieInfo?.cookieType}
            readOnly={option.validate}
            t={t}
          />
        </div>
        <div className='cookie-form__block'>
          <div className='text_input cookie-form__elem form--33'>
            <input
              id='owner'
              name={`${index}_domain`}
              type='text'
              placeholder=''
              readOnly={option.validate}
              onChange={e => {
                handleChange(index, 'domain', e.target.value)
                this.handleTextFieldChange(e, 'domain')
              }}
              value={domain || missingCookieInfo?.owner}
              style={{
                border: this.state.domainValid ? '1px solid #adadad' : '1px solid #f00',
              }}
            />
            <div className='text__label'>
              <label>{t('.owner')}</label>
            </div>
          </div>

          <DropdownItem
            name='expDateFormat'
            className='cookie-form__elem form--33'
            placeholder='--'
            handleChange={this.onChange}
            options={expiration_date_format}
            label={t('.expDateFormat')}
            value={expDateFormat || missingCookieInfo?.lifespan?.toLowerCase()}
            readOnly={option.validate}
            t={t}
          />

          {((expDateFormat && expDateFormat !== 'session') ||
            (missingCookieInfo && missingCookieInfo?.lifespan?.toLowerCase() !== 'session')) && (
              <div className='text_input cookie-form__elem form--25'>
                <input
                  id='lifespanTime'
                  name={`${index}_lifespanTime`}
                  type='text'
                  placeholder='0'
                  readOnly={option.validate}
                  onChange={e => {
                    handleChange(index, 'lifespanTime', e.target.value)
                    this.handleTextFieldChange(e, 'lifespanTime')
                  }}
                  value={this.state.lifespanTime || missingCookieInfo?.duration}
                  style={{
                    border: this.state.lifespanTimeValid ? '1px solid #adadad' : '1px solid #f00',
                  }}
                />
                <div className='text__label'>
                  <label>{t('.life_time')}</label>
                </div>
              </div>
            )}

          <DropdownItem
            name='technology'
            className='cookie-form__elem form--25'
            placeholder='--'
            handleChange={this.onChange}
            options={technology_type_list}
            label={t('.technology')}
            value={technology || missingCookieInfo?.technologies}
            readOnly={option.validate}
            t={t}
          />
        </div>
        <div className='cookie-form__block flex_center margin-bottom-20'>
          <DropdownItem
            label={t('.category')}
            className='cookie-form__elem form--25'
            name='category'
            placeholder='--'
            value={category}
            handleChange={this.onChange}
            options={category_list ? category_list : []}
            readOnly={option.validate}
            t={t}
          />
          {(category || missingCookieInfo?.category) && (
            <DropdownItem
              label={t('.purpose')}
              className='cookie-form__elem form--75'
              name='purpose'
              placeholder='--'
              handleChange={this.onChange}
              options={purpose_list ? purpose_list : []}
              value={purpose || missingCookieInfo?.category}
              readOnly={option.validate}
            />
          )}
        </div>
        {this.state.purpose === 'other' && (
          <TextArea
            key={'other_' + index}
            name='other_purpose'
            options={other_options ? other_options : []}
            index={index}
            locales={locales}
            onChange={this.otherChange}
            {...question}
          />
        )}
        {!option.validate ? (
          <div>
            <div style={{ float: 'left' }}>
              <Button
                className='btn btn_pill is-outlined is-blue'
                onClick={() => removeCookie(option.id)}
              >
                {t('.remove_cookie')}
              </Button>
            </div>
            <div style={{ float: 'right' }}>
              <Button
                className='btn btn_pill is-blue'
                published
                disabled={!allSelected || !((option.name && option.domain) || (name && domain))}
                onClick={() => handleChange(index, 'validate', true)}
                style={{ marginRight: '10px' }}
              >
                {' '}
                {t('.validate_cookie')}{' '}
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className='btn btn_pill is-outlined is-blue'
            published
            onClick={() => handleChange(index, 'validate', false)}
          >
            {' '}
            {t('.edit_cookie')}{' '}
          </Button>
        )}
      </div>
    )
  }
}

export default withTranslation('cookie_form')(CookieFormItem)
