import React, { Fragment } from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import withApollo from 'react-apollo/withApollo';
import { Link, withRouter } from 'react-router-dom';
import UnAuthenticateAccess from 'src/components/Header/unAuthenticateAccess';
import Button from 'src/components/Button';
import { IoMdCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import './App.css'
// import PaymentFlow from '../PaymentFlow';
import Styles from './Styles'
import { Form, Field } from 'react-final-form';
import DropdownItem from './dropdown';
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import NotificationModel from 'src/providers/notifications/models/notification.model';
import { values } from 'lodash';
import { TitleComponent } from 'src/providers/pageTitles/TitleComponent';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../NewOnboarding/CompanyOnboarding/cardUtils';
import { withTranslation } from 'src/providers/I18n';
import AuthWrapper from '../../components/Auth'
import { hideModal, loadModal } from 'src/redux/modal/action';
import connect from 'react-redux/es/connect/connect'

import PaymentBuyChangeForm from 'src/containers/changeLicense/Payment/index.jsx'
import cardImage from '../../assets/img/card-company-image.png';
import { AiOutlinePlus } from 'react-icons/ai';
import cardimageNew from '../../assets/img/card.png';
import arrowup from '../../assets/img/arrow-up.png';
import arrowdown from '../../assets/img/arrow-down.png';
import PackageList from '../PackageList';

class ChangeLicense extends React.Component {
    constructor() {
        super();

        this.state = {
            name: '',
            firstname: '',
            organization_name: '',
            street: '',
            vat: '',
            zip_code: '',
            city: '',
            country: '',
            phone_number: '',
            sector: '',
            employees: '',
            hasVat: null,
            vatInfo: null,
            initValue: {},
            step: 0,
            activePackage: [],
            currentLan: 'fr',
            email: '',
            companycountryname: '',
            company_name: '',
            sub_domain: '',
            userPassword: '',
            userRePassword: '',
            checboxTerm: false,
            packDetails: {},
            paymentMode: 'yearly',
            errorDisplay: '',
            cardcvc: '',
            isPrimary: false,
            cardexpiry: '',
            cardemail: '',
            cardnumber: '',
            plan_list: [{
                value: 'monthly',
                label: '.monthly',
            },
            {
                value: 'yearly',
                label: '.yearly',
            },],
            card_list: [],
            selectCard: {},
            namecard: '',
            subscriptionPack: {},
            license1: {},
            license2: {},
            checkUpdateReponse: {},
            payCardButton: false,
            cardShow: false,
            arrowHide: true,
            paymentReponse: {},
            subDomainName: "",
            selectLicenseSet: ''

        };
    }
    async componentDidMount() {
        var lan = localStorage.getItem('locale') || 'en';
        if (lan && lan.length > 0) {
            this.setState({ currentLan: lan })
        }
        await this.fetchPackages();
        await this.subscriptionDataApi();
        await this.getCards();
        await this.getOrignazation();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });


    }
    getOrignazation = () => {
        const { client } = this.props;

        client
            .query({
                query: ORGANIZATION_QUERY,
                fetchPolicy: 'network-only'
            })
            .then(({ data }) => {
                if (data.organization) {
                    const { organization } = data
                    this.setState({ vat: organization?.billing?.vat })
                    this.setState({ company_name: organization?.billing?.companyName })
                    this.setState({ city: organization?.billing?.address?.city })
                    this.setState({ country: organization?.billing?.address?.country })
                    this.setState({ country_code: organization?.billing?.address?.country_code })
                    this.setState({ street: organization?.billing?.address?.street })
                    this.setState({ phone_number: organization?.billing?.address?.phone })
                    this.setState({ firstname: organization?.billing?.managerName })
                    this.setState({ sector: organization?.billing?.industry })
                    this.setState({ employees: organization?.billing?.size })
                    this.setState({ email: organization?.admin?.email })
                    this.setState({ zip_code: organization?.billing?.address?.zip_code })
                    this.setState({ subDomainName: organization?.subDomainName })
                    this.setState({ name: organization?.billing?.lastName })




                }
            }).catch(e => {
                this.setState({ isLoading: false });
            })
    }
    withCard = (token, valueset, isPrimaryValue) => {
        const { packDetails, selectCard, subscriptionPack } = this.state;
        const {

            notificationsProvider,
            t,
            match: {
                params: { licenseId },
            }

        } = this.props
        try {
            this.setState({ payCardButton: true })

            const { client, history } = this.props;


            const values = {
                "stripeToken": token,
                "isPrimary": isPrimaryValue,
                "userStripeSourceId": "",
                licenseId: licenseId,
                oldPackageId: subscriptionPack?.packageId,
                oldPackageUuid: subscriptionPack?.package?.uid,
                newPackageId: packDetails?.id,
                newPackageUuid: packDetails?.data?.uid
            }
            client
                .mutate({
                    mutation: CHANGE_SUBSCRIPTION,
                    variables: values,
                })
                .then(({ data }) => {
                    const { subscriptionPackageUpdate } = data;
                    this.setState({ paymentReponse: subscriptionPackageUpdate })
                    const notification = new NotificationModel({
                        title: '',
                        message: t('.upgrade_success_message'),
                        type: 'success',
                    })

                    notificationsProvider.add(notification)
                    // history.push('/');
                    this.setState({ step: 2 })
                    this.setState({ payCardButton: false })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                })
                .catch(({ graphQLErrors }) => {
                    var errorDisplay = '';
                    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                        graphQLErrors[0].extensions.errors.map(item => {
                            errorDisplay += ' ' + item.detail
                        })
                        const notification = new NotificationModel({
                            title: '',
                            message: errorDisplay,
                            type: 'alert',
                        })

                        notificationsProvider.add(notification)
                        this.setState({ payCardButton: false })

                    }
                    else {
                        const notification = new NotificationModel({
                            title: '',
                            message: t('.server_error'),
                            type: 'alert',
                        })

                        notificationsProvider.add(notification)
                        this.setState({ payCardButton: false })

                    }
                    // throw error;
                });
        }
        catch (e) {
            const notification = new NotificationModel({
                title: '',
                message: t('.server_error'),
                type: 'alert',
            })

            notificationsProvider.add(notification)
            this.setState({ payCardButton: false })

        }
    }
    withOutCard = () => {
        const { packDetails, selectCard, subscriptionPack } = this.state;
        const {

            notificationsProvider,
            t,
            match: {
                params: { licenseId },
            }

        } = this.props
        try {
            this.setState({ payCardButton: true })
            const { client, history } = this.props;
            const { packDetails } = this.state;

            var values = {
                "stripeToken": "",
                "isPrimary": false,
                "userStripeSourceId": selectCard.id,
                licenseId: licenseId,
                oldPackageId: subscriptionPack?.packageId,
                oldPackageUuid: subscriptionPack?.package?.uid,
                newPackageId: packDetails?.id,
                newPackageUuid: packDetails?.data?.uid
            }
            client
                .mutate({
                    mutation: CHANGE_SUBSCRIPTION,
                    variables: values,
                })
                .then(({ data }) => {
                    const { subscriptionPackageUpdate } = data;
                    this.setState({ paymentReponse: subscriptionPackageUpdate })
                    const notification = new NotificationModel({
                        title: '',
                        message: t('.upgrade_msg_success'),
                        type: 'success',
                    })

                    notificationsProvider.add(notification)
                    this.setState({ step: 2 })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                })
                .catch(({ graphQLErrors }) => {
                    var errorDisplay = '';
                    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                        graphQLErrors[0].extensions.errors.map(item => {
                            errorDisplay += ' ' + item.detail
                        })
                        const notification = new NotificationModel({
                            title: '',
                            message: errorDisplay,
                            type: 'alert',
                        })

                        notificationsProvider.add(notification)
                        this.setState({ payCardButton: false })

                    }
                    else {
                        const notification = new NotificationModel({
                            title: '',
                            message: t('.server_error'),
                            type: 'alert',
                        })

                        notificationsProvider.add(notification)
                        this.setState({ payCardButton: false })

                    }
                });
        }
        catch (e) {
            const notification = new NotificationModel({
                title: '',
                message: t('.server_error'),
                type: 'alert',
            })

            notificationsProvider.add(notification)
            this.setState({ payCardButton: false })

        }

    }
    apiCall = () => {

        const {

            notificationsProvider,
            history,
            t,
            client,
            match: {
                params: { licenseId },
            }

        } = this.props
        const { subscriptionPack, packDetails } = this.state
        var variables = {
            licenseId: licenseId,
            oldPackageId: subscriptionPack?.packageId,
            oldPackageUuid: subscriptionPack?.package?.uid,
            newPackageId: packDetails?.id,
            newPackageUuid: packDetails?.data?.uid
        }

        client
            .mutate({
                mutation: CHECK_FOR_UPDATE,
                variables,
            })
            .then(({ data }) => {
                const { checkForUpdate } = data
                if (!checkForUpdate) {
                    return;
                }
                if (checkForUpdate?.updateType && checkForUpdate?.updateType === "Downgrade") {
                    const {
                        loadModal,
                        t
                    } = this.props
                    loadModal({
                        modalType: 'down_grade_popup_model',
                        content: {
                            subscriptionPack,
                            packDetails,
                            licenseId
                        },
                        title: '',
                    })
                }
                else if (checkForUpdate?.updateType && checkForUpdate?.updateType === "Upgrade") {
                    this.setState({ step: 1 })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    this.setState({ checkUpdateReponse: checkForUpdate })
                }
            })
            .catch(({ graphQLErrors }) => {
                var errorDisplay = '';
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                    graphQLErrors[0].extensions.errors.map(item => {
                        errorDisplay += ' ' + item.detail
                    })
                    const notification = new NotificationModel({
                        title: '',
                        message: errorDisplay,
                        type: 'alert',
                    })

                    notificationsProvider.add(notification)
                }
                else {
                    const notification = new NotificationModel({
                        title: '',
                        message: t('.server_error'),
                        type: 'alert',
                    })

                    notificationsProvider.add(notification)
                }
                // throw error;
            });
    }
    paymentApiCall = (token, valueset, isPrimaryValue) => {

        const { packDetails, selectCard, subscriptionPack } = this.state;
        const {

            notificationsProvider,
            t,
            match: {
                params: { licenseId },
            }

        } = this.props
        try {
            this.setState({ payCardButton: true })

            if (selectCard && selectCard.stripeSourceId && valueset === "selectOldCard") {
                const { client, history } = this.props;
                const { packDetails } = this.state;


                var values = {
                    "stripeToken": "",
                    "isPrimary": isPrimaryValue,
                    "userStripeSourceId": selectCard.id,
                    licenseId: licenseId,
                    oldPackageId: subscriptionPack?.packageId,
                    oldPackageUuid: subscriptionPack?.package?.uid,
                    newPackageId: packDetails?.id,
                    newPackageUuid: packDetails?.data?.uid
                }
                client
                    .mutate({
                        mutation: CHANGE_SUBSCRIPTION,
                        variables: values,
                    })
                    .then(() => {
                        const notification = new NotificationModel({
                            title: '',
                            message: 'Your plan upgrade successful....',
                            type: 'success',
                        })

                        notificationsProvider.add(notification)
                        history.push('/');
                    })
                    .catch(({ graphQLErrors }) => {
                        var errorDisplay = '';
                        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                            graphQLErrors[0].extensions.errors.map(item => {
                                errorDisplay += ' ' + item.detail
                            })
                            const notification = new NotificationModel({
                                title: '',
                                message: errorDisplay,
                                type: 'alert',
                            })

                            notificationsProvider.add(notification)
                            this.setState({ payCardButton: false })

                        }
                        else {
                            const notification = new NotificationModel({
                                title: '',
                                message: t('.server_error'),
                                type: 'alert',
                            })

                            notificationsProvider.add(notification)
                            this.setState({ payCardButton: false })

                        }
                    });
            }
            else if (valueset === "newCardAdd") {
                const { client, history } = this.props;


                const values = {
                    "stripeToken": token,
                    "isPrimary": isPrimaryValue,
                    "userStripeSourceId": "",
                    licenseId: licenseId,
                    oldPackageId: subscriptionPack?.packageId,
                    oldPackageUuid: subscriptionPack?.package?.uid,
                    newPackageId: packDetails?.id,
                    newPackageUuid: packDetails?.data?.uid
                }
                client
                    .mutate({
                        mutation: CHANGE_SUBSCRIPTION,
                        variables: values,
                    })
                    .then(() => {
                        const notification = new NotificationModel({
                            title: '',
                            message: t('.upgrade_success_message'),
                            type: 'success',
                        })

                        notificationsProvider.add(notification)
                        history.push('/');
                        this.setState({ payCardButton: false })

                    })
                    .catch(({ graphQLErrors }) => {
                        var errorDisplay = '';
                        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                            graphQLErrors[0].extensions.errors.map(item => {
                                errorDisplay += ' ' + item.detail
                            })
                            const notification = new NotificationModel({
                                title: '',
                                message: errorDisplay,
                                type: 'alert',
                            })

                            notificationsProvider.add(notification)
                            this.setState({ payCardButton: false })

                        }
                        else {
                            const notification = new NotificationModel({
                                title: '',
                                message: t('.server_error'),
                                type: 'alert',
                            })

                            notificationsProvider.add(notification)
                            this.setState({ payCardButton: false })

                        }
                        // throw error;
                    });
            }
            else {
                this.setState({ payCardButton: false })

            }
        }
        catch (e) {
            const notification = new NotificationModel({
                title: '',
                message: t('.server_error'),
                type: 'alert',
            })

            notificationsProvider.add(notification)
            this.setState({ payCardButton: false })

        }


    }
    pageViewCount = (item) => {
        var count = (item.isCookie && item.isPolicy) ? Math.min(item.cTrafficBucket, item.pTrafficBucket) : item.isCookie ? item.cTrafficBucket : item.isPolicy ? item.pTrafficBucket : '';
        if (count) {
            count = new Intl.NumberFormat('fr-FR').format(count)
        }
        return count;
    }
    languageCount = (item, value) => {
        const { t } = this.props;

        if (value === 'langage') {
            var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLang, item.pNoOfLang) : item.isCookie ? item.cNoOfLang : item.isPolicy ? item.pNoOfLang : '';
            count = count >= 2 ? `${t('.up_to')} ${(count)}` : count.toString();;
            return count;
        }
        if (value === 'legislations') {
            var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLegis, item.pNoOfLegis) : item.isCookie ? item.cNoOfLegis : item.isPolicy ? item.pNoOfLegis : '';
            return count;
        }
        if (value === "users") {
            var count;
            if (!item.cNoOfUsers && item.cNoOfUsers !== 0 && !item.pNoOfUsers && item.pNoOfUsers !== 0) {
                count = 'Unlimited'
            }
            else if (!item.cNoOfUsers && item.cNoOfUsers === 0 && !item.pNoOfUsers && item.pNoOfUsers === 0) {
                count = '1'
            }
            else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers !== item.cNoOfUsers)) {
                count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
            }
            else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers === item.cNoOfUsers)) {
                count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
            }
            else if (item.cNoOfUsers || item.pNoOfUsers || item.pNoOfUsers === 0 || item.cNoOfUsers === 0) {
                if (item.cNoOfUsers || item.cNoOfUsers === 0) {
                    count = item.cNoOfUsers + 1
                }
                if (item.pNoOfUsers || item.pNoOfUsers === 0) {
                    count = item.pNoOfUsers + 1
                }
            }
            else {
                count = "1"
            }
            return count;
        }
    }
    fetchPackages = () => {
        const { client } = this.props;
        client
            .query({
                query: GET_PACKAGES,
                fetchPolicy: 'network-only'
            })
            .then(({ data }) => {
                if (data.packages) {
                    var activePackage = data.packages.filter(item => item.isActive)
                    this.setState({ activePackage });
                }
            })
    }
    subscriptionDataApi = () => {
        const { client, match: {
            params: { id, licenseId },
        } } = this.props;
        client.query({
            query: ID_WISE_SUBSCRIPTION,
            fetchPolicy: 'network-only',
            variables: {
                id: id
            },
        }).then(({ data }) => {
            const { subscription } = data;
            const { paymentMode, licenses } = subscription
            var license1 = licenses?.filter(item => Number(item.id) === Number(licenseId))
            var license2 = licenses?.filter(item => Number(item.id) !== Number(licenseId))
            this.setState({ subscriptionPack: subscription })
            this.setState({ paymentMode, license1: license1[0] ? license1[0] : {}, license2: license2[0] ? license2[0] : {} })
        })
    }
    getCards = () => {
        const { client } = this.props;
        client
            .query({
                query: GET_CARDS,
                fetchPolicy: 'network-only'
            })
            .then(({ data }) => {
                if (data.userStripeSources) {
                    const sortArray = data.userStripeSources.sort((a, b) => b.isPrimary - a.isPrimary);
                    this.setState({ card_list: sortArray })

                    var selectCardDisplay = sortArray.find((item) => item.isPrimary)
                    if (selectCardDisplay) {
                        this.setState({ selectCard: selectCardDisplay })
                    }
                    // this.setState({ card_list: data.userStripeSources })

                }
            })
    }
    stephandleChange = async (e) => {
        const {
            target: { value, name },
        } = e;
        await this.setState({ [name]: value });
    }
    Totalpersonalisation = (item, code) => {

        var total = false;
        if (code === 'Personalisation') {
            item.cookieFeatures.forEach(element => {
                if (element.feature_group.code === 'personalisation') {
                    total = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.feature_group.code === 'personalisation') {
                    total = true
                }
            });
        }
        if (code === 'format_web_version') {
            item.cookieFeatures.forEach(element => {
                if (element.code === 'format_web_version') {
                    total = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.code === 'format_web_version') {
                    total = true
                }
            });
        }
        if (code === 'format_pdf') {
            item.cookieFeatures.forEach(element => {
                if (element.code === 'format_pdf') {
                    total = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.code === 'format_pdf') {
                    total = true
                }
            });
        }
        return total;
    }
    inprogressButton = (value) => {
        this.setState({ payCardButton: value })
    }
    loadPaymentModal = () => {
        const { loadModal, t } = this.props;

        loadModal({
            modalType: 'confirm_payment_model',
            content: {
                dataApiset: () => {
                    this.withOutCard()
                }
            },
            t,
            title: '',
        })
    }
    loadCardPaymentModal = (token, valueset, isPrimaryValue) => {
        const { loadModal, t } = this.props;
        this.setState({ payCardButton: false })

        loadModal({
            modalType: 'confirm_payment_model',
            content: {
                dataApiset: () => {
                    this.withCard(token, valueset, isPrimaryValue)
                }
            },
            t,
            title: '',
        })
    }
    dateSetChange = (value) => {
        if (!value) {
            return '';
        }
        var date = new Date(value)
        var lan = localStorage.getItem('locale') || 'en';
        return date.toLocaleString(lan, { dateStyle: 'full' })
    }
    subDomainUrl = (subDomainName) => {
        if (!subDomainName) {
            return '-'
        }
        return process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', subDomainName)
    }
    render() {
        const { subDomainName, street, vat, firstname, name, sector, employees, company_name, zip_code, city, country, phone_number, paymentReponse, arrowHide, subscriptionPack, email, cardShow, plan_list, payCardButton, step, activePackage, checkUpdateReponse, card_list, selectCard, currentLan, packDetails, paymentMode, license1, license2 } = this.state;
        const { t, history } = this.props;
        const htmlContent = {
            __html: t('.payment_success_message_redirect')
        };
        return (
            <>
                <AuthWrapper>
                    <main>
                        <div className='main_content'>
                            {step === 0 ? (
                                <>
                                    <div className="margin-bottom-20">
                                        <div className="row">
                                            <div className="columns">
                                                <h1>
                                                    <i className='fas fa-arrow-left margin-right-20 cursor-pointer' onClick={(e) => {
                                                        e.preventDefault();
                                                        history.goBack();

                                                    }} />
                                                    {license1?.name}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-bottom-20">
                                        <div className="row">
                                            <h3><span style={{ marginRight: 20 }}>
                                                {t('.purchase_of_desc_text')}</span>
                                                <span style={{ fontWeight: 200 }}>{(subscriptionPack?.startAt) ? this.dateSetChange(subscriptionPack?.startAt) : '-'}</span></h3>
                                        </div>
                                        {/* <div className="row">
                                            <div className="columns small-2 large-3">
                                                <h3>{t('.change_pack_included_title')}</h3>
                                            </div>
                                            <div className="columns small-4 large-3"> {subscriptionPack?.endAt}</div>
                                        </div> */}
                                    </div>


                                    <div className="margin-bottom-20">
                                        <div className="row">
                                            <h3><span style={{ marginRight: 20 }}>
                                                {t('.change_pack_included_title')}</span>
                                                <span style={{ fontWeight: 200 }}>{(subscriptionPack?.endAt) ? this.dateSetChange(subscriptionPack?.endAt) : '-'}</span></h3>
                                        </div>
                                        {/* <div className="row">
                                            <div className="columns small-2 large-3">
                                                <h3>{t('.change_pack_included_title')}</h3>
                                            </div>
                                            <div className="columns small-4 large-3"> {subscriptionPack?.endAt}</div>
                                        </div> */}
                                    </div>
                                    <div className="margin-bottom-20">
                                        <div className="row">
                                            <h3><span style={{ marginRight: 20 }}>
                                                {t('.change_pack_included')}</span>
                                                <span style={{ fontWeight: 200 }}>{license2?.name}</span></h3>
                                        </div>

                                        {/* <div className="row">
                                    <div className="columns small-2 large-3">{t('.vat_number')}</div>
                                    <div className="columns small-4 large-3">fu9dfuidyfidyf</div>
                                </div> */}
                                    </div>

                                    <div className='row collapse align-bottom align-justify margin-bottom-40'>

                                        <div className='row'>
                                            <div className='column'>
                                                <h1>{t('.purchase_desc')}</h1>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row package-box margin-bottom-40">
                                        {activePackage && activePackage.length > 0 ? (
                                            <>
                                                {
                                                    activePackage.map(item => {
                                                        return (
                                                            <div className={`columns medium-3 margin-bottom-40`} onClick={() => {
                                                                if (subscriptionPack?.package?.uid !== item?.data?.uid) {
                                                                    this.setState({ packDetails: item })
                                                                }
                                                            }}>
                                                                <div className={`card  ${packDetails?.uid === item.uid ? 'active' : ''}  ${subscriptionPack?.package?.uid === item?.data?.uid ? 'published-new-current' : ''}`}>
                                                                    <h4>{currentLan === 'en' ? item.name[0].en : item.name[1].fr} </h4>
                                                                    <span className="package-name">{currentLan === 'en' ? item.description[0].en : item.description[1].fr} </span>
                                                                    {paymentMode === 'monthly' ? <p>€{item.monthlyPrice} {t('.per_month_per_website')} </p> : <p>€{item.yearlyPrice} {t('.per_year_per_website')} </p>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : null}
                                    </div>

                                    {activePackage && activePackage.length > 0 ? (
                                        <div className="row package-box margin-bottom-40">
                                            <div className='text-form auth_form table-new-user' style={{ margin: 0 }}>
                                                <table className='unstriped '>
                                                    <PackageList activePackage={activePackage} />
                                                </table>
                                            </div>

                                        </div>
                                    ) : null}
                                    <div className='row'>
                                        <div className='button-end'>
                                            <Button
                                                className='btn btn_pill is-blue'
                                                type='submit'
                                                disabled={!packDetails || !packDetails.id}
                                                onClick={() => {
                                                    const {

                                                        notificationsProvider,

                                                    } = this.props
                                                    if (packDetails && packDetails.id) {
                                                        this.apiCall();
                                                    }
                                                    else {
                                                        const notification = new NotificationModel({
                                                            title: '',
                                                            message: t('.select_plan_error'),
                                                            type: 'alert',
                                                        })

                                                        notificationsProvider.add(notification)
                                                    }
                                                }}
                                            >
                                                {t('.nextstep')}
                                            </Button>
                                        </div>

                                    </div>

                                </>
                            ) : null}
                            {step === 1 ? (
                                <>
                                    <div className='row margin-bottom-20'>
                                        <div className='column'>
                                            <h2>{t('.checkUpdateReponseAmount')} {checkUpdateReponse.amount}€ {t('.checkUpdateReponseAmountAfter')}</h2>
                                        </div>
                                    </div>



                                    <div className='row'>
                                        <div className='payment-method'>
                                            <h2 className='payment-block-title'>{t('.select_card')}</h2>
                                            <div className="payment-method-wrapper">
                                                <div className='payment-method-left'>
                                                    {card_list && card_list.length > 0 ? (
                                                        <>
                                                            {
                                                                card_list.map(item => {
                                                                    return (
                                                                        <div className={`payment-method-left-inner  ${selectCard?.stripeSourceId === item.stripeSourceId ? 'selected' : ''} `} onClick={() => {
                                                                            this.setState({ selectCard: item })
                                                                            this.setState({ cardShow: false })

                                                                        }}>

                                                                            <div className="checkbox">
                                                                                <input type="radio" name="radiobtn" checked={selectCard?.stripeSourceId === item.stripeSourceId} />
                                                                                <label className="custome-radio"></label>
                                                                            </div>
                                                                            <div className='card-detail'>
                                                                                <span className='card-holder'>{item.name ? item.name : '-'}</span>
                                                                                <div className="card-detail-inner">
                                                                                    <span className='card-number'>{item.cardType ? item.cardType : '-'} **** {item.last4 ? item.last4 : '-'}</span>
                                                                                    <span className='card-cvv'>{t('.exp_date')} {item.exMonth}/{item.exYear}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-image'>
                                                                                <img src={cardImage} alt="Card Icon" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : null}
                                                    <div className={`payment-method-left-inner border-set-addcard`} onClick={() => {
                                                        this.setState({ cardShow: true })
                                                        this.setState({ selectCard: {} })
                                                    }}>
                                                        <div className='card-detail'>
                                                            <span className='card-holder plusicon'><AiOutlinePlus /> {t('.add_new_card')}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='payment-method-right'>
                                                    <div className="row">
                                                        <div className="columns small-2 large-8">
                                                            <PaymentBuyChangeForm payButton={payCardButton} paysuccess={this.loadCardPaymentModal} productName={currentLan === 'en' ? packDetails?.name[0]?.en : packDetails?.name[1]?.fr} email={email} monthlyPrice={checkUpdateReponse?.amountIncludedVat ? parseFloat(checkUpdateReponse?.amountIncludedVat).toFixed(2) : "0.00"} inprogressButton={this.inprogressButton} cardShow={cardShow} includeTaxShow={checkUpdateReponse?.isTax} validateCompanyData={checkUpdateReponse} />
                                                        </div>
                                                    </div>
                                                    {!cardShow ? (
                                                        <div className="row" onClick={() => {
                                                            this.loadPaymentModal();
                                                        }} >
                                                            <div className="columns small-2 large-8">
                                                                <button className='paymentselectbutton'>
                                                                    {!payCardButton ? t('.pay_with_select_card') : t('.payment_inprogress_msg')}   <img src={cardimageNew} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div id={"packpaymentdetails"} >

                                        <div className="row package-box  package-box-inner-wrapp-section margin-bottom-20">
                                            <div className='row margin-bottom-20' style={{ width: "100%" }}>
                                                <div className='column arrowset' onClick={() => {
                                                    this.setState({ arrowHide: !arrowHide })
                                                }}>
                                                    <h2>{t('.pack_detail')}</h2>
                                                    <img src={arrowHide ? arrowdown : arrowup} />
                                                </div>
                                            </div>
                                            {!arrowHide ? (
                                                <div className=" package-inner-sec-main" style={{ paddingBottom: "15px" }}>
                                                    <div className="columns medium-4 card-payment-section-inner-wrapper">
                                                        <div className="card back-ground-blue" style={{ height: "50%" }}>

                                                            <h4>{currentLan === 'en' ? packDetails.name[0].en : packDetails.name[1].fr} </h4>
                                                            <span className="package-name">{currentLan === 'en' ? packDetails.description[0].en : packDetails.description[1].fr} </span>
                                                            {paymentMode === 'monthly' ? <p className="font-color">€{parseFloat(checkUpdateReponse?.amountIncludedVat).toFixed(2)} {t('.per_month_per_website')} </p> : <p className="font-color">€{parseFloat(checkUpdateReponse?.amountIncludedVat).toFixed(2)} {t('.per_year_per_website')} </p>}
                                                            {/* {paymentMode === 'monthly' ? <p className="font-color">€{this.taxCount(packDetails.monthlyPrice, 21)} {t('.per_month_per_website')} </p> : <p className="font-color">€{this.taxCount(packDetails.yearlyPrice, 21)} {t('.per_year_per_website')} </p>} */}
                                                            {/* {paymentMode === 'monthly' ? <p className="font-color">€{packDetails.monthlyPrice} </p> : <p className="font-color">€{packDetails.yearlyPrice} {t('.per_year_per_website')} </p>} */}
                                                            <span className="pricetext">{t('.price_includes')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="columns medium-8 left-dark-sec">
                                                        <div className='text-form '>
                                                            <table className='unstriped table-new-user'>
                                                                <PackageList activePackage={[packDetails]} />

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}


                                        </div>

                                    </div>
                                    <div>
                                        <div className='row'>
                                            <Button
                                                className='btn btn_pill'
                                                onClick={() => {

                                                    this.setState({ step: 0 });
                                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                                }}
                                            >

                                                <AiOutlineArrowLeft className='icon-class' />
                                                {t('.back_to_company')}
                                            </Button>
                                        </div>

                                    </div>





                                </>
                            ) : null}
                            {step === 2 ? (
                                <>
                                    <div className='payment-success'>
                                        <div className='check-image'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                                <path d="M35.8477 8.45989C38.1238 6.51337 41.8514 6.51337 44.1606 8.45989L49.3726 12.9468C50.3622 13.8045 52.2095 14.4974 53.5291 14.4974H59.1369C62.6336 14.4974 65.5036 17.3677 65.5036 20.8648V26.4734C65.5036 27.7601 66.1963 29.6406 67.054 30.6303L71.5403 35.843C73.4866 38.1195 73.4866 41.8475 71.5403 44.157L67.054 49.3697C66.1963 50.3594 65.5036 52.207 65.5036 53.5266V59.1352C65.5036 62.6323 62.6336 65.5026 59.1369 65.5026H53.5291C52.2425 65.5026 50.3622 66.1954 49.3726 67.0532L44.1606 71.5401C41.8844 73.4866 38.1568 73.4866 35.8477 71.5401L30.6356 67.0532C29.646 66.1954 27.7987 65.5026 26.4792 65.5026H20.7723C17.2756 65.5026 14.4057 62.6323 14.4057 59.1352V53.4936C14.4057 52.207 13.713 50.3594 12.8883 49.3697L8.43496 44.124C6.52168 41.8475 6.52168 38.1525 8.43496 35.876L12.8883 30.6303C13.713 29.6406 14.4057 27.7931 14.4057 26.5064V20.8318C14.4057 17.3347 17.2756 14.4644 20.7723 14.4644H26.4792C27.7657 14.4644 29.646 13.7716 30.6356 12.9138L35.8477 8.45989Z" fill="#46C246" />
                                                <path d="M26 40.4333L35.4333 49.8667L54.3333 31" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='success-color'>
                                            <p>{t('.payment_success_message')}</p>
                                        </div>
                                        <span className='redirectlink' dangerouslySetInnerHTML={htmlContent}></span>
                                    </div>
                                    <div className='row collapse align-bottom align-justify margin-bottom-20'>
                                        <div className='column '>
                                            <h1 className='payment-title'>{t('.payment_overview')}</h1>
                                        </div>
                                    </div>
                                    <div className="margin-bottom-20  payment-overview">
                                        {/* <div className="row margin-bottom-20">
                            <div className="columns small-3 large-4">
                              <h3>{t('.payment_via')}</h3>
                            </div>
                          </div> */}
                                        <div className="row">
                                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_status')}</p></div>
                                            <div className=" small-4 large-4"><p className='field-text'>{t('.payment_via')}</p></div>
                                        </div>
                                        <div className="row">
                                            <div className=" small-2 large-2"><p className='field-label'>{t('.amount_paid')}</p></div>
                                            <div className=" small-4 large-4"><p className='field-text'>€{paymentReponse?.total}</p></div>
                                        </div>
                                        <div className="row">
                                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_method')}</p></div>
                                            <div className=" small-4 large-4">
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ marginRight: 5 }}>
                                                        {paymentReponse?.paymentMethod === "Visa" ?

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" fill="none">
                                                                <path d="M1.92 0H35.52C36.5804 0 37.44 0.859632 37.44 1.92V22.08C37.44 23.1404 36.5804 24 35.52 24H1.92C0.859632 24 0 23.1404 0 22.08V1.92C0 0.859632 0.859632 0 1.92 0Z" fill="#0E4595" />
                                                                <path d="M14.0736 16.739L15.6749 7.34249H18.2362L16.6337 16.739H14.0736ZM25.8869 7.54505C25.3795 7.35468 24.5843 7.15039 23.5914 7.15039C21.0606 7.15039 19.2779 8.42479 19.2628 10.2513C19.2484 11.6016 20.5354 12.3548 21.5069 12.8043C22.5039 13.2648 22.8389 13.5585 22.8342 13.9698C22.828 14.5997 22.0381 14.8874 21.302 14.8874C20.2768 14.8874 19.7322 14.745 18.8911 14.3942L18.561 14.2449L18.2016 16.3484C18.7998 16.6107 19.906 16.8379 21.0546 16.8497C23.7469 16.8497 25.4946 15.5898 25.5146 13.6393C25.5241 12.5704 24.8418 11.757 23.3641 11.0863C22.4689 10.6517 21.9207 10.3615 21.9265 9.92143C21.9265 9.53085 22.3905 9.11316 23.3932 9.11316C24.2308 9.1002 24.8375 9.28284 25.3102 9.47316L25.5397 9.58164L25.8869 7.54505ZM32.4777 7.34234H30.4986C29.8855 7.34234 29.4267 7.50972 29.1574 8.12157L25.3537 16.7328H28.0432C28.0432 16.7328 28.4829 15.5749 28.5823 15.3207C28.8763 15.3207 31.489 15.3247 31.8626 15.3247C31.9392 15.6537 32.1741 16.7328 32.1741 16.7328H34.5508L32.4777 7.34205V7.34234ZM29.3377 13.41C29.5494 12.8686 30.3581 10.7833 30.3581 10.7833C30.343 10.8084 30.5683 10.2393 30.6977 9.88649L30.8707 10.6967C30.8707 10.6967 31.3612 12.9397 31.4637 13.41H29.3377ZM11.8993 7.34234L9.39174 13.7503L9.12453 12.4481C8.65773 10.947 7.20333 9.32066 5.57742 8.50644L7.87019 16.724L10.5801 16.721L14.6123 7.34225H11.8993" fill="white" />
                                                                <path d="M7.05218 7.34248H2.92221L2.88953 7.53798C6.10255 8.31577 8.22857 10.1954 9.11129 12.4537L8.21321 8.13558C8.05817 7.54062 7.60855 7.36307 7.05228 7.34229" fill="#F2AE14" />
                                                            </svg> :
                                                            <svg width="38" height="24" viewBox="0 0 39 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M36.5156 0.0357666H1.55564C0.719645 0.0357666 0.0356445 0.719767 0.0356445 1.55577V3.83577H38.0356V1.55577C38.0356 0.719767 37.3516 0.0357666 36.5156 0.0357666Z" fill="#AAB2BC" />
                                                                <path d="M36.5156 1.55579H1.55564C0.719645 1.55579 0.0356445 2.23979 0.0356445 3.07579V3.88329H38.0356V3.07579C38.0356 2.23979 37.3516 1.55579 36.5156 1.55579Z" fill="#F2FAFF" />
                                                                <path d="M38.0356 23.5958C38.0356 24.4318 37.3516 25.1158 36.5156 25.1158H1.55564C0.719645 25.1158 0.0356445 24.4318 0.0356445 23.5958V3.8358C0.0356445 2.9998 0.719645 2.3158 1.55564 2.3158H36.5156C37.3516 2.3158 38.0356 2.9998 38.0356 3.8358V23.5958Z" fill="#D8E0EA" />
                                                                <path d="M23.8128 6.11584C22.0028 6.11584 20.3409 6.74893 19.0356 7.80523C20.7573 9.1985 21.8585 11.3284 21.8585 13.7158C21.8585 16.1033 20.7573 18.2332 19.0356 19.6265C20.3409 20.6828 22.0028 21.3158 23.8128 21.3158C28.0103 21.3158 31.4128 17.9133 31.4128 13.7158C31.4128 9.51836 28.0103 6.11584 23.8128 6.11584Z" fill="#F79F1A" />
                                                                <path d="M16.2125 13.7158C16.2125 11.3284 17.3137 9.1985 19.0354 7.80523C17.7301 6.74893 16.0682 6.11584 14.2582 6.11584C10.0607 6.11584 6.6582 9.51836 6.6582 13.7158C6.6582 17.9133 10.0607 21.3158 14.2582 21.3158C16.0682 21.3158 17.7301 20.6828 19.0354 19.6265C17.3137 18.2332 16.2125 16.1033 16.2125 13.7158Z" fill="#EA0A1B" />
                                                                <path d="M21.8585 13.7158C21.8585 11.3283 20.7574 9.19845 19.0357 7.80518C17.314 9.19845 16.2129 11.3283 16.2129 13.7158C16.2129 16.1032 17.314 18.2331 19.0357 19.6264C20.7574 18.2331 21.8585 16.1032 21.8585 13.7158Z" fill="#FF5F01" />
                                                            </svg>
                                                        }


                                                    </div>
                                                    <p className='field-text'>
                                                        {paymentReponse?.paymentMethod} ending XXXX.{paymentReponse?.userStripeSource?.last4}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" small-2 large-2"><p className='field-label'>{t('.payment_time')}</p></div>
                                            <div className=" small-4 large-4"><p className='field-text'>{this.dateSetChange(paymentReponse?.createdAt)}</p></div>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                          <Button
                            className='btn btn_pill is-blue'
                            onClick={() => {
                              sessionStorage.setItem("stateData", "")
                              window.location = process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', sub_domain)
                            }}
                          >
                            {t('.continue_application')}
                          </Button>
                        </div> */}
                                    <div className='row collapse align-bottom align-justify margin-bottom-40'>
                                        <div className='row'>
                                            <div className='column'>
                                                <h1 className='order-summary-title'>{t('.confirm_of_your_order')}</h1>
                                                <h2 className='order-summary-sub-title'>{t('.company_details')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="detail-wrapper row">
                                        <div className="detail-wrapper-left">
                                            <div className="margin-bottom-20">
                                                <div className="row">
                                                    <div className="columns small-2 large-12">
                                                        <h3>{t('.vat')}</h3>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.vat_number')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{vat ? vat : t('.no_vat_number')}</p></div>
                                                </div>
                                            </div>
                                            <div className="margin-bottom-20 payment-success-overview-detail">
                                                <div className="row">
                                                    <div className="columns small-2 large-12">
                                                        <h3>{t('.account_tenant')}</h3>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.firstname')}</p></div>
                                                    <div className="columns small-4 large-8 "><p className='field-text'>{firstname}</p></div>
                                                </div>
                                                <div className="row ">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.lastname')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{name ? name : '-'}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.sub_domain')}</p></div>
                                                    <div className="columns small-4 large-8 break-word-set icon-subdomain"><p className='field-text'>
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                            <g clip-path="url(#clip0_125_1763)">
                                                                <path d="M10.1673 4.83484C9.72918 2.13666 8.69583 0.242188 7.49328 0.242188C6.29073 0.242188 5.25738 2.13666 4.81927 4.83484H10.1673ZM4.59266 7.73547C4.59266 8.40624 4.62891 9.04981 4.69237 9.66922H10.2912C10.3546 9.04981 10.3909 8.40624 10.3909 7.73547C10.3909 7.0647 10.3546 6.42112 10.2912 5.80172H4.69237C4.62891 6.42112 4.59266 7.0647 4.59266 7.73547ZM14.4034 4.83484C13.5393 2.78326 11.7898 1.19698 9.62947 0.556422C10.3667 1.57768 10.8743 3.11562 11.1402 4.83484H14.4034ZM5.35407 0.556422C3.19673 1.19698 1.44427 2.78326 0.583146 4.83484H3.84635C4.10922 3.11562 4.61683 1.57768 5.35407 0.556422ZM14.7267 5.80172H11.2611C11.3245 6.43623 11.3608 7.08585 11.3608 7.73547C11.3608 8.38509 11.3245 9.03471 11.2611 9.66922H14.7237C14.8899 9.04981 14.9835 8.40624 14.9835 7.73547C14.9835 7.0647 14.8899 6.42112 14.7267 5.80172ZM3.62578 7.73547C3.62578 7.08585 3.66204 6.43623 3.72549 5.80172H0.259848C0.0966875 6.42112 0 7.0647 0 7.73547C0 8.40624 0.0966875 9.04981 0.259848 9.66922H3.72247C3.66204 9.03471 3.62578 8.38509 3.62578 7.73547ZM4.81927 10.6361C5.25738 13.3343 6.29073 15.2288 7.49328 15.2288C8.69583 15.2288 9.72918 13.3343 10.1673 10.6361H4.81927ZM9.63249 14.9145C11.7898 14.274 13.5423 12.6877 14.4064 10.6361H11.1432C10.8773 12.3553 10.3697 13.8933 9.63249 14.9145ZM0.583146 10.6361C1.44729 12.6877 3.19673 14.274 5.35709 14.9145C4.61985 13.8933 4.11224 12.3553 3.84635 10.6361H0.583146Z" fill="#0028E6" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_125_1763">
                                                                    <rect width="14.9866" height="15.47" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg></span>{this.subDomainUrl(subDomainName)}</p></div>
                                                </div>
                                                <div className="row ">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.email_address')}</p></div>
                                                    <div className="columns small-4 large-8 break-word-set"><p className='field-text'>{email ? email : '-'}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.name_of_packages')}</p></div>
                                                    <div className="columns small-4 large-8 break-word-set"><p className='field-text'>{currentLan === 'en' ? packDetails?.name[0]?.en : packDetails?.name[1]?.fr}</p></div>
                                                </div>

                                                {/* <div className="row">
                      <div className="columns small-2 large-3">Last name</div>
                      <div className="columns small-4 large-3">{name}</div>
                    </div> */}

                                            </div>
                                            {/* <div className="margin-bottom-20">
                              <div className="row">
                                <div className="columns small-2 large-12">
                                  <h3>{t('.company_details')}</h3>
                                </div>
                              </div>
                              <div className="row margin-bottom-20">
                                <div className="columns small-2 large-6">{t('.sector')}</div>
                                <div className="columns small-4 large-6 break-word-set">{this.sectorFind(sector)}</div>
                              </div>
                              <div className="row margin-bottom-20">
                                <div className="columns small-2 large-6">{t('.employee_rate')}</div>
                                <div className="columns small-4 large-6 break-word-set">{this.employeeFind(employees)}</div>
                              </div>
                            </div> */}
                                        </div>
                                        <div className="detail-wrapper-right">
                                            <div className="margin-bottom-20">
                                                <div className="row">
                                                    <div className="columns small-2 large-12">
                                                        <h3>{t('.company_billing_info')}</h3>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.company_name')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{company_name ? company_name : ''}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.street_number')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{street ? street : '-'}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.zip_code')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{zip_code ? zip_code : ''}</p></div>
                                                </div>  <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.city')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{city ? city : '-'}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.country')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{country ? country : "-"}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="columns small-2 large-4"><p className='field-label'>{t('.phone_number')}</p></div>
                                                    <div className="columns small-4 large-8"><p className='field-text'>{phone_number ? phone_number : "-"}</p></div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>







                                    <div id={"packpaymentdetails"} >

                                        <div className="row package-box  package-box-inner-wrapp-section margin-bottom-20">
                                            <div className='row margin-bottom-20' style={{ width: "100%" }}>
                                                <div className='column arrowset' onClick={() => {
                                                    this.setState({ arrowHide: !arrowHide })
                                                    localStorage.setItem('buyExtraState', JSON.stringify(this.state))

                                                }}>
                                                    <h2>{t('.pack_detail')}</h2>
                                                    <img src={arrowHide ? arrowdown : arrowup} />
                                                </div>
                                            </div>
                                            {!arrowHide ? (
                                                <div className="package-inner-sec-main" style={{ paddingBottom: "15px" }}>
                                                    <div className="columns medium-4 card-payment-section-inner-wrapper">
                                                        <div className="card back-ground-blue" style={{ height: "50%" }}>

                                                            <h4>{currentLan === 'en' ? packDetails.name[0].en : packDetails.name[1].fr} </h4>
                                                            <span className="package-name">{currentLan === 'en' ? packDetails.description[0].en : packDetails.description[1].fr} </span>

                                                            {/* <p className="font-color">€{checkUpdateReponse?.amount} {`+  €`}{checkUpdateReponse?.isTax ? (checkUpdateReponse?.amountIncludedVat - checkUpdateReponse?.amount)?.toFixed(2) : "0.00"}  {"VAT"}</p> */}


                                                            {/* {paymentMode === 'monthly' ? <p className="font-color">€{getPackagePurchasePrice?.total ? parseFloat(getPackagePurchasePrice?.total).toFixed(2) : "0.00"} {t('.per_month_per_website')} </p> : <p className="font-color">€{getPackagePurchasePrice?.total ? parseFloat(getPackagePurchasePrice?.total).toFixed(2) : "0.00"} {t('.per_year_per_website')} </p>} */}


                                                            {checkUpdateReponse?.isTax ? (
                                                                <span className="pricetext">{t('.price_includes')}</span>
                                                            ) : null}
                                                            {/* {paymentMode === 'monthly' ? <p className="font-color">€{this.taxCount(packDetails.monthlyPrice, 21)} {t('.per_month_per_website')} </p> : <p className="font-color">€{this.taxCount(packDetails.yearlyPrice, 21)} {t('.per_year_per_website')} </p>}
                              <span className="pricetext">{t('.price_includes')}</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="columns medium-8 left-dark-sec">
                                                        <div className='text-form '>
                                                            <table className='unstriped table-new-user'>
                                                                <PackageList activePackage={[packDetails]} />
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}


                                        </div>

                                    </div>


                                    <div className='bottom-wrapper'>

                                        <div className='row'>
                                            <Button
                                                className='btn btn_pill is-blue'
                                                onClick={() => {
                                                    const { history } = this.props;
                                                    history.push('/');


                                                }}
                                            >
                                                {t('.continue_application')}
                                            </Button>
                                        </div>

                                    </div>
                                </>
                            ) : null}

                        </div>
                    </main>

                </AuthWrapper>
            </>
        );
    }
}

const CHECK_FOR_UPDATE = gql`
mutation CheckForUpdate($licenseId: ID!, $oldPackageId: ID!, $oldPackageUuid: String!, $newPackageId: ID!, $newPackageUuid: String!) {
    checkForUpdate(licenseId: $licenseId, oldPackageId: $oldPackageId, oldPackageUuid: $oldPackageUuid, newPackageId: $newPackageId, newPackageUuid: $newPackageUuid) {
        updateType
        amount
        effectOn
        previousRequestId
        subscriptionId
        isRenewed
        amountIncludedVat
        isTax
    }
}
`;
const GET_CARDS = gql`
  query UserStripeSources{
    userStripeSources{
        id
        stripeSourceId
        last4
        exMonth
        exYear
        isPrimary
        name
        cardType
        createdAt
        updatedAt
    }
}
`
const ORGANIZATION_QUERY = gql`
  query {
    organization {
      id
      acceptedAt
      admin {
        email
        id
      }
      billing {
        address
        companyName
        createdAt
        id
        industry
        managerName
        size
        updatedAt
        vat
      }
      createdAt
      name
      registrationIp
      subDomainName
      type
      updatedAt
    }
  }
`
const GET_PACKAGES = gql`
  query packages{
    packages {
        id,
        uid,
        status,
        isActive,
        name,
        description,
        isShow,
        monthlyPrice,
        yearlyPrice,
        duration,
        data {
            uid
            cookieFeatures
        },
        isShow,
        isCookie,
        isPolicy,
        isShow,
        cTrafficBucket,
        cNoOfLang,
        cNoOfLegis,
        cNoOfUsers,
        support,
        legalUpdate,
        cIsVersioning,
        cIsExport,
        pTrafficBucket,
        pNoOfLang,
        pNoOfLegis,
        pNoOfUsers,
        pIsVersioning,
        cookieFeatureIds,
        policyFeatureIds,
        cookieFeatures,
        policyFeatures,
        createdAt,
        updatedAt,
        ifBeyondTheLimit,
        legalDesignWebPolicy,
        dedicatedCsm,
        isBannerConsentInteractionStatistics
    }
}
`
const ID_WISE_SUBSCRIPTION = gql`
  query Subscription($id: ID!) {
    subscription(id: $id) {
        id
        startAt
        endAt
        canceledAt
        subType
        paymentMode
        amount
        stopRenewAt
        packageId
        package{
            uid
            name
        }
        licenses{
            id
            name
        }
        createdAt
        updatedAt
    }
}
`
const CHANGE_SUBSCRIPTION = gql`
  mutation SubscriptionPackageUpdate($licenseId: ID!, $oldPackageId: ID!, $oldPackageUuid: String!, $newPackageId: ID!, $newPackageUuid: String!, $stripeToken: String!, $userStripeSourceId: ID!, $isPrimary: Boolean) {
    subscriptionPackageUpdate(licenseId: $licenseId, oldPackageId: $oldPackageId, oldPackageUuid: $oldPackageUuid, newPackageId: $newPackageId, newPackageUuid: $newPackageUuid, stripeToken: $stripeToken, userStripeSourceId: $userStripeSourceId, isPrimary: $isPrimary) {
        id,
        total,
        paymentMethod,
        createdAt,
        userStripeSource{
          last4
        }
    }
}
`
const mapDispatchToProps = {
    closeExampleModal: hideModal,
    loadModal,
}
export default compose(withApollo, connect(null, mapDispatchToProps), withRouter, withTranslation('account_onboarding'), withNotifications())(ChangeLicense);
