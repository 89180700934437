import React from 'react';
import { compose } from 'recompose';
import { reverse, clone, drop } from 'lodash';

import VersionCard from '../../components/VersionCard';
import withTranslation from '../../providers/I18n/HoC/t';

class VersionsList extends React.Component {
  render() {
    const { privacyPolicyVersions, policyType, ppId, t } = this.props;

    let versionList = clone(privacyPolicyVersions);
    versionList = reverse(versionList);
    versionList = drop(versionList, 1);

    return (
      <div className='versions_page'>
        <div className='archived_version'>
          <div className='version_card-list'>
            {versionList &&
              versionList.map(version => (
                <VersionCard
                  version={version.version}
                  publishedDate={version.publishedAt}
                  archivedDate={version.archivedAt}
                  id={ppId}
                  policyType={policyType}
                  key={version.id}
                  t={t}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation('dashboard'))(VersionsList);
