import React from 'react';

import position_bottom from '../../../../assets/img/cookie_placement-bottom.png';
import position_top from '../../../../assets/img/cookie_placement-top.png';

const BannerPosition = ({ vue, handleChange, t }) => {
  return (
    <div className='customisation__position'>
      <h5> {t('.position')}</h5>
      <div className='cst__position__content'>
        <div
          className={`cst__position__item ${vue === 'bottom' ? 'selected' : ''}`}
          onClick={() => handleChange('bottom', 'vue')}
        >
          <img src={position_bottom} alt='' />
        </div>
        <div
          className={`cst__position__item ${vue === 'top' ? 'selected' : ''}`}
          onClick={() => handleChange('top', 'vue')}
        >
          <img src={position_top} alt='' />
        </div>
      </div>
    </div>
  );
};

export default BannerPosition;
