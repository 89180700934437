// @ts-nocheck
/* eslint max-len: off */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag'
import { compose } from 'recompose'
import connect from 'react-redux/es/connect/connect'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'

import { formattedDate } from '../../helpers/date'
import add from '../../assets/img/add.svg'
import blocked from '../../assets/img/blocked.svg'
import AuthWrapper from '../../components/ProductAuth'
import Loading from '../../components/Loading'
import { withTranslation } from '../../providers/I18n'
import PolicyCard from '../../components/ProductPolicyCard'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'
import { AiOutlineFileAdd, AiOutlineCloseCircle } from 'react-icons/ai'
import Button from 'src/components/Button'
import usePagination from './pagination';
import PaginationControl from './PaginationControl';
import { withApollo } from 'react-apollo';

const CookiePolicies = ({ t, history, userId, client, isSuperAdmin }) => {
  const per_page = 15;
  const [page, setPage] = useState(1);
  const [data, loading, error, paginationObj] = usePagination({
    page,
    query: ALL_PRODUCTS_PRIVACY_POLICIES_QUERY,
    variables: { per_page, user_id: userId, type: 'cookie_policy', page },
    fetchPolicy: 'network-only',
    client,
  });

  const updatePage = useCallback((_, { activePage }) => {
    setPage(activePage);
  }, []);
  return (
    <AuthWrapper>
      <TitleComponent title={t('.my_cookie_policies')} />
      {loading ? (
        <Loading />
      ) : error ? (
        `Error! ${error.message}`
      ) : (
        <Fragment>
          <div className='row privacy-policies'>
            <div className='columns'>
              <div className='row button-buy-extra'>
                <div className='column'>
                  <h1>{t('.my_cookie_policies')}</h1>
                </div>
                {/* {isSuperAdmin ? (
                  <Button className='btn btn_pill is-blue' onClick={() => {
                    localStorage.setItem('buyExtraState', "")

                    history.push('/buylicenses')
                  }}>
                    <i className='fa fa-plus-circle margin-right-10' />
                    {t('.buyextra')}
                  </Button>
                ) : null} */}

              </div>
              <ul className='privacy-policies-list'>
                <li className='privacy-policy-card'>
                  <Link to={`/cookie-policies/new`}>
                    <div className={`add-policy`}>
                      <div className='add-policy-container'>
                        <AiOutlineFileAdd size='3rem' />
                        <span className='text-center'> {t('.add_new_policy')}</span>
                      </div>
                    </div>
                  </Link>
                </li>
                {/*.reverse().map for reverse order*/}
                {data.map(pp => {
                  return <PolicyCard key={pp.id} policyType='cookie' t={t} policy={pp} />;
                })}
              </ul>
            </div>
          </div>
          <PaginationControl paginationObj={paginationObj} setPage={updatePage} />
        </Fragment>
      )}
    </AuthWrapper>
  );
};


const ALL_PRODUCTS_PRIVACY_POLICIES_QUERY = gql`
  query PagyProducts($type: String, $user_id: ID, $page: Int!, $per_page: Int!) {
    pagyProducts(type: $type, userId: $user_id, page: $page, perPage: $per_page) {
      pagination {
        totalCount
        prevPage
        nextPage
        currentPage
        totalPages
      }
      products {
        id
        licenseKey
        parentId
        licenseId
        uid
        title
        status
        version
        createdAt
        updatedAt
        publishedAt
        owner {
          id
          email
          organization {
            name
            subDomainName
            countryCode
          }
        }
      }
    }
  }
`;

const mapStateToProps = ({ auth }) => {
  const { userId, isSuperAdmin } = auth
  return {
    userId,
    isSuperAdmin
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation('privacyPolicies'),
  withApollo,
)(CookiePolicies);
