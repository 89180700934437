import * as React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import { renderCheckboxField } from '../../components/Form/Checkbox';
import UnAuthWrapper from '../../components/UnAuth';
import { submitSignIn } from './submit_validation';
import validate from './validate';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class Signup extends React.Component {
  render() {
    const { submitting, handleSubmit, error, t } = this.props;
    return (
      <UnAuthWrapper title={t('.connection')}>
        <TitleComponent title={t('.connection')} />
        <form className='text-form auth_form' onSubmit={handleSubmit(submitSignIn)}>
          <Field name='email' type='text' label={t('.email')} component={renderInputField} />
          <Field
            name='password'
            type='password'
            label={t('.password')}
            component={renderInputField}
          />
          <div className='auth_form-util'>
            <Field name='remember' label={t('.remember_me')} component={renderCheckboxField} />
            <a href=''> {t('.forgotten_password')} </a>
          </div>
          {error && <p className='validation_error'>{error}</p>}
          <button type='submit' disabled={submitting}>
            {submitting ? t('.waiting') : t('.confirm_my_account')}
          </button>
        </form>
      </UnAuthWrapper>
    );
  }
}

export default compose(
  reduxForm({ form: 'sign_in_form', validate }),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(Signup);
