import React from 'react';

import StyledBanner from '../styled_banner';
import Button from '../../../../components/Button';
import DropdownItem from '../../../Onboarding/CompanyOnboarding/dropdown';
import banner_translation from '../banner_translation';

import HorizontalLanguageSelector from '../../../../components/Form/LanguageSelection/horizontalLanguageSelector';

const BannerPreview = ({
  color,
  theme,
  data,
  cookies_consent,
  text,
  t,
  cookies_available,
  openPreview,
  invalid,
  selectedLanguage,
  language_options,
  onLanguageChange,
  highContract,
  highContractClick,
  mainHightContractShow
}) => (
  <div className='customisation__preview'>
    <div className='flex_row align-justify'>
      <h4> {t('.preview')} </h4>

      <HorizontalLanguageSelector
        handleLanguageChange={onLanguageChange}
        languageOptions={language_options}
        selectedLanguage={selectedLanguage}
        previewButton='true'
        invalid={invalid}
        openPreview={openPreview}
      />
    </div>

    {/*<Button rounded invert expanded onClick={openPreview} style={{ width: "auto", float: "right", marginTop: "-5px"}}>*/}
    {/*    <i className="fas fa-desktop margin-right-10"></i>*/}
    {/*    {t('.visualize')}*/}
    {/*</Button>*/}

    <div className='styled__banner' style={{ marginTop: '40px' }}>
      <StyledBanner
        locale={selectedLanguage}
        color={color}
        light={theme === 'light'}
        position='center'
        theme={theme}
        banner_t={banner_translation?.[selectedLanguage] ? banner_translation?.[selectedLanguage] : banner_translation?.["en"]}
        data={data}
        text={text}
        cookies_consent={cookies_consent}
        cookiesType={data.banner_cookies}
        cookies_available={cookies_available}
        t={t}
        highContract={highContract}
        highContractClick={highContractClick}
        mainHightContractShow={mainHightContractShow}
      />
    </div>
  </div>
);

export default BannerPreview;
