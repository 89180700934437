import React from 'react';
import gql from 'graphql-tag';
import Link from 'react-router-dom/es/Link';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withTranslation } from '../../I18n';
import { AiOutlineRight } from 'react-icons/ai';

class BreadcrumbItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentWillMount() {
    this.getBreadcrumbs();
    const { match } = this.props;
    this.setState({
      policyType: this.isCookiePolicy(match.url) ? 'cookie-policies' : 'privacy-policies',
    });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(nextProps.ids) !== JSON.stringify(this.state.ids)) this.getBreadcrumbs();
  }

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  getBreadcrumbs = () => {
    const { client, ids } = this.props;
    var token = localStorage.getItem("token")
    if (token) {
      client
        .query({
          query: GET_BREADCRUMB_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            ppId: ids[0] || 0,
            catId: ids[1] || 0,
            questId: ids[2] || 0,
          },
        })
        .then(({ data }) => {
          this.setState({
            data,
            ids: ids,
          });
        });
    }
  };

  render() {
    const {
      data: { privacyPolicy, category, fetchQuestionById },
      policyType,
    } = this.state;
    const { pages, t } = this.props;
    const preview = pages.includes('preview');

    return (
      <div className='breadcrumbs'>
        <div className='breadcrumbs_item'>
          <Link to={`/${policyType}`}>
            <div className='breadcrumbs_item-txt'>
              <p> {t('.your_policies')} </p>
            </div>
          </Link>
        </div>

        {privacyPolicy && (
          <div className='breadcrumbs_item'>
            <Link to={`/${policyType}/${privacyPolicy.id}`}>
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {privacyPolicy.title} </p>
              </div>
            </Link>
          </div>
        )}

        {category && (
          <div className='breadcrumbs_item'>
            <Link to={`/${policyType}/${privacyPolicy.id}/categories/${category.id}`}>
              <AiOutlineRight />
              {/* <div className="breadcrumbs_item-img" >
                                    <img src={category.avatar} alt={ category.name }/>
                                </div> */}
              <div className='breadcrumbs_item-txt'>
                <p> {category.name} </p>
              </div>
            </Link>
          </div>
        )}
        {fetchQuestionById && (
          <div className='breadcrumbs_item'>
            <Link
              to={`/${policyType}/${privacyPolicy.id}/categories/${category.id}/questions/${fetchQuestionById.id}`}
            >
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {fetchQuestionById.statement} </p>
              </div>
            </Link>
          </div>
        )}
        {preview && (
          <div className='breadcrumbs_item'>
            <Link to={'/'}>
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {t('.preview')} </p>
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const GET_BREADCRUMB_QUERY = gql`
  query getBreadCrumbs($ppId: ID!, $catId: ID!, $questId: ID!) {
    privacyPolicy(id: $ppId) {
      id
      title
    }
    category(id: $catId) {
      id
      avatar
      name
    }
    fetchQuestionById(id: $questId) {
      id
      statement
    }
  }
`;

export default compose(withRouter, withTranslation('breadcrumbs'))(BreadcrumbItem);
