const emailValidation = (input) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(input);
};

// Check if password has minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
const passwordValidation = input => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  return regex.test(input)
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = '.required';
  }
  if (!emailValidation(values.email)) {
    errors.email = '.email_error_format'
  } 
  if (!values.password) {
    errors.firstname = '.required';
  }

  if (!passwordValidation(values.password)) {
    errors.password = '.password_error'
  }


  return errors;
};


export default validate;
