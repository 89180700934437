/* eslint no-console: off */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const DraftCategory = ({ id, ppId, avatar, name, status, position, policyType, t }) => {
  return (
    <div className='columns policy_category-card'>
      <Link to={`/${policyType}-policies/${ppId}/categories/${id}`}>
        <div className={`question_item`}>
          <div className='question_item-link'>
            <div
              className='icon-part'
              style={{ WebkitMaskImage: `url(${avatar}`, maskImage: `url(${avatar}` }}
            ></div>
          </div>
          <div className='question_item-text'>
            <div className='title-part'>
              <p>{name}</p>
            </div>
            <div className='privacy-policy-edit clearfix'>
              {status === 'in_progress' && (
                <p className='pp-edit-text left fill'>
                  {t('.state')} : <strong> {t('.to_complete')} </strong>
                </p>
              )}
              {status === 'completed' && (
                <p className='pp-edit-text left fill complet'>
                  {t('.state')} : <strong> {t('.complete')} </strong>
                </p>
              )}
            </div>
          </div>
          <div className='question_item-reference'>
            <i className='far fa-edit' />
          </div>
        </div>
      </Link>
    </div>
  );
};

DraftCategory.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string,
};

DraftCategory.defaultProps = {
  avatar: '',
  name: '',
};

export default withRouter(DraftCategory);
