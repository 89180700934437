import { AUTHENTICATE, LOG_OUT } from './types'

const INITIAL_STATE = {
  authenticated: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  userId: localStorage.getItem('userId'),
  userEmail: localStorage.getItem('userEmail'),
  isSuperAdmin: localStorage.getItem('isSuperAdmin') === 'true',
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        isSuperAdmin: action.payload.isSuperAdmin,
      }
    case LOG_OUT:
      return {
        ...state,
        authenticated: false,
        token: null,
        userId: null,
        isSuperAdmin: null,
      }
    default:
      return state
  }
}

export default authReducer
