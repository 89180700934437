import React from 'react'
import Button from '../../components/Button'

const PrivacyPolicyIntegrationComponent = ({ t, privacyUID, copyIframe, privacyLocales, licenseKeyCookie, licenseKeyPrivacy }) => {
  const setIframesLink = uid => {
    const location = window.location.href
    const url = location
      .replace('https://', '')
      .replace('http://', '')
      .split(/[/?#]/)[0]
    const domain = url.split('.')[0]
    const api_link = process.env.REACT_APP_BASE_URL.replace('##PREFIX##', domain)

    return privacyLocales.length !== 0
      ? privacyLocales.map(locale => ({
        locale,
        link: `<iframe src="${api_link}/reader/products/html/${locale}/${uid}"></iframe>`,
      }))
      : ['fr']
  }

  return (
    <div className='margin-top-20'>
      <h2> {t('.pp_name')} </h2>
      <div className='flex_col'>
        <div className='flex_row link_wrapper'>
          <span className='language'>{`${t(`.license_key`)}: `}</span>

          <input
            value={licenseKeyCookie}
            readOnly
            className='read-only'
            id={`iframepolicy-License-Key`}

          />
          <Button
            published
            className='btn btn_pill is-outlined is-blue margin-left-20'
            onClick={() => copyIframe(`iframepolicy-License-Key`)}
          >
            <i className='far fa-copy margin-right-10' />
            {t('.copy_link')}
          </Button>
        </div>
        {setIframesLink(privacyUID).map(privacyLink => (
          <div className='flex_row link_wrapper'>
            <span className='language'>{`${t(`.${privacyLink.locale}`)}: `}</span>

            <input
              value={privacyLink.link}
              id={`iframe-${privacyLink.locale}`}
              readOnly
              className='read-only'
            />
            <Button
              published
              className='btn btn_pill is-outlined is-blue margin-left-20'
              onClick={() => copyIframe(`iframe-${privacyLink.locale}`)}
            >
              <i className='far fa-copy margin-right-10' />
              {t('.copy_link')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PrivacyPolicyIntegrationComponent
