/* eslint no-console: off */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withTranslation } from '../../providers/I18n';
import { withNotifications, NotificationModel } from '../../providers/notifications';

import RetrievePasswordForm from './form';

class LostPassword extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const { retrievePasswordUserMutation, history, notificationsProvider } = this.props;
    retrievePasswordUserMutation({
      variables: values,
    })
      .then(() => {
        const notification = new NotificationModel({
          title: 'Redirection Set Up Password',
          message: 'You are going to be redirected to another page',
          type: 'success',
        });

        notificationsProvider.add(notification);

        history.replace('/');
      })
      .catch(() => {
        const notification = new NotificationModel({
          title: 'Error',
          message: 'Your email address is incorrect',
          type: 'alert',
        });

        notificationsProvider.add(notification);
      });
  }

  render() {
    return (
      <div className='row align-middle'>
        <RetrievePasswordForm onSubmit={this.submit} />
      </div>
    );
  }
}

LostPassword.propTypes = {
  retrievePasswordUserMutation: PropTypes.func.isRequired,
  notificationsProvider: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

const RETRIEVE_USER_PASSWORD_MUTATION = gql`
  mutation($email: String!) {
    retrievePasswordUser(email: $email) {
      id
      email
    }
  }
`;

export default compose(
  graphql(RETRIEVE_USER_PASSWORD_MUTATION, {
    name: 'retrievePasswordUserMutation',
  }),
  withNotifications(),
)(LostPassword);
