import React from 'react';

import { LocalesAndCountriesConsumer } from '../provider';

const withLocalesAndCountries = WrappedComponent => {
  const LocalesAndCountriesHoC = props => (
    <LocalesAndCountriesConsumer>
      {({ availableCountries, availableLocales, availablePolicyLocales }) => (
        <WrappedComponent
          {...props}
          {...{ availableCountries, availableLocales, availablePolicyLocales }}
        />
      )}
    </LocalesAndCountriesConsumer>
  );

  return LocalesAndCountriesHoC;
};

export default withLocalesAndCountries;
