import React from 'react';
import { Query } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import Link from 'react-router-dom/es/Link';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';

import { withTranslation } from '../../providers/I18n';
import AuthWrapper from '../../components/Auth';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

import CookieBannerIntegrationProcess from '../../containers/CookiePolicies/cookie_banner_integration';

class PrivacyPolicyIntegrationProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      policyType: 'privacy',
      integrate: true,
      locales: null,
    };
  }

  componentWillMount() {
    const { match } = this.props;
    const {
      params: { id },
    } = match;

    const type = this.isCookiePolicy(match.url) ? 'cookie' : 'privacy';

    this.fetchPolicy(id).then(({ data: { privacyPolicy } }) => {
      const { parentId, cookiePolicy, locales } = privacyPolicy;
      if (parentId) {
        this.fetchPolicy(parentId).then(({ data }) => {
          this.setUID(data.privacyPolicy.uid, privacyPolicy.uid);
        });
      } else {
        this.setUID(privacyPolicy.uid, cookiePolicy && cookiePolicy.uid ? cookiePolicy.uid : null);
      }

      this.setState({ policyType: type, locales: locales });
    });
  }

  setUID = (ppUID, cpUID) => {
    this.setState({
      isLoading: false,
      privacyUID: ppUID,
      cookieUID: cpUID,
    });
  };

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  fetchPolicy = async id => {
    const { client } = this.props;
    return await client.query({
      query: PRIVACY_POLICY_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  };

  setLink = uid => {
    const location = window.location.href;
    const url = location
      .replace('https://', '')
      .replace('http://', '')
      .split(/[/?#]/)[0];
    const domain = url.split('.')[0];
    const api_link = process.env.REACT_APP_BASE_URL.replace('##PREFIX##', domain);

    const default_locale = 'fr';
    const locales = this.state.locales;
    let locale;

    if (locales) {
      for (let item of locales) {
        if (item.isoCode === default_locale) {
          locale = default_locale;
          break;
        } else {
          locale = item.isoCode;
        }
      }
    } else {
      locale = default_locale;
    }

    return `<iframe src="${api_link}/reader/products/html/${locale}/${uid}" />`;
  };

  copyIframe = id => {
    let item = document.getElementById(id);
    item.select();
    document.execCommand('copy');
  };

  render() {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const { cookieUID, privacyUID, policyType, isLoading } = this.state;

    const CookieBannerIntegrationProcessComponent = () => {
      return <CookieBannerIntegrationProcess integrate={this.state.integrate} />;
    };

    return (
      <AuthWrapper>
        <TitleComponent title={t('.plural_title')} />
        {isLoading && <Loading />}

        {!isLoading && (
          <div className='row'>
            <div className='columns integration_process'>
              <p className='onboarding_message'>
                {t(`.${policyType === 'cookie' ? 'cp' : 'pp'}_intro_text`)}
              </p>
              <div>
                <div className='row collapse align-bottom align-justify align-middle'>
                  {cookieUID ? (
                    <h1> {t('.plural_title')} </h1>
                  ) : (
                    <h1> {t(`.${policyType === 'cookie' ? 'cp' : 'pp'}_title`)} </h1>
                  )}
                  <Link to={`/${policyType}-policies/${id}`}>
                    <Button className='btn btn_pill is-blue'>
                      <i className='fas fa-arrow-left margin-right-10' />
                      {t('.dashboard')}
                    </Button>
                  </Link>
                </div>
                <div>
                  <p> {t('.explanation_text')} </p>
                </div>
              </div>
              <div className='margin-top-20'>
                {cookieUID && <h2> {t('.pp_name')} </h2>}
                <div className='flex_row'>
                  <input
                    value={this.setLink(privacyUID)}
                    id='iframe'
                    readOnly
                    className='read-only width-75'
                  />
                  <Button
                    published
                    className='btn btn_pill is-outlined is-blue margin-left-20'
                    onClick={() => this.copyIframe('iframe')}
                  >
                    <i className='far fa-copy margin-right-10' />
                    {t('.copy_link')}
                  </Button>
                </div>
              </div>

              {cookieUID && (
                <div className='margin-top-20'>
                  <h2> {t('.cp_name')} </h2>
                  <div className='flex_row'>
                    <input
                      value={this.setLink(cookieUID)}
                      id='iframecookie'
                      readOnly
                      className='read-only width-75'
                    />
                    <Button
                      published
                      className='btn btn_pill is-outlined is-blue margin-left-20'
                      onClick={() => this.copyIframe('iframecookie')}
                    >
                      <i className='far fa-copy margin-right-10' />
                      {t('.copy_link')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.policyType === 'cookie' && <CookieBannerIntegrationProcessComponent />}
      </AuthWrapper>
    );
  }
}

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      id
      uid
      parentId
      locales {
        isoCode
      }
      cookiePolicy {
        id
        uid
      }
    }
  }
`;

export default compose(
  withApollo,
  withRouter,
  withTranslation('privacyPolicyIntegration'),
)(PrivacyPolicyIntegrationProcess);
