import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { withTranslation } from '../../providers/I18n';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

import searchIcon from '../../assets/img/search-light.svg';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Loading from '../../components/Loading';
import { AiOutlineSearch } from 'react-icons/ai';

class SearchConsentDatabase extends React.Component {
  constructor(props) {
    super(props);

    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleUuidChange = this.handleUuidChange.bind(this);
    this.handleDomainNameChange = this.handleDomainNameChange.bind(this);

    this.state = {
      dateFrom: new Date(),
      dateTo: new Date(),
      uuid: '',
      domainName: '',
      consents: null,
      calendarOpen: false,
      isConsentsLoading: false,
    };
  }

  handleUuidChange = e => {
    this.setState({
      uuid: e.target.value,
      domainName: '',
      dateFrom: '',
      dateTo: '',
    });
  };

  handleDomainNameChange = e => {
    this.setState({
      domainName: e.target.value,
      uuid: '',
      dateFrom: '',
      dateTo: '',
    });
  };

  handleDateRangeChange = ranges => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    this.setState({
      dateFrom: startDate,
      dateTo: endDate,
      uuid: '',
      domainName: '',
    });

    this.toggleCalendar();
  };

  toggleCalendar = () => {
    this.setState({ calendarOpen: !this.state.calendarOpen });
  };

  closeCalendar = () => {
    this.setState({ calendarOpen: false });
  };

  dummyChangeDate = () => {
    // do nothing
  };

  ISODateString = (date, type) => {
    let time = type === 'start' ? 'T00:00:00' : 'T23:59:59';
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    let localIsoString =
      date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + time;
    if (date.getTimezoneOffset() === 0) localIsoString += 'Z';
    return localIsoString;
  };

  ISODateDisplay = date => {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return date.getFullYear() + '/' + pad(date.getMonth() + 1) + '/' + pad(date.getDate());
  };

  ISODateTimeDisplay = date => {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return (
      date.getFullYear() +
      '/' +
      pad(date.getMonth() + 1) +
      '/' +
      pad(date.getDate()) +
      ' ' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds())
    );
  };

  fetchConsentsByUuid = (cpToken, uuid) => {
    const { client } = this.props;
    return client.query({
      query: SEARCH_CONSENT_BY_UUID,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        uuid,
      },
    });
  };

  fetchConsentsByDomainName = (cpToken, domainName) => {
    const { client } = this.props;
    return client.query({
      query: SEARCH_CONSENT_BY_DOMAIN_NAME,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        domainName,
      },
    });
  };

  fetchConsentsByDate = (cpToken, dateFrom, dateTo) => {
    const { client } = this.props;
    const consentDateFrom = this.ISODateString(dateFrom, 'start');
    const consentDateTo = this.ISODateString(dateTo, 'end');

    return client.query({
      query: SEARCH_CONSENT_BY_DATE,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        consentDateFrom,
        consentDateTo,
      },
    });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.searchConsent();
    }
  };

  searchConsent = () => {
    const { dateFrom, dateTo, uuid, domainName } = this.state;
    const { cpToken } = this.props;

    if (dateFrom !== '' && dateTo !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByDate(cpToken, dateFrom, dateTo)
        .then(result => {
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    } else if (uuid !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByUuid(cpToken, uuid)
        .then(result => {
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    } else if (domainName !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByDomainName(cpToken, domainName)
        .then(result => {
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    }
  };

  render() {
    const { t, cpToken } = this.props;
    const { dateFrom, dateTo, consents, calendarOpen, isConsentsLoading } = this.state;

    const selectionRange = {
      startDate: this.state.dateFrom,
      endDate: this.state.dateTo,
      key: 'selection',
    };

    const displayDateFrom = dateFrom !== '' ? this.ISODateDisplay(dateFrom) : '';
    const displayDateTo = dateTo !== '' ? this.ISODateDisplay(dateTo) : '';

    const columns = [
      {
        name: t('.uuid'),
        selector: 'uuid',
        sortable: true,
      },
      {
        name: t('.date'),
        selector: 'consentDate',
        sortable: true,
        maxWidth: '180px',
      },
      {
        name: t('.domain'),
        selector: 'domainName',
        sortable: true,
        maxWidth: '180px',
        wrap: true,
      },
      {
        name: t('.button'),
        selector: 'button',
        sortable: true,
        maxWidth: '140px',
        center: true,
      },
      {
        name: t('.necessary'),
        selector: 'necessary',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.functional'),
        selector: 'functional',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.marketing'),
        selector: 'marketing',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.statistic'),
        selector: 'statistic',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
    ];

    let data = [];

    if (consents) {
      data = consents.map(item => {
        let formattedItem = {
          uuid: item.uuid,
          domainName: item.domainName,
        };

        const unixTimeZero = Date.parse(item.consentDate);
        formattedItem.consentDate = this.ISODateTimeDisplay(new Date(unixTimeZero));

        switch (item.button) {
          case 'close':
            formattedItem.button = t('.close');
            break;

          case 'reject':
            formattedItem.button = t('.reject');
            break;

          case 'accept_all':
            formattedItem.button = t('.accept_all');
            break;

          case 'accept_selection':
            formattedItem.button = t('.accept_selection');
            break;
        }

        if (item.necessary === null) {
          formattedItem.necessary = t('.not_available');
        } else if (item.necessary === true) {
          formattedItem.necessary = t('.true');
        } else {
          formattedItem.necessary = t('.false');
        }

        if (item.functional === null) {
          formattedItem.functional = t('.not_available');
        } else if (item.functional === true) {
          formattedItem.functional = t('.true');
        } else {
          formattedItem.functional = t('.false');
        }

        if (item.marketing === null) {
          formattedItem.marketing = t('.not_available');
        } else if (item.marketing) {
          formattedItem.marketing = t('.true');
        } else {
          formattedItem.marketing = t('.false');
        }

        if (item.statistic === null) {
          formattedItem.statistic = t('.not_available');
        } else if (item.statistic) {
          formattedItem.statistic = t('.true');
        } else {
          formattedItem.statistic = t('.false');
        }

        return formattedItem;
      });
    }

    const tableData = {
      columns,
      data,
      filterPlaceholder: t('.filter_results'),
      exportHeaders: true,
    };

    return (
      <Fragment>
        <div className='search-form'>
          {cpToken && calendarOpen && (
            <Fragment>
              <DateRangePicker
                ranges={[selectionRange]}
                moveRangeOnFirstSelection={false}
                onChange={this.handleDateRangeChange}
                dateDisplayFormat={'yyyy-MM-dd'}
                direction='horizontal'
                months={2}
                scroll={{ enabled: false }}
                editableDateInputs={true}
              />
              <button type='button' className='search-close-button' onClick={this.toggleCalendar}>
                x
              </button>
            </Fragment>
          )}

          {cpToken && (
            <div className='search-input'>
              <input
                type='text'
                className='search-input-field'
                value={displayDateFrom + ' - ' + displayDateTo}
                onChange={this.dummyChangeDate}
                onClick={this.toggleCalendar}
              />
              <div className='search-input-label'>
                <label>{t('.date')}</label>
              </div>
            </div>
          )}

          {cpToken && (
            <div className='search-input'>
              <input
                type='text'
                onClick={this.closeCalendar}
                className='search-input-field'
                value={this.state.uuid}
                onChange={e => {
                  this.handleUuidChange(e);
                }}
                onKeyDown={this.handleKeyDown}
              />
              <div className='search-input-label'>
                <label>{t('.uuid')}</label>
              </div>
            </div>
          )}

          {cpToken && (
            <div className='search-input'>
              <input
                type='text'
                onClick={this.closeCalendar}
                className='search-input-field'
                value={this.state.domainName}
                onChange={e => {
                  this.handleDomainNameChange(e);
                }}
                onKeyDown={this.handleKeyDown}
              />
              <div className='search-input-label'>
                <label>{t('.domain')}</label>
              </div>
            </div>
          )}

          {cpToken && (
            <div className='search-input'>
              <button
                type='button'
                className='search-button btn btn_pill is-blue'
                onClick={this.searchConsent}
              >
                <AiOutlineSearch size='1.5rem' className='mr-05' />
                <span>{t('.search')}</span>
              </button>
            </div>
          )}
        </div>

        {cpToken && consents && !isConsentsLoading && (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField='consentDate'
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}

        {isConsentsLoading && (
          <div className='row'>
            <div className='column'>
              <Loading />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const SEARCH_CONSENT_BY_UUID = gql`
  query($cpToken: String!, $uuid: String!) {
    consents(cpToken: $cpToken, uuid: $uuid) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;

const SEARCH_CONSENT_BY_DOMAIN_NAME = gql`
  query($cpToken: String!, $domainName: String!) {
    consents(cpToken: $cpToken, domainName: $domainName) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;

const SEARCH_CONSENT_BY_DATE = gql`
  query($cpToken: String!, $consentDateFrom: String!, $consentDateTo: String!) {
    consents(cpToken: $cpToken, consentDateFrom: $consentDateFrom, consentDateTo: $consentDateTo) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;

export default compose(
  withApollo,
  withRouter,
  withTranslation('consent_database'),
)(SearchConsentDatabase);
