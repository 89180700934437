import React from 'react';
import PropTypes from 'prop-types';

import { notificationsIcons } from '../constants';
import Modal from 'src/components/Modal';
import CheckSuccess from '../../../assets/img/check-success.svg';
import CloseError from '../../../assets/img/close-error.svg';

import Button from 'src/components/Button';
import { withTranslation } from 'src/providers/I18n';
import { compose } from 'recompose';

const Notification = ({ id, title, message, type, timer, remove, t }) => (
  <>
    <Modal>
      <div className='modal_bar'>
      </div>
      <div className='modal_content' style={{ width: 500 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <div style={{ textAlign: "center" }}>
            <img src={type === "success" ? CheckSuccess : CloseError} alt="Card Icon" />
          </div>
          <div className='modal_statement' style={{ margin: 0, textAlign: "center" }}>
            {message}
          </div>
        </div>
        <div className='list_buttons'>
          <Button
            className='btn btn_pill is-outlined is-blue'
            published
            onClick={() => {
              remove(id);
            }}
          >
            {t('.ok')}
          </Button>
        </div>
      </div>
    </Modal>
  </>
);

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  timer: PropTypes.number.isRequired,
};

export default compose(withTranslation('dashboard'))(Notification);