import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import cookie_king_dark from '../../../assets/img/cookie_king--dark.svg';
import cookie_king_light from '../../../assets/img/cookie_king--light.svg';
import cookie_highcontrast_dark from '../../../assets/img/cookie_highcontrast--dark.svg';
import cookie_highcontrast_light from '../../../assets/img/cookie_highcontrast--light.svg';



const StyledPreview = styled.div`
    color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#404040' : '#ededed')};
    background-color: ${({ light, highContract }) => (highContract ? "#fff" : light ? '#ededed' : '#404040')};
    
    position: ${({ preview }) => (preview ? 'fixed' : 'inherit')};
    z-index : ${({ preview }) => (preview ? '100' : '0')};
    
    border-radius: ${({ preview }) => (!preview ? '0.5em' : '0')};
    
    font-size: 9.5pt;
    width: ${({ preview }) => (preview ? '100vw' : '100%')};
    
    ${({ position, preview }) => {
    if (preview && position === 'top') {
      return `
                top: 0;
            `;
    }
  }}
            
    ${({ position, preview }) => {
    if (preview && position === 'bottom') {
      return `
                bottom: 0;
            `;
    }
  }}
    
        
    .cst__preview__container{
        position : relative;
        max-width: 964px;
        padding: 24px;
        margin: 0 auto;
    }
    
    .cst__preview__header{
      display: flex;
      align-items: center;
    
      img{
        max-width: 96px;
        margin-right: 24px;
      }
    
      .cst__preview__text{
        margin-left: 12px;
    
        .cst__textblock--title {
          color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#343434' : '#e2e2e2')};
          font-size: 16px;
          font-weight:${({ highContract }) => (highContract ? "900" : 600)};
        }
         .cst__textblock-high--title {
          color: #000000;
          font-size: 16px;
        }
        .cst__textblock--text{
           ul{
            list-style: square;
            li{
                margin-left: 16px;
                display: list-item;
                list-style-type: square;
            }
           }
           ol{
            margin-bottom: 0;
            li {
              display: list-item;
              list-style-type: decimal;
              list-style-position: inside;
            }
           }
           
           & > :nth-last-child(2) {
            display: inline;
           }
          font-size: 9.5pt;
          font-weight: ${({ highContract }) => (highContract ? "600" : 200)};
        }
        .cst__textblock-hight--text{
          color: #000000;
          font-weight:${({ highContract }) => (highContract ? "900" : 600)};
        }
      }
    }
    
    .cst__preview__footer{
      display: flex;
      margin-top: 12px;
      align-items: center;
      .cst__preview__footer__checkbox{
        margin-left: 130px;
        flex: 5 0px;
        display: flex;
        color:  ${({ light, highContract }) => (highContract ? "#000000" : light ? '#343434' : '#e2e2e2')};
        input[type="checkbox"] + label{
            position : relative;
            padding-left: 24px;
            flex: 1 0px;
            color:  ${({ light, highContract }) => (highContract ? "#000000" : light ? '#343434' : '#e2e2e2')};
   
            &:before{
              position: absolute;
              content: '';
              width: 20px;
              height: 20px;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              border-radius: 0.3em;
              border : 1px solid ${({ color, highContract }) => (highContract ? "#000000" : color)};
              
            }
        }
        
        input[type="checkbox"]:checked + label{
          &:before{
            background: ${({ color }) => color};
            box-shadow: inset 0 0 0 2px ${({ light }) => (light ? '#ededed' : '#404040')};
          }
        }
        
        input[type="checkbox"]:disabled + label {
            &:before{
                border-color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#404040' : '#ededed')};
                background: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#404040' : '#ededed')};
                box-shadow: inset 0 0 0 2px ${({ light, highContract }) => (highContract ? "#fff" : light ? '#ededed' : '#404040')};
            }
        }
            
            
            &.required{
              &:before{
                border : 2px solid white;
                background: white;
              }  
            } 
        }
      }
      
      .cookie__option {
        width: 25%;
      }
    
      .cst__preview__action{
        flex: 3 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    
        p{
          text-decoration: underline;
          color: ${({ color, highContract }) => highContract ? "#000000" : color};
          cursor : ${({ preview }) => (preview ? 'pointer' : 'default')};  
        }
    
        button{
          cursor : ${({ preview }) => (preview ? 'pointer' : 'default')};  
          padding: 12px 24px;
          margin-left: 24px;
          border-radius: 0.3em;
          background-color: ${({ color, highContract }) => (highContract ? "#fff" : color)};
          color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#ededed' : '#404040')};
          border: ${({ highContract }) => (highContract ? "1px solid #000000" : "none")};
        }
      }
    }
    
    .cookies__switch__container{
      display: flex;
      margin-left: 12px;
      color: #404040;
      .cookies__switch{
        position: relative;
        width: 50px;
        height: 25px;
        background-color: #aaa;
        border-radius: 1em;
        margin: 0 12px;
        span{
          position: absolute;
          height: 25px;
          width: 25px;
          background-color: white;
          opacity: 0.5;
          border-radius: 1em;
          left: 0;
          transition: all 0.2s ease-in-out;
        }
        &.active{
            background-color: ${({ color, highContract }) => highContract ? "#fff" : color};
            span{
                background-color: black;
                left: 25px !important;
            }
        }
        &.blocked{
            background-color: #404040;
            span{
                background-color: white;
            }
        }
      }
    }
    
    .cst__preview__switchall{
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        padding-top: 16px;
        border-top: 1px solid #a2a2a2;
        
        .cookies__switch__container{
            color : ${({ light, highContract }) => (highContract ? "#000000" : light ? '#404040' : '#ededed')};
        }
    }
    .cst__preview__cookies{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.15s ease-out;
          &.opened{
            max-height: 1000px;
            transition: max-height 0.25s ease-in;
          }
    }
    
    .cst__preview__cookies__container{
        display: flex;
        padding-top: 16px;
       .cookies__nav{
            flex: 1 0;
            .cookies__nav__item{
                cursor: pointer;
                padding: 16px 34px;
                background-color: transparent;
                color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#606060' : '#ededed')};
                border-top-left-radius : 0.5em;
                border-bottom-left-radius : 0.5em;
                &.active{
                    color: #404040;
                    background-color: white;
                }
            }
       }
       .cookies__content{
            flex: 4 0;
            background-color: white;
            padding: 0 16px 16px;
            height: 208px;
            border-top-right-radius : 0.5em;
            border-bottom-right-radius : 0.5em;
            
            .cookies__content__header{
                display: flex;
                justify-content: space-between;
                align-items: center;  
                border-bottom: 1px solid #a2a2a2;
            }
            
            .cookies__content__text{
                padding-top: 24px;
                max-height: 140px;
                overflow: scroll;
                color: #404040;
            }
       }
    }
    
    .cst__preview__close-btn{
      position: absolute;
      cursor: pointer;
      top : 24px;
      right: 32px;
      
      span {
        vertical-align: text-bottom;
        margin-right: 5px;
      }
      
      i {
        padding: 4px 7px;
        font-size: 18px;
        border-radius: 100%;
        border: 2px solid #404040;
        background-color: rgba(255, 255, 255, 0.5);
        color: ${({ color, highContract }) => highContract ? "#000000" : color};
      }
    }
    
    .cst__preview__vignette { 
        position: fixed;
        cursor: pointer;
        padding: 1em;
        bottom: -45px;
        right: 5%;
        height: 60px;
        width: 100px;
        border-radius: 0.3em;
        background-color: ${({ light, highContract }) => (highContract ? "#fff" : light ? '#ededed' : '#404040')};
        text-align: center;
        transition: bottom 0.2s ease-in-out;
        
        &:hover{
            bottom: -5px;
        }
    }
    .table_container .owner {
      flex: 2 0px;
    }
    .table_container .name {
      flex: 4 0px;
    }
    .table_container .purpose {
      flex: 6 0px;
    }
    
    .cst__preview__copyright{
        margin-top: 12px;
        text-align: right;
        a {
          color: ${({ light, highContract }) => (highContract ? "#000000" : light ? '#343434' : '#e2e2e2')};
          text-decoration: underline;
        }
      }
       .cst_preview_hight_contrast{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
      }
      .cst_preview_hight_contrast_border_light{
        border:1px solid #fff;
        color:#fff;

      }
      .cst_preview_hight_contrast_border_dark{
        border:1px solid #000000;
        color:#000000;
      }
`;

const StyledBanner = ({
  data,
  text,
  theme,
  cookies_available,
  t,
  banner_t,
  locale,
  isCookiesOpen,
  cookiesType,
  cookies_consent,
  allCookiesOptIn,
  isCookiesValidated,
  activeNav,
  switchNav,
  switchOptIn,
  toggleCookiesContent,
  switchAllOptIn,
  onCookiesValidation,
  highContract,
  highContractClick,
  mainHightContractShow,
  ...rest
}) => {
  const newProps = {
    ...rest, // Include all existing properties from rest
    highContract: highContract // Add your new property
  };
  return (
    <StyledPreview {...newProps}>
      {!isCookiesValidated && (
        <div className='cst__preview__container'>
          <div className='cst__preview__content'>
            <div className='cst__preview__header'>
              <img src={highContract ? cookie_king_dark : theme !== 'light' ? cookie_king_light : cookie_king_dark} alt='cookie_king' />
              <div className='cst__preview__text'>
                <p className='cst__textblock--title'> {data.banner_title} </p>
                <div className='cst__textblock--text' dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
            <div className='cst__preview__footer'>
              <div className='cst__preview__footer__checkbox'>
                {cookiesType &&
                  cookiesType.map((cookie, i) => {
                    return (
                      cookies_available.includes(cookie.name) && (
                        <div className='cookie__option' key={cookie.name}>
                          <input
                            type='checkbox'
                            name={cookie.name}
                            disabled={cookie.name === 'necessary'}
                            onChange={() => { }}
                            value={cookie.name}
                            checked={cookies_consent[cookie.name] === 'yes'}
                          />
                          <label
                            htmlFor={cookie.name}
                            onClick={() =>
                              switchOptIn(
                                cookies_consent[cookie.name] === 'no' || cookie.name === 'necessary'
                                  ? 'yes'
                                  : 'no',
                                cookie.name,
                              )
                            }
                          >
                            {' '}
                            {banner_t[cookie.name]} ({cookie.cookies_number})
                          </label>
                        </div>
                      )
                    );
                  })}
              </div>
            </div>
            <div className='cst__preview__footer'>
              <div className='cst__preview__action'>
                <p onClick={() => toggleCookiesContent(!isCookiesOpen)}>
                  {' '}
                  {!isCookiesOpen ? banner_t['see_more'] : banner_t['see_less']}{' '}
                </p>
                <button
                  onClick={() => {
                    switchAllOptIn(false);
                    onCookiesValidation(true);
                  }}
                >
                  {' '}
                  {banner_t['reject']}{' '}
                </button>
                <button onClick={() => onCookiesValidation(true)}> {banner_t['validate']} </button>
                <button
                  onClick={() => {
                    switchAllOptIn(true);
                    onCookiesValidation(true);
                  }}
                >
                  {' '}
                  {banner_t['accept_all']}{' '}
                </button>
              </div>
            </div>
          </div>
          <div className={`cst__preview__cookies ${isCookiesOpen ? 'opened' : ''}`}>
            <div className='cst__preview__switchall'>
              <p> {banner_t['activate_all']} :</p>
              <div className='cookies__switch__container '>
                <p> Off </p>

                <div
                  className={`cookies__switch ${allCookiesOptIn ? 'active' : ''}`}
                  onClick={() => switchAllOptIn(!allCookiesOptIn)}
                >
                  <span />
                </div>
                <p> On </p>
              </div>
            </div>
            <div className='cst__preview__cookies__container'>
              <div className='cookies__nav'>
                <ul>
                  {cookiesType &&
                    cookiesType.map(cookie => {
                      return (
                        cookies_available.includes(cookie.name) && (
                          <li
                            className={`cookies__nav__item ${cookie.name === activeNav ? 'active' : ''
                              }`}
                            key={cookie.name}
                            onClick={() => switchNav(cookie.name)}
                          >
                            {' '}
                            {banner_t[cookie.name]} ({cookie.cookies_number})
                          </li>
                        )
                      );
                    })}
                </ul>
              </div>
              <div className='cookies__content'>
                {cookiesType &&
                  cookiesType.map((cookie, i) => (
                    <div
                      style={{
                        display: `${cookie.name === activeNav ? 'block' : 'none'}`,
                      }}
                      key={cookie.name}
                    >
                      <div className='cookies__content__header'>
                        <h5> {cookie.title} </h5>
                        <div className='cookies__switch__container'>
                          {cookie.name === 'necessary' ? (
                            <p>{banner_t['always_active']}</p>
                          ) : (
                            <Fragment>
                              <p> Off </p>
                              <label
                                htmlFor={cookie.name}
                                className={`cookies__switch ${cookie.name === 'necessary' ? 'blocked' : ''
                                  } ${cookies_consent[cookie.name] === 'yes' ? 'active' : ''}`}
                                onClick={() =>
                                  switchOptIn(
                                    cookies_consent[cookie.name] === 'no' ||
                                      cookie.name === 'necessary'
                                      ? 'yes'
                                      : 'no',
                                    cookie.name,
                                  )
                                }
                              >
                                <span />
                              </label>
                              <p> On </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className='cookies__content__text'>
                        <div dangerouslySetInnerHTML={{ __html: cookie.text }} />

                        <table className='table_container'>
                          <thead>
                            <tr className='table_header'>
                              <th className='table_title owner'> {banner_t['owner']} </th>
                              <th className='table_title name'> {banner_t['name']} </th>
                              <th className='table_title purpose'> {banner_t['purpose']} </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cookie.cookies_list &&
                              cookie.cookies_list.map(c => (
                                <tr key={c.name} className=' table_row'>
                                  <td className='table_row_content owner'>
                                    <p> {c.domain} </p>
                                  </td>
                                  <td className='table_row_content name'>
                                    <p> {c.name} </p>
                                  </td>
                                  <td className='table_row_content purpose'>
                                    <p className=' italic'>
                                      {' '}
                                      {c.other
                                        ? findLocaleValue(locale, c.other)
                                        : c.purpose_text}{' '}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='cst__preview__copyright'>© 2019 - {getFullYear()} Powered by Admeet</div>
          </div>

          <div
            className={`cst__preview__close-btn ${(highContract) ? 'highContract-background' : ''}`}>
            <div onClick={() => {
              switchAllOptIn(false);
              onCookiesValidation(true);
            }}>
              <span>{banner_t['continue_without_accepting']}</span> <i className={`fas fa-times ${highContract ? 'highContract-background' : ''}`} />
            </div>
            {
              mainHightContractShow ? (
                <div className={`cst_preview_hight_contrast ${highContract ? 'cst_preview_hight_contrast_border_dark' : (theme !== 'light') ? 'cst_preview_hight_contrast_border_light' : 'cst_preview_hight_contrast_border_dark'}`} onClick={() => {
                  highContractClick()
                }}>
                  <img src={highContract ? cookie_highcontrast_light : theme !== 'light' ? cookie_highcontrast_dark : cookie_highcontrast_light} alt='cookie_king' style={{ width: 20, marginRight: 0 }} />
                  <div className='cst__preview__text' style={{ textWrap: "nowrap" }}>{highContract ? banner_t['standard_contrast'] : banner_t['high_contrast']}</div>
                </div>
              ) : null

            }
          </div>
        </div>
      )}
      {
        isCookiesValidated && (
          <div className='cst__preview__vignette' onClick={() => onCookiesValidation(false)}>
            Changer mes préférences
          </div>
        )
      }
    </StyledPreview >
  )
}


const findLocaleValue = (locale, values) => {
  let localeValue = '';
  values.map(obj => {
    if (obj.hasOwnProperty(locale)) {
      localeValue = obj[locale];
    }
  });
  return localeValue;
};

const getFullYear = () => {
  let d = new Date();
  return d.getFullYear();
};

StyledBanner.propTypes = {
  toggleCookiesContent: PropTypes.func,
  switchNav: PropTypes.func,
  switchOptIn: PropTypes.func,
  switchAllOptIn: PropTypes.func,
  onCookiesValidation: PropTypes.func,
};

StyledBanner.defaultProps = {
  toggleCookiesContent: () => { },
  switchNav: () => { },
  switchOptIn: () => { },
  switchAllOptIn: () => { },
  onCookiesValidation: e => {
    e?.preventDefault();
  },
};

export default StyledBanner;
