import React from 'react';
import { compose } from 'recompose';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import Loading from '../../components/Loading';
import AuthWrapper from '../../components/Auth';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';
import Select from 'react-select';
import SearchConsentDatabase from './search';
import './consents.css';

class ConsentDatabasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpToken: null,
    };
  }

  onPolicyChange = e => {
    const cpToken = e.value;
    this.setState({ cpToken: cpToken });
  };

  render() {
    const { t } = this.props;
    return (
      <AuthWrapper>
        <TitleComponent title={t('.consent_database')} />
        <Query query={ALL_PRIVACY_POLICIES_QUERY} fetchPolicy={'network-only'}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;

            const { allPrivacyPolicies } = data;

            const policyOptions = allPrivacyPolicies
              .filter(policy => policy.status === 'published')
              .map(({ uid: value, title: label }) => {
                return { value, label };
              });

            return (
              <div className='row'>
                <div className='columns'>
                  <h1> {t('.consent_database')} </h1>
                  <h2> {t('.select_cookie_policy')} </h2>
                  <Select options={policyOptions} onChange={this.onPolicyChange} className='select-padded' />
                  <SearchConsentDatabase cpToken={this.state.cpToken} />
                </div>
              </div>
            );
          }}
        </Query>
      </AuthWrapper>
    );
  }
}

const ALL_PRIVACY_POLICIES_QUERY = gql`
  query allPrivacyPolicies {
    allPrivacyPolicies(type: "cookie") {
      id
      uid
      title
      status
    }
  }
`;

export default compose(withRouter, withTranslation('consent_database'))(ConsentDatabasePage);
