import { AUTHENTICATE } from '../../redux/types'

const authenticateUserAction = ({ token, userId, isSuperAdmin, dispatch }) => {
  dispatch({
    type: AUTHENTICATE,
    payload: {
      token,
      userId,
      isSuperAdmin,
    },
  })
}

export default authenticateUserAction
