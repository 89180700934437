import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import withTranslation from '../../../providers/I18n/HoC/t';

class CookiesModal extends React.Component {
  handlePrivacyModalLink = () => {
    const { closeExampleModal, match, history } = this.props;
    const { id, categoryId } = match.params;

    history.replace(`/privacy-policies/${id}/categories/${categoryId}`);

    closeExampleModal();
  };

  handleCookiesModalLink = () => {
    const { closeExampleModal, content, history } = this.props;
    const {
      pp: {
        cookiePolicy: { id },
      },
    } = content;

    history.replace(`/cookie-policies/${id}`);

    closeExampleModal();
  };

  render() {
    const { content, title, closeExampleModal, t } = this.props;
    return (
      <Modal>
        <div className='modal_bar'>
          <h4 className='modal_title'> {title} </h4>
          <button type='button' className='close' onClick={closeExampleModal} />
        </div>
        <div className='modal_content'>
          <div
            className='modal_statement'
            dangerouslySetInnerHTML={{ __html: content.question.statement }}
          />
          <div className='modal_buttons'>
            <Button
              onClick={this.handleCookiesModalLink}
              rounded
              style={{ marginRight: '1em' }}
              invert
            >
              {t('.complete_cookies')}
            </Button>
            <Button onClick={this.handlePrivacyModalLink} rounded published>
              {t('.complete_privacy')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

CookiesModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withApollo,
  withTranslation('question'),
)(CookiesModal);
