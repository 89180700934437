import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import GraphQlCalls from '../../graphql/product_queries/'
import gql from 'graphql-tag'
import Link from 'react-router-dom/es/Link'
import { withRouter, Redirect } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import countryLanguages from './countriesCode'

import { withNotifications, NotificationModel } from '../../providers/notifications/'
import { withTranslation } from '../../providers/I18n'
import AuthWrapper from '../../components/ProductAuth'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

import CookieBannerIntegrationProcess from '../../containers/ProductCookiePolicies/cookie_banner_integration'
import CookiePolicyIntegrationComponent from './cookie_policy_integration.jsx'
import PrivacyPolicyIntegrationComponent from './privacy_policy_integration.jsx'

class PrivacyPolicyIntegrationProcess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      policyType: 'privacy',
      integrate: false,
      privacyLocales: [],
      cookieLocales: [],
      bannerPreference: null,
      noAccess: false,
      licenseKeyCookie: '',
      licenseKeyPrivacy: ''
    }
  }

  componentWillMount() {
    const { match, notificationsProvider, t } = this.props
    const {
      params: { id },
    } = match

    const type = this.isCookiePolicy(match.url) ? 'cookie' : 'privacy'

    this.fetchPolicy(id).then(({ data: { product } }) => {
      if (!product) {
        const notification = new NotificationModel({
          title: t('.no_access_to_policy_title'),
          message: t('.no_access_to_policy'),
          type: 'alert',
        })
        notificationsProvider.add(notification)
        this.setState({ noAccess: true })
      }
      const { license, bannerPreference, linkedPolicy } = product

      let locales = []
      let linkedPolicyLocales = []

      license.features.forEach(item => {
        if (item.groupCode === 'language' && item.active) {
          locales.push(item['code'])
        }
      })

      linkedPolicy?.license.features.forEach(item => {
        if (item.groupCode === 'language' && item.active) {
          linkedPolicyLocales.push(item['code'])
        }
      })

      if (type === 'privacy') {
        this.setUID(product.uid, linkedPolicy?.uid || null)
        this.setLicenseKey(product.licenseKey, linkedPolicy?.licenseKey || null)
      } else {
        this.setUID(linkedPolicy?.uid || null, product.uid)
        this.setLicenseKey(linkedPolicy?.licenseKey || null, product.licenseKey)
      }

      this.setState({
        policyType: type,
        integrate: !!linkedPolicy || (type === 'cookie' && this.bannerScriptShow(license.features)),
        bannerPreference: bannerPreference?.value[0] || {},
        privacyLocales: type === 'privacy' ? locales : linkedPolicyLocales,
        cookieLocales: type === 'cookie' ? locales : linkedPolicyLocales,
      })
    })
  }

  bannerScriptShow = (features) => {
    var dataFeatures = features ? features : [];
    var returnValue = false;
    dataFeatures.forEach(element => {
      if (element.groupCode === 'banner' && element.code === 'script' && element.preselect) {
        returnValue = true;
      }
    });
    return returnValue;
  }
  setLicenseKey = (licenseKeyCookie, licenseKeyPrivacy) => {
    this.setState({
      licenseKeyCookie: licenseKeyCookie,
      licenseKeyPrivacy: licenseKeyPrivacy,
    })
  }

  setUID = (ppUID, cpUID) => {
    this.setState({
      isLoading: false,
      privacyUID: ppUID,
      cookieUID: cpUID,
    })
  }

  isCookiePolicy = url => {
    return url.includes('cookie-policies')
  }

  fetchPolicy = async id => {
    const { client, userId } = this.props
    return await client.query({
      query: GraphQlCalls.GET_PRODUCT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        userId,
      },
    })
  }

  copyIframe = id => {
    let item = document.getElementById(id)
    item.select()
    document.execCommand('copy')
  }

  getCountryLanguage = countryCode => {
    if (countryLanguages.hasOwnProperty(countryCode)) {
      return countryLanguages[countryCode]
    } else {
      return countryCode
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props
    const {
      cookieUID,
      privacyUID,
      policyType,
      isLoading,
      noAccess,
      integrate,
      cookieLocales,
      privacyLocales,
      licenseKeyPrivacy,
      licenseKeyCookie
    } = this.state

    const CookieBannerIntegrationProcessComponent = () => {
      return (
        <CookieBannerIntegrationProcess
          integrate={this.state.integrate}
          bannerPreference={this.state.bannerPreference}
          cookieUID={cookieUID}
        />
      )
    }

    return (
      <AuthWrapper>
        <TitleComponent title={t('.plural_title')} />
        {isLoading && <Loading />}
        {noAccess && <Redirect to={`/${policyType}-policies`} />}
        {!isLoading && (
          <div className='row'>
            <div className='columns integration_process'>
              <p className='onboarding_message'>
                {t(`.${policyType === 'cookie' ? 'cp' : 'pp'}_intro_text`)}
              </p>
              <div>
                <div className='row collapse align-bottom align-justify align-middle'>
                  {privacyUID && cookieUID ? (
                    <h1> {t('.plural_title')} </h1>
                  ) : (
                    <h1> {t(`.${policyType === 'cookie' ? 'cp' : 'pp'}_title`)} </h1>
                  )}
                  <Link to={`/${policyType}-policies/${id}`}>
                    <Button className='btn btn_pill is-blue'>
                      <i className='fas fa-arrow-left margin-right-10' />
                      {t('.dashboard')}
                    </Button>
                  </Link>
                </div>
                <div>
                  <p> {t('.explanation_text')} </p>
                </div>
              </div>
              {privacyUID && (
                <PrivacyPolicyIntegrationComponent
                  setIframesLink={this.setIframesLink}
                  t={t}
                  privacyUID={privacyUID}
                  copyIframe={this.copyIframe}
                  privacyLocales={privacyLocales}
                  licenseKeyCookie={licenseKeyCookie}
                  licenseKeyPrivacy={licenseKeyPrivacy}
                />
              )}
              {cookieUID && (
                <CookiePolicyIntegrationComponent
                  setIframesLink={this.setIframesLink}
                  t={t}
                  cookieUID={cookieUID}
                  copyIframe={this.copyIframe}
                  cookieLocales={cookieLocales}
                  licenseKeyCookie={licenseKeyCookie}
                  licenseKeyPrivacy={licenseKeyPrivacy}
                />
              )}
              <h2>{t('.style_cp')}</h2>
              <p className='style_cp_snippet'>{t('.style_cp_explanation')}</p>
              <input value="width='100%' height='1200px'" className='read-only' />
            </div>
          </div>
        )}

        {integrate && <CookieBannerIntegrationProcessComponent />}
      </AuthWrapper>
    )
  }
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

export default compose(
  connect(mapStateToProps),
  withApollo,
  withRouter,
  withTranslation('privacyPolicyIntegration'),
  withNotifications(),
)(PrivacyPolicyIntegrationProcess)
