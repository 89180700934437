import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { renderField } from '../TextArea';
import DurationForm from './duration_form';

class DropdownItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocale: props.locales.length === 1 ? props.locales[0] : props.selectedLocale,
    };
  }

  onSelectLocale = locale => {
    this.setState({ selectedLocale: locale });
  };

  renderLocale = () => {
    const { locales } = this.props;
    const { selectedLocale } = this.state;

    return (
      <ul className='form_locales__list'>
        {locales.map(locale => (
          <li
            key={locale}
            className={`form_locales__item ${selectedLocale === locale ? 'active' : ''}`}
            onClick={() => this.onSelectLocale(locale)}
          >
            {locale.toUpperCase()}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      handleChange,
      handleTextChange,
      toggleCriteriaDropdown,
      options,
      values,
      locales,
      name,
      criteriaValue,
      isOpen,
      value,
      t,
    } = this.props;
    return (
      <Fragment>
        <div className='duration_form'>
          <div className='criteria_select'>
            <div
              className={`dropdown margin-bottom-20 ${isOpen ? 'opened' : 'closed'}`}
              onClick={() => toggleCriteriaDropdown(name)}
            >
              <div className='dropdown_header'>
                <div className='dropdown_label'>{t('.criteria')}</div>
                {criteriaValue}
              </div>
              <div className='dropdown_items'>
                {options.map(option => (
                  <div
                    key={option.value}
                    className={`dropdown_item ${value === option.value ? 'selected' : ''}`}
                    data-value={option.value}
                    onClick={e => handleChange(e, 'name', option.name)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
            {value === 'other' && (
              <div className='text-form'>
                {this.renderLocale(locales)}
                {locales.map(locale => (
                  <Field
                    key={locale}
                    name={`${name}_other_text_${locale}`}
                    component={renderField}
                    type='textarea'
                    label={t('.other')}
                    onChange={e => handleTextChange(e, name, this.state.selectedLocale)}
                    display={locale === this.state.selectedLocale}
                  />
                ))}
              </div>
            )}
          </div>
          <DurationForm
            name={name}
            values={values}
            type={value}
            handleChange={handleChange}
            t={t}
          />
        </div>
      </Fragment>
    );
  }
}

DropdownItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DropdownItem.defaultProps = {
  value: '',
};

export default DropdownItem;
