import React from 'react';

const ProgressBar = ({ status, categories, previewIsLoading, t }) => {
  const categoriesProgression = categories.filter(category => category.status.key === 'completed')
    .length;
  const progressPercentage = (categoriesProgression / categories.length) * 70;

  const renderProgressBarElement = (state, type, label, name, icon) => {
    type = status === 'published' ? 'done' : type;
    switch (type) {
      case 'loading':
        return (
          <div
            className={`progress-bar__el loading ${name}`}
            style={{ '--progress-bar_percentage': `${progressPercentage}%` }}
          >
            <i className={`fas fa-${icon}`}></i>
            <span>
              {' '}
              <i className='fas fa-spinner'></i>{' '}
            </span>
            <p> {label} </p>
          </div>
        );
      case 'blocked':
        return (
          <div className={`progress-bar__el blocked ${name}`}>
            <i className={`fas fa-${icon}`}></i>
            <span> </span>
            <p> {label} </p>
          </div>
        );
      case 'done':
        return (
          <div className={`progress-bar__el done ${name}`}>
            <i className={`fas fa-${icon}`}></i>
            <span>
              {' '}
              <i className='fas fa-check'></i>{' '}
            </span>
            <p> {label} </p>
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className='progress-bar'>
      {renderProgressBarElement(status, 'done', t('.creation'), 'creation', 'file')}
      {renderProgressBarElement(
        status,
        previewIsLoading ? 'done' : 'loading',
        t('.questions'),
        'questions',
        'pen',
      )}
      {renderProgressBarElement(
        status,
        previewIsLoading ? 'loading' : 'blocked',
        t('.previsualisation'),
        'previsualisation',
        'file-alt',
      )}
      {renderProgressBarElement(
        status,
        status === 'published' ? 'done' : 'blocked',
        t('.publication'),
        'publication',
        'book',
      )}
    </div>
  );
};

export default ProgressBar;
