/* eslint no-console: off */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import withApollo from 'react-apollo/withApollo';

import AuthWrapper from '../../components/Auth';
import CategoryQuestionsList from './list';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import WizardForm from '../../components/WizardForm';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class PrivacyPolicyCategory extends React.Component {
  constructor() {
    super();
    this.state = { policyType: 'privacy' };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ policyType: this.isCookiePolicy(match.url) ? 'cookie' : 'privacy' });
  }

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  previewPolicy = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { policyType } = this.state;

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`);
    } else {
      localStorage.setItem('ppStatus', 'blocked');
      history.push(`/${policyType}-policies/${id}/preview`);
    }
  };

  isCategoriesCompleted(categories) {
    let categoriesCompleted = true;
    for (let cat of categories) {
      let state = cat.status.key;
      if (state === 'in_progress') categoriesCompleted = false;
    }

    return !categoriesCompleted;
  }

  handleCategoryChange = (category, categories, next) => {
    const { position, type } = category;
    const { policyType } = this.state;
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;

    const nextCategory = categories.filter(cat => {
      if (next) {
        return cat.position === position + 1 && type === policyType;
      } else {
        return cat.position === position - 1 && type === policyType;
      }
    })[0];

    nextCategory
      ? history.push(`/${policyType}-policies/${id}/categories/${nextCategory.id}`)
      : history.push(`/${policyType}-policies/${id}`);
  };

  categoriesProgression = categories =>
    categories.filter(category => category.status.key === 'completed').length;

  render() {
    const {
      match: {
        params: { id, categoryId },
      },
      t,
      history,
    } = this.props;
    const { policyType } = this.state;

    return (
      <AuthWrapper>
        <Query
          query={ALL_CATEGORIES_QUESTIONS_QUERY}
          fetchPolicy={'network-only'}
          variables={{
            id: categoryId,
            ppId: id,
            type: policyType,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;
            const { category, privacyPolicy, categories, privacyPolicyVersions } = data;
            const wizard_option = categories.map(category => {
              return {
                isComplete: category.status.key === 'completed',
                icon: category.avatar,
                id: parseInt(category.id),
              };
            });
            const ppName = localStorage.getItem('ppName');

            if (category.position === 8) {
              history.push(
                `/${policyType}-policies/${id}/categories/${categoryId}/questions/${category.questionGroups[0].questions[0].id}`,
              );
              return null;
            } else {
              return (
                <div className='row'>
                  <TitleComponent title={ppName + ' | ' + category.name} />
                  <div className='columns'>
                    <div className='category'>
                      <div className='section__heading'>
                        <div className='header__title'>
                          <h6> {t(`.${policyType}_policy`)} </h6>
                          <h1 className='policy_title'> {ppName} </h1>
                        </div>
                        <div>
                          <Link to={`/${policyType}-policies/${id}`}>
                            <Button className='btn btn_pill has-text-blue is-borderless'>
                              <i className='fas fa-arrow-left margin-right-10' />
                              {t('.dashboard')}
                            </Button>
                          </Link>
                          <Button
                            className='btn btn_pill is-outlined margin-left-20'
                            disabled={this.isCategoriesCompleted(categories)}
                            onClick={() =>
                              this.previewPolicy(privacyPolicy.status, privacyPolicyVersions.length)
                            }
                          >
                            <i className='far fa-file-alt margin-right-10' />
                            {t('.preview')}
                          </Button>
                        </div>
                      </div>

                      <WizardForm options={wizard_option} policyType={policyType} />

                      <div className='arrow_title'>
                        <div
                          className='icon-part margin-right-20'
                          style={{
                            WebkitMaskImage: `url(${category.avatar}`,
                            maskImage: `url(${category.avatar}`,
                          }}
                        >
                          {/* <img src={category.avatar} alt={category.avatar} className='policy-icon' /> */}
                        </div>
                        <h2>{category.name}</h2>
                      </div>
                      <CategoryQuestionsList
                        category={category}
                        ppStatus={privacyPolicy.status}
                        getRef={this.getRef}
                        policyType={policyType}
                        pp={privacyPolicy}
                        locales={privacyPolicy.locales}
                      />

                      {privacyPolicy.status !== 'published' && (
                        <div className='row'>
                          <div className='columns flex_right'>
                            <Button
                              className='btn btn_pill is-borderless has-text-blue margin-right-20'
                              onClick={() => this.handleCategoryChange(category, categories, false)}
                            >
                              <i className='fas fa-arrow-left margin-right-10' />
                              {t('.return')}
                            </Button>
                            {category.position !== 5 ? (
                              <Button
                                className='btn btn_pill is-blue'
                                published
                                onClick={() =>
                                  this.handleCategoryChange(category, categories, true)
                                }
                              >
                                {t('.continue_encoding')}
                                <i className='fas fa-arrow-right margin-left-10' />
                              </Button>
                            ) : this.categoriesProgression(categories) === 5 ? (
                              <a onClick={() => this.previewPolicy(privacyPolicy.status)}>
                                <Button published className='btn btn_pill is-blue'>
                                  {t('.preview_your_policy')}
                                  <i className='fas fa-file-alt margin-left-10' />
                                </Button>
                              </a>
                            ) : (
                              <div className='flex_cl' style={{ position: 'relative' }}>
                                <p className='annotation'> * {t('.questions_unanswered')} </p>
                                <Button className='btn btn_pill is-blue' published disabled={true}>
                                  {t('.preview_your_policy')}
                                  <i className='fas fa-file-alt margin-left-10' />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          }}
        </Query>
      </AuthWrapper>
    );
  }
}

const ALL_CATEGORIES_QUESTIONS_QUERY = gql`
  query category($id: ID!, $ppId: ID!, $type: String) {
    category(id: $id) {
      id
      status(policyId: $ppId, policyType: $type)
      name
      position
      avatar
      type
      questionGroups {
        id
        name
        questions(policyId: $ppId, policyType: $type) {
          id
          type
          choices
          referenceNumber
          statement
          mandatoryChoices
          readOnly
          answer(policyId: $ppId) {
            autocompleted
            createdAt
            id
            referenceNumber
            updatedAt
            value
          }
        }
      }
    }
    privacyPolicy(id: $ppId) {
      id
      status
      cookies
      locales {
        isoCode
      }
    }
    privacyPolicyVersions(policyId: $ppId) {
      id
    }
    categories(type: $type) {
      status(policyId: $ppId, policyType: $type)
      id
      position
      avatar
      type
    }
  }
`;

PrivacyPolicyCategory.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

PrivacyPolicyCategory.defaultProps = {};

export default compose(withRouter, withApollo, withTranslation('category'))(PrivacyPolicyCategory);
