import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';

const CookieExplanationModal = props => {
  const { content, title, closeExampleModal } = props;
  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'> {title} </h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='query_text' dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};

CookieExplanationModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

export default connect(null, mapDispatchToProps)(CookieExplanationModal);
