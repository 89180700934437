/* eslint react/jsx-indent-props: off */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'src/providers/I18n';

import { renderInputField } from '../../../components/Form/TextFieldGroup/fieldInput';
import Button from '../../../components/Button';
import DropdownItem from './dropdown';
import { organization_sector, organization_employees } from './onboarding_data';
import validate from './validate';
import CountryDropdownItem from 'src/containers/NewOnboarding/CompanyOnboarding/countrydropdown';
import { countries } from 'country-data';
import countriesList from 'src/helpers/Countries/countries-list';

const OnBoardingForm = ({
  handleSubmit,
  handleChange,
  handleVATChange,
  toggleVAT,
  error,
  invalid,
  submitting,
  pristine,
  sector,
  employees,
  hasVat,
  isVatValid,
  vatInfo,
  t,
  vatVerfication,
  initialValues,
  countryhandleChange
}) => {
  function listOfCountries() {
    var currentLanguage = localStorage.getItem('locale')
    let listOfCountries = countriesList[currentLanguage ? currentLanguage : 'fr'];
    var coutrydata = ["at", "be", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hu", "ie", "it", "lv", "lt", "lu", "mt", "nl", "pl", "pt", "ro", "sk", "si", "es", "se"]
    var newList = [{
      name: '',
      label: t('.please_select_country'),
      value: ''
    }];
    listOfCountries.forEach(item => {
      if (coutrydata.includes(item.value)) {
        newList.push({
          name: item.label,
          label: item.label,
          value: item.value
        })
      }

    })
    newList = newList.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return newList;
  }
  return (
    <div className='onboarding_form'>
      <form className='text-form auth_form' onSubmit={handleSubmit}>
        <div>
          <h5>{t('.has_vat')}</h5>
          <div className='radio-options'>
            <Field
              name={'vat'}
              component={({ input }) => (
                <Fragment>
                  <div className='radio_input'>
                    <input
                      id='yes'
                      type='radio'
                      {...input}
                      value='yes'
                      name='yes'
                      checked={hasVat === 'yes'}
                      onChange={toggleVAT}
                    />
                    <label htmlFor='yes'>{t('.yes')}</label>
                  </div>
                  <div className='radio_input'>
                    <input
                      id='no'
                      type='radio'
                      {...input}
                      value='no'
                      name='no'
                      checked={hasVat === 'no'}
                      onChange={toggleVAT}
                    />
                    <label htmlFor='no'>{t('.no')}</label>
                  </div>
                </Fragment>
              )}
            />
          </div>
        </div>
        <div></div>
        {hasVat === 'yes' && (
          <div>
            <h5> {t('.vat')} </h5>
            <Field
              name='vat'
              type='text'
              label={t('.vat_number')}
              onChange={handleVATChange}
              mandatory
              component={renderInputField}
              t={t}
            />
            <Button
              type='submit'
              className='btn btn_pill is-blue'
              onClick={vatVerfication}
            >
              {t('.check_vat_number')}
            </Button>
          </div>
        )}

        {(hasVat === 'no' || isVatValid) && (
          <div>
            <h5> {t('.company_billing_info')} </h5>
            <Field
              name='organization_name'
              type='text'
              label={t('.company_name')}
              onChange={handleChange}
              mandatory
              component={renderInputField}
              t={t}
            />
            <div className='flex_row address_form'>
              <Field
                name='street'
                type='text'
                label={t('.street_number')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='zip_code'
                type='text'
                label={t('.zip_code')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='city'
                type='text'
                label={t('.city')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              {hasVat === 'yes' ? (

                <Field
                  name='country'
                  type='text'
                  label={t('.country')}
                  input={{ disabled: hasVat === 'yes', defaultValue: initialValues?.country, value: initialValues?.country }}
                  mandatory
                  component={renderInputField}
                  t={t}
                />
              ) : null}
              {hasVat === 'no' ? (
                <div className='flex-div newmargin'>
                  <CountryDropdownItem
                    label={t('.country')}
                    name='country'
                    handleChange={countryhandleChange}
                    options={listOfCountries()}
                    value={initialValues?.country}
                  />

                  {/* // <Field
                //   name='country'
                //   type='text'
                //   label={t('.country')}
                //   onChange={handleChange}
                //   mandatory
                //   component={renderInputField}
                //   t={t}
                // /> */}
                </div>

              ) : null}
              {/*
              <Field
                name='country'
                type='text'
                label={t('.country')}
                onChange={handleChange}
                disabled
                input={{ disabled: hasVat === 'yes', defaultValue: initialValues?.country, value: initialValues?.country }}
                mandatory
                component={renderInputField}
                t={t}
              /> */}
            </div>
            <Field
              name='phone_number'
              type='text'
              label={t('.phone_number')}
              onChange={handleChange}
              component={renderInputField}
              t={t}
            />
            <h5> {t('.account_tenant')} </h5>
            <div className='flex_row'>
              <Field
                name='firstname'
                type='text'
                label={t('.firstname')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='name'
                type='text'
                label={t('.lastname')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
            </div>
            <h5> {t('.company_informations')} </h5>
            <DropdownItem
              label={t('.sector')}
              name='sector'
              placeholder="Choisissez un secteur d'activité"
              handleChange={handleChange}
              options={organization_sector}
              value={sector}
              t={t}
            />
            <DropdownItem
              label={t('.employee_rate')}
              name='employees'
              placeholder="Sélectionnez le nombre d'employé(s) que comporte votre société"
              handleChange={handleChange}
              options={organization_employees}
              value={employees}
              t={t}
            />
          </div>
        )}
        {error && <p className='validation_error'>{error}</p>}
        <div>
          <Button
            type='submit'
            className='btn btn_pill is-blue'
            disabled={invalid || submitting || !initialValues?.country || (hasVat === 'yes' && !isVatValid)}
          >
            {t('.account_confirmation')}
          </Button>
        </div>
      </form>
    </div>
  );
};

OnBoardingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: 'onboarding_form',
    enableReinitialize: true,
    validate,
  }),
  withTranslation('account_onboarding'),
  withRouter,
)(OnBoardingForm);  
