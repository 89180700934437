import React, { Fragment } from 'react';
import { isSafari } from 'react-device-detect';
import Axios from 'axios';
import { pdfjs, Document, Page } from 'react-pdf';

import Button from '../../components/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFPreview extends React.Component {
  constructor() {
    super();
    this.state = {
      pdfFile: null,
    };
  }

  componentDidMount() {
    const { preview, selectedLocale } = this.props;
    this.getPDFPreview(preview, selectedLocale);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { preview, selectedLocale } = nextProps;
    this.getPDFPreview(preview, selectedLocale);
  }

  getPDFPreview(preview, locale) {
    const location = window.location.href;
    const url = location
      .replace('https://', '')
      .replace('http://', '')
      .split(/[/?#]/)[0];
    const domain = url.split('.')[0];

    const api_link = process.env.REACT_APP_BASE_URL.replace('##PREFIX##', domain);

    Axios(`${api_link}${preview}?lang=${locale}`, {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        this.setState({ pdfFile: fileURL });
      })
      .catch(error => {
        console.log(error);
      });
  }

  printIframe = () => {
    const iframe = document.frames
      ? document.frames['preview']
      : document.getElementById('preview');
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  render() {
    const { pdfFile } = this.state;
    return (
      <Fragment>
        {!pdfFile ? (
          <div>Loading...</div>
        ) : (
          <Fragment>
            {isSafari && (
              <div className='download_pdf'>
                <a href={pdfFile} download='my_privacy_policy-pdf'>
                  <Button className='btn btn_pill is-blue'>Télécharger le PDF</Button>
                </a>
                <Button
                  className='btn btn_pill is-blue is-outlined'
                  onClick={this.printIframe}
                  style={{ marginLeft: '12px' }}
                >
                  Imprimer le PDF
                </Button>
              </div>
            )}
            <iframe id='preview' src={pdfFile} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default PDFPreview;
