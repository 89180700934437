import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { loadModal } from '../../redux/modal/action';
import withTranslation from '../../providers/I18n/HoC/t';
import { AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';

class CategoryQuestionExplanation extends React.Component {
  constructor(props) {
    super(props);
    const { question } = this.props;
    this.handleClick = this.handleClick.bind(this);
    this.toggleExample = this.toggleExample.bind(this);
    this.showCountrySpecific = this.showCountrySpecific.bind(this);
    this.state = {
      openExplanation: true,
    };
  }

  handleClick() {
    this.setState(prevState => ({
      openExplanation: !prevState.openExplanation,
    }));
  }

  toggleExample() {
    const { question, loadExampleModal, t } = this.props;
    loadExampleModal({ modalType: 'example', content: question, title: t('.examples') });
  }

  questionHasExplanation = question => {
    const { explanation, explanationExt } = question;

    let trimExplanation = explanation.replace(' ', '');

    if (explanation && trimExplanation.length > 0) return true;
    else if (explanationExt) {
      let keys = Object.keys(explanationExt);
      if (keys.filter(key => explanationExt[key].length > 0).length > 0) return true;
    } else {
      return false;
    }
  };

  questionHasExample = question => {
    const { example } = question;
    let hasExample = false;

    try {
      JSON.parse(example);
    } catch (e) {
      hasExample = true;
    }

    return hasExample && question.example !== 'null' && question.example.replace(' ', '')
      ? true
      : false;
  };

  showCountrySpecific() {
    const {
      question: { explanationExt },
      t,
      availableCountries,
    } = this.props;
    const explanationParsed = explanationExt;
    const countriesExplanation = [];

    const keys = Object.keys(explanationParsed);

    for (const key of keys) {
      const text = explanationParsed[key];
      if (text && text.length > 1) {
        if (availableCountries.includes(key)) {
          countriesExplanation.push(
            <Fragment key={key}>
              <h3> {t(`.${key}`)} </h3>
              <div
                className='query_text'
                dangerouslySetInnerHTML={{ __html: explanationParsed[key] }}
              />
            </Fragment>,
          );
        }
      }
    }
    return countriesExplanation;
  }

  render() {
    const { question, t } = this.props;
    const { openExplanation } = this.state;

    let explanationPresent = this.questionHasExplanation(question);
    let examplePresent = this.questionHasExample(question);

    return (
      <div>
        {explanationPresent && (
          <div className={`question_explanation ${openExplanation ? 'cover' : 'show'}`}>
            <div className='explanation_title split' onClick={this.handleClick}>
              <p className='is-flex is-aic'>
                <AiOutlineInfoCircle size='1.5rem' className='mr-05' />{' '}
                {t('.additional_explanation')}{' '}
              </p>
              <button type='button'>
                <span className='arrow arrow_up' />
              </button>
            </div>
            <div className='question_wrapper'>
              {explanationPresent && (
                <div
                  className='query_text'
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              )}
              {this.showCountrySpecific()}
              <div className='examples'>
                {question.example && examplePresent && (
                  <button
                    className='btn btn_pill is-green'
                    type='button'
                    onClick={this.toggleExample}
                  >
                    {t('.see_examples')}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {question.warningMessage && question.warningMessage.length > 1 && (
          <div className='warning_message question_warning'>
            <AiOutlineWarning size='1.5rem' className='mr-05' />
            <span dangerouslySetInnerHTML={{ __html: question.warningMessage }} />
          </div>
        )}
        <div>
          <span style={{ color: 'red' }}> * </span> {t('.mandatory_answer')}
        </div>
      </div>
    );
  }
}

CategoryQuestionExplanation.propTypes = {
  question: PropTypes.shape({}).isRequired,
  loadExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  loadExampleModal: loadModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withTranslation('question'),
)(CategoryQuestionExplanation);
