import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import { withNotifications } from '../../../providers/notifications'
import Button from 'src/components/Button'

const ConfirmPaymentModel = ({
    title,
    closeExampleModal,
    content: { dataApiset },
    t,
    client,
    notificationsProvider,
    history,
}) => {
    useEffect(() => {

    }, [])

    return (
        <Modal>
            <div className='modal_bar'>
                <h4 className='modal_title'>{title}</h4>
                <button type='button' className='close' onClick={closeExampleModal} />
            </div>
            <div className='modal_content'>
                {t('.confirm_payment_msg')}
                <div className='list_buttons'>
                    <Button
                        className='btn btn_pill is-outlined is-blue'
                        published
                        onClick={closeExampleModal}
                    >
                        {t('.no')}
                    </Button>

                    <Button
                        className='btn btn_pill is-blue'
                        published
                        onClick={() => {
                            dataApiset('', 'selectOldCard', false)
                            closeExampleModal()
                        }}
                    >
                        {t('.yes')}
                    </Button>
                </div>
            </div>

        </Modal>
    )
}


ConfirmPaymentModel.propTypes = {
    title: PropTypes.string.isRequired,
    closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    closeExampleModal: hideModal,
}
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('account_onboarding'),
)(ConfirmPaymentModel)
