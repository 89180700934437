import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { loadModal } from '../../redux/modal/action.js'
import { withApollo } from 'react-apollo'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoIosCloseCircleOutline, } from 'react-icons/io';
import { AiOutlineDelete } from "react-icons/ai";
import gql from 'graphql-tag';
import { NotificationModel } from 'src/providers/notifications/index.js'

const CardRow = ({ card, t, loadModal, userId, checkActive, client, notificationsProvider, history, refetchData }) => {
  return (
    <div className='row '>
      <div className={`columns license-table-row flex-items ${card?.isPrimary ? ' published-new' : ''}`}>
      </div>
      <div className='columns license-table-row flex-items'>
        {card?.name ? card?.name : '-'}
      </div>
      <div className='columns license-table-row flex-items'>
        {card?.cardType ? card?.cardType : '-'}
      </div>
      <div className='columns license-table-row flex-items'>
        ***{card?.last4 ? card?.last4 : '-'}
      </div>
      <div className='columns license-table-row flex-items'>
        {card?.exMonth}/{card?.exYear}
      </div>
      <div className='columns license-table-row flex-items'>
        {card?.isPrimary ? (
          <>
            <IoMdCheckmarkCircleOutline className={`icon-size color-active`} onClick={() => {
              if (!card?.isPrimary) {
                checkActive({ id: card.id });
              }
            }} />
          </>
        ) :
          (
            <>
              <span style={{ cursor: "pointer" }} onClick={() => {
                if (!card?.isPrimary) {
                  checkActive({ id: card.id });
                }
              }}>
                {t('.make_primary')}
              </span>
              {/* <IoIosCloseCircleOutline className={`icon-size`} onClick={() => {
                if (!card?.isPrimary) {
                  checkActive({ id: card.id });
                }
              }}
              /> */}
            </>
          )
        }

      </div>
      <div className='columns license-table-row flex-items'>
        {!card?.isPrimary ? (
          <>
            <AiOutlineDelete className={`icon-size`} onClick={() => {
              client
                .mutate({
                  mutation: REMOVE_CARDS,
                  variables: {
                    id: card.id,
                  },
                })
                .then(() => {
                  // history.go(0)

                  const notification = new NotificationModel({
                    title: '',
                    message: t('.remove_card_success'),
                    type: 'success',
                  });
                  notificationsProvider.add(notification);
                  refetchData()
                })
                .catch(error => {
                  const notification = new NotificationModel({
                    title: '',
                    message: t('.remove_card_error'),
                    type: 'alert',
                  });
                  notificationsProvider.add(notification);
                });
            }} />
          </>
        ) :
          null}

      </div>

    </div >
  )
}

const mapDispatchToProps = {
  loadModal,
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

const REMOVE_CARDS = gql`
 mutation RemoveCard($id: ID!) {
    removeCard(id: $id) {
        message
    }
}
`;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withApollo,
)(CardRow)