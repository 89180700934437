import en from './en-countries.json';
import fr from './fr-countries.json';

let regex = /\([^)]*\)/;

const createEUCountryList = local => {
  return local.map(country => {
    return { label: country.name.replace(regex, ''), value: country.alpha2 };
  });
};

const countriesList = [];

countriesList['en'] = createEUCountryList(en);

countriesList['fr'] = createEUCountryList(fr);

export default countriesList;
