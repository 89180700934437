/*

Message d’une étoile,
“Quand tu regarderas le ciel, la nuit,
puisque j’habiterai dans l’une d’elles,
puisque je rirai dans l’une d’elles,
alors ce sera pour toi comme si riaient toutes les étoiles”.

Antoine de Saint-Exupery, Le Petit Prince.

Message from a star,
“When you will look at the sky at night,
in one of the stars I shall be living,
in one of them I shall be laughing,
And so it will be as if all stars were laughing.”

Antoine de Saint-Exupery, The Little Prince.

*/

import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';

import { NotificationsProvider } from './providers/notifications';
import { LocalesAndCountriesProvider } from './providers/localesAndCountries';
import { I18nProvider } from './providers/I18n';

import IeMessage from './components/IE';
import client from './config/apollo_client';
import store from './redux/config';

import './config/axios';
import Root from './root';
import './assets/scss/main.scss';

import './helpers/browser.js';

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

const render = Component => {
  ReactDOM.render(
    isIE ? (
      <IeMessage />
    ) : (
      <I18nProvider>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <LocalesAndCountriesProvider>
              <NotificationsProvider>
                <Component />
              </NotificationsProvider>
            </LocalesAndCountriesProvider>
          </Provider>
        </ApolloProvider>
      </I18nProvider>
    ),
    document.getElementById('app'),
  );
};

render(Root);
