/* eslint react/jsx-indent-props: off */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';

import Index from '../FieldInput';

const RetrievePasswordForm = ({ handleSubmit, submitting }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className='columns large-offset-3 large-6 medium-offset-3 medium-6 small-12 align-self-middle sign-in-form'
    >
      <Field
        name='email'
        type='email'
        label='Email'
        component={Index}
        validate={[required(), email()]}
      />
      <br />
      <div className='float-right'>
        <ul>
          <li>
            <button type='submit' className='button' disabled={submitting}>
              Send me my password
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

RetrievePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(reduxForm({ form: 'retrievePasswordForm' }))(RetrievePasswordForm);
