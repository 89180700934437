// @ts-nocheck
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'

import Loading from '../../components/Loading'
import AuthWrapper from '../../components/Auth'
import AccountEdit from './edit'
import NoMatch from '../NoMatch/'
import { withTranslation } from '../../providers/I18n'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'
import Button from 'src/components/Button'

class AccountPage extends React.Component {
  render() {
    const { t, isSuperAdmin } = this.props
    return (
      <AuthWrapper>
        <TitleComponent title={t('.account')} />
        <Query query={ORGANIZATION_QUERY} fetchPolicy={'network-only'}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return `Error! ${error.message}`
            const { organization } = data
            return (
              <div className='row'>
                <div className='columns'>
                  <div className='button-buy-extra'>
                    <div>
                      <h1> {t('.account')} </h1>
                    </div>
                    {/* <div>
                      <Button className='btn btn_pill is-blue' onClick={() => {
                        localStorage.setItem('buyExtraState', "")

                        const {
                          history
                        } = this.props
                        history.push('/buylicenses')
                      }}>
                        <i className='fa fa-plus-circle margin-right-10' />
                        {t('.buyextra')}
                      </Button>
                    </div> */}
                  </div>
                  {organization.billing && isSuperAdmin ? (
                    <AccountEdit organization={organization} t={t} />
                  ) : (
                    <NoMatch />
                  )}
                </div>
              </div>
            )
          }}
        </Query>
      </AuthWrapper>
    )
  }
}

const ORGANIZATION_QUERY = gql`
  query {
    organization {
      id
      acceptedAt
      admin {
        email
        id
      }
      settings
      billing {
        address
        companyName
        createdAt
        id
        industry
        managerName
        size
        updatedAt
        vat
        mailLanguage
      }
      createdAt
      name
      registrationIp
      subDomainName
      type
      updatedAt
    }
  }
`

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin } = auth
  return {
    isSuperAdmin,
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslation('account_onboarding'),
)(AccountPage)
