import React from 'react';
import { Link } from 'react-router-dom';

import { formattedDate } from '../../helpers/date';

const PolicyCard = ({ t, policyType, policy }) => (
  <li className='privacy-policy-card' key={policy.id}>
    <Link to={`/${policyType}-policies/${policy.id}`}>
      <div className='policy'>
        <div className='policy_content'>
          <div className='title-wrapper'>
            <span className='title block'>{policy.title}</span>
          </div>

          <div className='is-flex'>
            <div className=' policy_card-version'>
              <span className='text'>{t('.version')}</span>
              <span className='number'>{policy.version}</span>
            </div>
            <div>
              <span className={`status right ${policy.status}`}>{t(`.${policy.status}`)}</span>
            </div>
          </div>
          <div className='policy-desc'>
            <div>
              <span className='block'>
                {t('.creation')}: {formattedDate(policy.createdAt)}
              </span>
              <span className='block'>
                {t('.modification')}: {formattedDate(policy.updatedAt)}
              </span>
              <span className='block'>
                {t('.publication')}:{' '}
                {policy.publishedAt ? formattedDate(policy.publishedAt) : ' - '}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </li>
);

export default PolicyCard;
