import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

let nextUrl = localStorage.getItem('nextUrl');
let redirectUrl;
if (nextUrl !== null) {
  redirectUrl = nextUrl;
  localStorage.removeItem('nextUrl');
} else {
  redirectUrl = '/cookie-policies';
}

const UnAuthenticateRoute = ({ component: Component, authenticate, ...rest }) => (
  <Route
    {...rest}
    render={props => (authenticate ? <Redirect to={redirectUrl} /> : <Component {...props} />)}
  />
);

UnAuthenticateRoute.defaultProps = {
  authenticate: false,
};

UnAuthenticateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticate: PropTypes.bool,
};

export default UnAuthenticateRoute;
