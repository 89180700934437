import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import { withNotifications } from '../../../providers/notifications'
import Button from 'src/components/Button'

const PrimarycardExpiry = ({
    t,
    history,
    closeExampleModal
}) => {
    return (
        <Modal>
            <div className='modal_bar'>
                <h4 className='modal_title'>{t('.expiry_popup_tile')}</h4>
            </div>
            <div className='modal_content'>
                {t('.expiry_popup_desc')}
                <div className='list_buttons'>
                    <Button
                        className='btn btn_pill is-blue'
                        published
                        onClick={() => {
                            closeExampleModal();
                            history.push(`/account`)
                        }}
                    >
                        {t('.Account_settings')}
                    </Button>
                </div>
            </div>

        </Modal>
    )
}


const mapDispatchToProps = {
    closeExampleModal: hideModal,
}
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('dashboard'),
)(PrimarycardExpiry)
