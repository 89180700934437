import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import gql from 'graphql-tag';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import withTranslation from '../../../providers/I18n/HoC/t';

const DeleteModal = props => {
  const { title, closeExampleModal, t } = props;

  const handleDelete = () => {
    const {
      client,
      match: {
        params: { id },
      },
      history,
    } = props;

    client
      .mutate({
        mutation: DELETE_POLICY_MUTATION,
        variables: {
          id,
        },
      })
      .then(() => {
        history.push(`/privacy-policies/`);
      });
  };

  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'> {title} </h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='modal_content'>
        <p> {t('.modal_delete_text')} </p>
        <div className='list_buttons' onClick={closeExampleModal}>
          <Button rounded invert>
            {t('.abort_delete')}
          </Button>
          <Button rounded published onClick={handleDelete}>
            {t('.delete_policy')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DELETE_POLICY_MUTATION = gql`
  mutation deletePrivacyPolicy($id: ID!) {
    deletePrivacyPolicy(id: $id) {
      id
    }
  }
`;

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withTranslation('dashboard'),
)(DeleteModal);
