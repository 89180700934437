import React from 'react';
import { Query } from 'react-apollo';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import gql from 'graphql-tag';

import { withTranslation } from '../../providers/I18n';
import AuthWrapper from '../../components/Auth';
import AuthWrapperNoHeader from '../../components/AuthNoHeader';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class CookieBannerIntegrationProcess extends React.Component {
  copyIframe = () => {
    let item = document.getElementById('bannerLink');
    item.select();
    document.execCommand('copy');
  };

  render() {
    const {
      t,
      match: {
        params: { id },
      },
      integrate,
    } = this.props;
    const isIntegrated = typeof integrate !== 'undefined';
    const banner_integration_url = process.env.REACT_APP_BANNER_APP_BASE_SCRIPT_URL;

    if (isIntegrated) {
      return (
        <AuthWrapperNoHeader>
          <Query query={PRIVACY_POLICY_QUERY} variables={{ id: id }} fetchPolicy={'network-only'}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;
              const {
                privacyPolicy: { uid },
              } = data;
              return (
                <div className='row'>
                  <div className='columns process_explanation'>
                    <h1> {t('.how_integrate_title')}</h1>
                    <p> {t('.how_integrate_explanation')}</p>
                    <div>
                      <h2> 1. {t('.insert_all')} </h2>
                      <div className='flex_row'>
                        <input
                          value={`<script src='${banner_integration_url}' data-admeet-token='${uid}' data-admeet-button-theme='dark' />`}
                          id='bannerLink'
                          readOnly
                          className='read-only width-75'
                        />
                        <Button
                          published
                          className='btn btn_pill is-outlined is-blue margin-left-20'
                          onClick={this.copyIframe}
                        >
                          <i className='far fa-copy margin-right-10' />
                          {t('.copy')}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <h2> 2. {t('.script_modification')} </h2>
                      <p>
                        {' '}
                        {t('.add_script')} <span>type</span> {t('.in')}
                        <span>text/plain</span>
                      </p>
                      <p>
                        {' '}
                        {t('.add_attribute')}
                        <span>data-cookieconsent={' {scriptType } '}</span>, {t('.replace_by_this')}
                      </p>
                      <ul>
                        <li>
                          <span>necessary</span>
                        </li>
                        <li>
                          <span>functional</span>
                        </li>
                        <li>
                          <span>marketing</span>
                        </li>
                        <li>
                          <span>statistic</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h2> {t('.examples')} </h2>
                      <div className='flex_row margin-top-20'>
                        <p
                          style={{
                            width: '10%',
                            textAlign: 'center',
                          }}
                        >
                          {t('.from')}
                        </p>
                        <span>
                          <p>&lt;script</p>
                          <p style={{ marginLeft: '32px' }}>
                            src="https://cookies.netlify.com/vendors/site.js"
                          </p>
                          <p style={{ marginLeft: '32px' }}>type="text/javascript"</p>
                          <p>/&gt;</p>
                        </span>
                      </div>
                      <div className='flex_row margin-top-20'>
                        <p
                          style={{
                            width: '10%',
                            textAlign: 'center',
                          }}
                        >
                          {t('.to')}
                        </p>
                        <span>
                          <p>&lt;script</p>
                          <p style={{ marginLeft: '32px' }}>
                            src="https://cookies.netlify.com/vendors/site.js"
                          </p>
                          <p style={{ marginLeft: '32px' }}>type="text/plain"</p>
                          <p style={{ marginLeft: '32px' }}>data-cookieconsent="functional"</p>
                          <p>/&gt;</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Query>
        </AuthWrapperNoHeader>
      );
    } else {
      return (
        <AuthWrapper>
          <TitleComponent title={t('.how_integrate_title')} />
          <Query query={PRIVACY_POLICY_QUERY} variables={{ id: id }} fetchPolicy={'network-only'}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;
              const {
                privacyPolicy: { uid },
              } = data;
              return (
                <div className='row'>
                  <div className='columns process_explanation'>
                    <h1> {t('.how_integrate_title')}</h1>
                    <p> {t('.how_integrate_explanation')}</p>
                    <div>
                      <h2> 1. {t('.insert_all')} </h2>
                      <div className='flex_row'>
                        <input
                          value={`<script src='${banner_integration_url}' data-admeet-token='${uid}' data-admeet-lang='fr' data-admeet-button-theme='dark' />`}
                          id='bannerLink'
                          readOnly
                          className='read-only width-75'
                        />
                        <Button
                          published
                          className='btn btn_pill is-outlined is-blue margin-left-20'
                          onClick={this.copyIframe}
                        >
                          <i className='far fa-copy margin-right-10' />
                          {t('.copy')}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <h2> 2. {t('.script_modification')} </h2>
                      <p>
                        {' '}
                        {t('.add_script')} <span>type</span> {t('.in')}
                        <span>text/plain</span>
                      </p>
                      <p>
                        {' '}
                        {t('.add_attribute')}
                        <span>data-cookieconsent={' {scriptType } '}</span>, {t('.replace_by_this')}
                      </p>
                      <ul>
                        <li>
                          <span>necessary</span>
                        </li>
                        <li>
                          <span>functional</span>
                        </li>
                        <li>
                          <span>marketing</span>
                        </li>
                        <li>
                          <span>statistics</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h2> {t('.examples')} </h2>
                      <div className='flex_row margin-top-20'>
                        <p
                          style={{
                            width: '10%',
                            textAlign: 'center',
                          }}
                        >
                          {t('.from')}
                        </p>
                        <span>
                          <p>&lt;script</p>
                          <p style={{ marginLeft: '32px' }}>
                            src="https://cookies.netlify.com/vendors/site.js"
                          </p>
                          <p style={{ marginLeft: '32px' }}>type="text/javascript"</p>
                          <p>/&gt;</p>
                        </span>
                      </div>
                      <div className='flex_row margin-top-20'>
                        <p
                          style={{
                            width: '10%',
                            textAlign: 'center',
                          }}
                        >
                          {t('.to')}
                        </p>
                        <span>
                          <p>&lt;script</p>
                          <p style={{ marginLeft: '32px' }}>
                            src="https://cookies.netlify.com/vendors/site.js"
                          </p>
                          <p style={{ marginLeft: '32px' }}>type="text/plain"</p>
                          <p style={{ marginLeft: '32px' }}>data-cookieconsent="functional"</p>
                          <p>/&gt;</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Query>
        </AuthWrapper>
      );
    }
  }
}

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      id
      uid
    }
  }
`;

export default compose(
  withApollo,
  withRouter,
  withTranslation('cookie_banner_integration'),
)(CookieBannerIntegrationProcess);
