import React from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import AccountForm from './account_form';
import OrganizationForm from './company_form';
import Button from '../../components/Button';
import Licenses from '../Licenses';
import BillingCard from '../BllingCard';
import Invoice from '../Invoice';

class ProfileForm extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedTab: 'account',
    };
  }

  renderNavigation = () => {
    const { t } = this.props;
    return (
      <div className='account_nav_container'>
        <ul className='account_nav'>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'account' ? 'active' : ''}`}
            onClick={() => {
              this.setState({ selectedTab: 'account' });
            }}
          >
            {t('.account_information')}
          </li>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'organization' ? 'active' : ''
              }`}
            onClick={() => {
              this.setState({ selectedTab: 'organization' });
            }}
          >
            {t('.company_information')}
          </li>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'product' ? 'active' : ''}`}
            onClick={() => {
              this.setState({ selectedTab: 'product' });
            }}
          >
            {t('.products')}
          </li>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'invoice' ? 'active' : ''}`}
            onClick={() => {
              this.setState({ selectedTab: 'invoice' });
            }}
          >
            {t('.invoice')}
          </li>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'billCard' ? 'active' : ''}`}
            onClick={() => {
              this.setState({ selectedTab: 'billCard' });
            }}
          >
            {t('.bill_card')}
          </li>
          <li
            className={`account_nav_item ${this.state.selectedTab === 'bill' ? 'active' : ''}`}
            onClick={() => {
              this.setState({ selectedTab: 'bill' });
            }}
            style={{ display: 'none' }}
          >
            {t('.billing')}
          </li>


        </ul>
      </div>
    );
  };

  renderTab = () => {
    const { selectedTab } = this.state;
    const { sector, employees, handleChange, handleSubmit, t, client, initialValues } = this.props;
    switch (selectedTab) {
      case 'account':
        return <AccountForm handleChange={handleChange} t={t} initialValues={initialValues} />;

      case 'organization':
        return (
          <OrganizationForm
            sector={sector}
            employees={employees}
            handleChange={handleChange}
            t={t}
          />
        );

      case 'bill':
        return <h5> {t('.does_not_exist')} </h5>;

      case 'product':
        return <Licenses t={t} />;

      case 'billCard':
        return <BillingCard t={t} client={client} />;
      case 'invoice':
        return <Invoice t={t} client={client} />;
      default:
        return;
    }
  };

  render() {
    const { resetState, handleSubmit, t } = this.props;
    const { selectedTab } = this.state;
    return (
      <div>
        {this.renderNavigation()}
        {/* Getting profile update message that way I am adding this condition */}
        {(selectedTab === 'product' || selectedTab === 'billCard' || selectedTab === 'invoice') ? this.renderTab() : null}
        <form className='text-form auth_form onboarding_form' onSubmit={handleSubmit}>
          {(selectedTab !== 'product' && selectedTab !== 'billCard' && selectedTab !== 'invoice') ? this.renderTab() : null}
          {(selectedTab !== 'product' && selectedTab !== 'billCard' && selectedTab !== 'invoice') && (
            <div className='list_buttons'>
              <Button className='btn btn_pill is-outlined' onClick={() => resetState()}>
                <i className='fas fa-times margin-right-10' />
                {t('.refresh')}
              </Button>
              <Button className='btn btn_pill is-blue' published type='submit'>
                <i className='fas fa-check margin-right-10' />
                {t('.confirm')}
              </Button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: 'account_form',
    enableReinitialize: true,
  }),
)(ProfileForm);
