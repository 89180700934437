import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { withTranslation } from '../../../providers/I18n';

const TokenOutdated = props => {
  const { content, title, closeModal, history, t } = props;

  const closeResetModal = () => {
    history.push('/');
    closeModal();
  };

  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'> {title} </h4>
        <button type='button' className='close' onClick={closeResetModal} />
      </div>
      <div className='modal_content'>
        <div className='query_text'>
          <h5> {t('.outdated_token_text')}</h5>
        </div>
        <div className='modal_buttons'>
          <Button onClick={closeResetModal} className='btn btn_pill is-black' published>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

TokenOutdated.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeModal: hideModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(TokenOutdated);
