const organization_sector = [
  {
    value: 'administration',
    label: '.administration',
  },
  {
    value: 'household',
    label: '.household',
  },
  {
    value: 'extraterritorial',
    label: '.extraterritorial',
  },
  {
    value: 'financial',
    label: '.financial',
  },
  {
    value: 'realty',
    label: '.realty',
  },
  {
    value: 'science',
    label: '.science',
  },
  {
    value: 'husbandry',
    label: '.husbandry',
  },
  {
    value: 'art',
    label: '.art',
  },
  {
    value: 'vehicle',
    label: '.vehicle',
  },
  {
    value: 'construction',
    label: '.construction',
  },
  {
    value: 'accomodation',
    label: '.accomodation',
  },
  {
    value: 'factory',
    label: '.factory',
  },
  {
    value: 'extractive',
    label: '.extractive',
  },
  {
    value: 'communication',
    label: '.communication',
  },
  {
    value: 'sanitation',
    label: '.sanitation',
  },
  {
    value: 'resources',
    label: '.resources',
  },
  {
    value: 'transport',
    label: '.transport',
  },
  {
    value: 'service_activities',
    label: '.service_activities',
  }
];


const organization_employees = [
  {
    value: 'micro',
    label: '.micro_organization',
  },
  {
    value: 'small',
    label: '.small_organization',
  },
  {
    value: 'medium',
    label: '.medium_organization',
  },
  {
    value: 'large',
    label: '.large_organization',
  },
];

export { organization_sector, organization_employees };
