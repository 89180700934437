import React from 'react';
import * as PropTypes from 'prop-types';

const TabulationItem = ({ className, label, tab, currentTab, onChangeTab }) => (
  <li
    className={`${className} ${currentTab === tab ? 'active' : ''}`}
    onClick={() => onChangeTab(tab)}
  >
    {' '}
    {label}{' '}
  </li>
);

TabulationItem.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export { TabulationItem };
