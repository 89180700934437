import React from 'react';

import banner_button_right from '../../../../assets/img/banner_button_right.png';
import banner_button_left from '../../../../assets/img/banner_button_left.png';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { hideModal, loadModal } from 'src/redux/modal/action';

const ButtonPosition = ({ button_position, handleChange, t, bannerPersonalization, loadModal, selectedLicense }) => {
  return (
    <div className='customisation__position'>
      <h5>{t('.position_button')}</h5>
      <div className='cst__position__content'>
        <div
          className={`cst__position__item ${button_position === 'left' ? 'selected' : ''}`}
          onClick={() => {
            if (bannerPersonalization) {
              handleChange('left', 'button_position')
            }
            else {
              loadModal({
                modalType: 'access_limit_user_model',
                content: {
                  selectedLicense: selectedLicense
                },
                title: '',
              })
            }
          }}

        >
          <img src={banner_button_left} alt='' />
        </div>
        <div
          className={`cst__position__item ${button_position === 'right' ? 'selected' : ''}`}
          onClick={() => handleChange('right', 'button_position')}
        >
          <img src={banner_button_right} alt='' />
        </div>
      </div>
    </div>
  );
};

// export default ButtonPosition;
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(
  connect(null, mapDispatchToProps),
)(ButtonPosition)
