// @ts-nocheck
import React from 'react'
import { compose } from 'recompose'
import connect from 'react-redux/es/connect/connect'
import { withRouter, Redirect } from 'react-router-dom'
import { withNotifications, NotificationModel } from '../../providers/notifications/'
import { Query } from 'react-apollo'

import EditForm from './form'
import AuthWrapper from '../../components/ProductAuth'
import Loading from '../../components/Loading'
import GraphqlCalls from './GraphQl'
import { withTranslation } from '../../providers/I18n'

const PrivacyPolicyEdit = ({
  match: {
    params: { id },
    url,
  },
  t,
  userId,
  notificationsProvider,
}) => {
  const isCookiePolicy = () => {
    return url.includes('cookie-policies')
  }

  const policyType = isCookiePolicy() ? 'cookie' : 'privacy'
  return (
    <AuthWrapper>
      <div className='row'>
        <div className='columns'>
          <h1> {t(`.my_${policyType}_policies`)} </h1>
          <Query
            fetchPolicy={'network-only'}
            query={GraphqlCalls.GET_PRODUCT_POLICY_QUERY}
            variables={{ id, userId }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return `Error! ${error.message}`
              const { product, products } = data

              if (!product) {
                const notification = new NotificationModel({
                  title: t('.no_access_to_policy_title'),
                  message: t('.no_access_to_policy'),
                  type: 'alert',
                })
                notificationsProvider.add(notification)

                return <Redirect to={`/${policyType}-policies`} />
              }

              const availableLegislation = product.license.features.reduce(
                (arr, item) => (item.groupCode === 'legislation' && arr.push(item['code']), arr),
                [],
              )
              const availableLanguages = product.license.features.reduce(
                (arr, item) => (item.groupCode === 'language' && arr.push(item['code']), arr),
                [],
              )

              const languages = product.license.features.reduce(
                (arr, item) => (
                  item.groupCode === 'language' && item.active && arr.push(item['code']), arr
                ),
                [],
              )
              console.log("product==70===*******=", product)
              return (
                <EditForm
                  pp={product}
                  selectedLicense={product.license}
                  title={product.title}
                  domainName={product.license.domainName}
                  availableLanguages={availableLanguages}
                  availableLegislation={availableLegislation}
                  allCookiePolicyProducts={products}
                  packageData={product?.license}
                  formType={'edit'}
                  locales={languages}
                  linkPolicyMutation={GraphqlCalls.LINK_PRODUCT_POLICIES_MUTATION}
                  graphqlMutation={GraphqlCalls.UPDATE_PRODUCT_POLICY_MUTATION}
                />
              )
            }}
          </Query>
        </div>
      </div>
    </AuthWrapper>
  )
}

const mapStateToProps = ({ auth }) => {
  const { userId } = auth
  return { userId }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslation('privacyPolicyEdit'),
  withNotifications(),
)(PrivacyPolicyEdit)
