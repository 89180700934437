import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userFormReducer from './user_form_reducer'
import modalReducer from './modal/reducer';
import previewReducer from './preview/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  userForm: userFormReducer,
  auth: authReducer,
  modal: modalReducer,
  preview: previewReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


export default store;
