import React from 'react';
import Button from '../../../components/Button';
import withTranslation from '../../../providers/I18n/HoC/t';
import { compose } from 'recompose';

class HorizontalLanguageSelector extends React.Component {
  constructor() {
    super();
  }

  render() {
    const {
      handleLanguageChange,
      languageOptions,
      selectedLanguage,
      openPreview,
      previewButton,
      invalid,
      t,
    } = this.props;

    const mt = previewButton ? '48px' : '0px';

    return (
      <div style={{ display: 'inline-block', width: '50%', textAlign: 'right', marginTop: mt }}>
        {previewButton && (
          <Button
            className='btn btn_pill is-outlined is-blue margin-right-20'
            expanded
            style={{ width: 'auto', padding: '9px 20px' }}
            onClick={openPreview}
            disabled={invalid}
          >
            <i className='fas fa-desktop margin-right-10'></i>
            {t('.visualize')}
          </Button>
        )}

        {languageOptions.map(option => (
          <Button
            className={`form_locales__horizontal_item ${
              selectedLanguage === option.value ? 'active' : ''
            }`}
            rounded
            invert
            key={option.value}
            value={option.value}
            onClick={handleLanguageChange}
          >
            {option.value}
          </Button>
        ))}
      </div>
    );
  }
}

export default compose(withTranslation('cookie_customisation'))(HorizontalLanguageSelector);

//export default HorizontalLanguageSelector;
