import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';

import logOutAction from './actions';
import logo from '../../assets/img/logo-white.png'
import logOut from '../../assets/img/log-out.svg';
import LanguageSelection from '../Form/LanguageSelection';

class UnAuthenticateAccess extends React.Component {
  logOut = () => {
    const { history, logOutAction } = this.props;
    logOutAction();
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    history.replace('/');
  };

  render() {
    return (
      <div className='row'>
        <div className='columns'>
          <div className='main-header'>
            <div className='menu-left'>
              {/* <Link to='/'> */}
              <img src={logo} className='logo' alt='Logo Admeet' />
              {/* </Link> */}
            </div>
            <div className='menu-right'>
              <div className='language_selection'>
                <LanguageSelection />
              </div>
              {/* <button onClick={this.logOut}>
                <img src={logOut} alt='Log Out' className='button_logout' />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnAuthenticateAccess.defaultProps = {
  history: {
    replace: () => {
      return 'Redirection';
    },
  },
  logOutAction: () => {
    return 'Log Out';
  },
};

UnAuthenticateAccess.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  logOutAction: PropTypes.func,
};

// CONNECT

const mapDispatchToProps = {
  logOutAction,
};

export default compose(connect(null, mapDispatchToProps))(UnAuthenticateAccess);
