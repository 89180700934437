import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';

class DropdownItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.dropdownRef = createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };
  toggleDropdown = () => {
    if (!this.props.readOnly) this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const {
      handleChange,
      name,
      options,
      value,
      label,
      placeholder,
      className,
      size,
      t,
      isPill,
      arraowShow
    } = this.props;

    let valueSelected = options.find(function (option) {
      return option.value === value;
    });

    return (
      <>
        {arraowShow ?
          <div
            className={`dropdown ${className} ${size} ${this.props.readOnly ? 'readOnly' : ''} ${this.state.isOpen ? 'opened' : 'closed'
              }  `}
            onClick={this.toggleDropdown}
            ref={this.dropdownRef}
          >
            <div className={`${isPill ? 'dropdown_header is-pill' : 'dropdown_header'}  ${arraowShow ? 'dropdown_header-payment-mode' : ''} dropdownaddnew`}>
              <div className="dd">


                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M8 2V5" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16 2V5" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.5 9.08984H20.5" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.6947 13.7002H15.7037" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.6947 16.7002H15.7037" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.9955 13.7002H12.0045" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.9955 16.7002H12.0045" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.29431 13.7002H8.30329" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.29431 16.7002H8.30329" stroke="#0D0D0D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <div className='dropdown_label'>{label}</div>
                {valueSelected ? (t ? t(valueSelected.label) : valueSelected.label) : placeholder}
              </div>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19.92 8.9502L13.4 15.4702C12.63 16.2402 11.37 16.2402 10.6 15.4702L4.08002 8.9502" stroke="#C5C5C5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
            <div className='dropdown_items'>
              {options.map(option => (
                <div
                  key={option.value}
                  className={`dropdown_item ${value === option.value ? 'selected' : ''}`}
                  value={option.value}
                  name={name}
                  onClick={() => handleChange({ target: { value: option.value, name: name } })}
                >
                  {t ? t(option.label) : option.label}
                </div>
              ))}
            </div>
          </div>
          : (
            <div
              className={`dropdown ${className} ${size} ${this.props.readOnly ? 'readOnly' : ''} ${this.state.isOpen ? 'opened' : 'closed'
                }`}
              onClick={this.toggleDropdown}
              ref={this.dropdownRef}
            >
              <div className={`${isPill ? 'dropdown_header is-pill' : 'dropdown_header'}  ${arraowShow ? 'dropdown_header-payment-mode' : ''}`}>
                <div className='dropdown_label'>{label}</div>
                {valueSelected ? (t ? t(valueSelected.label) : valueSelected.label) : placeholder}
                {arraowShow ? <i className={`dropdown_arrow fas ${this.state.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i> : null}
              </div>
              <div className='dropdown_items'>
                {options.map(option => (
                  <div
                    key={option.value}
                    className={`dropdown_item ${value === option.value ? 'selected' : ''}`}
                    value={option.value}
                    name={name}
                    onClick={() => handleChange({ target: { value: option.value, name: name } })}
                  >
                    {t ? t(option.label) : option.label}
                  </div>
                ))}
              </div>
            </div>
          )}

      </>
    );
  }
}

DropdownItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

DropdownItem.defaultProps = {
  value: '',
  label: null,
};

export default DropdownItem;
