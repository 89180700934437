/* eslint react/jsx-indent-props: off */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';

import Registration from './registration';
import logOutAction from '../Header/actions';
import client from 'src/config/apollo_client';
import gql from 'graphql-tag';
import { hideModal, loadModal } from 'src/redux/modal/action';

const LOGOUT_PERIOD = 3600000 / 2;

class AuthenticatedRoute extends React.Component {
  constructor() {
    super();

    window.addEventListener('mousemove', this.resetTimer, false);
    window.addEventListener('mousedown', this.resetTimer, false);
    window.addEventListener('keypress', this.resetTimer, false);
    window.addEventListener('DOMMouseScroll', this.resetTimer, false);
    window.addEventListener('mousewheel', this.resetTimer, false);
    window.addEventListener('touchmove', this.resetTimer, false);
    window.addEventListener('MSPointerMove', this.resetTimer, false);

    this.timer = window.setTimeout(this.isInactive, LOGOUT_PERIOD);
    this.date = new Date();
  }

  componentDidMount() {
    let unloadDate = localStorage.getItem('unloadDate');
    let currentDate = this.date.getTime();

    if (currentDate >= parseInt(unloadDate, 10) + LOGOUT_PERIOD) this.isInactive();

    this.setUnloadListener();
    this.routeChange();
  }

  componentWillUnmount() {
    window.clearTimeout(this.timer);
    window.removeEventListener('mousemove', this.resetTimer, false);
    window.removeEventListener('mousedown', this.resetTimer, false);
    window.removeEventListener('keypress', this.resetTimer, false);
    window.removeEventListener('DOMMouseScroll', this.resetTimer, false);
    window.removeEventListener('mousewheel', this.resetTimer, false);
    window.removeEventListener('touchmove', this.resetTimer, false);
    window.removeEventListener('MSPointerMove', this.resetTimer, false);
    window.removeEventListener('beforeunload', this.setDateAfterUnload, false);
  }
  onPageLoad = () => {
    let token = localStorage.getItem('token');
    if (token && this.props?.path !== "/account") {
      client
        .query({
          query: CHECKPRIMARYCARD,
          fetchPolicy: 'network-only',
          variables: {},
        })
        .then(({ data }) => {
          const { checkPrimaryCardValid } = data;
          if (!checkPrimaryCardValid) {
            const { t, loadModal } = this.props
            loadModal({
              modalType: 'primary_card_expiry_model',
            })
          }
        })
    }

  };
  routeChange() {
    if (document.readyState === 'complete') {
      this.onPageLoad();
    } else {
      window.addEventListener('load', this.onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', this.onPageLoad);
    }
  }

  componentDidUpdate(prevState) {
    if (prevState?.path !== this.props?.path) {
      this.routeChange();
    }
  }
  setUnloadListener = () => {
    window.addEventListener('beforeunload', this.setDateAfterUnload);
  };

  setDateAfterUnload = () => {
    localStorage.setItem('unloadDate', this.date.getTime());
  };

  resetTimer = () => {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(this.isInactive, LOGOUT_PERIOD);
  };

  isInactive = () => {
    const { logOutAction } = this.props;
    localStorage.removeItem('unloadDate');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');

    logOutAction();
    window.location.reload();
  };

  storeNextUrlAfterLogin = () => {
    localStorage.setItem('nextUrl', window.location.pathname);
  };

  render() {
    const { component: Component, authenticate, ...rest } = this.props;

    if (!authenticate) this.storeNextUrlAfterLogin();

    return (
      <Route
        {...rest}
        render={props =>
          authenticate ? <Registration component={Component} {...props} /> : <Redirect to='/' />
        }
      />
    );
  }
}

AuthenticatedRoute.defaultProps = {
  component: () => {
    return 'Component';
  },
  authenticate: false,
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.func,
  authenticate: PropTypes.bool,
};

const mapDispatchToProps = {
  logOutAction,
  closeExampleModal: hideModal,
  loadModal
};
const CHECKPRIMARYCARD = gql`
  query CheckPrimaryCardValid {
    checkPrimaryCardValid
  }
`

export default compose(connect(null, mapDispatchToProps))(AuthenticatedRoute);
