import React, { Fragment } from 'react'
import update from 'immutability-helper'
import gql from 'graphql-tag'

import CookieFormItem from './form_item'
import Button from '../Button'
import { withTranslation } from '../../providers/I18n'
import withApollo from 'react-apollo/withApollo'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { hideModal, loadModal } from 'src/redux/modal/action'
import { connect } from 'react-redux';

class ScannedCookieForm extends React.Component {
  constructor(props) {
    super(props)
    this.deleteCookie = this.deleteCookie.bind(this)
    this.state = {
      maxId: 0,
      invalid: true,
      allSystemCookies: [],
    }
  }

  componentWillMount() {
    const { cookies_scanned, cookies_validate } = this.props


    this.fetchAllSystemCookies().then(res =>
      this.setState({ allSystemCookies: res.data.systemCookies }),
    )
    let cookieValidate = cookies_validate
    let cookieScannedParsed = []
    if (cookies_scanned) {
      if (cookies_scanned.hasOwnProperty('results')) {
        try {
          cookieScannedParsed = JSON.parse(cookies_scanned['results'])
        } catch (e) {
          cookieScannedParsed = cookies_scanned['results']
        }
      } else {
        try {
          cookieScannedParsed = JSON.parse(cookies_scanned)
        } catch (e) {
          cookieScannedParsed = []
        }
      }
    }
    let cookieList

    let notAnswered = cookies_validate === undefined || cookies_validate.length === 0

    if (notAnswered) {
      let cookieIndex = 0

      cookieList = cookieScannedParsed.map((scanned, index) => {
        let cookieId = typeof scanned.id === 'undefined' ? index : scanned.id

        let cookie_formated = {
          id: cookieId,
          domain: scanned.domain,
          name: scanned.name,
          session: scanned.session,
          validate: false,
        }

        if (scanned.session) cookie_formated['expDateFormat'] = 'session'

        cookieIndex = index

        return cookie_formated
      })

      cookieList.push({
        id: cookieIndex + 1,
        domain: 'Admeet',
        name: 'admeet-consent',
        session: false,
        validate: false,
        type: 'third_party',
        expDateFormat: 'month',
        lifespanTime: '6',
        technology: 'other',
        category: 'necessary',
        purpose: 'management',
      })
    } else {
      const {
        location,
      } = this.props;
      const queryParams = new URLSearchParams(location.search);
      const Rescan = queryParams.get('Rescan');

      if (Rescan) {
        cookieList = cookies_validate.map((validate, index) => {
          if (typeof validate.id === 'undefined') {
            validate.id = index
          }
          validate["infoShow"] = false;
          validate["validate"] = true;
          if (cookieScannedParsed) {
            var cookieFind = cookieScannedParsed?.find(item => item.name === validate.name)
            if (!cookieFind) {
              const newIndex = cookieValidate?.findIndex(obj => obj.name === validate.name)
              if (index >= 0) {
                cookieValidate = [...cookieValidate.slice(0, newIndex), ...cookieValidate.slice(newIndex + 1)]
              }
              validate["infoShow"] = true;
              validate["validate"] = false;
            }
          }
          return validate
        })
        if (cookieScannedParsed && cookieScannedParsed.length > 0) {
          var removeCookie = [];
          cookieScannedParsed.forEach(item => {
            var findCookieExist = cookieValidate.find(itemCookie => item.name === itemCookie.name)
            if (!findCookieExist) {
              item["infoShow"] = false;
              removeCookie.push(item);
            }
          })
          cookieList = [...cookieList, ...removeCookie]
        }

      }
      else {
        cookieList = cookies_validate.map((validate, index) => {
          if (typeof validate.id === 'undefined') {
            validate.id = index
          }
          validate["infoShow"] = false;
          validate["validate"] = true;
          return validate
        })
      }
    }
    cookieValidate = cookieValidate.map((validate, index) => {
      validate.id = index + 1
      return validate
    })
    cookieList = cookieList.map((validate, index) => {
      validate.id = index + 1
      return validate
    })
    let max = 0

    cookieList.forEach(item => {
      if (item.id > max) {
        max = item.id
      }
    })
    this.setState({ cookieList, cookieValidate, maxId: max }, () => {
      this.props.onChange(cookieValidate)
    })

    this.validateForm(cookieList);
    if (!cookieValidate || cookieValidate?.length <= 0) {
      this.fetchAllSystemCookies().then(res => {
        if (res && res.data && res.data.systemCookies) {
          this.setState({ allSystemCookies: res.data.systemCookies })
          cookieList.forEach(element => {
            var cookieset = this.state.allSystemCookies && this.state.allSystemCookies.find(cookie => cookie.name === element.name)
            if (cookieset) {
              element['purpose'] = cookieset.purpose;
              element['category'] = cookieset.category;
              element['expDateFormat'] = cookieset.lifespan.toLowerCase();
              element['lifespanTime'] = cookieset.duration;
              element['technology'] = cookieset.technologies;
              element['purpose'] = cookieset.purpose;
              element['type'] = cookieset.cookieType;
            }
          });
          this.validateForm(cookieList);
        }
      }
      )
    }
  }

  removeByKeyValue = (array, key, value) => {
    const index = array.findIndex(obj => obj[key] === value)
    return index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array
  }

  fetchAllSystemCookies = () => {
    const { client } = this.props
    return client.query({
      query: ALL_SYSTEM_COOKIES,
      fetchPolicy: 'network-only',
    })
  }

  deleteCookie = id => {
    const { t } = this.props

    let cookieList = this.state.cookieList
    let cookieValidate = this.state.cookieValidate

    let confirm = window.confirm(t('.remove_cookie_confirm'))
    if (confirm === true) {
      cookieValidate = this.removeByKeyValue(cookieValidate, 'id', id)
      cookieList = this.removeByKeyValue(cookieList, 'id', id)
    }

    this.setState(
      {
        cookieList,
        cookieValidate,
      },
      () => {
        this.props.onChange(cookieValidate)
      },
    )

    this.validateForm(cookieList)
  }

  addCookie = () => {
    let newMaxId = this.state.maxId + 1

    let newCookie = {
      id: newMaxId,
      domain: '',
      name: '',
      session: false,
      validate: false,
    }

    this.setState({
      maxId: newMaxId,
      cookieList: [...this.state.cookieList, newCookie],
    })

    this.validateForm([], true)
  }

  handleChange = async (id, key, value) => {
    setTimeout(() => {
      let cookieListState = this.state.cookieList

      let toUpdateCookie = cookieListState.findIndex(function (c) {
        return c.id === id
      })
      let updatedCookie = key !== 'allvalue' ? update(cookieListState[toUpdateCookie], {
        [key]: {
          $set: value,
        },
      }) : update(cookieListState[toUpdateCookie], {
        name: {
          $set: value.name,
        },
        domain: {
          $set: value.domain,
        },
        type: {
          $set: value.type,
        },
        expDateFormat: {
          $set: value.expDateFormat,
        },
        lifespanTime: {
          $set: value.lifespanTime,
        }, technology: {
          $set: value.technology,
        }, category: {
          $set: value.category,
        },
        purpose: {
          $set: value.purpose,
        }
      })
      let cookieList = update(this.state.cookieList, {
        $splice: [[toUpdateCookie, 1, updatedCookie]],
      })

      let cookieValidate = this.state.cookieValidate

      if (key === 'validate') {
        if (!value) {
          cookieValidate = this.removeByKeyValue(cookieValidate, 'id', id)
        }
        else {
          var findCookieAvilable = cookieValidate.find(item => item.name === updatedCookie.name);
          if (!findCookieAvilable) {
            cookieValidate = update(cookieValidate, { $push: [updatedCookie] })
          }
          else {
            const { loadModal } = this.props;
            loadModal({
              modalType: 'duplicate_cookie_model',
              content: {
              },
              title: '',
            })

            return

          }
        }
        // cookieValidate = value
        //   ? update(cookieValidate, { $push: [updatedCookie] })
        //   : this.removeByKeyValue(cookieValidate, 'id', id)
      }

      this.setState(
        {
          cookieList,
          cookieValidate,
        },
        () => {
          this.props.onChange(cookieValidate)
        },
      )
      this.validateForm(cookieList)
    }, 5)
  }

  validateForm = (cookieList, addCookie = false) => {
    let invalid = false

    // look for unvalidated cookies in the list
    cookieList.forEach(item => {
      if (item.validate === false) invalid = true
    })

    // no cookies in the list
    if (cookieList.length === 0) invalid = true

    // adding new cookie to the list
    if (addCookie) invalid = true

    this.setState({ invalid: invalid })
  }

  render() {
    const { cookieList, allSystemCookies } = this.state
    const { activeTab, locales, question, t, goBack, handleSubmit } = this.props

    return (
      <Fragment>
        <>
          <div className={`cookies__list--content ${activeTab === 'tovalidate' ? '' : 'hidden'}`}>
            <div>
              {cookieList.map(
                cookie =>
                  !cookie.validate && (
                    <>
                      <CookieFormItem
                        key={cookie.id}
                        index={cookie.id}
                        option={cookie}
                        readOnly={true}
                        handleChange={this.handleChange}
                        locales={locales}
                        question={question}
                        removeCookie={this.deleteCookie}
                        allSystemCookies={allSystemCookies.sort((a, b) => a.name.localeCompare(b.name))}
                        activeTab={activeTab}
                      />
                    </>
                  ),
              )}
            </div>
            <div>
              <Button className='btn btn_pill is-blue' published onClick={this.addCookie}>
                <i className='fas fa-plus margin-right-10' />
                {t('.add_cookie')}
              </Button>
            </div>
          </div>

          <div className={`cookies__list--content ${activeTab === 'validated' ? '' : 'hidden'}`}>
            {allSystemCookies && allSystemCookies.length > 0 && cookieList.map(
              cookie =>
                cookie.validate && (
                  <>
                    <CookieFormItem
                      key={cookie.id}
                      index={cookie.id}
                      option={cookie}
                      readOnly={true}
                      handleChange={this.handleChange}
                      locales={locales}
                      question={question}
                      removeCookie={this.deleteCookie}
                      allSystemCookies={allSystemCookies.sort((a, b) => a.name.localeCompare(b.name))}
                      activeTab={activeTab}
                    />
                  </>
                ),
            )}
          </div>

          <div className='cookie-form__break'>&nbsp;</div>

          <div className='text-right cookie-form__list_buttons'>
            <Button
              className='btn btn_pill is-borderless has-text-blue margin-right-20'
              onClick={goBack}
            >
              {t('.previous')}
            </Button>
            <Button
              className='btn btn_pill is-blue'
              published
              disabled={this.state.invalid}
              onClick={handleSubmit}
            >
              {t('.validate')}
            </Button>
          </div>
        </>

      </Fragment>
    )
  }
}

const ALL_SYSTEM_COOKIES = gql`
  query allSystemCookies {
    systemCookies {
      id
      name
      cookieType
      owner
      lifespan
      duration
      technologies
      category
      purpose
      others
    }
  }
`
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(connect(null, mapDispatchToProps), withRouter, withTranslation('cookie_form'), withApollo)(ScannedCookieForm)