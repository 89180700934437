import React from 'react';
import { compose } from 'recompose';
import Link from 'react-router-dom/es/Link';
import { reverse, clone, slice, isEmpty } from 'lodash';

import { withTranslation } from '../../providers/I18n';
import Button from '../../components/Button';
import VersionCard from '../../components/ProductVersionCard';

const DashboardVersions = ({ privacyPolicyVersions, ppId, policyType, t }) => {
  let versionList = clone(privacyPolicyVersions);
  versionList = reverse(versionList);

  return (
    <div className='dashboard_element el_bottom'>
      <h3> {t('.versions')} </h3>
      {!isEmpty(privacyPolicyVersions) ? (
        <div className='dashboard_versions'>
          <div className='published_version'>
            <h5> {t('.online')} :</h5>
            <div className='version_card-list'>
              <VersionCard
                version={versionList[0].version}
                publishedDate={versionList[0].publishedAt}
                id={ppId}
                policyType={policyType}
                t={t}
              />
            </div>
          </div>
          {privacyPolicyVersions.length > 1 && (
            <div className='archived_version'>
              <h5> {t('.archived_ones')} :</h5>
              <div className='version_card-list'>
                {versionList &&
                  slice(versionList, 1, 4).map(version => (
                    <VersionCard
                      version={version.version}
                      publishedDate={version.publishedAt}
                      archivedDate={version.archivedAt}
                      policyType={policyType}
                      id={ppId}
                      key={version.id}
                      t={t}
                    />
                  ))}
              </div>
            </div>
          )}
          {privacyPolicyVersions.length > 4 && (
            <div className='see-more'>
              <Link to={`/${policyType}-policies/${ppId}/archive`}>
                <Button className='btn btn_pill is-blue is-outlined margin-left-20' small>
                  <i className='fas fa-plus margin-right-10' />
                  {t('.see_all')}
                </Button>
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>
            {' '}
            {policyType === 'privacy'
              ? t('.pp_empty_version_quote')
              : t('.cp_empty_version_quote')}{' '}
          </p>
        </div>
      )}
    </div>
  );
};
export default compose(withTranslation('dashboard'))(DashboardVersions);
