import exampleModal from '../../containers/Questions/Modal/example'
import allUsersModal from '../../containers/ProductDashboard/Modal/all_users_modal.jsx'
import dpoModal from '../../containers/ProductQuestions/Modal/dpo'
import cookiesModal from '../../containers/Questions/Modal/cookies'
import scanModal from '../../containers/Questions/Modal/scan'
import publishModal from '../../containers/PrivacyPolicies/Modal/publish'
import unpublishModal from '../../containers/Dashboard/Modal/unpublish'
import deleteModal from '../../containers/PrivacyPolicies/Modal/delete'
import resetPasswordModal from '../../containers/UnAuthenticatedForm/Modal/reset'
import tokenOutdated from '../../containers/UnAuthenticatedForm/Modal/token_outdated'
import cookieExplanation from '../../containers/CookiePolicies/BannerCustomisation/explanation_modal'
import newVersionModel from '../../containers/ProductDashboard/Modal/new_version_model'
import LicensesDetailsCancelModel from '../../containers/ProductDashboard/Modal/licence_details_cancel_model'
import SubscriptionDetailsModel from '../../containers/ProductDashboard/Modal/subscription_details_model'
import AccessLimitUserModel from '../../containers/ProductDashboard/Modal/access_limit_user_model';
import AllowExportModel from 'src/containers/ProductDashboard/Modal/allow_export_model'
import DownGradePopupModel from 'src/containers/ProductDashboard/Modal/down_grade_popup_model'
import VatNumberFoundModel from 'src/containers/ProductDashboard/Modal/vat_not_found_model'
import confirm_payment_model from 'src/containers/ProductDashboard/Modal/confirm_payment_model'
import PrimarycardExpiry from 'src/containers/ProductDashboard/Modal/primary_card_expiry_model.jsx'
import DuplicateCookieModel from 'src/containers/ProductDashboard/Modal/duplicate_cookie_model.jsx'

const ModalConstant = {
  example: exampleModal,
  dpo: dpoModal,
  cookies: cookiesModal,
  publish: publishModal,
  unpublish: unpublishModal,
  delete: deleteModal,
  reset: resetPasswordModal,
  token_outdated: tokenOutdated,
  cookie_explanation: cookieExplanation,
  cookie_scan: scanModal,
  all_users_modal: allUsersModal,
  new_version_model: newVersionModel,
  access_limit_user_model: AccessLimitUserModel,
  primary_card_expiry_model: PrimarycardExpiry,
  confirm_payment_model: confirm_payment_model,
  allow_export_model: AllowExportModel,
  licenses_details_cancel_model: LicensesDetailsCancelModel,
  subscription_details_model: SubscriptionDetailsModel,
  down_grade_popup_model: DownGradePopupModel,
  vat_not_found_model: VatNumberFoundModel,
  duplicate_cookie_model: DuplicateCookieModel

}

export default ModalConstant
