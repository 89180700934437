navigator.sayswho = (function() {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

function IsBrowserLatestVersion() {
  let browserVersion = navigator.sayswho;
  let browserArray = browserVersion.split(' ');
  let browserVersionName = browserArray[0];
  let browserVersionNumber = parseInt(browserArray[1], 10);

  switch (browserVersionName) {
    case 'Chrome':
      return browserVersionNumber < 76 ? false : true;
    case 'Safari':
      return browserVersionNumber < 12 ? false : true;
    case 'Firefox':
      return browserVersionNumber < 68 ? false : true;
    case 'Edge':
      return browserVersionNumber < 18 ? false : true;
  }
}

export { IsBrowserLatestVersion };
