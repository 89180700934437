import React from 'react';
import { Pagination } from 'semantic-ui-react';

const PaginationControl = ({ paginationObj, setPage }) => {
  return (
    <>
      {paginationObj && paginationObj.totalPages > 1 && (
        <div className='pagination_wrapper'>
          <Pagination
            onPageChange={setPage}
            activePage={paginationObj?.currentPage}
            totalPages={paginationObj?.totalPages}
          />
        </div>
      )}
    </>
  );
};

export default PaginationControl;
