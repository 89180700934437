import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import gql from 'graphql-tag';

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import Button from 'src/components/Button'
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import NotificationModel from 'src/providers/notifications/models/notification.model';
import { Link } from 'react-router-dom';
import { loadModal } from 'src/redux/modal/action';
import Loading from 'src/components/Loading'

const VatNumberFoundModel = ({
    title,
    closeExampleModal,
    content: { },
    t,
    client,
    notificationsProvider,
    history,
    loadModal
}) => {
    return (
        <Modal>
            <div className='modal_bar'>
                <h4 className='modal_title'>{title}</h4>
                <button type='button' className='close' onClick={closeExampleModal} />
            </div>
            <div className='modal_content'>
                <div>{t('.vat_invalid_content_1')}</div>
                <div>{t('.vat_invalid_content_2')}</div>
                <div>{t('.vat_invalid_content_3')}</div>
                <div className='list_buttons'>
                    <Button
                        className='btn btn_pill is-blue'
                        published
                        onClick={closeExampleModal}
                    >
                        {t('.i_understand')}
                    </Button>
                </div>
            </div>

        </Modal>
    )
}

VatNumberFoundModel.propTypes = {
    title: PropTypes.string.isRequired,
    closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    closeExampleModal: hideModal,
    loadModal
}
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('dashboard'),
)(VatNumberFoundModel)
