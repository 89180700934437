import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalConstant from './constant';

const ModalContainer = props => {
  const { modalType, content, title } = props;
  if (!modalType) {
    return null;
  }
  const ModalToRender = ModalConstant[modalType];
  return <ModalToRender content={content} title={title} />;
};

ModalContainer.propTypes = {
  modalType: PropTypes.string,
  title: PropTypes.string,
};

ModalContainer.defaultProps = {
  modalType: null,
  content: null,
  title: null,
};

const mapStateToProps = ({ modal }) => {
  return { modalType: modal.modalType, content: modal.content, title: modal.title };
};

export default connect(mapStateToProps, null)(ModalContainer);
