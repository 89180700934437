import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import React from 'react'
import withApollo from 'react-apollo/withApollo'
import gql from 'graphql-tag'
import { compose } from 'recompose'
import { isEmpty } from 'lodash'
import connect from 'react-redux/es/connect/connect'
import withTranslation from '../../providers/I18n/HoC/t'
import AuthWrapper from '../../components/Auth'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import ProgressBar from './progressbar'
import Categories from './categories'
import { loadModal } from '../../redux/modal/action'
import DashboardProfile from './profile'
import DashboardVersions from './versions'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

class PrivacyPolicyDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.deletePrivacyPolicy = this.deletePrivacyPolicy.bind(this)
    this.state = {
      previewIsLoading: false,
      policyType: this.isCookiePolicy(props.match.url) ? 'cookie' : 'privacy',
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      policyType: this.isCookiePolicy(nextProps.match.url) ? 'cookie' : 'privacy',
    })
  }

  componentWillUnmount() {
    localStorage.setItem('isNewVersion', null)
  }

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/)

    arr = arr.filter(item => {
      return item.match(/[\/]/g)
    })

    return arr[0] === '/cookie-policies'
  }

  unpublishMutation = async id => {
    const { client } = this.props
    return await client.mutate({
      mutation: UNPUBLISH_PRIVACY_POLICY_MUTATION,
      variables: {
        id,
      },
    })
  }

  fetchPolicyStatus = async id => {
    const { client } = this.props
    return await client.query({
      query: PRIVACY_POLICY_STATUS_QUERY,
      variables: {
        id,
      },
    })
  }

  isCategoriesCompleted(categories) {
    let categoriesCompleted = true
    for (let cat of categories) {
      let state = cat.status.key
      if (state === 'in_progress') {
        categoriesCompleted = false
        return categoriesCompleted;
      }
    }

    return categoriesCompleted
  }

  createNewPolicyVersion = async privacyPolicy => {
    const {
      loadModal,
      t,
      match: {
        params: { id },
      },
    } = this.props

    let otherId = null

    const hasCookiePolicy = privacyPolicy.cookiePolicy
    const hasPrivacyPolicy = privacyPolicy.parentId

    if (hasCookiePolicy) {
      const { status, id } = privacyPolicy.cookiePolicy
      otherId = status === 'published' && id
    } else if (hasPrivacyPolicy) {
      try {
        await this.fetchPolicyStatus(privacyPolicy.parentId).then(({ data }) => {
          const {
            privacyPolicy: { status, id },
          } = data

          otherId = status === 'published' && id
        })
      } catch (e) {
        //error handler
      }
    }

    loadModal({
      modalType: 'unpublish',
      content: {
        unpublishMutation: this.unpublishMutation,
        reload: this.forceReload,
        currentId: id,
        otherPolicyId: otherId,
      },
      title: t('.create_new_version_title'),
    })
  }

  deletePrivacyPolicy() {
    const { loadModal, t } = this.props
    loadModal({
      modalType: 'delete',
      content: '',
      title: t('.delete_policy_title'),
      t: t,
    })
  }

  handleClick = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props
    const { policyType } = this.state

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`)
    } else {
      localStorage.setItem('ppStatus', 'blocked')
      history.push(`/${policyType}-policies/${id}/preview`)
    }
  }

  render() {
    const { match, history, t } = this.props
    const {
      params: { id },
    } = match
    const { previewIsLoading, policyType } = this.state

    return (
      <AuthWrapper>
        <div className='row'>
          <div className='columns'>
            <Query
              query={PRIVACY_POLICY_QUERY}
              fetchPolicy={'network-only'}
              variables={{ id, type: policyType }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) return <Loading />
                if (error) return `Error! ${error.message}`
                if (isEmpty(data)) return <Loading />
                const { privacyPolicy, categories, privacyPolicyVersions } = data
                const { status, title, publishedAt, cookies } = privacyPolicy
                const isNewVersion = localStorage.getItem('isNewVersion')
                localStorage.setItem('ppName', title)

                status === 'blocked' && history.push(`/${policyType}-policies/${id}/preview`)

                return (
                  <div className='dashboard'>
                    {isNewVersion === 'true' && (
                      <div className='onboarding_message'>
                        {policyType === 'privacy' ? (
                          <p> {t('.pp_question_based')} </p>
                        ) : (
                          <p> {t('.cp_question_based')} </p>
                        )}
                        <p> {t('.making_changes')} </p>
                      </div>
                    )}

                    <TitleComponent title={privacyPolicy.title} />

                    <DashboardProfile
                      status
                      title
                      countries
                      locales
                      createdAt
                      updatedAt
                      publishedAt
                      customization
                      policyType={policyType}
                      categories={categories}
                      deletePrivacyPolicy={this.deletePrivacyPolicy}
                      {...privacyPolicy}
                    />
                    <div className='dashboard_element el_right'>
                      <div className='row collapse align-bottom align-justify'>
                        <h3>
                          {' '}
                          {policyType === 'privacy'
                            ? t('.my_privacy_policy')
                            : t('.my_cookies_policy')}{' '}
                        </h3>217

                        <div>
                          {status === 'published' && (
                            <Button
                              className='btn btn_pill is-borderless'
                              onClick={() => this.createNewPolicyVersion(privacyPolicy)}
                            >
                              <i className='fas fa-file-export margin-right-10' />
                              {t('.new_version')}
                            </Button>
                          )}

                          <Button
                            disabled={
                              !this.isCategoriesCompleted(categories) && status !== 'published'
                            }
                            onClick={() => this.handleClick(status, privacyPolicyVersions.length)}
                            className='btn btn_pill is-outlined is-blue margin-left-20'
                          >
                            <i className='far fa-file-alt margin-right-10' />
                            {t('.preview')}
                          </Button>
                        </div>
                      </div>
                      <ProgressBar
                        status={status}
                        categories={categories}
                        previewIsLoading={previewIsLoading}
                        t={t}
                      />
                      <Categories
                        categories={categories}
                        ppID={id}
                        ppStatus={status}
                        publishedAt={publishedAt}
                        policyType={policyType}
                        t={t}
                      />
                    </div>

                    <DashboardVersions
                      privacyPolicyVersions={privacyPolicyVersions}
                      ppId={id}
                      policyType={policyType}
                    />
                  </div>
                )
              }}
            </Query>
          </div>
        </div>
      </AuthWrapper>
    )
  }
}

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!, $type: String) {
    privacyPolicy(id: $id) {
      id
      uid
      title
      version
      createdAt
      updatedAt
      publishedAt
      status
      parentId
      cookies
      cookiePolicy {
        id
        title
        status
      }
      locales {
        isoCode
      }
      countries {
        isoCode
      }
      customization {
        colors
        logo {
          type
          path
        }
      }
      data {
        version
        pdf
        html
        uid
      }
    }
    categories(type: $type) {
      avatar
      id
      name
      position
      status(policyId: $id, policyType: $type)
      questionGroups {
        id
        questions(policyId: $id, policyType: $type) {
          id
          type
          referenceNumber
        }
      }
    }
    privacyPolicyVersions(policyId: $id) {
      archivedAt
      html
      id
      pdf
      publishedAt
      status
      uid
      version
    }
  }
`

const PRIVACY_POLICY_STATUS_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      status
      id
    }
  }
`

const UNPUBLISH_PRIVACY_POLICY_MUTATION = gql`
  mutation unpublishPrivacyPolicy($id: ID!) {
    unpublishPrivacyPolicy(id: $id) {
      id
    }
  }
`

const mapDispatchToProps = {
  loadModal,
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withApollo,
  withTranslation('dashboard'),
)(PrivacyPolicyDashboard)