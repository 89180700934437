import React from 'react';

import position_bottom from '../../../../assets/img/cookie_placement-bottom.png';
import position_top from '../../../../assets/img/cookie_placement-top.png';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { hideModal, loadModal } from 'src/redux/modal/action';

const BannerPosition = ({ vue, handleChange, t, bannerPersonalization, loadModal, selectedLicense }) => {
  return (
    <div className='customisation__position'>
      <h5> {t('.position')}</h5>
      <div className='cst__position__content'>
        <div
          className={`cst__position__item ${vue === 'bottom' ? 'selected' : ''}`}
          onClick={() => handleChange('bottom', 'vue')}
        >
          <img src={position_bottom} alt='' />
        </div>
        <div
          className={`cst__position__item ${vue === 'top' ? 'selected' : ''}`}
          onClick={() => {
            if (bannerPersonalization) {
              handleChange('top', 'vue')
            }
            else {
              loadModal({
                modalType: 'access_limit_user_model',
                content: {
                  selectedLicense: selectedLicense
                },
                title: '',
              })
            }
          }}
        >
          <img src={position_top} alt='' />
        </div>
      </div>
    </div>
  );
};

// export default BannerPosition;

const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(
  connect(null, mapDispatchToProps),
)(BannerPosition)
