import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FlagIcon } from 'react-flag-kit';
import { compose } from 'recompose';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import { formattedDate } from '../../helpers/date';
import { withTranslation } from '../../providers/I18n';

const DashboardProfile = ({
  status,
  title,
  customization,
  countries,
  locales,
  policyType,
  categories,
  createdAt,
  updatedAt,
  publishedAt,
  t,
  client,
  match: {
    params: { id },
  },
  deletePrivacyPolicy,
  ...privacyPolicy
}) => {
  const [linkedPolicy, setLinkedPolicy] = useState(null);

  useEffect(() => {
    if (privacyPolicy.cookiePolicy) {
      setLinkedPolicy(privacyPolicy.cookiePolicy);
    } else if (privacyPolicy.parentId) {
      client
        .query({
          query: PRIVACY_POLICY_BY_ID_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            id: privacyPolicy.parentId,
          },
        })
        .then(({ data }) => {
          setLinkedPolicy(data.privacyPolicy);
        });
    }
  }, []);

  return (
    <div className='dashboard_element el_left'>
      <div className='row collapse align-bottom align-justify margin-bottom-40'>
        <div>
          <h6> {t('.name')} </h6>
          <h3 className='policy_title'>{title}</h3>
        </div>
        {status !== 'published' && (
          <div className='dashboard_profil__actions'>
            <Link to={`/${policyType}-policies/${id}/edit`}>
              <i className='fas fa-pen btn_icon font_20' style={{ color: '#001230' }} />
            </Link>
          </div>
        )}
      </div>
      <div className='dashboard_info'>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-tint margin-right-10'></i>
            <p> {t('.colors')}: </p>
          </div>
          <div
            className='dashboard_color'
            style={{
              backgroundColor: `${customization.colors ? customization.colors.primary : '#00548a'}`,
            }}
          />
        </div>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-flag margin-right-10'></i>
            <p> {t('.countries')}: </p>
          </div>
          <div>
            {countries.map(country => (
              <FlagIcon
                key={country.isoCode}
                code={country.isoCode.toUpperCase()}
                size={25}
                className='flag'
              />
            ))}
          </div>
        </div>
        <div className='row collapse align-middle align-justify margin-top-10'>
          <div className='dashboard_info-title'>
            <i className='fas fa-comment margin-right-10'></i>
            <p>{t('.languages')}: </p>
          </div>
          <div>
            {locales.map(locale => (
              <strong key={locale.isoCode}> {t(`.${locale.isoCode}`)} </strong>
            ))}
          </div>
        </div>
        {linkedPolicy && (
          <div className='row collapse align-middle align-justify margin-top-10'>
            <div className='dashboard_info-title'>
              <i className='fas fa-clone margin-right-10'></i>
              <p>{policyType === 'cookie' ? t('.associated_pp') : t('.associated_cp')}: </p>
            </div>
            <div>
              <Link
                to={`/${policyType === 'cookie' ? 'privacy' : 'cookie'}-policies/${
                  linkedPolicy.id
                }`}
                style={{ textDecoration: 'underline' }}
              >
                {' '}
                {linkedPolicy.title}{' '}
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className='dashboard_section'>
        <div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='fas fa-file-excel'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.version')}: </p>
              <strong> {privacyPolicy.version} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='far fa-file'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.creation')}: </p>
              <strong>{formattedDate(createdAt)} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='far fa-file-alt'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p> {t('.modification')}: </p>
              <strong>{formattedDate(updatedAt)} </strong>
            </div>
          </div>
          <div className='row collapse align-middle margin-top-40'>
            <div className='circle_icon'>
              <i className='fas fa-book'></i>
            </div>
            <div className='column dashboard_dates-title'>
              <p>{t('.publication')}: </p>
              <strong>{publishedAt ? formattedDate(publishedAt) : '-'}</strong>
            </div>
          </div>
        </div>
      </div>
      {privacyPolicy.data && (
        <div className='dashboard_section'>
          <div className='row collapse align-middle margin-top-20'>
            <Link
              to={`/${policyType}-policies/${privacyPolicy.id}/integration_process`}
              style={{ textDecoration: 'underline' }}
            >
              {policyType === 'cookie'
                ? t('.integration_cp_process')
                : t('.integration_pp_process')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const PRIVACY_POLICY_BY_ID_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      title
      id
    }
  }
`;

export default compose(withRouter, withApollo, withTranslation('dashboard'))(DashboardProfile);
