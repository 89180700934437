/* eslint no-console: off */
/* eslint no-shadow: off */
import React from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withTranslation } from '../../providers/I18n';

const Footer = ({ t }) => {
  var lan = localStorage.getItem('locale') || 'fr';
  return (
    <footer className='main-footer-wrapper'>
      <div className='columns'>
        <div className='row main-footer'>
          <div className='footer__policies'>
            <ul>
              <li>
                {' '}
                <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/mentions-legales/' : 'https://www.admeet.eu/en/legal-notice/'}>
                  {t('.legal_mention_link')}
                </a>{' '}
              </li>
              <li>
                {' '}
                <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/conditions-generales-admeet/' : 'https://www.admeet.eu/en/terms-and-conditions/'}>
                  {t('.terms_conditions_link')}
                </a>{' '}
              </li>
            </ul>
            <ul>
              <li>
                {' '}
                <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/politique-de-vie-privee-admeet/' : 'https://www.admeet.eu/en/admeet-privacy-policy/'}>
                  {t('.privacy_policy_link')}
                </a>{' '}
              </li>
              <li>
                {' '}
                <a target='_blank' href={lan === 'fr' ? "https://www.admeet.eu/politique-de-cookies-admeet/" : "https://www.admeet.eu/en/admeet-cookie-policy/"}>
                  {t('.cookie_policy_link')}
                </a>{' '}
              </li>
            </ul>
          </div>
          <div className='footer__rights'>
            <p>© {`${new Date().getFullYear()} ${t('.copyrights_text')}`}</p>
            <p>{t('.all_rights_reserved_text')}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default compose(withTranslation('footer'))(Footer);
