import React, { Fragment } from 'react';

import RadioGroup from '../../../../components/Form/RadioGroupColor';
import { dark_colors, light_colors } from '../../../../helpers/colors';
import Button from '../../../../components/Button';
import { hideModal, loadModal } from 'src/redux/modal/action';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { SketchPicker } from 'react-color';

const BannerColors = ({
  handleChange,
  handleThemeChange,
  selectedColor,
  selectedTheme,
  openPreview,
  t,
  bannerPersonalization,
  loadModal,
  selectedLicense
}) => {
  const onThemeChange = value => {
    let theme = value[0];
    let color = theme === 'dark' ? '#fbc531' : '#00548a';
    handleThemeChange(theme, color);
  };

  const RADIO_OPTIONS = [
    {
      id: 0,
      name: 'theme',
      value: 'light',
      label: t('.light'),
    },
    {
      id: 1,
      name: 'theme',
      value: 'dark',
      label: t('.dark'),
    },
  ];

  let colors = selectedTheme === 'light' ? dark_colors : light_colors;

  return (
    <Fragment>
      <div className='customisation__theme left_radio'>
        <h5> {t('.theme')}</h5>
        <RadioGroup options={RADIO_OPTIONS} onChange={onThemeChange} value={[selectedTheme]} bannerPersonalization={bannerPersonalization} selectedLicense={selectedLicense} />

      </div>
      <div className='customisation__colors' style={{ height: 150 }}>
        <div className='cst_default_colors' >
          <h5> {t('.colors')}</h5>
          <span>
            <Button
              rounded
              invert
              onClick={() => handleChange(selectedTheme === 'dark' ? '#fbc531' : '#00548a', 'color')}
            >
              <i className='fas fa-eraser margin-right-10' />
              {t('.default')}
            </Button>
          </span>

        </div>
        <div className='cst__colors'>
          <SketchPicker
            width='300px'
            color={selectedColor}
            onChangeComplete={(change) => {
              if (bannerPersonalization) {
                handleChange(change.hex, 'color')
              }
              else {
                loadModal({
                  modalType: 'access_limit_user_model',
                  content: {
                    selectedLicense: selectedLicense
                  },
                  title: '',
                })
              }

            }}
          />
          {/* {colors.map(color => (
            <div
              key={color}
              className={`cst__colors__item ${color === selectedColor ? 'selected' : ''}`}
              onClick={() => {
                if (bannerPersonalization) {
                  handleChange(color, 'color')
                }
                else {
                  loadModal({
                    modalType: 'access_limit_user_model',
                    content: {
                      selectedLicense: selectedLicense
                    },
                    title: '',
                  })
                }
              }}
            >
              <div style={{ backgroundColor: color }} />
            </div>
          ))} */}
        </div>
      </div>
    </Fragment>
  );
};

// export default BannerColors;
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(
  connect(null, mapDispatchToProps),
)(BannerColors)
