import { vatValidation } from "src/containers/NewOnboarding/CompanyOnboarding/form_validation";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = '.required';
  }
  if (!values.firstname) {
    errors.firstname = '.required';
  }
  if (!values.organization_name) {
    errors.organization_name = '.required';
  }
  if (!values.street) {
    errors.street = '.required';
  }
  if (!values.country) {
    errors.country = '.required';
  }
  if (!values.city) {
    errors.city = '.required';
  }
  if (!values.zip_code) {
    errors.zip_code = '.required';
  }

  if (values.vat && values.vat.length <= 0) {
    errors.vat = '.required';
  } else if (values.vat && values.vat.length > 0 && !vatValidation(values.vat)) {
    errors.vat = '.vat_number_validation_error_country'
  }

  return errors;
};

export default validate;
