import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import QuestionAnswers from './questionAnswer';

const referenceNumberToNumber = rfn => {
  let nbr = rfn.replace(/\D/g, '');
  nbr = nbr.length === 1 ? '0' + nbr : nbr;
  return nbr;
};

const QuestionItem = ({
  match: {
    params: { categoryId, id },
  },
  ppStatus,
  policyType,
  locales,
  pp,
  t,
  ...question
}) => {
  let answered = question.answer && !question.answer.autocompleted;

  if (question.referenceNumber === 'R.1')
    answered = question.answer && question.answer.value.length >= 1;

  return (
    <Fragment>
      {ppStatus !== 'published' && !question.readOnly ? (
        <Link
          to={`/${policyType}-policies/${id}/categories/${categoryId}/questions/${question.id}`}
          className='modif_button'
        >
          <div
            className={`question_item ${answered ? 'answered' : ''}`}
            key={question.id}
            data-answered={answered ? 'true' : 'false'}
          >
            {answered ? (
              <div className='question_item-link'>
                <i className='fas fa-check' />
              </div>
            ) : (
              <div className='question_item-link'>
                {question.readOnly ? <i className='fas fa-eye' /> : <i className='far fa-edit' />}
              </div>
            )}

            <div className='question_item-text'>
              <p className='question_item-title'>{question.statement}</p>
              {question.answer && <QuestionAnswers question={question} locales={locales} t={t} />}
            </div>
          </div>
        </Link>
      ) : (
        <div>
          <div className='question_item published' key={question.id}>
            <div className='question_item-text'>
              <p className='question_item-title'>{question.statement}</p>
              {question.answer && <QuestionAnswers question={question} locales={locales} t={t} />}
            </div>
            <div className='question_item-reference'>
              <p>{referenceNumberToNumber(question.referenceNumber)}</p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

QuestionItem.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(QuestionItem);
