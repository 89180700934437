import * as React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import { renderCheckboxField } from '../../components/Form/Checkbox';
import UnAuthWrapper from '../../components/UnAuth';
import { submitAccountVerification } from './submit_validation';
import validate from './validate';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class AccountVerification extends React.Component {
  render() {
    const { submitting, handleSubmit, error, t } = this.props;

    return (
      <UnAuthWrapper title={t('.account_verification')}>
        <TitleComponent title={t('.account_verification')} />
        <form className='text-form auth_form' onSubmit={handleSubmit(submitAccountVerification)}>
          <Field
            name='email'
            type='text'
            label={t('.email')}
            placeholder='test@mail.com'
            component={renderInputField}
            t={t}
          />
          {error && <p className='validation_error'>{t(error)}</p>}
          <button type='submit' disabled={submitting} className='btn_pill is-blue'>
            {submitting ? t('.waiting') : t('.account_verification_btn')}
          </button>
        </form>
      </UnAuthWrapper>
    );
  }
}

export default compose(
  reduxForm({ form: 'account_verification_form', validate }),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(AccountVerification);
