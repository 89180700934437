import React from 'react';
import PropTypes from 'prop-types';

function onDialogClick(event) {
  event.stopPropagation();
}

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.onOverlayClick = this.onOverlayClick.bind(this);
  }

  componentDidMount() {
    const { onClose } = this.props;
    if (onClose) {
      window.addEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  componentWillUnmount() {
    const { onClose } = this.props;
    if (onClose) {
      window.removeEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  onOverlayClick() {
    const { onClose } = this.props;
    onClose && onClose();
  }

  listenKeyboard(event) {
    const { onClose } = this.props;
    if (event.key === 'Escape' || event.keyCode === 27) {
      onClose();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <div className='modal-overlay-div' />
        <div
          className='modal-content-div'
          role='presentation'
          onClick={this.onOverlayClick.bind(this)}
        >
          <div className='modal-dialog-div' role='presentation' onClick={onDialogClick}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  onClose: null,
};

export default Modal;
