import React from 'react';
import Helmet from 'react-helmet';

import favicon from '../../assets/favicon.ico';

const TitleComponent = ({ title }) => {
  let defaultTitle = 'Admeet';
  return (
    <Helmet>
      <title>{title ? 'Admeet | ' + title : defaultTitle}</title>
      <link rel='icon' type='image/png' href={favicon} sizes='32x32' />
    </Helmet>
  );
};

export { TitleComponent };
