import { SubmissionError } from 'redux-form'

import client from '../../config/apollo_client'
import history from '../../helpers/history'
import authenticateUserAction from '../../components/Login/actions'
import { Queries, Mutation } from './submit_queries'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function submitPassword(values) {
  let urlParams = new URLSearchParams(window.location.search)

  let token = urlParams.get('token')

  return sleep(1000).then(() => {
    client
      .mutate({
        mutation: Mutation.password_change,
        variables: {
          token: token,
          password: values.password,
          new: false,
        },
      })
      .then(({ data }) => {
        history.push('/')
        window.location.reload()
      })
      .catch(error => {
        throw error
      })
  })
}

function submitNewPassword(values) {
  return sleep(1000).then(() => {
    if (!values.conditions) {
      throw new SubmissionError({
        conditions: '.required',
        _error: '.select_all_error',
      })
    } else {
      let urlParams = new URLSearchParams(window.location.search)
      let token = urlParams.get('token')

      client
        .mutate({
          mutation: Mutation.password_change,
          variables: {
            token: token,
            password: values.password,
            new: true,
          },
        })
        .then(({ data }) => {
          history.push('/')
          window.location.reload()
        })
        .catch(error => {
          throw error
        })
    }
  })
}

function resetPassword(values) {
  return client.mutate({
    mutation: Mutation.password_reset,
    variables: {
      email: values.email,
    },
  })
}

function submitAccountVerification(values) {
  return client
    .query({
      query: Queries.user_exist,
      variables: {
        email: values.email,
      },
    })
    .then(({ data }) => {
      if (!data.userExist) {
        throw new SubmissionError({
          email: '.no_match',
          _error: '.no_match_email',
        })
      } else {
        const { subDomainName } = data.userExist.organization
        window.location = process.env.REACT_APP_DOMAIN_URL.replace('##PREFIX##', subDomainName)
      }
    })
    .catch(error => {
      throw error
    })
}

const submitSignIn = (values, dispatch, props) => {
  if (values.remember) {
    rememberUser(values.email)
  } else {
    forgetUser()
  }
  const { t } = props
  return client
    .mutate({
      mutation: Mutation.user_login,
      variables: {
        email: values.email,
        password: values.password,
      },
    })
    .then(async ({ data }) => {
      if (data.loginUser) {
        const {
          token,
          user: { id, isSuperAdmin },
        } = data.loginUser

        const date = new Date()
        await localStorage.setItem('token', token)
        await localStorage.setItem('userId', id)
        await localStorage.setItem('isSuperAdmin', isSuperAdmin)
        await authenticateUserAction({ token, userId: id, isSuperAdmin, dispatch })
        // await window.location.reload()
      } else {
        throw new SubmissionError({
          _error: '.no_match_email_password',
        })
      }
    })
    .catch(error => {
      const thrownError = error && error.errors
      throw new SubmissionError(
        thrownError || {
          _error: '.no_match_email_password',
        },
      )
    })
}

const submitRegisterUser = (registerInfo, dispatch, props) => {
  const { token, t } = props
  return client
    .mutate({
      mutation: Mutation.sign_up_mutation,
      variables: { ...registerInfo, token },
    })
    .then(({ data }) => {
      const {
        token,
        user: { id },
      } = data.registerUser

      let isSuperAdmin = false
      localStorage.setItem('token', token)
      localStorage.setItem('userId', id)
      localStorage.setItem('isSuperAdmin', isSuperAdmin)
      authenticateUserAction({ token, id, isSuperAdmin, dispatch })

      window.location.reload()
    })
    .catch(error => {
      const thrownError = error && error.errors

      throw new SubmissionError(
        thrownError || {
          _error: '.no_match_email_password',
        },
      )
    })
}

const rememberUser = async email => {
  try {
    await window.localStorage.setItem('userEmail', email)
  } catch (error) {
    // Error handler
  }
}

const forgetUser = async () => {
  try {
    await window.localStorage.removeItem('userEmail')
  } catch (error) {
    // Error handler
  }
}

export {
  submitNewPassword,
  submitPassword,
  submitAccountVerification,
  submitSignIn,
  submitRegisterUser,
  resetPassword,
}
