import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import withBreadcrumb from '../../providers/breadcrumbs/hoc/breadcrumbs';

const AuthWrapperNoHeader = ({ children }) => {
  return (
    <Fragment>
      <main>
        <div className='main_content'>{children}</div>
      </main>
    </Fragment>
  );
};

AuthWrapperNoHeader.defaultProps = {};

AuthWrapperNoHeader.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { authenticated } = auth;
  return { authenticated };
};

export default compose(withBreadcrumb, connect(mapStateToProps))(AuthWrapperNoHeader);
