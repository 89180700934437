import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'

import AuthWrapper from '../../components/Auth'
import EditForm from './form'
import GraphqlCalls from './GraphQl'
import { withTranslation } from '../../providers/I18n'
import Loading from '../../components/Loading'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

const PrivacyPolicyNew = ({
  match: {
    params: { id },
    url,
  },
  t,
}) => {
  const isCookiePolicy = () => {
    let arr = url.split(/\/([\d]+)/)

    arr = arr.filter(item => {
      return item.match(/[\/]/g)
    })

    return arr[0] === '/cookie-policies'
  }

  const policyType = !isCookiePolicy() ? 'cookie' : 'privacy'

  return (
    <AuthWrapper>
      <TitleComponent title={t('.new_policy')} />
      <div className='row'>
        <div className='columns'>
          <h1> {t('.new_policy')} </h1>
          <Query
            fetchPolicy={'network-only'}
            query={GraphqlCalls.GET_PRIVACY_POLICY_QUERY}
            variables={{ ppId: '0', type: policyType }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return `Error! ${error.message}`
              const { allPrivacyPolicies } = data
              return (
                <EditForm
                  allPrivacyPolicies={allPrivacyPolicies}
                  formType={'new'}
                  linkPolicyMutation={GraphqlCalls.LINK_POLICIES_MUTATION}
                  graphqlMutation={GraphqlCalls.CREATE_PRIVACY_POLICY_MUTATION}
                />
              )
            }}
          </Query>
        </div>
      </div>
    </AuthWrapper>
  )
}
export default compose(withRouter, withTranslation('privacyPolicyEdit'))(PrivacyPolicyNew)
