import React from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import withApollo from 'react-apollo/withApollo';
import { withRouter } from 'react-router-dom';

import OnBoardingForm from './onboarding_form';
import { getNameWithIsoCode } from '../../../helpers/Countries';
import { withTranslation } from '../../../providers/I18n';
import { TitleComponent } from '../../../providers/pageTitles/TitleComponent';
import { withNotifications, NotificationModel } from 'src/providers/notifications';
import { hideModal, loadModal } from 'src/redux/modal/action.js';
import { connect } from 'react-redux';
import ModalContainer from '../../../components/Modal/container';

class Onboarding extends React.Component {
  constructor() {
    super();

    this.state = {
      name: '',
      firstname: '',
      organization_name: '',
      street: '',
      vat: '',
      zip_code: '',
      city: '',
      country: '',
      phone_number: '',
      sector: '',
      employees: '',
      hasVat: null,
      vatInfo: null,
      initValue: {},
      setVatValue: '',
      vatCountryCode: "",
      organizationDataCheck: {}
    };
  }

  handleChange = e => {
    const {
      target: { value, name },
    } = e;
    this.setState({ [name]: value });
  };

  handleVATChange = e => {
    const {
      target: { value },
    } = e;
    this.setState({ setVatValue: value, vatInfo: false })

  };
  vatVerfication = (e) => {


    e.preventDefault();
    const { client, notificationsProvider, t, loadExampleModal, hideModal } = this.props;
    const { setVatValue } = this.state;

    const vatNumber = setVatValue.replace(/[\. ]+/g, '');
    if (vatNumber) {
      loadExampleModal({
        modalType: 'cookie_scan',
        content: t('.vat_number_scan_message'),
        title: t('.vat_number_title'),
      });
      client
        .query({
          query: CHECK_VAT_VALIDATION_QUERY,
          variables: {
            number: vatNumber,
          },
        })
        .then(({ data }) => {
          hideModal();
          const { checkVat } = data;
          if (checkVat) {
            this.setState({
              vat: vatNumber,
              vatInfo: true,
            });
            this.createAddressWithVat(checkVat, vatNumber);

          }
          else {
            loadExampleModal({
              modalType: 'vat_not_found_model',
              content: t('.vat_number_title'),
              title: t('.vat_number_title'),
            });
            this.setState({
              vatInfo: false,
            });

          }
        })
        .catch(error => {
          this.setState({
            vatInfo: false,
          });
          loadExampleModal({
            modalType: 'vat_not_found_model',
            content: t('.vat_number_title'),
            title: t('.vat_number_title'),
          });

        });
    }
  }
  componentDidMount() {
    this.getOrignazation();
  }
  getOrignazation = () => {
    const { client } = this.props;
    client
      .query({
        query: ORGANIZATION_QUERY,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        if (data.organization) {
          const { organization } = data
          console.log("organizationDataCheck==125==", organization)
          this.setState({ organizationDataCheck: organization })
          this.setState({
            name: (organization && organization.lastName && organization.billing.lastName) ? organization.billing.lastName : "",
            firstname: (organization && organization.billing && organization.billing.managerName) ? organization.billing.managerName : "",
            organization_name: (organization && organization.billing && organization.billing.companyName) ? organization.billing.companyName : "",

            street: (organization && organization.billing && organization.billing?.address?.street
            ) ? organization.billing?.address?.street : "",

            vat: (organization && organization.billing && organization.billing.vat) ? organization.billing.vat.replace(" ", "") : "",
            zip_code: (organization && organization.billing && organization.billing?.address?.zip_code
            ) ? organization.billing?.address?.zip_code : "",
            city: (organization && organization.billing && organization.billing?.address?.city
            ) ? organization.billing?.address?.city : "",
            country: (organization && organization.billing && organization.billing?.address?.country_code
            ) ? organization.billing?.address?.country_code : "",
            phone_number: '',
            sector: (organization && organization.billing && organization.billing.industry) ? organization.billing.industry : "",
            employees: (organization && organization.billing && organization.billing.size) ? organization.billing.size : "",
            vatInfo: null,
            initValue: {},
            setVatValue: (organization && organization.billing && organization.billing.vat) ? organization.billing.vat.replace(" ", "") : "",
            vatCountryCode: (organization && organization.billing && organization.billing.vat) ? organization.billing?.address?.country_code : "",
            hasVat: (organization && organization.billing && organization.billing.vat) ? ("yes") : "",

          })

        }
      }).catch(e => {

      })
  }
  // vatVerfication = (e) => {
  //   e.preventDefault();
  //   const { client, notificationsProvider, t } = this.props;
  //   const { setVatValue } = this.state;


  //   const vatNumber = setVatValue.replace(/[\. ]+/g, '');
  //   if (vatNumber) {
  //     client
  //       .query({
  //         query: CHECK_VAT_VALIDATION_QUERY,
  //         variables: {
  //           number: vatNumber,
  //         },
  //       })
  //       .then(({ data }) => {
  //         const { checkVat } = data;
  //         if (checkVat) {
  //           this.setState({
  //             vat: vatNumber,
  //             vatInfo: true,
  //           });
  //           this.createAddressWithVat(checkVat, vatNumber);
  //         }
  //         else {
  //           this.setState({
  //             vatInfo: false,
  //           });
  //           const notification = new NotificationModel({
  //             title: '',
  //             message: t('.error_vat_message'),
  //             type: 'alert',
  //           });

  //           notificationsProvider.add(notification);

  //         }
  //       })
  //       .catch(error => {
  //         this.setState({
  //           vatInfo: false,
  //         });
  //       });
  //   }
  // }

  toggleVAT = e => {
    const {
      target: { value },
    } = e;
    this.setState({
      hasVat: value,
    });
    this.setState({
      vat: '',
      vatInfo: false
    });
    this.setState({
      country: '',
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { client, history, notificationsProvider } = this.props;
    const { name, firstname, organization_name, vat, sector, employees, hasVat, organizationDataCheck } = this.state;

    let address = this.buildAddress();

    const values = {
      managerName: `${name} ${firstname}`,
      companyName: organization_name,
      address: address,
      industry: sector,
      size: employees,
    };
    if (!organizationDataCheck?.billing?.isOnboardingDone) {
      values.id = organizationDataCheck?.billing?.id
      values.mailLanguage = organizationDataCheck?.billing?.mailLanguage
    }
    if (vat) {
      values.vat = vat ? vat : ''
    }
    client
      .mutate({
        mutation: organizationDataCheck?.billing?.id ? UPDATE_BILLING_MUTATION : CREATE_BILLING_MUTATION,
        variables: values,
      })
      .then(() => {
        history.push('/');
      })
      .catch(error => {
        error?.graphQLErrors?.map(({ message, extensions }, i) => {
          const notification = new NotificationModel({
            title: 'Error',
            message: extensions.errors[0].title + ': ' + extensions.errors[0].detail,
            type: 'alert',
          })
          notificationsProvider.add(notification)
        });
        // throw error;
      });

  };

  createAddressWithVat = (vatInfo, vat) => {
    if (vatInfo !== null) {
      let addressToReturn = {};
      addressToReturn.vat = vat;
      addressToReturn.organization_name = vatInfo.name;
      addressToReturn.country = getNameWithIsoCode(vatInfo.countryCode);

      let address = vatInfo.address.split('\n');
      addressToReturn.street = address[0]?.replace(',', '');

      let cityAndZip = address[1]?.split(' ');
      addressToReturn.zip_code = cityAndZip?.[0];
      addressToReturn.city = cityAndZip?.[1];

      this.setState({
        vat: addressToReturn.vat,
        organization_name: addressToReturn.organization_name,
        vatCountryCode: vatInfo?.countryCode,
        country: addressToReturn.country,
        street: addressToReturn.street,
        zip_code: addressToReturn.zip_code,
        city: addressToReturn.city,
        vatInfo: vatInfo ? vatInfo : null,
        initValue: addressToReturn,
      });
    } else {
      this.setState({
        vat: vat,
        vatInfo: true,
      });
    }
  };
  buildAddress = () => {
    const { street, zip_code, city, phone_number, country, vatCountryCode, hasVat } = this.state;

    let address = {
      street,
      zip_code,
      city,
      phone_number,
      country,
      country_code: (vatCountryCode && hasVat === 'yes') ? (vatCountryCode?.slice(0, 2).toLowerCase()) : (country?.slice(0, 2)).toLowerCase(),
    };

    return address;
  };

  render() {
    const { hasVat, initValue, sector, employees, vatInfo } = this.state;
    const { t } = this.props;
    return (
      <>
        <ModalContainer />
        <div className='row collapse align-bottom align-justify margin-bottom-40'>
          <TitleComponent title={t('.account_confirmation')} />
          <p className='onboarding_message'>
            {' '}
            <b>{t('.welcom')} </b> {t('.onboarding_message')}{' '}
          </p>
          <OnBoardingForm
            initialValues={hasVat === 'yes' ? {
              vat: this.state.vat,
              organization_name: this.state.organization_name,
              street: this.state.street,
              zip_code: this.state.zip_code,
              city: this.state.city,
              country: this.state.country,
              phone_number: this.state.phone_number,
              firstname: this.state.firstname,
              name: this.state.name,
            } : {
              organization_name: this.state.organization_name,
              street: this.state.street,
              zip_code: this.state.zip_code,
              city: this.state.city,
              phone_number: this.state.phone_number,
              firstname: this.state.firstname,
              name: this.state.name,
              country: this.state.country,
            }}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            toggleVAT={this.toggleVAT}
            handleVATChange={this.handleVATChange}
            vatVerfication={this.vatVerfication}
            countryhandleChange={(e) => {
              const {
                target: { value, name },
              } = e;
              this.setState({ [name]: value });

            }}
            sector={sector}
            employees={employees}
            hasVat={hasVat}
            isVatValid={vatInfo}
            t={t}
          />
        </div>
      </>

    );
  }
}
const UPDATE_BILLING_MUTATION = gql`
  mutation updateBilling(
    $id: ID!
    $managerName: String!
    $companyName: String!
    $vat: String
    $address: JSON!
    $industry: String
    $size: String
    $mailLanguage: String
  ) {
    updateBilling(
      id: $id,
      managerName: $managerName
      companyName: $companyName
      vat: $vat
      address: $address
      industry: $industry
      size: $size
      mailLanguage: $mailLanguage
    ) {
      id
    }
  }
`;

const CREATE_BILLING_MUTATION = gql`
  mutation createBilling(
    $managerName: String!
    $companyName: String!
    $vat: String
    $address: JSON!
    $industry: String
    $size: String
    $vatExist: Boolean
  ) {
    createBilling(
      managerName: $managerName
      companyName: $companyName
      vat: $vat
      address: $address
      industry: $industry
      size: $size
      vatExist: $vatExist
    ) {
      id
    }
  }
`;

const CHECK_VAT_VALIDATION_QUERY = gql`
  query($number: String!) {
    checkVat(number: $number) {
      address
      countryCode
      id
      name
      vatNumber
    }
  }
`;
const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      id
      name
      countryCode
      subDomainName
      settings
      type
      createdAt
      acceptedAt
      billing {
        id
        managerName
        companyName
        lastName
        vat
        address
        industry
        mailLanguage
        isOnboardingDone
        size
      }
    }
  }
`;
const mapDispatchToProps = {
  loadExampleModal: loadModal,
  hideModal
};

export default compose(connect(null, mapDispatchToProps), withApollo, withRouter, withTranslation('account_onboarding'), withNotifications())(Onboarding);