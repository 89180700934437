import React, { Fragment } from 'react';

import { reader } from './preview.js';
import { withTranslation } from '../../providers/I18n';

class HTMLPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryColor: null,
      secondaryColor: null,
      tertiaryColor: null,
    };
  }

  componentDidMount() {
    this.setUpIframe(this.props.preview);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setUpIframe(nextProps.preview);
  }

  setUpIframe = preview => {

    const iframe = document.getElementById('FileFrame');
    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write(preview);
    doc.close();

    let outerLinks1 = doc.getElementsByClassName("banner-buttons");
    let outerLinks2 = doc.getElementsByClassName("header-buttons");

    let i;
    for (i = 0; i < outerLinks1.length; i++) {
      outerLinks1[i].remove();
    }

    let j;
    for (j = 0; j < outerLinks2.length; j++) {
      outerLinks2[i].remove();
    }

    reader();
  };

  render() {
    const { preview, t } = this.props;
    return (
      <Fragment>
        {!preview && <div className='callout alert margin-1'>{t('.locale_mismatch')}</div>}
        <iframe id='FileFrame' src='about:blank'></iframe>
      </Fragment>
    );
  }
}

export default withTranslation('privacyPolicyPreview')(HTMLPreview);
