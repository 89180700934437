import React from 'react';
import { Field } from 'redux-form';

import { renderInputField } from '../Form/TextFieldGroup/fieldInput';
import DropdownItem from '../../containers/Onboarding/CompanyOnboarding/dropdown';
import TextArea from '../Form/TextArea';
import Button from '../Button';
import { withTranslation } from '../../providers/I18n';
import Tooltip from '../Tooltip';

const category_list = [
  {
    value: 'necessary',
    label: '.necessary',
  },
  {
    value: 'functional',
    label: '.functional',
  },
  {
    value: 'statistic',
    label: '.statistic',
  },
  {
    value: 'marketing',
    label: '.marketing',
  },
];

const cookie_type_list = [
  {
    value: 'owner',
    label: '.cookie_owner',
  },
  {
    value: 'third_party',
    label: '.third_party_cookie',
  },
];

const technology_type_list = [
  {
    value: 'cookie',
    label: '.cookies',
  },
  {
    value: 'other',
    label: '.similar_technology',
  },
];

const expiration_date_format = [
  {
    value: 'minute',
    label: '.minute',
  },
  {
    value: 'day',
    label: '.day',
  },
  {
    value: 'month',
    label: '.month',
  },
  {
    value: 'session',
    label: '.session',
  },
];

class CookieFormItem extends React.Component {
  constructor(props) {
    super(props);

    let isSession = props.option.session ? 'session' : null;

    this.state = {
      name: props.option.name,
      domain: props.option.domain,
      purpose_list: [],
      category: props.option.category || null,
      purpose: props.option.purpose || null,
      type: props.option.type || null,
      technology: props.option.technology || null,
      expDateFormat: props.option.expDateFormat || isSession,
      lifespanTime: props.option.lifespanTime || '',
      allSelected: false,
      lifespanTimeValid: true,
      nameValid: true,
      domainValid: true,
    };
  }

  componentWillMount() {
    const { category } = this.props.option;
    category && this.categoryChange(category);
  }

  categoryChange = cat => {
    let purposes = this.props.question.choices.data;
    let purpose_list = purposes[cat].map(prp => {
      let keys = Object.keys(prp);
      return {
        value: keys[0],
        label: prp[keys[0]],
      };
    });

    this.setState({ purpose_list });
  };

  otherChange = value => {
    this.props.handleChange(this.props.index, 'other', value);
  };

  onChange = e => {
    const { purpose, category, type, technology, expDateFormat } = this.state;
    const { name, value } = e.target;

    name === 'category' && this.categoryChange(value);
    name === 'category' && this.setState({ purpose: null });

    this.props.handleChange(this.props.index, name, value);

    this.setState(
      { [name]: value },
      () =>
        purpose &&
        category &&
        type &&
        technology &&
        expDateFormat &&
        this.setState({ allSelected: true }),
    );
  };

  handleTextFieldChange(event, field) {
    let value = event.target.value;

    if (field === 'lifespanTime') {
      if (isNaN(value) || value < 1) {
        this.setState({ lifespanTimeValid: false });
      } else {
        this.setState({ lifespanTimeValid: true });
      }
    }
    if (field === 'name') {
      if (value === '') {
        this.setState({ nameValid: false });
      } else {
        this.setState({ nameValid: true });
      }
    }
    if (field === 'domain') {
      if (value === '') {
        this.setState({ domainValid: false });
      } else {
        this.setState({ domainValid: true });
      }
    }

    this.setState({ [field]: value });
  }

  render() {
    const { index, locales, question, option, handleChange, removeCookie, t } = this.props;
    const {
      purpose,
      category,
      type,
      technology,
      purpose_list,
      expDateFormat,
      lifespanTime,
      lifespanTimeValid,
      nameValid,
      domainValid,
    } = this.state;
    let allSelected =
      purpose &&
      category &&
      type &&
      technology &&
      lifespanTimeValid &&
      nameValid &&
      domainValid &&
      !(expDateFormat !== 'session' && lifespanTime === '');

    let other_options = [];

    if (this.state.purpose === 'other') {
      if (typeof this.props.option.other !== 'undefined') {
        other_options = [{ value: this.props.option.other }];
        // validate locale values for other purpose
        this.props.option.other.forEach(item => {
          Object.values(item).forEach(value => {
            if (value === '') {
              allSelected = false;
            }
          });
        });
      } else {
        let empty_options = locales.map(locale => {
          return { [locale.isoCode]: '' };
        });
        other_options = [{ value: empty_options }];
        allSelected = false;
      }
    }

    return (
      <div className='text-form cookie-form'>
        <div className='cookie-form__block'>
          <div className='text_input cookie-form__elem form--50'>
            <input
              id='name'
              name={`${index}_name`}
              type='text'
              placeholder=''
              readOnly={option.validate}
              onChange={e => {
                handleChange(index, 'name', e.target.value);
                this.handleTextFieldChange(e, 'name');
              }}
              value={this.state.name}
              style={{
                border: this.state.nameValid ? '1px solid #adadad' : '1px solid #f00',
              }}
            />
            <div className='text__label'>
              <label>{t('.cookie_name')}</label>
            </div>
          </div>

          <DropdownItem
            label={t('.type')}
            className='cookie-form__elem form--50'
            name='type'
            placeholder='--'
            handleChange={this.onChange}
            options={cookie_type_list}
            value={this.state.type}
            readOnly={option.validate}
            t={t}
          />
        </div>
        <div className='cookie-form__block'>
          <div className='text_input cookie-form__elem form--33'>
            <input
              id='owner'
              name={`${index}_domain`}
              type='text'
              placeholder=''
              readOnly={option.validate}
              onChange={e => {
                handleChange(index, 'domain', e.target.value);
                this.handleTextFieldChange(e, 'domain');
              }}
              value={this.state.domain}
              style={{
                border: this.state.domainValid ? '1px solid #adadad' : '1px solid #f00',
              }}
            />
            <div className='text__label'>
              <label>{t('.owner')}</label>
            </div>
          </div>

          <DropdownItem
            name='expDateFormat'
            className='cookie-form__elem form--33'
            placeholder='--'
            handleChange={this.onChange}
            options={expiration_date_format}
            label={t('.expDateFormat')}
            value={expDateFormat}
            readOnly={option.validate}
            t={t}
          />

          {expDateFormat && expDateFormat !== 'session' && (
            <div className='text_input cookie-form__elem form--25'>
              <input
                id='lifespanTime'
                name={`${index}_lifespanTime`}
                type='text'
                placeholder='0'
                readOnly={option.validate}
                onChange={e => {
                  handleChange(index, 'lifespanTime', e.target.value);
                  this.handleTextFieldChange(e, 'lifespanTime');
                }}
                value={this.state.lifespanTime}
                style={{
                  border: this.state.lifespanTimeValid ? '1px solid #adadad' : '1px solid #f00',
                }}
              />
              <div className='text__label'>
                <label>{t('.life_time')}</label>
              </div>
            </div>
          )}

          <DropdownItem
            name='technology'
            className='cookie-form__elem form--25'
            placeholder='--'
            handleChange={this.onChange}
            options={technology_type_list}
            label={t('.technology')}
            value={technology}
            readOnly={option.validate}
            t={t}
          />
        </div>
        <div className='cookie-form__block flex_center margin-bottom-20'>
          <DropdownItem
            label={t('.category')}
            className='cookie-form__elem form--25'
            name='category'
            placeholder='--'
            value={category}
            handleChange={this.onChange}
            options={category_list}
            readOnly={option.validate}
            t={t}
          />
          {category && (
            <DropdownItem
              label={t('.purpose')}
              className='cookie-form__elem form--75'
              name='purpose'
              placeholder='--'
              handleChange={this.onChange}
              options={purpose_list}
              value={purpose}
              readOnly={option.validate}
            />
          )}
        </div>
        {this.state.purpose === 'other' && (
          <TextArea
            name='other_purpose'
            options={other_options}
            index={index}
            locales={locales}
            onChange={this.otherChange}
            {...question}
          />
        )}
        {!option.validate ? (
          <div>
            <div style={{ float: 'left' }}>
              <Button
                className='btn btn_pill is-outlined is-blue'
                onClick={() => removeCookie(option.id)}
              >
                {t('.remove_cookie')}
              </Button>
            </div>
            <div style={{ float: 'right' }}>
              <Button
                className='btn btn_pill is-blue'
                published
                disabled={!allSelected || !(option.name && option.domain)}
                onClick={() => handleChange(index, 'validate', true)}
                style={{ marginRight: '10px' }}
              >
                {' '}
                {t('.validate_cookie')}{' '}
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className='btn btn_pill is-outlined is-blue'
            published
            onClick={() => handleChange(index, 'validate', false)}
          >
            {' '}
            {t('.edit_cookie')}{' '}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslation('cookie_form')(CookieFormItem);
