import React, { Fragment, useState, useEffect } from 'react';
import update from 'immutability-helper';

import StyledBanner from './styled_banner';
import banner_translation from './banner_translation.json';

const PreviewPage = ({
  data,
  locale,
  cookies_consent,
  text,
  color,
  vue,
  theme,
  preview_url,
  togglePreview,
  cookies_available,
  t,
}) => {
  const [isIframeLoading, setIframeLoadingState] = useState(true);
  const [isCookiesOpen, toggleCookiesContent] = useState(false);
  const [activeNav, setNavigationItem] = useState(
    cookies_available.includes('necessary') ? 'necessary' : cookies_available[0],
  );
  const [cookiesOptIn, setCookiesOptIn] = useState(cookies_consent);
  const [allCookiesOptIn, setAllCookiesOptIn] = useState(false);
  const [isCookiesValidated, onCookiesValidation] = useState(false);

  const switchAllOptIn = value => {
    let cookiesKey = Object.keys(cookiesOptIn);
    let newCookies = Object.assign({}, cookiesOptIn);

    cookiesKey.forEach(cookie => {
      if (cookie !== 'necessary') {
        newCookies[cookie] = value ? 'yes' : 'no';
      }
    });

    setCookiesOptIn(newCookies);
    setAllCookiesOptIn(value);
  };

  const switchOptIn = (value, name) => {
    let newCookies = update(cookiesOptIn, {
      [name]: { $set: value },
    });

    let allIn = true;
    let cookies_available_filtered = cookies_available.filter(function (value) {
      return value !== 'necessary';
    });

    cookies_available_filtered.map(type => {
      if (newCookies[type] === 'no') allIn = false;
    });

    setAllCookiesOptIn(allIn);
    setCookiesOptIn(newCookies);
  };

  useEffect(() => {
    window.addEventListener(
      'keyup',
      function _listener(event) {
        event.preventDefault();
        let key = event.key || event.keyCode;

        if (key === 'Escape' || key === 'Esc' || key === 27) {
          togglePreview();
          window.removeEventListener('keyup', _listener, true);
        }
      },
      true,
    );

    let cookiesKey = Object.keys(cookies_consent);
    let isAllOptIn = true;

    cookiesKey.forEach(cookie => {
      if (cookies_consent[cookie] === 'no') isAllOptIn = false;
    });

    setAllCookiesOptIn(isAllOptIn);
  }, []);

  return (
    <div className='banner__preview'>
      <iframe src={preview_url} onLoad={() => setIframeLoadingState(false)} />
      {!isIframeLoading && (
        <Fragment>
          <div className='banner__preview__close-btn' onClick={togglePreview}>
            <i className='fas fa-times' />
          </div>
          <StyledBanner
            color={color}
            light={theme === 'light'}
            theme={theme}
            position={vue}
            preview
            data={data}
            banner_t={banner_translation?.[locale] ? banner_translation?.[locale] : banner_translation["en"]}
            text={text}
            cookies_consent={cookiesOptIn}
            cookiesType={data.banner_cookies}
            cookies_available={cookies_available}
            t={t}
            isCookiesOpen={isCookiesOpen}
            allCookiesOptIn={allCookiesOptIn}
            isCookiesValidated={isCookiesValidated}
            activeNav={activeNav}
            switchNav={setNavigationItem}
            toggleCookiesContent={toggleCookiesContent}
            switchOptIn={switchOptIn}
            switchAllOptIn={switchAllOptIn}
            onCookiesValidation={onCookiesValidation}
            locale={locale}
          />
        </Fragment>
      )}
    </div>
  );
};

export default PreviewPage;
