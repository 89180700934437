import React from 'react';
import Select from 'react-select';

import { withTranslation } from '../../../providers/I18n';

class LocaleField extends React.Component {
  constructor(props) {
    super(props);
    const { t, values, availableLocales } = props;
    this.state = {
      selectedOption:
        values.locales &&
        values.locales.map(locale => {
          return {
            value: locale,
            label: t(`.${locale}`),
          };
        }),
      availableOptions: availableLocales.map(locale => {
        return {
          value: locale,
          label: t(`.${locale}`),
        };
      }),
      values: values.locales,
    };
  }

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption: selectedOption,
        values: selectedOption && selectedOption.map(opt => opt.value),
      },
      () => this.props.handleChange(this.state.values, 'locales'),
    );
  };

  render() {
    const { selectedOption, availableOptions } = this.state;
    const { alerts } = this.props;
    const alertStyle = alerts
      ? {
          control: styles => ({ ...styles, borderColor: 'red !important' }),
        }
      : {
          control: styles => ({ ...styles, borderColor: '#adadad !important' }),
        };
    return (
      <Select
        value={selectedOption}
        classNamePrefix={'locale'}
        options={availableOptions}
        onChange={this.handleChange}
        isMulti={true}
        styles={alertStyle}
      />
    );
  }
}

export default withTranslation('privacyPolicyEdit.localelist')(LocaleField);
