import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import update from 'immutability-helper';
import withApollo from 'react-apollo/withApollo';

import ProfileForm from './form';
import { withNotifications, NotificationModel } from '../../providers/notifications';

class AccountEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      values: {},
      hasChanged: false,
    };
  }

  componentWillMount() {
    this.initState();
  }

  initState = () => {
    const { organization } = this.props;
    let initState = {};
    let billingAddress = organization.billing.address;

    initState.name = organization.billing.managerName;
    initState.organization_name = organization.name;
    initState.email_address = organization.admin.email;
    initState.vat = organization.billing.vat;
    initState.company_name = organization.billing.companyName;
    initState.street = billingAddress.street;
    initState.zip_code = billingAddress.zip_code;
    initState.city = billingAddress.city;
    initState.country = billingAddress.country;
    initState.sector = organization.billing.industry;
    initState.employees = organization.billing.size;
    initState.phone = organization.billing.address.phone ? organization.billing.address.phone : '';
    initState.mailLanguage = organization?.billing?.mailLanguage ? organization?.billing?.mailLanguage : '';
    initState.isReminderToUpdatePolicies = organization?.settings?.is_reminder_to_update_policies ? organization?.settings?.is_reminder_to_update_policies : false;

    this.setState({ values: initState });
  };

  resetState = () => {
    this.initState();
  };

  handleChange = e => {
    const {
      target: { value, name },
    } = e;

    let newObj = update(this.state.values, {
      [name]: {
        $set: value,
      },
    });

    this.setState({ values: newObj });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { client, organization, notificationsProvider, t } = this.props;
    const {
      values: { name, company_name, vat, sector, employees, mailLanguage },
    } = this.state;

    let address = this.buildAddress();
    client
      .mutate({
        mutation: UPDATE_BILLING_MUTATION,
        variables: {
          id: organization.billing.id,
          managerName: name,
          companyName: company_name,
          vat: vat,
          address: address,
          industry: sector,
          size: employees,
          mailLanguage: mailLanguage
        },
      })
      .then(() => {
        const notification = new NotificationModel({
          title: t('.account_modification'),
          message: t('.account_modification_success'),
          type: 'success',
        });
        notificationsProvider.add(notification);
      })
      .catch(error => {
        const notification = new NotificationModel({
          title: t('.account_modification'),
          message: t('.account_modification_error'),
          type: 'alert',
        });
        notificationsProvider.add(notification);
      });
  };

  buildAddress = () => {
    const {
      values: { street, zip_code, city, phone, country },
    } = this.state;

    let address = {
      street,
      zip_code,
      city,
      phone,
      country,
      country_code: 'fr',
    };

    return address;
  };

  render() {
    const { values } = this.state;
    const { t, client } = this.props;
    return (
      <div>
        <ProfileForm
          initialValues={values}
          sector={values.sector}
          employees={values.employees}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          resetState={this.resetState}
          t={t}
          client={client}
        />
      </div>
    );
  }
}

const UPDATE_BILLING_MUTATION = gql`
  mutation updateBilling(
    $id: ID!
    $managerName: String
    $companyName: String
    $vat: String
    $address: JSON
    $industry: String
    $size: String
    $mailLanguage: String
  ) {
    updateBilling(
      id: $id
      managerName: $managerName
      companyName: $companyName
      vat: $vat
      address: $address
      industry: $industry
      size: $size
      mailLanguage: $mailLanguage
    ) {
      id
    }
  }
`;

export default compose(withRouter, withApollo, withNotifications())(AccountEdit);
