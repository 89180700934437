import React, { Fragment, useRef } from 'react';
import { compose } from 'recompose';
import { Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { withTranslation } from '../../providers/I18n';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

import searchIcon from '../../assets/img/search-light.svg';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import CopyMessage from '../../components/Header/copyMessage';
import 'react-data-table-component-extensions/dist/index.css';
import Loading from '../../components/Loading';
import { AiOutlineSearch } from 'react-icons/ai';
import export_buttton from '../../assets/img/export_button.png';
import export_print from '../../assets/img/print.png';

import '../../assets/scss/main.scss';
import { hideModal, loadModal } from '../../../src/redux/modal/action';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import Button from 'src/components/Button';
import iframeIcon from '../../assets/img/globe-solid.svg';
import { FaChartBar } from 'react-icons/fa';
import { MdTableRows } from 'react-icons/md';

class SearchConsentDatabase extends React.Component {
  constructor(props) {
    super(props);

    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleUuidChange = this.handleUuidChange.bind(this);
    this.handleDomainNameChange = this.handleDomainNameChange.bind(this);
    const currentDate = new Date();
    this.state = {
      dateFrom: new Date(currentDate.setFullYear(currentDate.getFullYear() - 1)),
      dateTo: new Date(),
      uuid: '',
      domainName: '',
      consents: null,
      calendarOpen: false,
      isConsentsLoading: false,
      rowCopied: false,
      monthNames: [],
      valueChart: [],
      barChartValue: {
        accept_all: [],
        accept_selection: [],
        reject: [],
        close: [],
      },
      apiButtonValue: {},
      chartValueSet: {
        accept_all: [],
        accept_selection: [],
        reject: [],
        close: [],
      },
      chartCheckedValueSet: {
        accept_all: false,
        accept_selection: false,
        reject: false,
        close: false,
      },
      roundOprions: [],
      allChartData: {},
      selectedTab: 'report',
    };
    this.myTargetElementRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (this.props.cpToken && this.props.cpToken !== prevProps.cpToken) {
      this.fetchConsentsChartApply();
      this.searchConsent();
    }
  }
  chartPercentageValue(valueChart) {
    const totalValue = valueChart?.data ? Object.values(valueChart?.data) : [];

    const totalValueSum = totalValue.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    const totalValueAccept = valueChart?.buttonData?.accept_all
      ? Object.values(valueChart?.buttonData?.accept_all)
      : [];

    const totalValueAcceptSection = valueChart?.buttonData?.accept_selection
      ? Object.values(valueChart?.buttonData?.accept_selection)
      : [];

    const totalValueClose = valueChart?.buttonData?.close
      ? Object.values(valueChart?.buttonData?.close)
      : [];

    const totalValueReject = valueChart?.buttonData?.reject
      ? Object.values(valueChart?.buttonData?.reject)
      : [];

    // =====================================================================
    const totalValueAcceptSum = totalValueAccept.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    const totalValueAccepSectiontSum = totalValueAcceptSection.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      },
      0,
    );
    const totalValueCloseSum = totalValueClose.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    const totalValueRejectSum = totalValueReject.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return {
      totalvalue: totalValueSum,
      totalAcceptPerDisplay: totalValueAcceptSum,
      totalAcceptPer: Number(
        (totalValueAcceptSum ? (totalValueAcceptSum * 100) / totalValueSum : 0).toFixed(2),
      ),
      totalAccpetSelectionPerDisplay: totalValueAccepSectiontSum,
      totalAccpetSelectionPer: Number(
        (totalValueAccepSectiontSum
          ? (totalValueAccepSectiontSum * 100) / totalValueSum
          : 0
        ).toFixed(2),
      ),
      totalrejectPerDisplay: totalValueRejectSum,
      totalrejectPer: Number(
        (totalValueRejectSum ? (totalValueRejectSum * 100) / totalValueSum : 0).toFixed(2),
      ),
      totalClosePerDisplay: totalValueCloseSum,
      totalClosePer: Number(
        (totalValueCloseSum ? (totalValueCloseSum * 100) / totalValueSum : 0).toFixed(2),
      ),
    };
  }
  fetchConsentsChartApply() {
    const { cpToken, t } = this.props;
    this.fetchConsentsChart(cpToken)
      .then(result => {
        const { data } = result;
        const button = Object.keys(data?.consentCharts?.[0]?.buttonData);

        this.setState({ allChartData: data });
        if (
          data &&
          data?.consentCharts &&
          data?.consentCharts?.[0] &&
          data?.consentCharts?.[0]?.data
        ) {
          this.setState({ apiButtonValue: data?.consentCharts?.[0]?.data });
          const keys = data?.consentCharts?.[0]?.data
            ? Object.keys(data?.consentCharts?.[0]?.data)
            : [];
          const valueChart = data?.consentCharts?.[0]?.data
            ? Object.values(data?.consentCharts?.[0]?.data)
            : [];

          this.setState(prevState => ({
            barChartValue: {
              ...prevState.barChartValue,
              accept_all: Object.values(data?.consentCharts?.[0]?.buttonData?.accept_all),
              accept_selection: Object.values(
                data?.consentCharts?.[0]?.buttonData?.accept_selection,
              ),
              close: Object.values(data?.consentCharts?.[0]?.buttonData?.close),
              reject: Object.values(data?.consentCharts?.[0]?.buttonData?.reject),
            },
          }));
          this.setState({ monthNames: keys });
          this.setState({ valueChart: valueChart });

          this.setState({
            chartCheckedValueSet: {
              accept_all: false,
              accept_selection: false,
              reject: false,
              close: false,
            },
          });
          this.setState({
            chartValueSet: {
              accept_all: [],
              accept_selection: [],
              reject: [],
              close: [],
            },
          });

          const totalValuePer = this.chartPercentageValue(data?.consentCharts?.[0]);
          const newDataSet = [
            {
              labelButton: t('.accept_all'),
              key: 'accept_all',
              option: {
                series: [100],
                options: {
                  chart: {
                    type: 'radialBar',
                  },
                  colors: ['#659c34'],
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                        background: '#cde8b5',
                      },
                      dataLabels: {
                        name: {
                          fontSize: '25px',
                          color: '#000',
                        },
                        value: {
                          fontSize: '15',
                          formatter: function (val) {
                            return totalValuePer?.totalAcceptPerDisplay; // This formatter adds the '+' sign to the percentage value
                          },
                        },
                      },
                    },
                  },
                  labels: [totalValuePer?.totalAcceptPer + '%'],
                },
              },
            },
            {
              labelButton: t('.accept_selection'),
              key: 'accept_selection',
              option: {
                series: [100],
                options: {
                  chart: {
                    type: 'radialBar',
                  },
                  colors: ['#c2d015'],
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                        background: '#e4ee65',
                      },
                      dataLabels: {
                        name: {
                          fontSize: '25px',
                          color: '#000',
                        },
                        value: {
                          fontSize: '15',
                          formatter: function (val) {
                            return totalValuePer?.totalAccpetSelectionPerDisplay; // This formatter adds the '+' sign to the percentage value
                          },
                        },
                      },
                    },
                  },
                  labels: [totalValuePer?.totalAccpetSelectionPer + '%'],
                },
              },
            },
            {
              labelButton: t('.reject'),
              key: 'reject',
              option: {
                series: [100],
                options: {
                  chart: {
                    type: 'radialBar',
                  },
                  colors: ['#51d6fc'],
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                        background: '#93e3fe',
                      },
                      dataLabels: {
                        name: {
                          fontSize: '25px',
                          color: '#000',
                        },
                        value: {
                          fontSize: '15',
                          formatter: function (val) {
                            return totalValuePer?.totalrejectPerDisplay; // This formatter adds the '+' sign to the percentage value
                          },
                        },
                      },
                    },
                  },
                  labels: [totalValuePer?.totalrejectPer + '%'],
                },
              },
            },
            {
              labelButton: t('.close'),
              key: 'close',
              option: {
                series: [100],
                options: {
                  chart: {
                    type: 'radialBar',
                  },
                  colors: ['#bf37f2'],
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                        background: '#e492fe',
                      },
                      dataLabels: {
                        name: {
                          fontSize: '25px',
                          color: '#000',
                        },
                        value: {
                          fontSize: '15',
                          formatter: function (val) {
                            return totalValuePer?.totalClosePerDisplay; // This formatter adds the '+' sign to the percentage value
                          },
                        },
                      },
                    },
                  },
                  labels: [totalValuePer?.totalClosePer + '%'],
                },
              },
            },
          ];
          this.setState({ roundOprions: newDataSet });
        }
      })
      .catch(() => {
        this.setState({ allChartData: '' });
      });
  }

  handleUuidChange = e => {
    this.setState({
      uuid: e.target.value,
      domainName: '',
      dateFrom: '',
      dateTo: '',
    });
  };

  handleDomainNameChange = e => {
    this.setState({
      domainName: e.target.value,
      uuid: '',
      dateFrom: '',
      dateTo: '',
    });
  };

  handleDateRangeChange = ranges => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    this.setState({
      dateFrom: startDate,
      dateTo: endDate,
      uuid: '',
      domainName: '',
    });

    this.toggleCalendar();
  };

  toggleCalendar = () => {
    this.setState({ calendarOpen: !this.state.calendarOpen });
  };

  closeCalendar = () => {
    this.setState({ calendarOpen: false });
  };

  dummyChangeDate = () => {
    // do nothing
  };

  ISODateString = (date, type) => {
    let time = type === 'start' ? 'T00:00:00' : 'T23:59:59';
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    let localIsoString =
      date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + time;
    if (date.getTimezoneOffset() === 0) localIsoString += 'Z';
    return localIsoString;
  };

  ISODateDisplay = date => {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return date.getFullYear() + '/' + pad(date.getMonth() + 1) + '/' + pad(date.getDate());
  };

  ISODateTimeDisplay = date => {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return (
      date.getFullYear() +
      '/' +
      pad(date.getMonth() + 1) +
      '/' +
      pad(date.getDate()) +
      ' ' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds())
    );
  };

  fetchConsentsByUuid = (cpToken, uuid) => {
    const { client } = this.props;
    return client.query({
      query: SEARCH_CONSENT_BY_UUID,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        uuid,
      },
    });
  };

  fetchConsentsByDomainName = (cpToken, domainName) => {
    const { client } = this.props;
    return client.query({
      query: SEARCH_CONSENT_BY_DOMAIN_NAME,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        domainName,
      },
    });
  };

  fetchConsentsChart = cpToken => {
    const { client } = this.props;
    const { dateFrom, dateTo } = this.state;
    const consentDateFrom = this.ISODateString(dateFrom, 'start');
    const consentDateTo = this.ISODateString(dateTo, 'end');
    return client.query({
      query: CONSENT_CHARTS,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        consentDateFrom,
        consentDateTo,
      },
    });
  };

  fetchConsentsByDate = (cpToken, dateFrom, dateTo) => {
    const { client } = this.props;
    const consentDateFrom = this.ISODateString(dateFrom, 'start');
    const consentDateTo = this.ISODateString(dateTo, 'end');

    return client.query({
      query: SEARCH_CONSENT_BY_DATE,
      fetchPolicy: 'network-only',
      variables: {
        cpToken,
        consentDateFrom,
        consentDateTo,
      },
    });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.searchConsent();
    }
  };

  searchConsent = () => {
    const { dateFrom, dateTo, uuid, domainName } = this.state;
    const { cpToken } = this.props;

    if (dateFrom !== '' && dateTo !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByDate(cpToken, dateFrom, dateTo)
        .then(result => {
          this.fetchConsentsChartApply();
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    } else if (uuid !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByUuid(cpToken, uuid)
        .then(result => {
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    } else if (domainName !== '') {
      this.setState({ isConsentsLoading: true });

      this.fetchConsentsByDomainName(cpToken, domainName)
        .then(result => {
          const consents = result.data.consents;
          this.setState({
            consents: consents,
            isConsentsLoading: false,
          });
        })
        .catch(() => {
          console.error('Error fetching data');
          this.setState({ isConsentsLoading: false });
        });
    }
  };

  consentDatabaseEntryHandler = row => {
    const copiedRow = Object.entries(row)
      .map(item => item.join(': '))
      .join(' | ');

    navigator.clipboard.writeText(copiedRow);
    this.setState({ rowCopied: true });

    setTimeout(() => {
      this.setState({ rowCopied: false });
    }, 3000);
  };

  handleClickFilterChart = (e, button) => {
    this.setState(prevState => ({
      chartCheckedValueSet: {
        ...prevState.chartCheckedValueSet,
        [button]: !prevState.chartCheckedValueSet[button],
      },
    }));
    const { allChartData, chartCheckedValueSet } = this.state;
    const valueChartFilterButton = allChartData?.consentCharts?.[0]?.buttonData?.[button]
      ? Object.values(allChartData?.consentCharts?.[0]?.buttonData?.[button])
      : [];
    this.setState(prevState => ({
      chartValueSet: {
        ...prevState.chartValueSet,
        [button]: chartCheckedValueSet[button] ? [] : valueChartFilterButton,
      },
    }));
  };

  renderNavigation = () => {
    const { t } = this.props;
    return (
      <div className='account_nav_container' ref={this.myTargetElementRef}>
        <ul className='account_nav'>
          <li
            className={` tabicon account_nav_item ${this.state.selectedTab === 'report' ? 'active' : ''
              }`}
            onClick={() => {
              this.setState({ selectedTab: 'report' });
            }}
          >
            <MdTableRows /> {t('.report')}
          </li>
          <li
            className={`tabicon account_nav_item ${this.state.selectedTab === 'traffic' ? 'active' : ''
              }`}
            onClick={() => {
              const { loadModal, selectedLicense, banner_cis } = this.props;
              if (banner_cis) {
                this.setState({ selectedTab: 'traffic' });
              } else {
                loadModal({
                  modalType: 'access_limit_user_model',
                  content: {
                    selectedLicense: selectedLicense,
                  },
                  title: '',
                  t,
                });
              }
            }}
          >
            <FaChartBar /> {t('.traffic_analysis')}
          </li>
          <li
            className={`account_nav_item 
              }`}
          >
            {/* {t('.report')} */}
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const { t, cpToken, selectedLicense } = this.props;
    const {
      dateFrom,
      dateTo,
      consents,
      calendarOpen,
      isConsentsLoading,
      monthNames,
      valueChart,
      roundOprions,
      selectedTab,
      chartValueSet,
      chartCheckedValueSet,
      barChartValue,
    } = this.state;
    console.log(barChartValue, 'barChartValue');
    console.log(chartValueSet, 'chartValueSet=========');
    const selectionRange = {
      startDate: this.state.dateFrom,
      endDate: this.state.dateTo,
      key: 'selection',
    };

    const displayDateFrom = dateFrom !== '' ? this.ISODateDisplay(dateFrom) : '';
    const displayDateTo = dateTo !== '' ? this.ISODateDisplay(dateTo) : '';

    const columns = [
      {
        name: t('.uuid'),
        selector: 'uuid',
        sortable: true,
      },
      {
        name: t('.date'),
        selector: 'consentDate',
        sortable: true,
        maxWidth: '180px',
      },
      {
        name: t('.domain'),
        selector: 'domainName',
        sortable: true,
        maxWidth: '180px',
        wrap: true,
      },
      {
        name: t('.button'),
        selector: 'button',
        sortable: true,
        maxWidth: '140px',
        center: true,
      },
      {
        name: t('.necessary'),
        selector: 'necessary',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.functional'),
        selector: 'functional',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.marketing'),
        selector: 'marketing',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
      {
        name: t('.statistic'),
        selector: 'statistic',
        sortable: true,
        maxWidth: '50px',
        center: true,
      },
    ];

    let data = [];

    if (consents) {
      data = consents.map(item => {
        let formattedItem = {
          uuid: item.uuid,
          domainName: item.domainName,
        };

        const unixTimeZero = Date.parse(item.consentDate);
        formattedItem.consentDate = this.ISODateTimeDisplay(new Date(unixTimeZero));

        switch (item.button) {
          case 'close':
            formattedItem.button = t('.close');
            break;

          case 'reject':
            formattedItem.button = t('.reject');
            break;

          case 'accept_all':
            formattedItem.button = t('.accept_all');
            break;

          case 'accept_selection':
            formattedItem.button = t('.accept_selection');
            break;
        }

        if (item.necessary === null) {
          formattedItem.necessary = t('.not_available');
        } else if (item.necessary === true) {
          formattedItem.necessary = t('.true');
        } else {
          formattedItem.necessary = t('.false');
        }

        if (item.functional === null) {
          formattedItem.functional = t('.not_available');
        } else if (item.functional === true) {
          formattedItem.functional = t('.true');
        } else {
          formattedItem.functional = t('.false');
        }

        if (item.marketing === null) {
          formattedItem.marketing = t('.not_available');
        } else if (item.marketing) {
          formattedItem.marketing = t('.true');
        } else {
          formattedItem.marketing = t('.false');
        }

        if (item.statistic === null) {
          formattedItem.statistic = t('.not_available');
        } else if (item.statistic) {
          formattedItem.statistic = t('.true');
        } else {
          formattedItem.statistic = t('.false');
        }

        return formattedItem;
      });
    }

    const tableData = {
      columns,
      data,
      filterPlaceholder: t('.filter_results'),
      exportHeaders: true,
      export: this.props?.allowExport ? true : false,
      print: this.props?.allowExport ? true : false,
    };
    const options = {
      options: {
        chart: {
          id: 'apexchart-example',
          toolbar: {
            show: false,
          },
        },
        colors: ['#c9aac6', '#659c34', '#c2d015', '#93e3fe', '#bf37f2'],
        markers: {
          size: 0, // Setting marker size to 0 will remove them from the chart
        },
        xaxis: {
          categories: monthNames,
        },
      },
      series: [
        {
          name: '',
          data: valueChart,
        },
        {
          name: '',
          data: chartValueSet?.accept_all,
        },
        {
          name: '',
          data: chartValueSet?.accept_selection,
        },
        {
          name: '',
          data: chartValueSet?.reject,
        },
        {
          name: '',
          data: chartValueSet?.close,
        },
      ],
    };
    const barChart = {
      options: {
        chart: {
          type: 'bar',
          height: 380,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true
          }
        },
        chart: {
          id: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: ['#659c34', '#c2d015', '#93e3fe', '#bf37f2'],
        markers: {
          size: 0,
        },
        xaxis: {
          categories: ['Accept all', 'Accept selection', 'Reject', 'Close'],
        },
      },
      series: [{
        data: [barChartValue['accept_all'].reduce((accumulator, currentValue) => accumulator + currentValue, 0), barChartValue['accept_selection'].reduce((accumulator, currentValue) => accumulator + currentValue, 0), barChartValue['reject'].reduce((accumulator, currentValue) => accumulator + currentValue, 0), barChartValue['close'].reduce((accumulator, currentValue) => accumulator + currentValue, 0)]
      }]
    };

    const totalValueSum = valueChart.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    const percentages = roundOprions.map(i => parseFloat(i?.option?.options.labels));

    const DonutChart = {
      options: {
        series: percentages,
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        colors: ['#659c34', '#c2d015', '#93e3fe', '#bf37f2'],
      },
    };

    const rejectData = roundOprions.find(option => option.key === 'reject');
    const rejectSeriesValue =
      parseFloat(rejectData?.option?.options?.labels[0].replace('%', '')) || 0;
    const radialBar = {
      options: {
        chart: {
          id: 'half-radial-bar',
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '75%',
              background: '#fff',
            },
            track: {
              background: '#ebebeb',
              strokeWidth: '100%',
              margin: 0,
            },

            dataLabels: {
              name: {
                offsetY: -10,
                show: true,
                color: '#51D6FC',
                fontSize: '16px',
              },
              value: {
                offsetY: -50,
                color: '#51D6FC',
                fontSize: '20px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'solid',
          colors: ['#51D6FC'],
          strokeWidth: '10%',
        },

        labels: ['Reject'],
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                offsetY: 0,
                offsetX: 0,
              },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                  },
                },
              },
            },
          },
        ],
      },
      series: [rejectSeriesValue],
    };

    const acceptAllData = roundOprions.find(option => option.key === 'accept_all');
    const acceptAllValue =
      parseFloat(acceptAllData?.option?.options?.labels[0].replace('%', '')) || 0;
    const radialBar2 = {
      options: {
        chart: {
          id: 'half-radial-bar',
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '75%',
              background: '#fff',
            },

            track: {
              background: '#ebebeb',
              strokeWidth: '100%',
              margin: 0,
            },
            dataLabels: {
              name: {
                offsetY: -10,
                show: true,
                fontSize: '16px',
                color: '#659c34',
              },
              value: {
                offsetY: -50,
                color: '#659c34',
                fontSize: '20px',
                show: true,
              },
            },
          },
        },

        fill: {
          type: 'solid',
          colors: ['#659c34'],
          strokeWidth: '10%',
        },
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                offsetY: 0,
                offsetX: 0,
              },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                  },
                },
              },
            },
          },
        ],
        labels: ['Accept all'],
      },
      series: [acceptAllValue],

      stroke: {
        lineCap: 'round',
      },

      dataLabels: {
        enabled: true,
      },
      tooltip: {
        enabled: false,
      },
    };

    return (
      <Fragment>
        <div className='search-form'>
          {cpToken && calendarOpen && (
            <Fragment>
              <DateRangePicker
                ranges={[selectionRange]}
                moveRangeOnFirstSelection={false}
                onChange={this.handleDateRangeChange}
                dateDisplayFormat={'yyyy-MM-dd'}
                direction='horizontal'
                months={2}
                scroll={{ enabled: false }}
                editableDateInputs={true}
              />
              <button type='button' className='search-close-button' onClick={this.toggleCalendar}>
                x
              </button>
            </Fragment>
          )}

          {cpToken && (
            <>
              <div className='search-input'>
                <input
                  type='text'
                  className='search-input-field'
                  value={displayDateFrom + ' - ' + displayDateTo}
                  onChange={this.dummyChangeDate}
                  onClick={this.toggleCalendar}
                />
                <div className='search-input-label'>
                  <label>{t('.date')}</label>
                </div>
              </div>
            </>
          )}

          {cpToken && (
            <div className='search-input'>
              <input
                type='text'
                onClick={this.closeCalendar}
                className='search-input-field'
                value={this.state.uuid}
                onChange={e => {
                  this.handleUuidChange(e);
                }}
                onKeyDown={this.handleKeyDown}
              />
              <div className='search-input-label'>
                <label>{t('.uuid')}</label>
              </div>
            </div>
          )}

          {cpToken && (
            <div className='search-input'>
              <input
                type='text'
                onClick={this.closeCalendar}
                className='search-input-field'
                value={this.state.domainName}
                onChange={e => {
                  this.handleDomainNameChange(e);
                }}
                onKeyDown={this.handleKeyDown}
              />
              <div className='search-input-label'>
                <label>{t('.domain')}</label>
              </div>
            </div>
          )}

          {cpToken && (
            <div className='search-input'>
              <button
                type='button'
                className='btn btn_pill is-blue'
                onClick={() => {
                  this.searchConsent();
                  this.myTargetElementRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}
              >
                <AiOutlineSearch size='1.5rem' className='mr-05' />
                <span>{t('.search')}</span>
              </button>
            </div>
          )}
        </div>
        {cpToken && this.renderNavigation()}
        {cpToken && selectedTab === "traffic" &&
          (
            <>
              <div className='search-form'>
                <div className='search-input'>
                  <div className='align-center-domain domain-gap-15 margin-bottom-20' >
                    <span>
                      <img src={iframeIcon} style={{ width: '20px', height: '20px' }} alt='' />
                    </span>
                    {selectedLicense?.domainName ? selectedLicense?.domainName : ''}
                  </div>
                  <Button
                    className='btn btn_pill is-blue ' style={{ marginBottom: 20 }}
                    published onClick={() => {
                      this.fetchConsentsChartApply();
                    }}>{t('.deafult_chart')}</Button>




                </div>

                <div className='search-input align-center-domain' style={{ justifyContent: 'end' }}>
                  <div className='align-center-domain domain-gap-15 '>
                    <span style={{ width: '20px', height: '20px', background: '#c9aac6' }}></span>
                    {t('.total_number_click')}: {totalValueSum}
                  </div>
                </div>
              </div>

              <div>
                <Chart
                  options={options?.options}
                  series={options?.series}
                  type='line'
                  width={'100%'}
                  height={280}
                />
                <div className='circle-container ' style={{ marginBottom: '40px' }}>
                  {roundOprions.map(roundOption => {
                    return (
                      <div className='text-align-center'>
                        <div className='high-contrast'>
                          <div className='high-contrast-content' style={{ display: 'inline-flex' }}>
                            {roundOption?.labelButton}
                            <div className='features-set'>
                              <div className='checkbox-wrapper'>
                                <input
                                  name={roundOption?.key}
                                  type='checkbox'
                                  id={roundOption?.key}
                                  checked={chartCheckedValueSet[roundOption?.key]}
                                  onChange={e => this.handleClickFilterChart(e, roundOption?.key)}
                                />
                                <label htmlFor={roundOption?.key}>Toggle</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0px',
                }}
              >
                <div style={{ boxShadow: '0px 0px 10px rgb(189 189 189)', margin: '10px' }}>
                  <Chart
                    options={{
                      grid: {
                        show: false,
                      },
                      ...barChart?.options,
                    }}
                    series={barChart?.series}
                    type='bar'
                    width='100%'
                    height='400'
                  />
                </div>
                <div style={{ boxShadow: '0px 0px 10px rgb(189 189 189)', margin: '10px', paddingTop: "50px", height: '415px' }}>
                  <Chart
                    options={DonutChart?.options}
                    series={DonutChart.options.series}
                    type='donut'
                    width='400'
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ boxShadow: '0px 0px 10px rgb(189 189 189)', margin: '10px', paddingTop: "50px", height: '415px' }}>
                    <Chart
                      options={radialBar2?.options}
                      series={radialBar2?.series}
                      type='radialBar'
                      height='280'
                    />
                    <div style={{ paddingTop: "50px" }}>
                      <Chart
                        options={radialBar?.options}
                        series={radialBar?.series}
                        type='radialBar'
                        height='280'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        {cpToken && consents && !isConsentsLoading && selectedTab === 'report' && (
          <>
            <div style={{ position: 'relative' }}>
              {!this.props?.allowExport ? (
                <div style={{ position: 'absolute', right: 0, top: '13px', cursor: 'pointer' }} onClick={() => {
                  const { loadModal, selectedLicense } = this.props;
                  loadModal({
                    modalType: 'access_limit_user_model',
                    content: {
                      selectedLicense: selectedLicense
                    },
                    title: '',
                    t,
                  })
                }}>
                  <img src={export_buttton} alt='' />
                </div>
              ) : null}
              {!this.props?.allowExport ? (
                <div style={{ position: 'absolute', right: "35px", top: '13px', cursor: 'pointer' }} onClick={() => {
                  const { loadModal, selectedLicense } = this.props;
                  loadModal({
                    modalType: 'access_limit_user_model',
                    content: {
                      selectedLicense: selectedLicense

                    },
                    title: '',
                    t,
                  })
                }}>
                  <img src={export_print} alt='' />
                </div>
              ) : null}

              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField='consentDate'
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                  onRowClicked={this.consentDatabaseEntryHandler}
                />
              </DataTableExtensions>
            </div>

            <CopyMessage showCopiedMessage={this.state.rowCopied} />
          </>
        )}

        {isConsentsLoading && (
          <div className='row'>
            <div className='column'>
              <Loading />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const SEARCH_CONSENT_BY_UUID = gql`
  query($cpToken: String!, $uuid: String!) {
    consents(cpToken: $cpToken, uuid: $uuid) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;

const SEARCH_CONSENT_BY_DOMAIN_NAME = gql`
  query($cpToken: String!, $domainName: String!) {
    consents(cpToken: $cpToken, domainName: $domainName) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;

const CONSENT_CHARTS = gql`
query ($cpToken: String!, $consentDateFrom: String!, $consentDateTo: String!) {
    consentCharts(cpToken: $cpToken , consentDateFrom: $consentDateFrom, consentDateTo: $consentDateTo) {
        data
        buttonData
    }
}
`;

const SEARCH_CONSENT_BY_DATE = gql`
  query($cpToken: String!, $consentDateFrom: String!, $consentDateTo: String!) {
    consents(cpToken: $cpToken, consentDateFrom: $consentDateFrom, consentDateTo: $consentDateTo) {
      uuid
      consentDate
      domainName
      button
      necessary
      functional
      marketing
      statistic
    }
  }
`;
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}
export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withTranslation('consent_database'),
)(SearchConsentDatabase);
