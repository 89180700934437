import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';

const WizardForm = ({ options, match: { params }, policyType }) => {
  return (
    <div className='wizard__form'>
      {options.map(({ isComplete, icon, id }) => (
        <div className={`wizard__item ${isComplete ? 'complete' : ''}`} key={id}>
          <Link to={`/${policyType}-policies/${params.id}/categories/${id}`}>
            <div className='wizard__completion'>
              <i className={`fas fa-${isComplete ? 'check' : 'times'}`} />
            </div>
          </Link>
          <div className='wizard__icon'>
            <img src={icon} alt={icon} />
          </div>
        </div>
      ))}
    </div>
  );
};

WizardForm.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      isComplete: PropTypes.bool.isRequired,
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default compose(withRouter)(WizardForm);
