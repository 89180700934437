import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import { withNotifications } from '../../../providers/notifications'
import Button from 'src/components/Button'

const AccessLimitUserModel = ({
  title,
  closeExampleModal,
  content: { licenseId, hasLinkedPolicy, selectedLicense },
  t,
  client,
  notificationsProvider,
  history,
}) => {

  useEffect(() => {
  }, [])

  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'>{t('.access_functionality_unavailable_header')}</h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='modal_content'>
        {selectedLicense && selectedLicense?.subscriptionId && selectedLicense?.id ?
          t('.access_functionality_unavailable_body')

          : t('.support_to_admin')
        }
        <div className='list_buttons'>
          <Button
            className='btn btn_pill is-outlined is-blue'
            published
            onClick={closeExampleModal}
          >
            {t('.ok')}
          </Button>
          {selectedLicense && selectedLicense?.subscriptionId && selectedLicense?.id ? (
            <Button
              className='btn btn_pill is-blue'
              published
              onClick={() => {
                closeExampleModal()
                // history.push(`/account`)
                history.push(`/change-licenses/${selectedLicense?.subscriptionId}/${selectedLicense?.id}`)
              }}
            >
              {/* {t('.Account_settings')} */}
              {t('.take_me_to_my_license_details')}
            </Button>
          ) :

            null}

        </div>
      </div>

    </Modal>
  )
}

AccessLimitUserModel.propTypes = {
  title: PropTypes.string.isRequired,
  closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  closeExampleModal: hideModal,
}
export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withNotifications(),
  withTranslation('dashboard'),
)(AccessLimitUserModel)
