import { SET_USER_EMAIL } from '../../../redux/types.js'

const setUserEmail = userEmail => dispatch => {
  return dispatch({
    type: SET_USER_EMAIL,
    payload: {
      userEmail,
    },
  })
}

export default setUserEmail;
