import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { withTranslation } from '../../../providers/I18n/';
import { withNotifications } from '../../../providers/notifications/';
import { withApollo } from 'react-apollo';
import { TitleComponent } from '../../../providers/pageTitles/TitleComponent';
import { Queries } from '../../UnAuthenticatedForm/submit_queries';
import UserRegisterForm from './onboarding_form.jsx';
import setUserEmail from './actions.js';
import authenticateUserAction from '../../../components/Login/actions.js';

const UserOnboarding = ({
  t,
  client,
  history,
  notificationsProvider,
  location,
  email,
  intializeEmail,
}) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    client
      .query({
        query: Queries.license_user_by_token,
        fetchPolicy: 'network-only',
        variables: {
          token,
        },
      })
      .then(({ data }) => {
        const {
          licenseUserByToken: { userEmail, isUserRegister, confirmationCode },
        } = data;
        if (isUserRegister) history.push(`/policy?token=${confirmationCode}`);
        const encodedEmail = encodeURIComponent(userEmail).replace('%20', '+');
        const email = decodeURIComponent(encodedEmail);
        intializeEmail(email);
        setRegisterInfo({ ...registerInfo, token, email });
        email && setIsReadOnly(true);
      });
  }, []);

  const [registerInfo, setRegisterInfo] = useState({ email: '', password: '', token: '' });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = e => {
    const {
      target: { value, name },
    } = e;
    setRegisterInfo({ ...registerInfo, [name]: value });
  };

  return (
    <div className='row collapse align-bottom align-justify margin-bottom-40'>
      <TitleComponent title={t('.account_confirmation')} />
      <p className='onboarding_message'>{t('.welcome_text')} </p>
      <UserRegisterForm
        t={t}
        registerInfo={registerInfo}
        handleChange={handleChange}
        isReadOnly={isReadOnly}
        isError={isError}
        errorMessage={errorMessage}
        token={registerInfo.token}
      />
    </div>
  );
};

const mapDispatchToProps = {
  intializeEmail: setUserEmail,
  authenticateUserAction,
};

const SIGN_UP_MUTATION = gql`
  mutation register_user($email: String!, $password: String!, $token: String!) {
    registerUser(email: $email, password: $password, token: $token) {
      token
      user {
        id
      }
    }
  }
`;

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withApollo,
  withNotifications(),
  withTranslation('unAuthenticatedForm'),
)(UserOnboarding);
