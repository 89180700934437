import { countries, lookup, languages } from 'country-data';

function getIsoCode(country) {
  let alpha2 = lookup.countries({ name: country })[0].alpha2;
  return alpha2.toLowerCase();
}

function getListOfCountries() {
  let listOfCountries = countries.all;

  return listOfCountries.map(country => {
    return {
      name: country.name,
      value: country.alpha2,
    };
  });
}

function getListOfLanguages() {
  return languages.all;
}

function getLanguageName(iso) {
  return languages.all.find(lang => {
    return lang.alpha2 === iso;
  });
}

function getNameWithIsoCode(isoCode) {
  let isoCodeUppercase = isoCode.toUpperCase();
  return countries[isoCodeUppercase].name;
}

export { getIsoCode, getListOfCountries, getListOfLanguages, getLanguageName, getNameWithIsoCode };
