/* eslint no-console: off */
import React, { Fragment } from 'react';

import DraftCategory from './draft';
import PublishedCategory from './publish';

const PrivacyPolicyCategory = ({ ppStatus, ...props }) => (
  <Fragment>
    {ppStatus !== 'published' ? <DraftCategory {...props} /> : <PublishedCategory {...props} />}
  </Fragment>
);

export default PrivacyPolicyCategory;
