import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { formattedDate } from '../../helpers/date';
import Button from '../Button';

const VersionCard = ({ version, publishedDate, archivedDate, policyType, id, t }) => (
  <div className='version_card'>
    <div className='card_element'>
      <div className='element_icon'>
        <i className='far fa-file-excel' />
      </div>
      <div className='element_info'>
        <h6> {t('.version')} : </h6>
        <p> {version} </p>
      </div>
    </div>
    <div className='card_element'>
      <div className='element_icon'>
        <i className='far fa-calendar-check' />
      </div>
      <div className='element_info'>
        <h6> {t('.publication')} : </h6>
        <p> {formattedDate(publishedDate)} </p>
      </div>
    </div>
    <div className='card_element'>
      <div className='element_icon'>
        <i className='far fa-calendar-times' />
      </div>
      <div className='element_info'>
        <h6> {t('.archiving')} : </h6>
        <p> {archivedDate ? formattedDate(archivedDate) : '-'} </p>
      </div>
    </div>
    <div className='card_element'>
      <div className='element_icon'>
        <i className='far fa-eye' />
      </div>
      <div className='element_info'>
        <Link to={`/${policyType}-policies/${id}/preview/${version}`}>
          <Button className='btn btn_pill is-blue is-outlined' small>
            {t('.see_policy')}
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

VersionCard.propTypes = {
  version: PropTypes.number.isRequired,
  publishedDate: PropTypes.string.isRequired,
  archivedDate: PropTypes.string,
  getPolicyPreview: PropTypes.func,
};

VersionCard.defaultProps = {
  archivedDate: null,
};

export default VersionCard;
