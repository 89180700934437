import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

export const renderField = ({ input, label, type, example, display, meta: { touched, error } }) => (
  <div className={`text_area ${display ? '' : 'hidden'}`}>
    <textarea {...input} />
    <label>{label}</label>
    {touched && error && <span>{error}</span>}
  </div>
);

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      defaultLocale: 'fr',
      selectedLocale: null,
    };
  }

  componentWillMount() {
    const { locales, options } = this.props;

    let isDefaultInLocales = false;

    locales.map(locale => {
      if (locale === this.state.defaultLocale) {
        this.setState({ selectedLocale: locale });
        isDefaultInLocales = true;
      }
    });

    const values = locales.map((locale, i) => {
      let iso = locale;
      if (!isDefaultInLocales) {
        this.setState({ selectedLocale: iso });
      }
      let findValue = options[0]?.value?.find(value => value[iso]);
      return findValue ? findValue : { [iso]: '' };
    });

    this.setState({ values: values }, () => this.props.onChange(values));
  }

  handleChange = event => {
    const { value } = event.target;

    const { selectedLocale, values } = this.state;

    const valueToSend = values.map(val => {
      return selectedLocale in val ? { [selectedLocale]: value } : val;
    });

    this.setState({ values: valueToSend }, () => this.props.onChange(this.state.values));
  };

  onSelectLocale = locale => {
    this.setState({ selectedLocale: locale });
  };

  renderLocale = () => {
    const { locales } = this.props;
    const { selectedLocale } = this.state;
    return (
      <ul className='form_locales__list'>
        {locales.map(locale => (
          <li
            key={locale}
            className={`form_locales__item ${selectedLocale === locale ? 'active' : ''}`}
            onClick={() => this.onSelectLocale(locale)}
          >
            {locale.toUpperCase()}
          </li>
        ))}
      </ul>
    );
  };

  findLocaleValue = locale => {
    let localeValue = '';
    this.state.values.map(obj => {
      if (obj.hasOwnProperty(locale)) {
        localeValue = obj[locale];
      }
    });
    return localeValue;
  };

  renderFields = option => {
    const { locales, index } = this.props;
    const { selectedLocale, values } = this.state;
    return (
      <div>
        {locales.map(locale => (
          <div className={`text_area ${locale === selectedLocale ? '' : 'hidden'}`}>
            <textarea
              key={locale}
              name={`${index}_dataX_${locale}`}
              onChange={this.handleChange}
              defaultValue={this.findLocaleValue(locale)}
            />
            <label>{option.label}</label>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { options, locales } = this.props;
    return (
      <div className='text-form'>
        {this.renderLocale(locales)}
        {this.renderFields(options[0])}
      </div>
    );
  }
}

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
};

export default TextArea;
