import { SET_USER_EMAIL } from './types'

const INITIAL_STATE = {
  userEmail: '',
}

const userFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload.userEmail,
      }

    default:
      return state
  }
}

export default userFormReducer
