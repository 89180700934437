/* eslint no-console: off */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { compose } from 'recompose';
import withApollo from 'react-apollo/withApollo';

import AuthWrapper from '../../components/Auth';
import CategoryQuestionExplanation from './explanation';
import PrivacyPolicyQuestionEdit from './edit';
import Loading from '../../components/Loading';
import { withTranslation } from '../../providers/I18n';
import BannerCustomisation from '../CookiePolicies/BannerCustomisation';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class PrivacyPolicyCategoryQuestion extends React.Component {
  constructor() {
    super();
    this.state = {
      policyType: 'privacy',
      loaded: false,
    };
  }

  componentDidMount() {
    const { match, history } = this.props;
    const {
      params: { id, categoryId },
    } = match;
    const locale = localStorage.getItem('locale');

    this.setState({ policyType: this.isCookiePolicy(match.url) ? 'cookie' : 'privacy' }, () => {
      this.fetchQuestionById().then(({ data }) => {
        const {
          fetchQuestionById: { localeKey, referenceNumber },
        } = data;
        if (locale !== localeKey) {
          this.fetchQuestionByLocale(referenceNumber, locale).then(({ data }) => {
            const { fetchQuestionByLocale } = data;
            history.replace(
              `/${this.state.policyType}-policies/${id}/categories/${categoryId}/questions/${fetchQuestionByLocale.id}`,
            );
          });
        }
        this.setState({
          loaded: true,
        });
      });
    });
  }

  fetchQuestionById = () => {
    const {
      client,
      match: {
        params: { questionId, id },
      },
    } = this.props;
    const { policyType } = this.state;

    return client.query({
      query: QUESTION_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: questionId,
        ppId: id,
        type: policyType,
      },
    });
  };

  fetchQuestionByLocale = (ref, locale) => {
    const { client } = this.props;
    return client.query({
      query: QUESTION_BY_LOCALE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        ref,
        locale,
      },
    });
  };

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  render() {
    const {
      match: {
        params: { questionId, categoryId, id },
      },
      t,
    } = this.props;
    const { policyType, loaded } = this.state;
    return (
      <AuthWrapper>
        {loaded && (
          <Query
            query={QUESTION_BY_ID_QUERY}
            fetchPolicy={'network-only'}
            variables={{ id: questionId, ppId: id, type: policyType }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;
              const { fetchQuestionById, privacyPolicy } = data;
              //console.log(fetchQuestionById)
              const { mandatoryChoices } = fetchQuestionById;
              const ppName = localStorage.getItem('ppName');

              let url = `/${policyType}-policies/${id}`;
              url =
                fetchQuestionById.type === 'cookie_configurator'
                  ? url
                  : `${url}/categories/${categoryId}`;

              return (
                <div className='question_main row'>
                  <TitleComponent title={ppName + ' | ' + fetchQuestionById.statement} />
                  <div className='columns category'>
                    <div>
                      <h6> {t(`.${policyType}_policy`)}</h6>
                      <h1 className='policy_title'> {ppName} </h1>
                    </div>
                    <div className='arrow_title'>
                      <Link to={url}>
                        <i className='fas fa-arrow-left margin-right-20' />
                      </Link>
                      <h2>
                        {fetchQuestionById.statement}{' '}
                        {mandatoryChoices && <span style={{ color: 'red' }}> * </span>}
                      </h2>
                    </div>
                    <CategoryQuestionExplanation
                      question={fetchQuestionById}
                      availableCountries={privacyPolicy.countries.map(country => country.isoCode)}
                    />
                    {fetchQuestionById.type === 'cookie_configurator' ? (
                      <BannerCustomisation question={fetchQuestionById} pp={privacyPolicy} />
                    ) : (
                      <PrivacyPolicyQuestionEdit
                        question={fetchQuestionById}
                        pp={privacyPolicy}
                        policyType={policyType}
                      />
                    )}
                  </div>
                </div>
              );
            }}
          </Query>
        )}
      </AuthWrapper>
    );
  }
}

const QUESTION_BY_ID_QUERY = gql`
  query fetchQuestionById($id: ID!, $ppId: ID!, $type: String) {
    fetchQuestionById(id: $id, policyId: $ppId, policyType: $type) {
      id
      type
      readOnly
      choices
      localeKey
      statement
      referenceNumber
      example
      exampleExt
      explanation
      explanationExt
      warningMessage
      warningMessageExt
      validationWarningMessage
      mandatoryChoices
      answer(policyId: $ppId) {
        id
        value
      }
    }
    privacyPolicy(id: $ppId) {
      id
      title
      cookies
      cookieUrl
      cookiesType
      cookiesByType
      parentId
      cookiePolicy {
        id
      }
      locales {
        isoCode
      }
      countries {
        isoCode
      }
    }
  }
`;

const QUESTION_BY_LOCALE_QUERY = gql`
  query fetchQuestion($ref: String!, $locale: String!) {
    fetchQuestionByLocale(referenceNumber: $ref, locale: $locale) {
      id
    }
  }
`;

PrivacyPolicyCategoryQuestion.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}),
};

PrivacyPolicyCategoryQuestion.defaultProps = {
  history: {},
};

export default compose(
  withRouter,
  withApollo,
  withTranslation('question'),
)(PrivacyPolicyCategoryQuestion);
