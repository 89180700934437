import React, { useState } from 'react';

import HTMLPreview from './HTMLPreview';
import PDFPreview from './PDFPreview';
import DropDown from '../../containers/Onboarding/CompanyOnboarding/dropdown_lang';
import { hideModal, loadModal } from 'src/redux/modal/action';
import { connect } from 'react-redux'
import { compose } from 'recompose'

const PreviewContainer = ({
  preview,
  previews,
  previewLoaded,
  locales,
  hasPDFAccess,
  hasBothPolicies,
  handlePolicyChange,
  cookieAccessible,
  privacyAccessible,
  policyType,
  policySelected,
  loadModal,
  packageData,
  t,
}) => {
  const [selectedLocale, switchLocale] = useState(locales[`${policySelected}_policy`][0]);
  const [selectedFormat, toggleFormat] = useState('online');
  const generateLocaleOptions = () => {
    return locales[`${policySelected}_policy`].map(locale => ({
      value: locale,
      label: t(`.${locale}`),
    }));
  };

  const safePreview =
    typeof preview.htmlPreview[selectedLocale] === 'undefined'
      ? preview.htmlPreview[locales[0]]
      : preview.htmlPreview[selectedLocale];

  return (
    <div className='preview_container'>
      {hasBothPolicies && (
        <ul className='preview__policies'>
          <li
            onClick={() => {
              privacyAccessible && handlePolicyChange('privacy');
              switchLocale(locales[`${policySelected}_policy`][0]);
            }}
            className={`preview__policies--item ${policySelected === 'privacy' &&
              'active'} ${!privacyAccessible && 'disabled'}`}
          >
            {t('.privacy_policy')} ({t(`.${previews['privacy'].status}`)})
          </li>
          <li
            onClick={() => {
              cookieAccessible && handlePolicyChange('cookie');
              switchLocale(locales[`${policySelected}_policy`][0]);
            }}
            className={`preview__policies--item ${policySelected === 'cookie' &&
              'active'} ${!cookieAccessible && 'disabled'}`}
          >
            {t('.cookie_policy')} ({t(`.${previews['cookie'].status}`)})
          </li>
        </ul>
      )}
      <div className='preview__header'>
        <ul className='preview__types'>
          <li
            className={`preview__link ${selectedFormat === 'online' && 'active'}`}
            onClick={() => {
              toggleFormat('online');
            }}
          >
            {' '}
            {t('.online_preview')}{' '}
          </li>
          <li
            className={`preview__link ${selectedFormat === 'pdf' && 'active'}`}
            onClick={() => {
              if (hasPDFAccess) {
                toggleFormat('pdf');
              }
              else {
                loadModal({
                  modalType: 'access_limit_user_model',
                  content: {
                    selectedLicense: packageData
                  },
                  title: '',
                  t,
                })
              }
            }}
          >
            {' '}
            {t('.pdf_preview')}{' '}
          </li>
        </ul>
        <DropDown
          options={generateLocaleOptions()}
          handleChange={e => switchLocale(e.target.value)}
          size='small'
          className='margin-right-20'
          isPill
          value={selectedLocale}
          name={'language'}
        />
      </div>
      {previewLoaded && (
        <div className='preview'>
          {selectedFormat === 'online' && <HTMLPreview preview={safePreview} />}
          {selectedFormat === 'pdf' && (
            <PDFPreview preview={preview.pdfLink} selectedLocale={selectedLocale} />
          )}
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(
  connect(null, mapDispatchToProps),
)(PreviewContainer)

