import React, { useState, useEffect, Fragment } from 'react'
import { AiOutlineWarning } from 'react-icons/ai'
import Select from 'react-select'
import './App.scss'
const PolicyField = ({
  values,
  allPrivacyPolicies,
  handleChange,
  handleLinkToPolicy,
  formType,
  t,
  cpId,
  cpUrl,
  linkedCpId,
  pp,
  style,
  domainName,
  cpLinkAlert
}) => {
  const HAS_CP_OPTIONS = [
    {
      id: 'has_cp-true',
      name: 'has_cp',
      value: 'true',
      label: t('.yes'),
    },
    {
      id: 'has_cp-false',
      name: 'has_cp',
      value: 'false',
      label: t('.no'),
    },
  ]

  const ORIGIN_CP_OPTIONS = [
    {
      id: 'cp_origin-true',
      name: 'cp_origin',
      value: 'true',
      label: t('.yes'),
    },
    {
      id: 'cp_origin-false',
      name: 'cp_origin',
      value: 'false',
      label: t('.no'),
    },
  ]

  const [availableOptions, setAvailableOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [hasCookiePolicy, togglePoliciesOptions] = useState(cpId || cpUrl ? 'true' : 'false')
  const [isCookiePolicyInternal, setCookiePolicyOrigin] = useState(cpUrl ? 'false' : 'true')
  const hasAlreadyCpLinked = !!cpId
  const disableCpRadios = linkedCpId ? true : false

  const [warningDirtyPolicy, setDirtyPolicy] = useState(false)

  useEffect(() => {
    setInitialState()
  }, [])

  const setInitialState = () => {
    let availableOptions = allPrivacyPolicies
      .filter(
        policy =>
          (!policy.parentId &&
            !hasAlreadyCpLinked &&
            (policy.status === 'draft' || policy.status === 'published')) ||
          hasAlreadyCpLinked,
      )
      .map(({ id: value, title: label, license }) => {
        const { domainName } = license
        const option = { value, label, domainName }

        if (value === cpId) {
          setSelectedOption(option)
        }

        return option
      })
    setAvailableOptions(availableOptions)
  }

  const onPolicyLinkChange = selectedPolicy => {
    setSelectedOption(selectedPolicy)
    handleLinkToPolicy(selectedPolicy)

    let policyObject = allPrivacyPolicies.filter(pp => pp.id === selectedPolicy.value)[0]

    if (policyObject.anyAnswers && formType === 'edit') {
      setDirtyPolicy(true)
    } else {
      setDirtyPolicy(false)
    }
    handleChange({ target: { value: selectedPolicy.domainName, name: 'linkExistingPolicies' } })
  }

  const setPoliciesOptions = isCpLinked => {
    if (isCpLinked === 'false') {
      handleChange({ target: { value: null, name: 'cpUrl' } })
      handleChange({ target: { value: null, name: 'cpId' } })
      handleChange({ target: { value: false, name: 'isCpLinked' } })
      setSelectedOption(null)
    } else {
      handleChange({ target: { value: true, name: 'isCpLinked' } })
    }

    togglePoliciesOptions(isCpLinked)
  }

  const resetCpSelect = (name, value) => {
    if (name === 'cp_origin') {
      if (value === 'false') {
        handleChange({ target: { value: null, name: 'cpId' } })
        handleChange({ target: { value: null, name: 'cpUrl' } })
        setSelectedOption(null)
        handleChange({ target: { value: null, name: 'linkExistingPolicies' } })
        handleChange({ target: { value: false, name: 'cp_origin' } })

      } else {
        handleChange({ target: { value: null, name: 'cpUrl' } })
        handleChange({ target: { value: null, name: 'linkExistingPolicies' } })
        handleChange({ target: { value: true, name: 'cp_origin' } })

      }
    }
  }

  const renderRadioInput = (option, value, handleChange) => {
    return (
      <div key={option.value} className='radio_input'>
        <input
          id={option.id}
          type='radio'
          value={option.value}
          name={option.name}
          disabled={disableCpRadios}
          checked={option.value === value}
          onChange={e => {
            handleChange(e.target.value)
            resetCpSelect(option.name, e.target.value)
          }}
        />
        <label htmlFor={option.id}>
          {option.label}
          {option.example && <p className='input_example'> {option.example} </p>}
        </label>
      </div>
    )
  }

  return (
    <Fragment>
      <h6 style={{ color: '#404040' }}> {t('.want_to_link')} </h6>
      <div className='radio-options left_radio'>
        {HAS_CP_OPTIONS.map(option =>
          renderRadioInput(option, hasCookiePolicy, setPoliciesOptions),
        )}
      </div>

      {hasCookiePolicy === 'true' && (
        <Fragment>
          <h6 style={{ color: '#404040' }}> {t('.is_link_from_app')} </h6>
          <div className='radio-options left_radio'>
            {ORIGIN_CP_OPTIONS.map(option =>
              renderRadioInput(option, isCookiePolicyInternal, setCookiePolicyOrigin),
            )}
          </div>
        </Fragment>
      )}

      {hasCookiePolicy === 'true' && isCookiePolicyInternal === 'true' && (
        <Fragment>
          <h6 style={{ color: '#404040' }}> {t('.choose_policy')} </h6>
          {availableOptions.length > 0 || selectedOption ? (
            <Select
              value={selectedOption}
              classNamePrefix={'locale'}
              options={availableOptions}
              onChange={data => onPolicyLinkChange(data)}
              isDisabled={linkedCpId}
            />
          ) : (
            <p className='warning_message margin-top-10'>
              <AiOutlineWarning size='1.5rem' className='mr-05' />{' '}
              <span>{t('.no_policy_warning')}</span>
            </p>
          )}
          {cpUrl && (
            <Fragment>
              <h6 style={{ color: '#404040', marginTop: '15px' }}>Cookie policy domain </h6>
              <input type='text' name='cpUrl' value={cpUrl} style={style} readOnly />
            </Fragment>
          )}

          <p className='warning_message margin-top-10'>
            <AiOutlineWarning size='1.5rem' className='mr-05' />{' '}
            <span>{t('.create_cp_first')}</span>
          </p>
          <p className='error_color margin-top-10'>
            <AiOutlineWarning size='1.5rem' className='mr-05' /> <span>{t('.no_change_link')}</span>
          </p>
          <p className='error_color margin-top-10'>
            <AiOutlineWarning size='1.5rem' className='mr-05' />{' '}
            <span>{t('.linking_policies_module_warning')}</span>
          </p>
          {warningDirtyPolicy && (
            <p className='error_color margin-top-10'>
              <AiOutlineWarning size='1.5rem' className='mr-05' />{' '}
              <span>{t('.dirty_policy_message')}</span>
            </p>
          )}
        </Fragment>
      )}

      {hasCookiePolicy === 'true' && isCookiePolicyInternal === 'false' && (
        <Fragment>
          <div className='text-form_url'>
            <h6 style={{ color: '#404040' }}> {t('.enter_url')} </h6>

            <input
              type='text'
              name='cpUrl'
              onChange={handleChange}
              defaultValue={cpUrl}
              style={style}
              className='input_url'
            />
            {cpLinkAlert && (
              <div className='input_error_url'>{t('.url_not_valid')}</div>
            )}
          </div>

        </Fragment>
      )}
    </Fragment>
  )
}

export default PolicyField
