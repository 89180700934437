import React, { Fragment } from 'react';
import update from 'immutability-helper';

import CookieFormItem from './form_item';
import Button from '../Button';
import { withTranslation } from '../../providers/I18n';

class ScannedCookieForm extends React.Component {
  constructor(props) {
    super(props);
    this.deleteCookie = this.deleteCookie.bind(this);
    this.state = {
      maxId: 0,
      invalid: true,
    };
  }

  componentWillMount() {
    const { cookies_scanned, cookies_validate } = this.props;

    let cookieValidate = cookies_validate;
    let cookieScannedParsed = [];
    if (cookies_scanned) {
      if (cookies_scanned.hasOwnProperty('results')) {
        try {
          cookieScannedParsed = JSON.parse(cookies_scanned['results']);
        } catch (e) {
          cookieScannedParsed = cookies_scanned['results'];
        }
      } else {
        try {
          cookieScannedParsed = JSON.parse(cookies_scanned);
        } catch (e) {
          cookieScannedParsed = [];
        }
      }
    }
    let cookieList;

    let notAnswered = cookies_validate === undefined || cookies_validate.length === 0;

    if (notAnswered) {
      let cookieIndex = 0;

      cookieList = cookieScannedParsed.map((scanned, index) => {
        let cookieId = typeof scanned.id === 'undefined' ? index : scanned.id;

        let cookie_formated = {
          id: cookieId,
          domain: scanned.domain,
          name: scanned.name,
          session: scanned.session,
          validate: false,
        };

        if (scanned.session) cookie_formated['expDateFormat'] = 'session';

        cookieIndex = index;

        return cookie_formated;
      });

      cookieList.push({
        id: cookieIndex + 1,
        domain: 'Admeet',
        name: 'admeet-consent',
        session: false,
        validate: false,
        type: 'third_party',
        expDateFormat: 'month',
        lifespanTime: '6',
        technology: 'other',
        category: 'necessary',
        purpose: 'management',
      });
    } else {
      cookieList = cookies_validate.map((validate, index) => {
        if (typeof validate.id === 'undefined') {
          validate.id = index;
        }

        return validate;
      });
    }

    // console.log(cookieList)

    let max = 0;

    cookieList.forEach(item => {
      if (item.id > max) {
        max = item.id;
      }
    });

    this.setState({ cookieList, cookieValidate, maxId: max }, () => {
      this.props.onChange(cookieValidate);
    });

    this.validateForm(cookieList);
  }

  removeByKeyValue = (array, key, value) => {
    const index = array.findIndex(obj => obj[key] === value);
    return index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array;
  };

  deleteCookie = id => {
    const { t } = this.props;

    let cookieList = this.state.cookieList;
    let cookieValidate = this.state.cookieValidate;

    let confirm = window.confirm(t('.remove_cookie_confirm'));
    if (confirm === true) {
      cookieValidate = this.removeByKeyValue(cookieValidate, 'id', id);
      cookieList = this.removeByKeyValue(cookieList, 'id', id);
    }

    this.setState(
      {
        cookieList,
        cookieValidate,
      },
      () => {
        this.props.onChange(cookieValidate);
      },
    );

    this.validateForm(cookieList);
  };

  addCookie = () => {
    let newMaxId = this.state.maxId + 1;

    let newCookie = {
      id: newMaxId,
      domain: '',
      name: '',
      session: false,
      validate: false,
    };

    this.setState({
      maxId: newMaxId,
      cookieList: [...this.state.cookieList, newCookie],
    });

    this.validateForm([], true);
  };

  handleChange = (id, key, value) => {
    let cookieListState = this.state.cookieList;

    let toUpdateCookie = cookieListState.findIndex(function(c) {
      return c.id === id;
    });
    let updatedCookie = update(cookieListState[toUpdateCookie], {
      [key]: {
        $set: value,
      },
    });
    let cookieList = update(this.state.cookieList, {
      $splice: [[toUpdateCookie, 1, updatedCookie]],
    });

    let cookieValidate = this.state.cookieValidate;

    if (key === 'validate') {
      cookieValidate = value
        ? update(cookieValidate, { $push: [updatedCookie] })
        : this.removeByKeyValue(cookieValidate, 'id', id);
    }

    this.setState(
      {
        cookieList,
        cookieValidate,
      },
      () => {
        this.props.onChange(cookieValidate);
      },
    );

    this.validateForm(cookieList);
  };

  validateForm = (cookieList, addCookie = false) => {
    let invalid = false;

    // look for unvalidated cookies in the list
    cookieList.forEach(item => {
      if (item.validate === false) invalid = true;
    });

    // no cookies in the list
    if (cookieList.length === 0) invalid = true;

    // adding new cookie to the list
    if (addCookie) invalid = true;

    this.setState({ invalid: invalid });
  };

  render() {
    const { cookieList } = this.state;
    const { activeTab, locales, question, t, goBack, handleSubmit } = this.props;

    return (
      <Fragment>
        <div className={`cookies__list--content ${activeTab === 'tovalidate' ? '' : 'hidden'}`}>
          <div>
            {cookieList.map(
              cookie =>
                !cookie.validate && (
                  <CookieFormItem
                    key={cookie.id}
                    index={cookie.id}
                    option={cookie}
                    readOnly={true}
                    handleChange={this.handleChange}
                    locales={locales}
                    question={question}
                    removeCookie={this.deleteCookie}
                  />
                ),
            )}
          </div>
          <div>
            <Button className='btn btn_pill is-blue' published onClick={this.addCookie}>
              <i className='fas fa-plus margin-right-10' />
              {t('.add_cookie')}
            </Button>
          </div>
        </div>

        <div className={`cookies__list--content ${activeTab === 'validated' ? '' : 'hidden'}`}>
          {cookieList.map(
            cookie =>
              cookie.validate && (
                <CookieFormItem
                  key={cookie.id}
                  index={cookie.id}
                  option={cookie}
                  readOnly={true}
                  handleChange={this.handleChange}
                  locales={locales}
                  question={question}
                  removeCookie={this.deleteCookie}
                />
              ),
          )}
        </div>

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            disabled={this.state.invalid}
            onClick={handleSubmit}
          >
            {t('.validate')}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation('cookie_form')(ScannedCookieForm);
