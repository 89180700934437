import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import QuestionItem from './questionItem';
import { withTranslation } from '../../providers/I18n';

const QuestionGroup = ({
  match: {
    params: { categoryId, id },
  },
  ppStatus,
  policyType,
  locales,
  index,
  pp,
  t,
  ...group
}) => {
  const { questions } = group;

  return (
    <div className='category'>
      <h4>{`${index + 1}. ${group.name}`}</h4>
      {questions && questions.length > 0 ? (
        <div className='question_list'>
          {questions.map((question, index) => (
            <QuestionItem
              {...question}
              ppStatus={ppStatus}
              key={index}
              policyType={policyType}
              locales={locales}
              pp={pp}
              t={t}
            />
          ))}
        </div>
      ) : (
        <p> {t('.question_dependency')} </p>
      )}
    </div>
  );
};

QuestionGroup.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default compose(withRouter, withTranslation('category'))(QuestionGroup);
