import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import gql from 'graphql-tag'
import GraphQlCalls from '../../ProductPrivacyPolicyEdit/GraphQl/'

import Modal from '../../../components/Modal'
import UserCard from './user_card.jsx'
import EditModule from '../../ProductPrivacyPolicyEdit/edit_module.jsx'
import { AiOutlineWarning } from 'react-icons/ai'
import { hideModal, loadModal } from '../../../redux/modal/action'
import { withNotifications, NotificationModel } from '../../../providers/notifications'
import withTranslation from '../../../providers/I18n/HoC/t'

const AllUsersModal = ({
  title,
  closeExampleModal,
  content: { licenseId, hasLinkedPolicy, maxNoOfUsers, selectedLicense },
  t,
  client,
  notificationsProvider,
  history,
  loadModal
}) => {
  const [allUsers, setAllUsers] = useState([])
  const [userEmail, setUserEmail] = useState('')
  const [userEmailAlert, setUserEmailAlert] = useState(null)

  useEffect(() => {
    client
      .query({
        query: GET_USERS_ACCESS,
        fetchPolicy: 'network-only',
        variables: { licenseId },
      })
      .then(({ data }) => {
        const { licenseUsers } = data
        setAllUsers(licenseUsers)
      })
  }, [])

  const onChange = e => {
    setUserEmail(e.target.value)
    validateFormInputs(e.target.name, e.target.value)
  }

  const validateEmail = input => {
    const emailPattern = /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i
    return emailPattern.test(input)
  }

  const validateFormInputs = (type, value) => {
    let userEmailAlert
    userEmailAlert = type === 'userEmail' && !validateEmail(value)
    setUserEmailAlert(userEmailAlert)
  }

  const addUser = () => {
    if (maxNoOfUsers === 0 || (allUsers && maxNoOfUsers && allUsers.length >= maxNoOfUsers)) {
      loadUserModal();
    }
    else {
      client
        .mutate({
          mutation: GraphQlCalls.ADD_USER_TO_PRODUCT,
          variables: {
            licenseId,
            userEmail,
          },
        })
        .then(() => {
          closeExampleModal()

          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: t('.modification_success'),
            type: 'success',
          })
          notificationsProvider.add(notification)
        })
        .catch(error => {
          let errorMessage = t('.error_fill_all_required_fields')
          error.graphQLErrors.map(({ message, extensions }) => {
            if (extensions.errors) {
              errorMessage = extensions.errors[0].detail
              if (extensions.errors[0].origin === '/maximum_no_of_users') {
                loadUserModal();
              }
            }
          })

          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: errorMessage,
            type: 'alert',
          })
          notificationsProvider.add(notification)
        })
    }
  }
  const loadUserModal = () => {
    closeExampleModal();
    loadModal({
      modalType: 'access_limit_user_model',
      content: {
        selectedLicense: selectedLicense
      },
      title: '',
      t,
    })
  }
  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'>{title}</h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='modal_content'>
        <h5>Add user: </h5>
        <div className='add_user_container'>
          <input type='text' name='userEmail' value={userEmail} onChange={onChange} />
          <i className='fas fa-user-plus margin-left-20' onClick={addUser}></i>
        </div>
        {hasLinkedPolicy && (
          <div className='warning_message question_warning margin-top-10'>
            <AiOutlineWarning size='1.5rem' className='mr-05' />
            <span>{t('.linked_policies_warning')}</span>
          </div>
        )}
        {userEmailAlert && (
          <p className='error_color margin-top-10'>
            <AiOutlineWarning size='1.5rem' className='mr-05' />
            <span>{t('.error_invalid_email')}</span>
          </p>
        )}

        {allUsers && allUsers.length > 0 ? (
          allUsers.map(({ userEmail, id, isConfirmed }) => (
            <UserCard
              t={t}
              id={id}
              userEmail={userEmail}
              closeExampleModal={closeExampleModal}
              key={id}
              isConfirmed={isConfirmed}
              licenseId={licenseId}
            />
          ))
        ) : (
          <p>{t('.no_users_message')}</p>
        )}
      </div>
    </Modal>
  )
}

AllUsersModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

const GET_USERS_ACCESS = gql`
  query LicenseUsers($licenseId: ID!) {
    licenseUsers(licenseId: $licenseId) {
      id
      organizationId
      userEmail
      isConfirmed
    }
  }
`

export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withNotifications(),
  withTranslation('privacyPolicyEdit'),
)(AllUsersModal)
