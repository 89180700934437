/* eslint no-console: off */
/* eslint no-shadow: off */
import React from 'react';
import { AiOutlineLoading, AiOutlineLoading3Quarters } from 'react-icons/ai';
import loadingGIF from '../../assets/img/loading-gif2.gif';
import { withTranslation } from '../../providers/I18n';

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'image',
    };
    this.timeoutPeriod = 120000;
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ display: 'text' });
    }, this.timeoutPeriod);
  }

  render() {
    const { t } = this.props;

    return (
      <div className='row'>
        <div className='columns'>
          <div className='loading_screen'>
            {this.state.display === 'image' ? (
              <i className='loading-spinner'>
                <AiOutlineLoading3Quarters size='3rem' />
                <AiOutlineLoading size='3rem' />
              </i>
            ) : (
              <div style={{ paddingTop: '50px', marginBottom: '50px' }}>
                {t('.timeout_message')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('loading')(Loading);
