import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import MultipleRadioItem from './item';
import Button from '../../Button';

class RadioMultipleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderField = this.renderField.bind(this);

    this.state = {
      values: {},
      choices: props.choices,
      options: props.options,
      invalid: true,
    };
  }

  componentWillMount() {
    const { choices, value, onChange } = this.props;

    let valueState = {};
    choices.data.forEach(choice => {
      let key = Object.entries(choice);
      let name = key[0][0];

      valueState[name] = value[name] ? value[name] : '';
    });
    onChange([valueState]);

    let invalid = false;
    for (const [key, value] of Object.entries(valueState)) {
      if (value === '') invalid = true;
    }

    this.setState({ values: valueState, invalid: invalid });
  }

  handleChange(event) {
    const { onChange } = this.props;
    let valuesToPass = this.state.values;
    valuesToPass[event.target.name] = event.target.value;
    onChange([valuesToPass]);

    let invalid = false;
    for (const [key, value] of Object.entries(valuesToPass)) {
      if (value === '') invalid = true;
    }

    this.setState({ values: valuesToPass, invalid: invalid });
  }

  renderField(options, i) {
    const { values } = this.state;
    return (
      <div className='radio-options'>
        <Field
          key={i}
          component={({ input, options }) =>
            options.map(option => (
              <div key={option.value} className='radio_input'>
                <input
                  id={option.id}
                  type='radio'
                  {...input}
                  value={option.value}
                  name={option.name}
                  checked={values[option.name] && option.value === values[option.name]}
                  onChange={this.handleChange}
                />
                <label htmlFor={option.id}>{option.label}</label>
                {option.example && <p className='input_example'> {option.example} </p>}
              </div>
            ))
          }
          name={'test'}
          options={options}
        />
      </div>
    );
  }

  render() {
    const { choices, options, values } = this.state;
    const { t, goBack, handleSubmit } = this.props;
    return (
      <div>
        {options.map((opt, i) => {
          const objectKey = Object.keys(choices.data[i]);
          const obj = choices.data[i];
          return (
            <div key={i}>
              <h5> {obj[objectKey].name} </h5>
              <MultipleRadioItem
                t={t}
                index={i}
                options={opt}
                values={values}
                relatedKeys={obj[opt[0].name].related}
                handleChange={this.handleChange}
              />
            </div>
          );
        })}

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    );
  }
}

RadioMultipleGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
  getAllOptions: PropTypes.func,
};

export default RadioMultipleGroup;
