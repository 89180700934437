import React, { Fragment } from 'react';
import { Field } from 'redux-form';

class MultipleRadioItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFiltered: true,
      options: props.options,
      filteredOptions: [],
    };
  }

  componentWillMount() {
    const { relatedKeys, options } = this.props;

    let filteredOptions = options;
    // relatedKeys
    //   ? options.filter(option => {
    //       return relatedKeys.includes(option.value);
    //     })
    //   : options;

    this.setState({ filteredOptions });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.newlySelected !== this.props.values) {
      this.setState({ newlySelected: this.props.values });
    }
  }

  toggleMoreOptions = e => {
    const { isFiltered, filteredOptions, newlySelected, options } = this.state;
    this.setState({ isFiltered: !isFiltered });

    if (
      !isFiltered &&
      newlySelected &&
      !filteredOptions.find(option => option.value === newlySelected[option.name])
    ) {
      this.setState({
        filteredOptions: [
          ...filteredOptions.slice(0, 2),
          options.find(option => option.value === newlySelected[option.name]),
        ],
      });
    }

    e.preventDefault();
  };

  render() {
    const { isFiltered, options, filteredOptions } = this.state;
    const { handleChange, values, index, relatedKeys, t } = this.props;
    const optionsToShow = isFiltered ? filteredOptions : options;

    return (
      <Fragment>
        <div className='radio-options'>
          <Field
            key={index}
            component={({ input, options }) =>
              optionsToShow.map(option => (
                <div key={option.value} className='radio_input'>
                  <input
                    id={option.id}
                    type='radio'
                    {...input}
                    value={option.value}
                    name={option.name}
                    checked={values[option.name] && option.value === values[option.name]}
                    onChange={handleChange}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                  {option.example && <p className='input_example'> {option.example} </p>}
                </div>
              ))
            }
            name={'test'}
            options={options}
          />
        </div>
        {/* {relatedKeys && (
          <button className='more_options' onClick={e => this.toggleMoreOptions(e)}>
            <i className='fas fa-plus margin-right-10'></i>
            {!isFiltered ? t('.see_less') : t('.see_more')}====
          </button>
        )} */}
      </Fragment>
    );
  }
}

export default MultipleRadioItem;