/* eslint no-console: off */
import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql, withApollo } from 'react-apollo'
import { compose } from 'recompose'
import _ from 'lodash'
import connect from 'react-redux/es/connect/connect'

import { formattedFormElements } from './formattedChoices'
import CategoryQuestionForm from '../../containers/ProductQuestions/form'
import { loadModal, hideModal } from '../../redux/modal/action'
import withTranslation from '../../providers/I18n/HoC/t'

class PrivacyPolicyQuestionEdit extends React.Component {
  constructor(props) {
    super(props)
    const { question } = props
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.nextQuestion = this.nextQuestion.bind(this)
    this.answerQuestion = this.answerQuestion.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.setInitValues = this.setInitValues.bind(this)
    this.toggleModal = this.toggleModal.bind(this)

    const objectKeys = Object.keys(question.choices)

    const questionAnswer = question.answer ? question.answer.value : []

    this.state = {
      choices: formattedFormElements(question),
      values: questionAnswer,
      oldValues: questionAnswer,
      name: objectKeys[0],
      warning: false,
      error: false,
      error_message: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange(value) {
    this.setState(() => {
      return { values: value }
    })
  }

  handleSubmit(event) {
    const { question, match, t } = this.props
    const { values, oldValues } = this.state
    const { id } = match.params

    let confirm

    confirm =
      question.referenceNumber === 'Q.2' && question.answer
        ? ((!this.areArraysOfObjectsEqual(values, oldValues)) ? true : true)
        : true
    if (confirm === true) {
      this.answerQuestion(question.referenceNumber, id, values)
      event.preventDefault()
    }
  }

  areArraysOfObjectsEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].data !== arr2[i].data) {
        return false;
      }
    }

    return true;
  }


  previousPage() {
    const { history } = this.props
    history.goBack()
  }

  toggleModal(question, pp) {
    const { loadExampleModal, t, policyType } = this.props

    let referenceLetter = question.referenceNumber.split('')[0]

    switch (referenceLetter) {
      case 'B':
        loadExampleModal({
          modalType: 'dpo',
          content: { question, policyType },
          title: t('.test_result'),
        })
        break
      case 'D':
        loadExampleModal({
          modalType: 'cookies',
          content: { question, pp },
          title: t('.cookies_policy_creation'),
        })
        break
    }
  }

  answerQuestion(rf, id, values) {
    const { answerQuestionMutation, loadExampleModal, hideModal, t } = this.props
    if (rf === 'Q.2')
      loadExampleModal({
        modalType: 'cookie_scan',
        content: t('.being_scanned'),
        title: t('.scan'),
      })
    answerQuestionMutation({
      variables: {
        question_reference_number: rf,
        policy_id: id,
        value: values,
      },
    })
      .then(() => {
        if (rf === 'Q.2') hideModal()
        this.nextQuestion()
      })
      .catch(error => {
        if (rf === 'Q.2') {
          hideModal()
          this.setState({
            error: true,
            error_message: t('.cookie_scan_error_try_later'),
          })
        } else {
          this.setState({ warning: true })
          error.graphQLErrors.map(({ message, extensions }, i) => {
            this.setState({
              error: true,
              error_message: extensions.errors[0].title + ': ' + extensions.errors[0].detail,
            })
          })
        }

        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      })
  }

  nextQuestion() {
    const { question, match, client, history, policyType, userId } = this.props
    const { id, categoryId } = match.params
    client
      .query({
        query: NEXT_UNANSWERED_QUESTION_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          referenceNumber: question.referenceNumber,
          policyId: id,
          userId,
        },
      })
      .then(({ data }) => {
        const { nextQuestion, product } = data
        nextQuestion
          ? nextQuestion.type === 'modal'
            ? this.toggleModal(nextQuestion, product)
            : history.push(
              `/${policyType}-policies/${id}/categories/${categoryId}/questions/${nextQuestion.id}?Rescan=true`,
            )
          : history.push(`/${policyType}-policies/${id}/categories/${categoryId}`)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  setInitValues() {
    const { question, pp } = this.props
    const { name, choices } = this.state

    let cookieParsed = []

    if (question.type === 'form') {
      if (pp.cookies) {
        try {
          cookieParsed = JSON.parse(pp.cookies)
        } catch (e) {
          cookieParsed = []
        }
      }
    }

    let initValue = question.answer ? question.answer.value : null

    if (initValue) {
      switch (question.type) {
        case 'text area':
          let elements = {}

          initValue.map(value => {
            let keys = Object.keys(value)
            elements[`data_${keys[0]}`] = value[keys[0]]
          })

          return elements

        case 'radio':
          let element = {}
          if (!(initValue[0] instanceof Object)) {
            if (initValue.length > 1) return { [name]: initValue }
            else return initValue
          } else {
            initValue.forEach(value => {
              element = value
            })
          }
          return element

        case 'checkbox':
          return { [name]: initValue }

        case 'duration':
          let keys = Object.keys(initValue[0])
          let valueToReturn = {}

          keys.map(key => {
            let subKeys = Object.keys(initValue[0][key])
            subKeys.map(subKey => {
              if (initValue[0][key][subKey] instanceof Array) {
                let localeKeys = Object.keys(initValue[0][key][subKey][0])
                localeKeys.map((locale, i) => {
                  let name = key + '_' + subKey + '_' + locale
                  valueToReturn[name] = initValue[0][key][subKey][0][locale]
                })
              } else {
                let name = key + '_' + subKey
                valueToReturn[name] = initValue[0][key][subKey]
              }
            })

            choices.map(choice => {
              if (choice[0].name === key) {
                let isValueIn = false
                choice.map(item => {
                  if (!isValueIn) isValueIn = item.value === initValue[0][key].name
                })

                if (!isValueIn) {
                  valueToReturn[`${key}_name`] = null
                  valueToReturn[`${key}_period`] = null
                  valueToReturn[`${key}_time`] = null
                }
              }
            })
          })

          return valueToReturn

        case 'free text':
          let valToSend = {}
          initValue.forEach(value => {
            if (value) {
              let valKeys = Object.entries(value)

              if (value instanceof Array) {
                value.forEach(val => {
                  let keys = Object.entries(val)
                  if (keys[0]) {
                    let name = keys[0][0]
                    valToSend[name] = keys[0][1]
                  }
                })
              } else {
                let name = valKeys[0][0]
                valToSend[name] = valKeys[0][1]
              }
            }
          })
          return valToSend
        case 'form':
          let formValue = {}

          if (question.referenceNumber === 'R.1') {
            initValue.forEach((scan, i) => {
              let scannedKeys = Object.entries(scan)
              initValue.forEach(validate => {
                let answerKeys = Object.entries(validate)
                if (validate.name === scan.name) {
                  answerKeys.map(val => {
                    if (val[0] === 'other')
                      val[1].map(local => {
                        let localKeys = Object.entries(local)
                        formValue[`${i}_data_${localKeys[0][0]}`] = localKeys[0][1]
                      })
                    formValue[`${i}_${val[0]}`] = val[1]
                  })
                } else {
                  scannedKeys.map(val => {
                    formValue[`${i}_${val[0]}`] = val[1]
                  })
                }
              })
            })
          } else {
            initValue.forEach((validate, i) => {
              let answerKeys = Object.entries(validate)
              answerKeys.map(val => {
                formValue[`${i}_${val[0]}`] = val[1]
              })
            })
          }
          return formValue
        default:
          return
      }
    } else {
      if (question.type === 'form') {
        let formValue = {}
        if (question.referenceNumber === 'R.1') {
          cookieParsed.forEach((value, i) => {
            let valKeys = Object.entries(value)
            valKeys.map(val => {
              formValue[`${i}_${val[0]}`] = val[1]
            })
          })
        }
        return formValue
      } else {
        return {}
      }
    }
  }

  render() {
    const { question, pp } = this.props
    const { choices, warning, error, error_message, name } = this.state
    return (
      <div>
        <div className='error-message'>
          {error && <div style={{ color: '#ff3246' }}> {error_message} </div>}
        </div>
        <CategoryQuestionForm
          question={question}
          pp={pp}
          choices={choices}
          name={name}
          initialValues={this.setInitValues()}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

const NEXT_UNANSWERED_QUESTION_QUERY = gql`
  query nextUnansweredQuestion($policyId: ID!, $referenceNumber: String!, $userId: ID!) {
    nextQuestion(productId: $policyId, referenceNumber: $referenceNumber) {
      id
      type
      statement
      choices
      explanation
      referenceNumber
    }
    product(id: $policyId, userId: $userId) {
      id
      cookiePolicy {
        id
      }
    }
  }
`

const ANSWER_QUESTION_MUTATION = gql`
  mutation ProductAnswer($question_reference_number: String!, $policy_id: ID!, $value: JSON!) {
    productAnswer(
      questionReferenceNumber: $question_reference_number
      productId: $policy_id
      value: $value
    ) {
      id
      value
    }
  }
`

PrivacyPolicyQuestionEdit.propTypes = {
  match: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}),
  answerQuestionMutation: PropTypes.func,
  saveLocalValues: PropTypes.func,
  loadExampleModal: PropTypes.func.isRequired,
}

PrivacyPolicyQuestionEdit.defaultProps = {
  answerQuestionMutation: () => { },
  history: {},
  localValues: [],
}

const mapDispatchToProps = {
  loadExampleModal: loadModal,
  hideModal,
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(ANSWER_QUESTION_MUTATION, { name: 'answerQuestionMutation' }),
  withRouter,
  withApollo,
  withTranslation('question'),
)(PrivacyPolicyQuestionEdit)
