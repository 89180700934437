import React from 'react';

import Tooltip from '../../Tooltip';

const renderInputField = ({
  option,
  className,
  input,
  label,
  placeholder,
  type,
  example,
  readOnly,
  tooltip,
  tooltipText,
  mandatory,
  t,
  invalid,
  meta: { touched, error, active },
  noTooltip,
}) => {
  return (
    <div className={`text_input ${className || ''} ${touched && error ? 'error' : ''}`}>
      <input
        {...input}
        type={type}
        placeholder={placeholder ? placeholder : label}
        readOnly={readOnly}
        style={{ border: invalid ? '1px solid #f00' : '1px solid #adadad' }}
      />
      {label && (
        <div className='text__label'>
          <label>
            {label}
            {mandatory && <span style={{ color: 'red' }}>&nbsp;* </span>}
            {tooltip && <Tooltip> {tooltipText} </Tooltip>}
          </label>
        </div>
      )}
      {touched && error && <div className='input_error'>{t(error)}</div>}
      {example && <p className='input_example'> {option.example} </p>}
      {active && touched && input.name === 'password' && !noTooltip && (
        <div className='tooltip__box'>
          <p> {t('.password_must_contain')} </p>
          <ul>
            <li> {t('.min_characters')} </li>
            <li> {t('.upper_lower_case')} </li>
            <li> {t('.a_number')} </li>
            <li> {t('.special_character')} </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export { renderInputField };
