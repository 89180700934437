// @ts-nocheck
import React from 'react'
import { compose } from 'recompose'
import connect from 'react-redux/es/connect/connect'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'

import Loading from '../../components/Loading'
import AuthWrapper from '../../components/Auth'
import { withTranslation } from '../../providers/I18n'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'
import Select from 'react-select'
import SearchConsentDatabase from './search'
import './consents.css'
import { hideModal, loadModal } from 'src/redux/modal/action'

class ConsentDatabasePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cpToken: null,
      allowExport: false,
      allowedForConsent: [],
      defaultValueSet: null,
      selectedLicense: null,
      banner_cis: false
    }
  }

  onPolicyChange = e => {
    const cpToken = e.value;
    let setCode = false;
    let banner_cis = false;
    e.license?.features?.forEach(f => {
      if (f.code === 'consents_db') {
        setCode = true;
      }
      if (f.code === 'cis') {
        banner_cis = true
      }
    })
    this.setState({ banner_cis: banner_cis })
    if (setCode) {
      this.setState({ defaultValueSet: e })
      this.setState({ allowExport: e.allowExport });
      this.setState({ cpToken: cpToken })
      this.setState({ selectedLicense: e.license })
    }
    else {
      this.setState({ selectedLicense: '' })

      this.setState({ defaultValueSet: null })
      this.setState({ cpToken: '' })
      const { t, loadModal } = this.props
      loadModal({
        modalType: 'access_limit_user_model',
        content: {
          selectedLicense: e.license
        },
        title: '',
        t,
      })
    }
  }

  render() {
    const { t, userId } = this.props
    const { defaultValueSet, banner_cis } = this.state
    return (
      <AuthWrapper>
        <TitleComponent title={t('.consent_database')} />
        <Query
          query={ALL_COOKIE_POLICIES_QUERY}
          fetchPolicy={'network-only'}
          variables={{ userId, type: 'cookie_policy' }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return `Error! ${error.message}`

            const { products } = data

            let allowedForConsent = []

            products
              .filter(product => (product.status === 'published' || product.version > 1) && !product.license?.isExpired)
              .forEach(item => {
                allowedForConsent.push(item)

                // item.license.features.forEach(f => {
                //   if (f.code === 'consents_db') {
                //     allowedForConsent.push(item)
                //   }
                // })
              })
            // this.setState({ allowedForConsent })
            const policyOptions = allowedForConsent.map(({ uid: value, title: label, license }) => {
              return { value, label, allowExport: license.allowExport ? license.allowExport : false, license: license }
            })
            return (
              <div className='row'>
                <div className='columns'>
                  <h1> {t('.consent_database')} </h1>
                  <h2> {t('.select_cookie_policy')} </h2>
                  <Select
                    options={policyOptions}
                    onChange={this.onPolicyChange}
                    className='select-padded'
                    value={defaultValueSet}
                  />
                  <SearchConsentDatabase cpToken={this.state.cpToken} allowExport={this.state.allowExport} selectedLicense={this.state.selectedLicense} banner_cis={banner_cis} />
                </div>
              </div>
            )
          }}
        </Query>
      </AuthWrapper>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { userId } = auth
  return { userId }
}

const ALL_COOKIE_POLICIES_QUERY = gql`
  query($type: String, $userId: ID!) {
    products(type: $type, userId: $userId) {
      id
      uid
      title
      status
      version
      license {
        domainName
        id
        allowExport
        subscriptionId
        features {
          id
          code
        }
        package{
          cNoOfLang
          cNoOfLegis
          pNoOfLang
          pNoOfLegis
        }
        isExpired
      }
    }
  }
`
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation('consent_database'),
)(ConsentDatabasePage)


