import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/es/Link';
import logo from '../../assets/img/logo-white.png';
import ModalContainer from '../Modal/container';
import LanguageSelection from '../Form/LanguageSelection';
import { withTranslation } from '../../providers/I18n';
import { IsBrowserLatestVersion } from '../../helpers/browser';
import { AiOutlineWarning } from 'react-icons/ai';

const UnAuthWrapper = ({ children, title, t }) => {
  var lan = localStorage.getItem('locale') || 'fr';
  return (
    <div className='test_password'>
      <div className='auth_content'>
        <div className='logo'>
          <Link to='/'>
            <img src={logo} alt='Admeet logo' />
          </Link>
        </div>
        <div className='language_selection'>
          <LanguageSelection />
        </div>
      </div>
      {!IsBrowserLatestVersion() && (
        <div className='warning_message-container'>
          <AiOutlineWarning size='1.5rem' className='mr-05' />
          To use Admeet, please use the latest version of your browser.
        </div>
      )}
      <div className='auth_main_content'>
        <div className='content_titles'>
          <h1> {t('.welcom')} </h1>
          <h2> {title} </h2>
        </div>
        <div className='auth_form'>{children}</div>
      </div>
      <div className='footer__policies'>
        {/* TODO: add translations for the following elements */}
        <ul>
          <li>
            <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/mentions-legales/' : 'https://www.admeet.eu/en/legal-notice/'}>
              {t('.legal_mention_link')}
            </a>
          </li>
          <li>
            <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/conditions-generales-admeet/' : 'https://www.admeet.eu/en/terms-and-conditions/'}>
              {t('.terms_conditions_link')}
            </a>
          </li>
          <li>
            <a target='_blank' href={lan === 'fr' ? 'https://www.admeet.eu/politique-de-vie-privee-admeet/' : 'https://www.admeet.eu/en/admeet-privacy-policy/'}>
              {t('.privacy_policy_link')}
            </a>
          </li>
          <li>
            <a target='_blank' href={lan === 'fr' ? "https://www.admeet.eu/politique-de-cookies-admeet/" : "https://www.admeet.eu/en/admeet-cookie-policy/"}>
              {t('.cookie_policy_link')}
            </a>
          </li>
        </ul>
      </div>
      <ModalContainer />
    </div>
  );
};

UnAuthWrapper.defaultProps = {};

UnAuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTranslation('unAuthenticatedForm')(UnAuthWrapper);
