import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import gql from 'graphql-tag';

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import Button from 'src/components/Button'
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import NotificationModel from 'src/providers/notifications/models/notification.model';
import { Link } from 'react-router-dom';
import { loadModal } from 'src/redux/modal/action';
import Loading from 'src/components/Loading'

const SubscriptionDetailsModel = ({
    title,
    closeExampleModal,
    content: { id, liecenseId },
    t,
    client,
    notificationsProvider,
    history,
    loadModal
}) => {
    const [subscriptionData, setSubscriptionData] = useState({})
    const [isLoading, setisLoading] = useState(true)
    const [subscriptionPack, setsubscriptionPack] = useState()
    const [license1, setlicense1] = useState()
    const [license2, setlicense2] = useState()
    const [paymentMode, setpaymentMode] = useState()
    const selectedLanguage = localStorage.getItem('locale') || 'fr'
    useEffect(() => {
        client.query({
            query: ID_WISE_SUBSCRIPTION,
            fetchPolicy: 'network-only',
            variables: {
                id: id
            },
        }).then(({ data }) => {
            const { subscription } = data;
            setSubscriptionData(subscription)
            setisLoading(false)
            const { paymentMode, licenses } = subscription
            var license1 = licenses?.filter(item => Number(item.id) === Number(liecenseId))
            var license2 = licenses?.filter(item => item.id !== liecenseId)
            setsubscriptionPack(subscription)
            setlicense1(license1[0] ? license1[0] : {})
            setlicense2(license2[0] ? license2[0] : {})
            setpaymentMode(paymentMode)

        }).catch(e => {
            setisLoading(false)
        })
    }, [])

    const automaticRenew = (automatic) => {
        client.mutate({
            mutation: automatic ? ON_AUTO_RENEW : OFF_AUTO_RENEW,
            variables: {
                id: subscriptionData.id,
            },
        }).then(({ data }) => {
            closeExampleModal();
            const notification = new NotificationModel({
                title: '',
                message: automatic ? t('.do_not_success') : t('.automatic_success'),
                type: 'success',
            })
            notificationsProvider.add(notification);
            // history.go(0);
        }).catch(({ graphQLErrors }) => {
            var errorDisplay = '';
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                graphQLErrors[0].extensions.errors.map(item => {
                    errorDisplay += ' ' + item.detail
                })
                const notification = new NotificationModel({
                    title: '',
                    message: errorDisplay,
                    type: 'alert',
                })

                notificationsProvider.add(notification)
            }
            else {
                const notification = new NotificationModel({
                    title: '',
                    message: t('.server_error'),
                    type: 'alert',
                })

                notificationsProvider.add(notification)
            }
            // throw error;
        });
    }
    const loadUserModal = () => {
        closeExampleModal();
        loadModal({
            modalType: 'licenses_details_cancel_model',
            content: {
                id: subscriptionData?.id
            },
            title: t('.confirm_text'),
        })
    }
    const dateSetChange = (value) => {
        if (!value) {
            return '';
        }
        var currentLanguage = localStorage.getItem('locale');

        var date = new Date(value)
        return date.toLocaleString(currentLanguage, { dateStyle: 'full' })
    }
    return (
        <>
            {isLoading ? <Loading /> : (
                <Modal>
                    <>
                        <div className='modal_bar'>
                            <button type='button' className='close' onClick={closeExampleModal} />
                        </div>
                        <div className='modal_content_details'>
                            <>
                                <div className='row collapse align-bottom align-justify margin-bottom-40'>
                                    <div className='row'>
                                        <div className='column'>

                                            <h1>
                                                {/* <Link to={'/account'}>
                                    <i className='fas fa-arrow-left margin-right-20' />
                                </Link> */}
                                                {license1?.name}</h1>
                                        </div >
                                    </div >
                                </div >
                                <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <h3>{t('.pack_purchased')} <span style={{ fontWeight: 200 }}>{selectedLanguage === 'en' ? subscriptionData?.package?.name[0]?.en : subscriptionData?.package?.name[1]?.fr}</span></h3>
                                    </div>
                                    {/* <div className="columns">
                                        <h3></h3>
                                    </div> */}
                                </div>
                                <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <h3>{t('.purchase_of_desc_text')} <span style={{ fontWeight: 200 }}>{subscriptionData?.startAt ? dateSetChange(subscriptionData?.startAt) : '-'}</span></h3>
                                    </div>
                                    {/* <div className="columns">
                                        <h3>{subscriptionData?.startAt}</h3>
                                    </div> */}
                                </div>

                                <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <h3>
                                            {t('.change_pack_included_title')}
                                            <span style={{ fontWeight: 200 }}>{(subscriptionData?.endAt) ? dateSetChange(subscriptionData?.endAt) : '-'}</span></h3>
                                    </div>
                                    {/* <div className="columns">
                                        <h3>{subscriptionData?.startAt}</h3>
                                    </div> */}
                                </div>

                                {subscriptionData?.nextPackageName ? (
                                    <div className="row margin-bottom-20">
                                        <div className="columns">
                                            <h3>{t('.next_renew_text')} <span style={{ fontWeight: 200 }}>{subscriptionData?.nextPackageName ? subscriptionData?.nextPackageName : '-'}</span></h3>
                                        </div>
                                        {/* <div className="columns">
                                            <h3>{subscriptionData?.nextPackageName}</h3>
                                        </div> */}
                                    </div>
                                ) : null}



                                <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <h3 >{t('.part_of_desc_text')} <span style={{ fontWeight: 200 }}>{license2?.name ? license2?.name : '-'}</span></h3>
                                    </div>
                                    {/* <div className="columns">
                                        <h3>{subscriptionData?.licenses?.[1]?.name}</h3>
                                    </div> */}

                                </div>
                                {
                                    !subscriptionData?.stopRenewAt ?
                                        <div className="row margin-bottom-20">
                                            <div className="columns">
                                                <Button
                                                    className='btn btn_pill is-orange margin-bottom-20'
                                                    onClick={() => {
                                                        automaticRenew(false);
                                                    }}
                                                >
                                                    {t('.do_not_btn')}
                                                </Button>
                                                <h3>{t('.do_not_renew')}</h3>
                                            </div>
                                        </div> :
                                        <div className="row margin-bottom-20">
                                            <div className="columns">
                                                <Button
                                                    className='btn btn_pill is-green margin-bottom-20'
                                                    onClick={() => {
                                                        automaticRenew(true);
                                                    }}
                                                >
                                                    {t('.automatic_renew')}
                                                </Button>
                                                <h3>{t('.automatic_renew_text')}</h3>
                                            </div>
                                        </div>
                                }


                                <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <Button
                                            className='btn btn_pill is-light-blue margin-bottom-20'
                                            onClick={() => {
                                                closeExampleModal();
                                                history.push(`change-licenses/${id}/${liecenseId}`)
                                            }}
                                        >
                                            {t('.change_license')}
                                        </Button>
                                        <h3>{t('.change_license_text')}</h3>
                                    </div>
                                </div>
                                {/* <div className="row margin-bottom-20">
                                    <div className="columns">
                                        <Button
                                            className='btn btn_pill is-error-sub margin-bottom-20'
                                            onClick={() => {
                                                loadUserModal();
                                            }}
                                        >
                                            {t('.cancel')}
                                        </Button>
                                        <h3>{t('.cancel_text')}</h3>
                                    </div>
                                </div> */}

                                <div>
                                    <div className='row'>
                                        <Button
                                            className='btn btn_pill is-blue'
                                            onClick={() => {
                                                closeExampleModal();
                                            }}
                                        >
                                            {t('.save_go_btn')}
                                        </Button>
                                    </div>

                                </div>
                            </>
                        </div >
                    </>

                </Modal>
            )}
        </>
    )
}

SubscriptionDetailsModel.propTypes = {
    title: PropTypes.string.isRequired,
    closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    closeExampleModal: hideModal,
    loadModal
}
const OFF_AUTO_RENEW = gql`
    mutation offAutoRenew($id: ID!) {
        offAutoRenew(id: $id) {
            id
        }
    }
`
const ON_AUTO_RENEW = gql`
    mutation onAutoRenew($id: ID!) {
        onAutoRenew(id: $id) {
            id
        }
    }
`
const ID_WISE_SUBSCRIPTION = gql`
  query Subscription($id: ID!) {
    subscription(id: $id) {
        id
        startAt
        endAt
        canceledAt
        subType
        paymentMode
        amount
        stopRenewAt
        package{
            uid
            name
        }
        licenses{
            id
            name
        }
        createdAt
        updatedAt
        nextPackageName
    }
}
`
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('dashboard'),
)(SubscriptionDetailsModel)
