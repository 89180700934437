import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { modules, formats } from './quill_options'
import Button from '../../Button'
import {
  validateInput,
  initDefaultValues,
  initQuestionAnswer,
  generateDefaultValues,
} from './helpers'

const TextAreaWithDefault = ({
  locales,
  options,
  answer,
  goBack,
  handleSubmit,
  t,
  selectedLanguage,
  onChange,
}) => {
  useEffect(() => {
    setInvalid(validateInput(values))
    onChange(values)
  }, [])

  const [selectedLocale, setSelectedLocale] = useState(
    locales.includes(selectedLanguage) ? selectedLanguage : locales[0],
  )
  const [defaultValues, setDefaultValues] = useState(() =>
    initDefaultValues({
      defaultValues: options[0]?.defaultValue || generateDefaultValues({ locales }),
      locales,
    }),
  )
  const [values, setValues] = useState(() =>
    initQuestionAnswer({
      answer,
      defaultValues,
      locales,
    }),
  )
  const [invalid, setInvalid] = useState(true)

  const renderLocale = () => {
    return (
      <ul className='form_locales__list'>
        {locales.map(locale => (
          <li
            key={locale}
            className={`form_locales__item ${selectedLocale === locale ? 'active' : ''}`}
            onClick={() => setSelectedLocale(locale)}
          >
            {locale.toUpperCase()}
          </li>
        ))}
      </ul>
    )
  }

  const renderField = option => {
    return (
      <div>
        {locales.map((locale, i) => (
          <div key={locale}>
            <ReactQuill
              defaultValue={
                values[0][locale]
                  ? values[0][locale]
                  : defaultValues?.find(value => locale in value)?.[locale]
              }
              onChange={text => handleChange(text, locale)}
              modules={modules}
              formats={formats}
              className={`quill-editor notranslate ${locale === selectedLocale ? '' : 'hidden'}`}
            />
          </div>
        ))}

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    )
  }

  const handleChange = (value, locale) => {
    let valueToSend

    valueToSend = values.map((answer, index) => {
      return selectedLocale in answer ? { ...values[index], [selectedLocale]: value } : answer
    })

    const formattedValueToSend = [Object.assign({}, ...valueToSend)]

    setValues(formattedValueToSend)
    setInvalid(validateInput(valueToSend))
    onChange(formattedValueToSend)
  }

  return (
    <div className='text-form'>
      {renderLocale(locales)}
      {renderField(options[0])}
    </div>
  )
}

TextAreaWithDefault.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
}

export default TextAreaWithDefault
