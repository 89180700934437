import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import gql from 'graphql-tag';
import update from 'immutability-helper';

import withTranslation from '../../../providers/I18n/HoC/t';
import BannerPosition from './Fields/banner_position';
import BannerPreview from './Fields/banner_preview';
import BannerColors from './Fields/banner_colors';
import BannerText from './Fields/banner_text';
import BannerCookiesForm from './Fields/cookies_form';
import Button from '../../../components/Button';
import PreviewPage from './preview_page';

import validate from './form_validation';
import Loading from '../../../components/Loading';

class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieUrl: props.pp.cookieUrl || 'https://admeet.eu',
      isValidating: false,
      isValidated: false,
      isPreviewOpen: false,
      banner: {},
      loading: true,
      policyType: 'cookie',
      policyVersion: null,
      policyStatus: null,
      productCategoriesData:[]
    };
  }

  componentWillMount() {
    const { pp } = this.props;

    let banner = this.setBannerInformation();
    let language_options = pp.locales.map(locale => ({
      value: locale.isoCode,
      label: `.${locale.isoCode}`,
    }));

    this.setState({
      banner,
      language_options,
    });

    this.fetchPolicy(pp.id, this.state.policyType).then(result => {
      const { categories } = result.data;
      this.setState({productCategoriesData:categories})
      this.setState({
        policyVersion: result.data.privacyPolicyVersions.length,
        policyStatus: result.data.privacyPolicy.status,
      });
    });

    this.fetchCookieFormChoices().then(data => {
      let newBanner = update(banner, {});

      let typeKeys = Object.keys(banner.cookies_consent);

      data.map(({ data }) => {
        const newCookies = typeKeys.map(type => {
          let updatedCookie = this.state.banner[
            data.fetchQuestionByLocale.localeKey
          ].banner_cookies.filter(cookie => {
            return cookie.name === type;
          })[0];

          let cookiesList =
            pp.cookiesByType[type] &&
            pp.cookiesByType[type].map(cookie => {
              let cookiePurpose = data.fetchQuestionByLocale.choices.data[cookie.category].filter(
                purpose => {
                  let key = Object.keys(purpose)[0];

                  return key === cookie.purpose;
                },
              )[0];

              return update(cookie, {
                purpose_text: {
                  $set: cookiePurpose[cookie.purpose],
                },
              });
            });

          updatedCookie = update(updatedCookie, {
            cookies_list: {
              $set: cookiesList,
            },
          });

          return updatedCookie;
        });

        newBanner = update(newBanner, {
          [data.fetchQuestionByLocale.localeKey]: {
            banner_cookies: {
              $set: newCookies,
            },
          },
        });
      });

      this.setState({
        banner: newBanner,
        loading: false,
      });
    });
  }
  isCategoriesCompleted() {
    let categoriesCompleted = true
    for (let cat of this.state.productCategoriesData) {
      let state = cat.status.key
      if (state === 'in_progress'){
         categoriesCompleted = false
         return categoriesCompleted;
        }
    }

    return categoriesCompleted
  }
  fetchCookieFormChoices = async () => {
    const { client, pp } = this.props;
    return await Promise.all(
      pp.locales.map(locale => {
        return client.query({
          query: QUESTION_BY_REFERENCE_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            ref: 'R.1',
            locale: locale.isoCode,
          },
        });
      }),
    );
  };

  fetchPolicy = async (id, type) => {
    const { client } = this.props;

    return await client.query({
      query: PRIVACY_POLICY_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id,
        type,
      },
    });
  };

  setBannerInformation = () => {
    const {
      question: { answer },
      choices,
      pp,
    } = this.props;
    let answerValues = answer ? answer.value[0] : null;

    if (answerValues !== null) {
      let banner_url = answerValues.banner_url;
      if (typeof banner_url !== 'undefined') {
        pp.locales.map(locale => {
          if (answerValues[locale.isoCode]) answerValues[locale.isoCode]['banner_url'] = banner_url;
        });
      }
    }

    const { theme, color, vue, cookies_consent } = answerValues ? answerValues : {};
    let banner = {};

    banner['theme'] = theme || 'dark';
    banner['color'] = color || '#fbc531';
    banner['vue'] = vue || 'bottom';
    banner['cookies_consent'] = {
      necessary: cookies_consent ? cookies_consent['necessary'] : 'yes',
      functional: cookies_consent ? cookies_consent['functional'] : 'no',
      statistic: cookies_consent ? cookies_consent['statistic'] : 'no',
      marketing: cookies_consent ? cookies_consent['marketing'] : 'no',
    };

    pp.locales.map(locale => {
      let values = answerValues ? answerValues[locale.isoCode] : null;
      const { title, text, cookies, text_url, url } = choices[locale.isoCode];
      banner[locale.isoCode] = {
        banner_title: values ? values.banner_title : title,
        banner_text: values ? values.banner_text : text,
        banner_text_url: values ? values.banner_text_url : text_url,
        banner_cookies: values ? values.banner_cookies : cookies,
        banner_url: values ? values.banner_url : url,
      };
    });
    return banner;
  };

  handleChange = (value, name, locale) => {
    const { banner } = this.state;
    const { selectedLanguage } = this.props;

    let newBanner;
    if (locale && locale === selectedLanguage) {
      newBanner = update(banner, {
        [selectedLanguage]: {
          [name]: {
            $set: value,
          },
        },
      });
    } else {
      newBanner = update(banner, {
        [name]: {
          $set: value,
        },
      });
    }

    if (name === 'cookies_consent') {
      const { pp } = this.props;

      pp.locales.map(locale => {
        newBanner[locale.isoCode]['banner_cookies'].map(item => {
          item['opt_in'] = value[item['name']];
        });
      });
    }

    this.setState({ banner: newBanner });
  };

  handleThemeChange = (theme, color, locale) => {
    const { banner } = this.state;
    const { selectedLanguage } = this.props;

    let newBanner;
    if (locale && locale === selectedLanguage) {
      newBanner = update(banner, {
        [selectedLanguage]: {
          theme: {
            $set: theme,
          },
          color: {
            $set: color,
          },
        },
      });
    } else {
      newBanner = update(banner, {
        theme: {
          $set: theme,
        },
        color: {
          $set: color,
        },
      });
    }

    this.setState({ banner: newBanner });
  };

  handleSubmit = () => {
    const {
      client,
      question: { referenceNumber },
      match: {
        params: { id },
      },
    } = this.props;

    this.setState({ isValidating: true });
    client
      .mutate({
        mutation: ANSWER_QUESTION_MUTATION,
        variables: {
          question_reference_number: referenceNumber,
          policy_id: id,
          value: [this.state.banner],
        },
      })
      .then(() => {
        window.setTimeout(() => {
          this.setState({ isValidated: false });
        }, 5000);
        this.setState({ isValidating: false, isValidated: true });
      });
  };

  redirectToPreview = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { policyType } = this.state;

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`);
    } else {
      localStorage.setItem('ppStatus', 'blocked');
      history.push(`/${policyType}-policies/${id}/preview`);
    }
  };

  togglePreview = () => {
    this.handleSubmit();
    this.setState({ isPreviewOpen: !this.state.isPreviewOpen });
  };

  onLanguageChange = e => {
    this.props.switchLanguage(e.target.value);
  };

  createBannerText = clickable => {
    const { selectedLanguage } = this.props;
    const { banner_text, banner_text_url, banner_url } = this.state.banner[selectedLanguage];

    let link = clickable
      ? `<a href='${banner_url}' style="text-decoration: underline" > ${
          banner_text_url ? banner_text_url : 'Lien'
        } </a>`
      : `<span style="text-decoration: underline" > ${
          banner_text_url ? banner_text_url : 'Lien'
        } </span>`;

    return `${banner_text} ${link}`;
  };

  render() {
    const {
      banner,
      language_options,
      cookieUrl,
      isValidating,
      isValidated,
      isPreviewOpen,
      loading,
    } = this.state;
    const { t, pp, selectedLanguage } = this.props;
    let { invalid } = this.props;
    const { color, theme, vue, cookies_consent } = banner;
    const { banner_text } = banner[selectedLanguage];
    let { banner_cookies } = banner[selectedLanguage];

    // strip html tags from QUILL input
    let clean_banner_text = banner_text.replace(/<\/?[^>]+(>|$)/g, '');
    if (clean_banner_text === '') invalid = true;
    let banner_text_invalid = clean_banner_text === '';

    banner_cookies.forEach(item => {
      if (typeof item.cookies_list !== 'undefined') {
        item.cookies_number = item.cookies_list.length;
      }
    });

    return (
      <div className='customisation text-form'>
        {loading ? (
          <Loading />
        ) : (
          <form>
            <div className='customisation__header'>
              <BannerPreview
                color={color}
                theme={theme}
                data={banner[selectedLanguage]}
                cookies_consent={cookies_consent}
                text={this.createBannerText(false)}
                cookies_available={pp.cookiesType}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                invalid={invalid}
                openPreview={this.togglePreview}
                t={t}
              />
            </div>
            <h4> {t('.aspect')} </h4>
            <div className='customisation__visual'>
              <BannerPosition vue={vue} handleChange={this.handleChange} t={t} />
              <BannerColors
                handleChange={this.handleChange}
                handleThemeChange={this.handleThemeChange}
                selectedTheme={theme}
                selectedColor={color}
                t={t}
              />
            </div>
            <div className='customisation__content'>
              <BannerText
                handleChange={this.handleChange}
                bannerText={banner_text}
                bannerTextInvalid={banner_text_invalid}
                t={t}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                pp={pp}
              />

              <BannerCookiesForm
                handleChange={this.handleChange}
                cookies={banner_cookies}
                cookies_consent={cookies_consent}
                cookies_available={pp.cookiesType}
                t={t}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                pp={pp}
              />
            </div>
            <div className='list_buttons'>
              {isValidated && (
                <p className='info_message margin-right-20'> {t('.banner_updated')} </p>
              )}
              {invalid && <p className='banner_validation'> {t('.missing_field')}</p>}

              <Button
                className='btn btn_pill is-outlined is-blue'
                published
                onClick={this.handleSubmit}
                disabled={invalid}
              >
                {!isValidating ? t('.save_banner') : t('.in_progress')}
              </Button>

              <Button
                className='btn btn_pill is-blue'
                published
                disabled={invalid || !this.isCategoriesCompleted()}
                onClick={() => {
                  this.handleSubmit();
                  this.redirectToPreview(this.state.policyStatus, this.state.policyVersion);
                }}
              >
                <i className='far fa-file-alt margin-right-10' />
                {t('.preview')}
              </Button>
            </div>
          </form>
        )}
        {isPreviewOpen && (
          <PreviewPage
            color={color}
            vue={vue}
            theme={theme}
            locale={selectedLanguage}
            data={banner[selectedLanguage]}
            cookies_consent={cookies_consent}
            text={this.createBannerText(true)}
            preview_url={cookieUrl}
            togglePreview={this.togglePreview}
            cookies_available={pp.cookiesType}
            t={t}
          />
        )}
      </div>
    );
  }
}

const QUESTION_BY_REFERENCE_QUERY = gql`
  query fetchQuestionByLocale($ref: String!, $locale: String!) {
    fetchQuestionByLocale(referenceNumber: $ref, locale: $locale) {
      choices
      localeKey
    }
  }
`;

const ANSWER_QUESTION_MUTATION = gql`
  mutation answer($question_reference_number: String!, $policy_id: ID!, $value: JSON!) {
    answer(
      question_reference_number: $question_reference_number
      policy_id: $policy_id
      value: $value
    ) {
      id
    }
  }
`;

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!, $type: String) {
    privacyPolicy(id: $id) {
      id
      uid
      title
      version
      createdAt
      updatedAt
      publishedAt
      status
      parentId
      cookies
      cookiePolicy {
        id
        title
        status
      }
      locales {
        isoCode
      }
      countries {
        isoCode
      }
      customization {
        colors
        logo {
          type
          path
        }
      }
      data {
        version
        pdf
        html
        uid
      }
    }
    categories(type: $type) {
      avatar
      id
      name
      position
      status(policyId: $id, policyType: $type)
      questionGroups {
        id
        questions(policyId: $id, policyType: $type) {
          id
          type
          referenceNumber
        }
      }
    }
    privacyPolicyVersions(policyId: $id) {
      archivedAt
      html
      id
      pdf
      publishedAt
      status
      uid
      version
    }
  }
`;

export default compose(
  withTranslation('cookie_customisation'),
  reduxForm({ form: 'customisation_form', validate, enableReinitialize: true }),
  withRouter,
  withApollo,
)(BannerForm);