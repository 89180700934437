import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'src/providers/I18n'
import setUserEmail from './actions.js'
import validate from './validate.js'
import { submitRegisterUser } from '../../UnAuthenticatedForm/submit_validation'

import { renderInputField } from '../../../components/Form/TextFieldGroup/fieldInput'
import Button from '../../../components/Button'

const UserRegisterForm = ({
  t,
  registerInfo,
  handleChange,
  handleSubmit,
  submitting,
  error,
  isError,
  errorMessage,
  invalid,
  pristine,
  isReadOnly,
  token
}) => {
  return (
    <div className='user_register_form'>
      <form className='text-form auth_form' onSubmit={handleSubmit}>
        <h5> Email </h5>
        <Field
          name='email'
          type='text'
          label='Email'
          onChange={handleChange}
          mandatory
          component={renderInputField}
          t={t}
          readOnly={isReadOnly}
        />
        <Field
          name='password'
          type='password'
          label='Password'
          onChange={handleChange}
          mandatory
          component={renderInputField}
          t={t}
        />
        {isError && <p className='validation_error'>{errorMessage}</p>}
        {error && <p className='validation_error'>{error}</p>}

        <div>
          <Button
            type='submit'
            className='btn btn_pill is-blue'
            disabled={invalid || submitting || pristine}
          >
            {t('.account_confirmation')}
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    initialValues: {
      email: state.userForm.userEmail,
      password: '',
    },
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'user_register_form',
    enableReinitialize: true,
    validate,
    onSubmit: submitRegisterUser,
  }),
  withTranslation('unAuthenticatedForm'),
  withRouter,
)(UserRegisterForm)
