import React, { useState } from 'react'

import { TabulationItem } from '../Tabulation/item'
import { withTranslation } from '../../providers/I18n'
import ScannedCookieForm from './scanned_form'

const CookieFormContainer = ({ ...props }) => {
  const [activeTab, toggleTab] = useState('tovalidate')

  const { t } = props

  return (
    <div className='cookies__list'>
      <ul className='cookies__list--nav'>
        <TabulationItem
          tab='tovalidate'
          label={t('.to_validate')}
          currentTab={activeTab}
          onChangeTab={toggleTab}
          className='nav__item'
        />
        <TabulationItem
          tab='validated'
          label={t('.validated')}
          currentTab={activeTab}
          onChangeTab={toggleTab}
          className='nav__item'
        />
      </ul>
      <ScannedCookieForm activeTab={activeTab} {...props} />
    </div>
  )
}

export default withTranslation('cookie_form')(CookieFormContainer)
