import React from 'react';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import { compose } from 'recompose';

import BreadcrumbItem from './breadcrumb_item_product';

const filterById = url => {
  let arr = url.split(/\/([\d]+)/);

  let arrToReturn = {
    ids: [],
    pages: [],
  };

  arrToReturn.ids = arr.filter(item => {
    return item.match(/[\d]/g);
  });

  arrToReturn.pages = arr.filter(item => {
    return item.match(/[\/]/g);
  });

  arrToReturn.pages = arrToReturn.pages.map(page => {
    return page.replace('/', '');
  });

  return arrToReturn;
};

const Breadcrumb = ({ match, client }) => {
  const matchFiltered = filterById(match.url);
  const { pages, ids } = matchFiltered;

  return (
    <div className='row'>
      {(pages.length > 1 || ids.length > 0) && (
        <BreadcrumbItem ids={ids} pages={pages} client={client} match={match} />
      )}
    </div>
  );
};

const RoutedBreadcrumb = compose(withRouter, withApollo)(Breadcrumb);

const withBreadcrumb = WrappedComponent => {
  return props => <WrappedComponent {...props} Breadcrumb={RoutedBreadcrumb} />;
};

export default withBreadcrumb;
