const formattedChoices = (choices, value, type, example, objectKey, mandatory) => {
  if (type === 'form') return [];
  return choices.map((choicesKey, index) => {
    let keys = Object.keys(choicesKey);
    if (choicesKey[keys] instanceof Object) {
      return formattedChoices(
        choicesKey[keys].values,
        value[index],
        type,
        null,
        keys[0],
        mandatory,
      );
    } else {
      switch (type) {
        case 'free text':
          return {
            value: value && value[index] ? value[index] : {},
            label: choicesKey[keys],
            name: keys[0],
            id: keys[0],
            type: type,
            example: example ? example[keys] : null,
            mandatory: mandatory.includes(keys[0]),
          };

        case 'radio':
          return {
            value: keys[0],
            label: choicesKey[keys],
            name: objectKey ? objectKey : 'radio',
            id: `${objectKey ? `${objectKey}_` : ''}${index}`,
            type: type,
            example: example ? example[keys] : null,
          };
        case 'duration':
          return {
            value: keys[0],
            label: choicesKey[keys],
            name: objectKey ? objectKey : 'radio',
            id: `${objectKey ? `${objectKey}_` : ''}${index}`,
            type: type,
            example: example ? example[keys] : null,
          };
        case 'checkbox':
          return {
            value: keys[0],
            label: choicesKey[keys],
            name: keys[0],
            id: index,
            type: type,
            example: example ? example[keys] : null,
          };
        case 'text area':
        case 'form':
        case 'cookie_configurator':
          return {
            value: value,
            label: choicesKey[keys],
            name: keys[0],
            id: keys[0],
            type: type,
            example: example ? example[keys] : null,
          };
        case 'text area with default':
          return {
            value: value,
            defaultValue: choices,
            name: keys[0],
            id: keys[0],
            type: type,
            example: example ? example[keys] : null,
          };
        default:
          return;
      }
    }
  });
};

const formattedFormElements = question => {
  const { choices, answer, type, example, mandatoryChoices } = question;
  const choicesParsed = choices;
  let exampleParsed;

  if (example) {
    try {
      exampleParsed = JSON.parse(example);
    } catch (e) {
      exampleParsed = null;
    }
  }

  if (Object.keys(choicesParsed).length !== 0) {
    const objectKeys = Object.keys(choicesParsed);

    const choicesToFormat = choicesParsed[objectKeys[0]];

    // put the cookie choice on the top
    // for question C.8
    if (question.referenceNumber === 'C.8') {
      Array.prototype.move = function(from, to) {
        this.splice(to, 0, this.splice(from, 1)[0]);
      };
      choicesToFormat.move(8, 0);
    }

    const value = answer ? answer.value : {};

    return formattedChoices(
      choicesToFormat,
      value,
      type,
      exampleParsed,
      objectKeys[0],
      mandatoryChoices,
    );
  } else {
    return [];
  }
};

export { formattedFormElements };
