import React from 'react'
import Button from '../../components/Button'

const CookiePolicyIntegrationComponent = ({ t, cookieUID, copyIframe, cookieLocales, licenseKeyCookie, licenseKeyPrivacy }) => {

  const setIframesLink = uid => {
    const location = window.location.href
    const url = location
      .replace('https://', '')
      .replace('http://', '')
      .split(/[/?#]/)[0]
    const domain = url.split('.')[0]
    const api_link = process.env.REACT_APP_BASE_URL.replace('##PREFIX##', domain)

    return cookieLocales.length !== 0
      ? cookieLocales.map(locale => ({
        locale,
        link: `<iframe src="${api_link}/reader/products/html/${locale}/${uid}"></iframe>`,
      }))
      : ['fr']
  }

  return (
    <div className='margin-top-20'>
      <h2> {t('.cp_name')} </h2>
      <div className='flex_col'>
        <div className='flex_row link_wrapper'>
          <span className='language'>{`${t(`.license_key`)}: `}</span>
          <input
            value={licenseKeyPrivacy}
            readOnly
            className='read-only'
            id={`iframecookie-License-Key`}
          />
          <Button
            published
            className='btn btn_pill is-outlined is-blue margin-left-20'
            onClick={() => copyIframe(`iframecookie-License-Key`)}
          >
            <i className='far fa-copy margin-right-10' />
            {t('.copy_link')}
          </Button>
        </div>
        {setIframesLink(cookieUID).map(cookieLink => (
          <div className='flex_row link_wrapper'>
            <span className='language'>{`${t(`.${cookieLink.locale}`)}: `}</span>

            <input
              value={cookieLink.link}
              id={`iframecookie-${cookieLink.locale}`}
              readOnly
              className='read-only'
            />
            <Button
              published
              className='btn btn_pill is-outlined is-blue margin-left-20'
              onClick={() => copyIframe(`iframecookie-${cookieLink.locale}`)}
            >
              <i className='far fa-copy margin-right-10' />
              {t('.copy_link')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CookiePolicyIntegrationComponent
