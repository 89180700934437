import gql from 'graphql-tag';

// QUERY

const USER_EXIST_QUERY = gql`
  query userExist($email: String!) {
    userExist(email: $email) {
      email
      id
      organization {
        id
        name
        subDomainName
      }
    }
  }
`;

const LICENSE_USER_BY_TOKEN_QUERY = gql`
  query LicenseUserByToken($token: String!) {
    licenseUserByToken(token: $token) {
      userEmail
      isUserRegister
      confirmationCode
    }
  }
`;

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      id
      billing {
        id
      }
    }
  }
`;

const CHECK_TOKEN_QUERY = gql`
  query checkToken($token: String!) {
    checkToken(token: $token)
  }
`;

// MUTATION

const USER_LOGIN_MUTATION = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      user {
        id
        isSuperAdmin
      }
    }
  }
`;

const PASSWORD_RESET_MUTATION = gql`
  mutation passwordReset($email: String!) {
    passwordReset(email: $email) {
      email
      id
      organization {
        id
      }
    }
  }
`;

const PASSWORD_CHANGE_MUTATION = gql`
  mutation changePassword($token: String!, $password: String!, $new: Boolean) {
    changePassword(token: $token, password: $password, new: $new) {
      token
      user {
        id
      }
    }
  }
`;

const SIGN_UP_MUTATION = gql`
  mutation register_user($email: String!, $password: String!, $token: String!) {
    registerUser(email: $email, password: $password, token: $token) {
      token
      user {
        id
      }
    }
  }
`;

const Queries = {
  user_exist: USER_EXIST_QUERY,
  organization_get: ORGANIZATION_QUERY,
  check_token: CHECK_TOKEN_QUERY,
  license_user_by_token: LICENSE_USER_BY_TOKEN_QUERY,
};

const Mutation = {
  user_login: USER_LOGIN_MUTATION,
  password_reset: PASSWORD_RESET_MUTATION,
  password_change: PASSWORD_CHANGE_MUTATION,
  sign_up_mutation: SIGN_UP_MUTATION,
};

export { Queries, Mutation };
