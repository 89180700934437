import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form';
import gql from 'graphql-tag';
import update from 'immutability-helper';

import withTranslation from '../../../providers/I18n/HoC/t';
import BannerPosition from './Fields/banner_position';
import ButtonPosition from './Fields/cookie_button_position';
import BannerPreview from './Fields/banner_preview';
import BannerColors from './Fields/banner_colors';
import BannerText from './Fields/banner_text';
import BannerCookiesForm from './Fields/cookies_form';
import Button from '../../../components/Button';
import PreviewPage from './preview_page';

import validate from './form_validation';
import Loading from '../../../components/Loading';

class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieUrl: props.pp.cookieUrl || 'https://admeet.eu',
      isValidating: false,
      isValidated: false,
      isPreviewOpen: false,
      banner: {},
      loading: true,
      policyType: 'cookie',
      policyVersion: null,
      policyStatus: null,
      productCategoriesData: [],
      selectedLicense: null,
      highContract: false,
      is_high_contrast: false
    };
  }

  componentWillMount() {
    this.getalldata();
  }
  getalldata() {
    const { pp } = this.props;

    let banner = this.setBannerInformation();

    let language_options = [];
    let languages = [];
    pp.license.features.forEach(item => {
      if (item.groupCode === 'language' && item.active) {
        language_options.push({
          value: item['code'].slice(-2),
          label: `.${item['code']}`,
        });
        languages.push(item['code']);
      }
    });

    this.setState({
      banner,
      language_options,
      languages,
    });
    this.fetchPolicy(pp.id, this.state.policyType).then(result => {
      const { productCategories } = result.data;
      if (result?.data?.product?.license) {
        this.setState({ selectedLicense: result?.data?.product?.license })
      }
      this.setState({ productCategoriesData: productCategories })
      this.setState({
        policyVersion: result.data.productVersions.length,
        policyStatus: result.data.product.status,
      });
    });

    this.fetchCookieFormChoices().then(data => {
      let newBanner = update(banner, {});

      let typeKeys = Object.keys(banner.cookies_consent);

      data.map(({ data }) => {
        const newCookies = typeKeys.map(type => {
          let updatedCookie = this.state.banner[
            data.questionByLocale.localeKey
          ].banner_cookies.filter(cookie => {
            return cookie.name === type;
          })[0];

          let cookiesList =
            pp.cookiesByType[type] &&
            pp.cookiesByType[type].map(cookie => {
              let cookiePurpose = data.questionByLocale.choices.data[cookie.category].filter(
                purpose => {
                  let key = Object.keys(purpose)[0];

                  return key === cookie.purpose;
                },
              )[0];

              return update(cookie, {
                purpose_text: {
                  $set: cookiePurpose[cookie.purpose],
                },
              });
            });

          updatedCookie = update(updatedCookie, {
            cookies_list: {
              $set: cookiesList,
            },
          });

          return updatedCookie;
        });

        newBanner = update(newBanner, {
          [data.questionByLocale.localeKey]: {
            banner_cookies: {
              $set: newCookies,
            },
          },
        });
      });

      this.setState({
        banner: newBanner,
        loading: false,
      });
    });
  }
  refreshdata(banner) {
    const { pp } = this.props;


    let language_options = [];
    let languages = [];
    pp.license.features.forEach(item => {
      if (item.groupCode === 'language' && item.active) {
        language_options.push({
          value: item['code'].slice(-2),
          label: `.${item['code']}`,
        });
        languages.push(item['code']);
      }
    });

    this.setState({
      banner,
      language_options,
      languages,
    });
    this.fetchPolicy(pp.id, this.state.policyType).then(result => {
      const { productCategories } = result.data;
      this.setState({ productCategoriesData: productCategories })
      this.setState({
        policyVersion: result.data.productVersions.length,
        policyStatus: result.data.product.status,
      });
    });

    this.fetchCookieFormChoices().then(data => {
      let newBanner = update(banner, {});

      let typeKeys = Object.keys(banner.cookies_consent);

      data.map(({ data }) => {
        const newCookies = typeKeys.map(type => {
          let updatedCookie = this.state.banner[
            data.questionByLocale.localeKey
          ].banner_cookies.filter(cookie => {
            return cookie.name === type;
          })[0];

          let cookiesList =
            pp.cookiesByType[type] &&
            pp.cookiesByType[type].map(cookie => {
              let cookiePurpose = data.questionByLocale.choices.data[cookie.category].filter(
                purpose => {
                  let key = Object.keys(purpose)[0];

                  return key === cookie.purpose;
                },
              )[0];

              return update(cookie, {
                purpose_text: {
                  $set: cookiePurpose[cookie.purpose],
                },
              });
            });

          updatedCookie = update(updatedCookie, {
            cookies_list: {
              $set: cookiesList,
            },
          });

          return updatedCookie;
        });

        newBanner = update(newBanner, {
          [data.questionByLocale.localeKey]: {
            banner_cookies: {
              $set: newCookies,
            },
          },
        });
      });

      this.setState({
        banner: newBanner,
        loading: false,
      });
    });
  }
  isCategoriesCompleted() {
    let categoriesCompleted = true
    for (let cat of this.state.productCategoriesData) {
      let state = cat.status.key
      if (state === 'in_progress') {
        categoriesCompleted = false
        return categoriesCompleted;
      }
    }

    return categoriesCompleted
  }
  fetchCookieFormChoices = async () => {
    const { client, pp } = this.props;

    const languages = pp.license.features.reduce(
      (arr, item) => (item.groupCode === 'language' && item.active && arr.push(item['code']), arr),
      [],
    );

    return await Promise.all(
      languages.map(locale => {
        return client.query({
          query: QUESTION_BY_REFERENCE_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            ref: 'R.1',
            locale: locale,
          },
        });
      }),
    );
  };

  // isCategoriesCompleted(categories) {
  //   let categoriesCompleted = true
  //   for (let cat of categories) {
  //     let state = cat.status.key
  //     if (state === 'in_progress') categoriesCompleted = false
  //   }
  //   return categoriesCompleted
  // }

  fetchPolicy = async (id, type) => {
    const { client, userId } = this.props;

    return await client.query({
      query: PRIVACY_POLICY_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id,
        type,
        userId
      },
    });
  };

  setBannerInformation = () => {
    const {
      question: { answer },
      choices,
      pp,
    } = this.props;
    const languages = pp.license.features.reduce(
      (arr, item) => (item.groupCode === 'language' && item.active && arr.push(item['code']), arr),
      [],
    );

    let answerValues = answer ? answer.value[0] : null;

    if (answerValues !== null) {
      let banner_url = answerValues.banner_url;
      if (banner_url) {
        languages.map(locale => {
          if (answerValues[locale]) answerValues[locale]['banner_url'] = banner_url;
        });
      }
    }

    const { theme, color, vue, cookies_consent, button_position, is_high_contrast } = answerValues
      ? answerValues
      : {};
    let banner = {};
    this.setState({ is_high_contrast })
    banner['theme'] = theme || 'dark';
    banner['color'] = color || '#fbc531';
    banner['vue'] = vue || 'bottom';
    banner['button_position'] = button_position || 'right';
    banner['is_high_contrast'] = is_high_contrast || false;
    banner['cookies_consent'] = {
      necessary: cookies_consent ? cookies_consent['necessary'] : 'yes',
      functional: cookies_consent ? cookies_consent['functional'] : 'no',
      statistic: cookies_consent ? cookies_consent['statistic'] : 'no',
      marketing: cookies_consent ? cookies_consent['marketing'] : 'no',
    };

    languages.map(locale => {
      let values = answerValues ? answerValues[locale] : null;
      const { title, text, cookies, text_url, url } = choices[locale];
      banner[locale] = {
        banner_title: values ? values.banner_title : title,
        banner_text: values ? values.banner_text : text,
        banner_text_url: values ? values.banner_text_url : text_url,
        banner_cookies: values ? values.banner_cookies : cookies,
        banner_url: values ? values.banner_url : url,
      };
    });
    return banner;
  };

  handleChange = (value, name, locale) => {
    const { banner } = this.state;
    const { selectedLanguage } = this.props;

    let newBanner;
    if (locale && locale === selectedLanguage) {
      newBanner = update(banner, {
        [selectedLanguage]: {
          [name]: {
            $set: value,
          },
        },
      });
    } else {
      newBanner = update(banner, {
        [name]: {
          $set: value,
        },
      });
    }

    if (name === 'cookies_consent') {
      const { pp } = this.props;
      const languages = pp.license.features.reduce(
        (arr, item) => (
          item.groupCode === 'language' && item.active && arr.push(item['code']), arr
        ),
        [],
      );
      languages.map(locale => {
        newBanner[locale]['banner_cookies'].map(item => {
          item['opt_in'] = value[item['name']];
        });
      });
    }

    this.setState({ banner: newBanner });
  };

  handleThemeChange = (theme, color, locale) => {
    const { banner } = this.state;
    const { selectedLanguage } = this.props;

    let newBanner;
    if (locale && locale === selectedLanguage) {
      newBanner = update(banner, {
        [selectedLanguage]: {
          theme: {
            $set: theme,
          },
          color: {
            $set: color,
          },
        },
      });
    } else {
      newBanner = update(banner, {
        theme: {
          $set: theme,
        },
        color: {
          $set: color,
        },
      });
    }

    this.setState({ banner: newBanner });
  };

  handleSubmit = () => {
    const {
      client,
      question: { referenceNumber },
      match: {
        params: { id },
      },
    } = this.props;
    const { is_high_contrast } = this.state
    this.setState({ isValidating: true });
    var newBannerData = {
      ...this.state.banner,
      is_high_contrast: is_high_contrast
    }
    client
      .mutate({
        mutation: ANSWER_QUESTION_MUTATION,
        variables: {
          question_reference_number: referenceNumber,
          policy_id: id,
          value: [newBannerData],
        },
      })
      .then(async (data) => {
        if (data?.data?.productAnswer?.value[0]) {
          this.refreshdata(data?.data?.productAnswer?.value[0])
        }
        await window.setTimeout(async () => {
          this.setState({ isValidated: false });
        }, 5000);
        await this.setState({ isValidating: false, isValidated: true });
        // window.location.reload(false);
      });
  };

  redirectToPreview = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { policyType } = this.state;

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`);
    } else {
      localStorage.setItem('ppStatus', 'blocked');
      history.push(`/${policyType}-policies/${id}/preview`);
    }
  };

  togglePreview = () => {
    this.handleSubmit();
    this.setState({ isPreviewOpen: !this.state.isPreviewOpen });
  };
  toggleHightContrast = () => {
    this.setState({ highContract: !this.state.highContract });
  };

  onLanguageChange = e => {
    this.props.switchLanguage(e.target.value);
  };

  createBannerText = clickable => {
    const { selectedLanguage } = this.props;
    const { banner_text, banner_text_url, banner_url } = this.state.banner[selectedLanguage];

    let link = clickable
      ? `<a href='${banner_url}' style="text-decoration: underline" > ${banner_text_url ? banner_text_url : 'Lien'
      } </a>`
      : `<span style="text-decoration: underline" > ${banner_text_url ? banner_text_url : 'Lien'
      } </span>`;

    return `${banner_text} ${link}`;
  };

  render() {
    const {
      banner,
      language_options,
      cookieUrl,
      isValidating,
      isValidated,
      isPreviewOpen,
      loading,
      languages,
      selectedLicense,
      highContract,
      is_high_contrast
    } = this.state;
    const { t, pp, selectedLanguage } = this.props;
    let { invalid } = this.props;
    const { color, theme, vue, cookies_consent, button_position } = banner;
    const { banner_text } = banner[selectedLanguage];
    let { banner_cookies } = banner[selectedLanguage];
    // strip html tags from QUILL input
    let clean_banner_text = banner_text.replace(/<\/?[^>]+(>|$)/g, '');
    if (clean_banner_text === '') invalid = true;
    let banner_text_invalid = clean_banner_text === '';

    banner_cookies.forEach(item => {
      if (typeof item.cookies_list !== 'undefined') {
        item.cookies_number = item.cookies_list.length;
      }
    });

    let bannerPersonalization = false;
    pp.license.features.forEach(item => {
      if (item.code === 'banner') bannerPersonalization = true;
    });

    let missing_fields = {};

    languages.forEach(lang => {
      if (banner[lang]['banner_title'] === '') {
        missing_fields['banner_title'] !== undefined
          ? missing_fields['banner_title'].push(`[${lang.toUpperCase()}]`)
          : (missing_fields['banner_title'] = [`[${lang.toUpperCase()}]`]);
      }

      if (banner[lang]['banner_text'] === '') {
        missing_fields['cookie_text'] !== undefined
          ? missing_fields['cookie_text'].push(`[${lang.toUpperCase()}]`)
          : (missing_fields['cookie_text'] = [`[${lang.toUpperCase()}]`]);
      }

      if (banner[lang]['banner_text_url'] === '') {
        missing_fields['banner_policy_text_link'] !== undefined
          ? missing_fields['banner_policy_text_link'].push(`[${lang.toUpperCase()}]`)
          : (missing_fields['banner_policy_text_link'] = [`[${lang.toUpperCase()}]`]);
      }

      if (banner[lang]['banner_url'] === '') {
        missing_fields['banner_policy_link'] !== undefined
          ? missing_fields['banner_policy_link'].push(`[${lang.toUpperCase()}]`)
          : (missing_fields['banner_policy_link'] = [`[${lang.toUpperCase()}]`]);
      }
    });

    return (
      <div className='customisation text-form'>
        {loading ? (
          <Loading />
        ) : (
          <form>
            <div className='customisation__header'>
              <BannerPreview
                color={color}
                theme={theme}
                data={banner[selectedLanguage]}
                cookies_consent={cookies_consent}
                text={this.createBannerText(false)}
                cookies_available={pp.cookiesType}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                invalid={invalid || !this.isCategoriesCompleted()}
                openPreview={this.togglePreview}
                highContract={highContract}
                highContractClick={this.toggleHightContrast}
                mainHightContractShow={is_high_contrast}
                t={t}
              />
            </div>

            {/* {bannerPersonalization && ( */}
            <Fragment>
              <h4> {t('.aspect')}</h4>
              <div className='customisation__visual'>
                <BannerPosition vue={vue} handleChange={this.handleChange} t={t} bannerPersonalization={bannerPersonalization}
                  selectedLicense={selectedLicense} />
                <ButtonPosition
                  button_position={button_position}
                  handleChange={this.handleChange}
                  bannerPersonalization={bannerPersonalization}
                  t={t}
                  selectedLicense={selectedLicense}
                />
                <BannerColors
                  handleChange={this.handleChange}
                  handleThemeChange={this.handleThemeChange}
                  selectedTheme={theme}
                  selectedColor={color}
                  t={t}
                  bannerPersonalization={bannerPersonalization}
                  selectedLicense={selectedLicense}
                />
                <div className='high-contrast'>
                  <div className='high-contrast-content'>
                    <h5> {t('.enable_hight_conract_mode')}
                    </h5>
                    <div className='features-set'>
                      <div className='checkbox-wrapper'>
                        <input
                          name={"highcontract"}
                          type='checkbox'
                          id={"highcontract"}
                          checked={is_high_contrast}
                          onChange={() => {
                            this.setState({ is_high_contrast: !this.state.is_high_contrast })
                            this.setState({ highContract: false })

                          }}
                        />
                        <label htmlFor={"highcontract"}>Toggle</label>
                      </div>
                    </div>
                  </div>
                  <span>{t('.enable_hight_conract_mode_desc')}</span>
                </div>

              </div>
            </Fragment>
            {/* )} */}

            <div className='customisation__content'>
              <BannerText
                handleChange={this.handleChange}
                bannerText={banner_text}
                bannerTextInvalid={banner_text_invalid}
                t={t}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                pp={pp}
                languages={languages}
              />

              <BannerCookiesForm
                handleChange={this.handleChange}
                cookies={banner_cookies}
                cookies_consent={cookies_consent}
                cookies_available={pp.cookiesType}
                t={t}
                language_options={language_options}
                selectedLanguage={selectedLanguage}
                onLanguageChange={this.onLanguageChange}
                pp={pp}
                languages={languages}
              />
            </div>
            <div className='list_buttons'>
              {isValidated && (
                <p className='info_message margin-right-20'> {t('.banner_updated')} </p>
              )}
              {invalid && <p className='banner_validation'> {t('.missing_field')}</p>}

              <Button
                className='btn btn_pill is-outlined is-blue'
                published
                onClick={this.handleSubmit}
                disabled={invalid}
              >
                {!isValidating ? t('.save_banner') : t('.in_progress')}
              </Button>

              <Button
                className='btn btn_pill is-blue'
                published
                disabled={invalid}
                onClick={() => {
                  this.handleSubmit();
                  this.redirectToPreview(this.state.policyStatus, this.state.policyVersion);
                }}
              >
                <i className='far fa-file-alt margin-right-10' />
                {t('.preview')}
              </Button>
            </div>

            <div style={{ marginTop: '40px', textAlign: 'right', color: 'red', fontWeight: '600' }}>
              {Object.entries(missing_fields).map(([key, value], index) => {
                return (
                  <p className='' key={index} style={{ marginBottom: '8px' }}>
                    {`${value.join(' ')} ` + t(`.${key}`)}
                  </p>
                );
              })}
            </div>
          </form>
        )}
        {isPreviewOpen && (
          <PreviewPage
            color={color}
            vue={vue}
            theme={theme}
            locale={selectedLanguage}
            data={banner[selectedLanguage]}
            cookies_consent={cookies_consent}
            text={this.createBannerText(true)}
            preview_url={cookieUrl}
            togglePreview={this.togglePreview}
            cookies_available={pp.cookiesType}
            t={t}
          />
        )}
      </div>
    );
  }
}

const QUESTION_BY_REFERENCE_QUERY = gql`
  query fetchQuestionByLocale($ref: String!, $locale: String!) {
    questionByLocale(referenceNumber: $ref, locale: $locale) {
      id
      choices
      localeKey
    }
  }
`;

const ANSWER_QUESTION_MUTATION = gql`
  mutation answer($question_reference_number: String!, $policy_id: ID!, $value: JSON!) {
    productAnswer(
      questionReferenceNumber: $question_reference_number
      productId: $policy_id
      value: $value
    ) {
      id
      value
    }
  }
`;

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!, $type: String, $userId: ID!) {
    product(id: $id, userId: $userId) {
      id
      uid
      title
      version
      createdAt
      updatedAt
      publishedAt
      status
      parentId
      cookies
      cookiePolicy {
        id
        title
        status
      }
      license {
        id
        subscriptionId
        features {
          id
          name
          code
          preselect
          groupName
          groupCode
          groupDescription
          active
        }
        package{
          cNoOfLang
          cNoOfLegis
          pNoOfLang
          pNoOfLegis
        }
      }
      customization {
        colors
        logo {
          type
          path
        }
      }
      data {
        id
        version
        pdf
        html
        uid
      }
    }
    productCategories(type: $type) {
      id
      position
      name
      avatar
      type
      questionGroups {
        id
        name
        questions(productId: $id) {
          id
          referenceNumber
          type
        }
      }
      status(productId: $id)
    }
    productVersions(productId: $id) {
      id
      uid
      version
      html
      pdf
      publishedAt
      archivedAt
      status
    }
  }
`;

const mapStateToProps = ({ auth }) => {
  const { userId } = auth
  return {
    userId
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation('cookie_customisation'),
  reduxForm({ form: 'customisation_form', validate, enableReinitialize: true }),
  withRouter,
  withApollo,
)(BannerForm);