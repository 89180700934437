import React from 'react'
import gql from 'graphql-tag'
import Link from 'react-router-dom/es/Link'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withTranslation } from '../../I18n'
import { AiOutlineRight } from 'react-icons/ai'

class BreadcrumbItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
    }
  }

  componentWillMount() {
    this.getBreadcrumbs()
    const { match } = this.props
    this.setState({
      policyType: this.isCookiePolicy(match.url) ? 'cookie-policies' : 'privacy-policies',
    })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(nextProps.ids) !== JSON.stringify(this.state.ids)) this.getBreadcrumbs()
  }

  isCookiePolicy = url => {
    return url.includes('cookie-policies')
  }

  getBreadcrumbs = () => {
    const { client, ids, userId } = this.props
    client
      .query({
        query: GET_BREADCRUMB_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          ppId: ids[0] || 0,
          catId: ids[1] || 0,
          questId: ids[2] || 0,
          userId,
        },
      })
      .then(({ data }) => {
        this.setState({
          data,
          ids: ids,
        })
      })
  }

  render() {
    const {
      data: { product, productCategory, question },
      policyType,
    } = this.state
    const { pages, t } = this.props
    const preview = pages.includes('preview')

    return (
      <div className='breadcrumbs'>
        <div className='breadcrumbs_item'>
          <Link to={`/${policyType}`}>
            <div className='breadcrumbs_item-txt'>
              <p> {t('.your_policies')} </p>
            </div>
          </Link>
        </div>

        {product && (
          <div className='breadcrumbs_item'>
            <Link to={`/${policyType}/${product.id}`}>
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {product.title} </p>
              </div>
            </Link>
          </div>
        )}

        {productCategory && (
          <div className='breadcrumbs_item'>
            <Link to={`/${policyType}/${product.id}/categories/${productCategory.id}`}>
              <AiOutlineRight />
              {/* <div className="breadcrumbs_item-img" >
                                    <img src={category.avatar} alt={ category.name }/>
                                </div> */}
              <div className='breadcrumbs_item-txt'>
                <p> {productCategory.name} </p>
              </div>
            </Link>
          </div>
        )}
        {question && (
          <div className='breadcrumbs_item'>
            <Link
              to={`/${policyType}/${product.id}/categories/${productCategory.id}/questions/${question.id}`}
            >
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {question.statement} </p>
              </div>
            </Link>
          </div>
        )}
        {preview && (
          <div className='breadcrumbs_item'>
            <Link to={'/'}>
              <AiOutlineRight />
              <div className='breadcrumbs_item-txt'>
                <p> {t('.preview')} </p>
              </div>
            </Link>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

const GET_BREADCRUMB_QUERY = gql`
  query getBreadCrumbs($ppId: ID!, $catId: ID!, $questId: ID!, $userId: ID!) {
    product(id: $ppId, userId: $userId) {
      id
      title
    }
    productCategory(id: $catId) {
      id
      avatar
      name
    }
    question(id: $questId, productId: $ppId) {
      id
      statement
    }
  }
`

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslation('breadcrumbs'),
)(BreadcrumbItem)
