import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import frLocaleData from 'react-intl/locale-data/fr';
import enLocaleData from 'react-intl/locale-data/en';

import translations from '../locales';

addLocaleData(frLocaleData);
addLocaleData(enLocaleData);

const I18nContext = React.createContext({
  language: 'fr',
  setLanguage: () => { },
});

class I18nProvider extends React.Component {
  constructor(props) {
    super(props);
    var newLang = "";
    const url = window?.location?.href;
    const parts = url?.split('/');
    if (parts?.[3] === 'en' || parts?.[3] === 'fr') {
      localStorage.setItem("locale", parts?.[3])
      newLang = parts[3]
    }
    this.state = {
      language: newLang || localStorage.getItem('locale') || 'fr',
      setLanguage: this.setLanguage,
    };
  }

  componentDidMount() {
    !localStorage.getItem('locale') && localStorage.setItem('locale', 'fr');
  }

  setLanguage = language => {
    this.setState(
      {
        language,
      },
      () => {
        localStorage.setItem('locale', language);
      },
    );
  };

  render() {
    const { children } = this.props;
    const { language, setLanguage } = this.state;

    const valuesProvider = {
      language,
      setLanguage,
    };

    return (
      <I18nContext.Provider value={valuesProvider}>
        <IntlProvider key={language} locale={language} messages={translations[language]}>
          {children}
        </IntlProvider>
      </I18nContext.Provider>
    );
  }
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const I18nConsumer = I18nContext.Consumer;

export default I18nProvider;

export { I18nConsumer };
