import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import { clone, difference, concat } from 'lodash';
import Select from 'react-select';

import { withTranslation } from '../../../providers/I18n';

class CountryField extends React.Component {
  constructor(props) {
    super(props);
    const { t, values, availableCountries } = props;
    this.state = {
      selectedOptions:
        values.countries &&
        values.countries.map(country => {
          return {
            value: country,
            label: t(`.${country}`),
          };
        }),
      availableCountries: availableCountries.map(country => {
        return {
          value: country,
          label: t(`.${country}`),
        };
      }),
      values: props.values.countries,
    };
  }

  onChange = country => {
    const { countries } = this.state;

    let countriesCopy = clone(countries) ? clone(countries) : [];
    countriesCopy = countriesCopy.includes(country)
      ? difference(countriesCopy, [country])
      : concat(countriesCopy, country);

    this.setState({ countries: countriesCopy }, () =>
      this.props.handleChange(this.state.countries, 'countries'),
    );
  };

  handleChange = selectedOptions => {
    this.setState(
      {
        selectedOptions: selectedOptions,
        values: selectedOptions && selectedOptions.map(opt => opt.value),
      },
      () => this.props.handleChange(this.state.values, 'countries'),
    );
  };

  render() {
    const { availableCountries, selectedOptions } = this.state;
    const { alerts } = this.props;
    const alertStyle = alerts
      ? {
          control: styles => ({ ...styles, borderColor: 'red !important' }),
        }
      : {
          control: styles => ({ ...styles, borderColor: '#adadad !important' }),
        };
    return (
      <Select
        value={selectedOptions}
        classNamePrefix={'locale'}
        options={availableCountries}
        onChange={this.handleChange}
        isMulti={true}
        styles={alertStyle}
      />
    );
  }
}

export default withTranslation('privacyPolicyEdit.countrylist')(CountryField);
