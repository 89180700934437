import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import logo from '../../assets/img/404.svg';
import Button from '../../components/Button';

const NoMatch = () => (
  <div className='error_page'>
    <img src={logo} alt='404' />
    <Link to={'/'}>
      <Button className='btn btn_pill is-blue'>Main page</Button>
    </Link>
  </div>
);

export default withRouter(NoMatch);
