// @ts-nocheck
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import Loading from '../../components/Loading';
import AuthWrapper from '../../components/Auth';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';
import './card.css';
import CardRow from './card_row';
import NotificationModel from 'src/providers/notifications/models/notification.model';
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import { graphql } from 'react-apollo'
import Button from '../../components/Button';
import { Form, Field } from 'react-final-form';
import Styles from './Styles'
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../NewOnboarding/CompanyOnboarding/cardUtils';
import PaymentBillingForm from './Payment/index.jsx'
import { AiOutlineDelete } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { AiOutlineCheck } from "react-icons/ai";

class BillingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addCard: false,
      cardnumber: '',
      cardexpiry: '',
      cardcvc: '',
      namecard: '',
      isPrimary: false,
      userStripeSources: [],
      addCardButton: false
    }
  }
  apiCall = (value, checkIsprimary) => {

    const {
      notificationsProvider,
      t

    } = this.props
    this.setState({ addCardButton: true })
    const { client, history } = this.props;
    var values = {
      "stripeToken": value,
      "isPrimary": checkIsprimary
    }
    client
      .mutate({
        mutation: NEW_CARD_API,
        variables: values,
      })
      .then(() => {
        const notification = new NotificationModel({
          title: '',
          message: t('.card_success_added_msg'),
          type: 'success',
        })

        notificationsProvider.add(notification)
        // history.go(0)

      })
      .catch(({ graphQLErrors }) => {
        this.setState({ addCardButton: false })

        var errorDisplay = '';
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
          graphQLErrors[0].extensions.errors.map(item => {
            errorDisplay += ' ' + item.detail
          })
          const notification = new NotificationModel({
            title: '',
            message: errorDisplay,
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        else {

          this.setState({ addCardButton: false })

          const notification = new NotificationModel({
            title: '',
            message: t('.server_error'),
            type: 'alert',
          })

          notificationsProvider.add(notification)
        }
        // throw error;
      });
  }
  inprogressButton = (value) => {
    this.setState({ payButton: value })
  }
  render() {
    const { t, notificationsProvider, PrimaryMutation, history, client } = this.props;
    const { addCard, addCardButton } = this.state;

    return (
      <>
        <Query query={GET_CARDS} fetchPolicy={'network-only'}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loading />;
            if (error) return `Error! ${error.message}`;

            const { userStripeSources } = data;
            const sortingdata = userStripeSources.sort((a, b) => {
              if (a.isPrimary === b.isPrimary) {
                return 0; // No change in order if both have the same isPrimary value
              }
              return a.isPrimary ? -1 : 1; // Puts true values before false values
            });
            const dataWithIndex = sortingdata && sortingdata.map((item, index) => ({
              ...item,
              index: String(index + 1).padStart(2, '0'), // Adding 1 to make the index 1-based instead of 0-based
            }));
            const columns = [
              {
                name: '#',
                sortable: true,
                width: "70px",
                selector: row => row?.index,
                cell: (card) => {
                  return (
                    <>
                      <div>{card?.index}</div>
                    </>
                  )
                },
              },
              {
                name: t('.card_holder_name'),
                sortable: true,
                selector: row => row?.name,
                cell: (card) => {
                  return (
                    <>
                      <div>{card?.name ? card?.name : '-'}</div>
                    </>
                  )
                },
              },
              {
                name: t('.card_type'),
                sortable: true,
                selector: row => row?.cardType,
                cell: (card) => {
                  return (
                    <>
                      <div>{card?.cardType ? card?.cardType : '-'}</div>
                    </>
                  )
                },
              },
              {
                name: "Card Number",
                cell: (card) => {
                  return (
                    <>
                      <div> XXXX {card?.last4 ? card?.last4 : '-'}</div>
                    </>
                  )
                },
              },
              {
                name: t('.exp_date'),
                sortable: true,
                cell: (card) => {
                  return (
                    <>
                      <div>{card?.exMonth}/{card?.exYear}</div>
                    </>
                  )
                },
              },
              {
                name: t('.primary_card'),
                sortable: true,
                cell: (card) => {
                  return (
                    <>

                      {!card?.isPrimary ?
                        <span className='make-primary' onClick={() => {
                          if (!card?.isPrimary) {
                            PrimaryMutation({
                              variables: { id: card.id },
                            })
                              .then(response => {
                                const notification = new NotificationModel({
                                  title: '',
                                  message: t('.card_active_success'),
                                  type: 'success',
                                })

                                notificationsProvider.add(notification)
                                refetch();
                              })
                              .catch(({ graphQLErrors }) => {
                                var errorDisplay = '';
                                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                                  graphQLErrors[0].extensions.errors.map(item => {
                                    errorDisplay += ' ' + item.detail
                                  })
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: errorDisplay,
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                }
                                else {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.server_error'),
                                    type: 'alert',
                                  })

                                  notificationsProvider.add(notification)
                                }
                                // throw error;
                              });
                          }
                        }}>
                          {t('.make_primary')}
                        </span>
                        : <button className='primary-button'>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                              <path d="M1 5.49999L5.16176 9.66175L13.5 1.33823" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span>
                            {t('.default')}</span>
                        </button>}

                      {/* <div></div> */}
                    </>
                  )
                },
              },

              {
                name: "",
                cell: (card) => {
                  return (
                    <>
                      {!card?.isPrimary ?
                        <span style={{ cursor: "pointer", width: "100%" }} key={Math.random() * 10}
                          style={{ marginRight: '5px' }}
                          data-title='Remove card' onClick={() => {
                            let confirm;

                            confirm =
                              window.confirm(t('.delete_confirm_card'))

                            if (confirm) {
                              client
                                .mutate({
                                  mutation: REMOVE_CARDS,
                                  variables: {
                                    id: card.id,
                                  },
                                })
                                .then(() => {
                                  // history.go(0)

                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.remove_card_success'),
                                    type: 'success',
                                  });
                                  notificationsProvider.add(notification);
                                  refetch();
                                })
                                .catch(error => {
                                  const notification = new NotificationModel({
                                    title: '',
                                    message: t('.remove_card_error'),
                                    type: 'alert',
                                  });
                                  notificationsProvider.add(notification);
                                });
                            }
                          }} >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.34997 1.25 8.12997 2.57 7.96997 3.54L7.75997 4.82C6.82997 4.88 5.89997 4.94 4.96997 5.03L2.92997 5.23C2.50997 5.27 2.20997 5.64 2.24997 6.05C2.28997 6.46 2.64997 6.76 3.06997 6.72L5.10997 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z" fill="#292D32" />
                            <path d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z" fill="#292D32" />
                          </svg>
                        </span>
                        : null}

                    </>
                  )
                },
              },
            ];

            const tableData = {
              columns,
              data: dataWithIndex,
              exportHeaders: true,
              export: false,
              print: false,
              // filter: false
            };

            return (
              <Fragment>
                <div className='row'>
                  <div className='columns'>
                  </div>
                  <div className='columns examples margin-top-10'>
                    {!addCard ?
                      <Button className='btn btn_pill is-blue ' onClick={() => {
                        this.setState({ addCard: !addCard })
                      }}>
                        {t('.add_card')}
                      </Button>
                      :
                      <Button className='btn btn_pill is-outlined' onClick={() => {
                        this.setState({ addCard: !addCard })
                      }}>
                        {t('.cancel')}
                      </Button>
                    }

                  </div>
                </div>
                {!addCard ? (
                  <>
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={dataWithIndex}
                        className="dataTable-card"
                        noHeader
                        pagination
                        highlightOnHover
                        defaultSortField="index" // Set the default sorting field
                        defaultSortAsc={true} // Set it to false for descending order

                      />
                    </DataTableExtensions>
                    {/* <div className='row license-table-header' style={{ marginTop: '10px' }}>
                      <div className='columns license-table-row'></div>
                      <div className='columns license-table-row'>{t('.card_holder_name')}</div>
                      <div className='columns license-table-row'>{t('.card_type')}</div>
                      <div className='columns license-table-row'>{t('.last_digit')}</div>
                      <div className='columns license-table-row'> {t('.exp_date')}</div>
                      <div className='columns license-table-row'>{t('.primary_card')}</div>
                      <div className='columns license-table-row'>{t('.remove_card')}</div>
                    </div>

                    {[...userStripeSources].map(card => (
                      <CardRow key={card.id} t={t} card={card} client={client} history={history} refetchData={() => {
                        refetch()
                      }} notificationsProvider={notificationsProvider} checkActive={(data) => {
                        PrimaryMutation({
                          variables: { id: data.id },
                        })
                          .then(response => {
                            const notification = new NotificationModel({
                              title: '',
                              message: t('.card_active_success'),
                              type: 'success',
                            })

                            notificationsProvider.add(notification)
                            refetch();
                          })
                          .catch(({ graphQLErrors }) => {
                            var errorDisplay = '';
                            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                              graphQLErrors[0].extensions.errors.map(item => {
                                errorDisplay += ' ' + item.detail
                              })
                              const notification = new NotificationModel({
                                title: '',
                                message: errorDisplay,
                                type: 'alert',
                              })

                              notificationsProvider.add(notification)
                            }
                            else {
                              const notification = new NotificationModel({
                                title: '',
                                message: t('.server_error'),
                                type: 'alert',
                              })

                              notificationsProvider.add(notification)
                            }
                            // throw error;
                          });

                      }}

                      />
                    ))} */}
                  </>
                ) : (
                  <>
                    <div className="columns medium-4">
                      <PaymentBillingForm payButton={addCardButton} paysuccess={(value, checkIsprimary) => {
                        const {
                          notificationsProvider,
                          t

                        } = this.props
                        this.setState({ addCardButton: true })
                        const { client, history } = this.props;
                        var values = {
                          "stripeToken": value,
                          "isPrimary": checkIsprimary
                        }
                        client
                          .mutate({
                            mutation: NEW_CARD_API,
                            variables: values,
                          })
                          .then(() => {
                            const notification = new NotificationModel({
                              title: '',
                              message: t('.card_success_added_msg'),
                              type: 'success',
                            })

                            notificationsProvider.add(notification)
                            this.setState({ addCard: !addCard })
                            this.setState({ addCardButton: false })
                            refetch()

                          })
                          .catch(({ graphQLErrors }) => {
                            this.setState({ addCardButton: false })

                            var errorDisplay = '';
                            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                              graphQLErrors[0].extensions.errors.map(item => {
                                errorDisplay += ' ' + item.detail
                              })
                              const notification = new NotificationModel({
                                title: '',
                                message: errorDisplay,
                                type: 'alert',
                              })

                              notificationsProvider.add(notification)
                              this.setState({ addCardButton: false })

                            }
                            else {

                              this.setState({ addCardButton: false })

                              const notification = new NotificationModel({
                                title: '',
                                message: t('.server_error'),
                                type: 'alert',
                              })

                              notificationsProvider.add(notification)
                              this.setState({ addCardButton: false })

                            }
                            // throw error;
                          });
                      }} productName={""} monthlyPrice={""} inprogressButton={this.inprogressButton} />

                    </div>
                  </>
                )}

              </Fragment>
            );
          }}
        </Query>
      </>
    );
  }
}

const GET_CARDS = gql`
 query UserStripeSources{
    userStripeSources{
        id
        stripeSourceId
        last4
        exMonth
        exYear
        isPrimary
        name
        cardType
        createdAt
        updatedAt
    }
}
`;
const PRIMARY_CARDS = gql`
 mutation setPrimaryCard($id: ID!) {
    setPrimaryCard(id: $id) {
        id
    }
}
`;
const NEW_CARD_API = gql`
mutation addCard($stripeToken: String!, $isPrimary: Boolean!) {
    addCard(stripeToken: $stripeToken, isPrimary: $isPrimary) {
        id
        isPrimary
    }
}
`;

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin, userId } = auth;
  return {
    isSuperAdmin,
    userId,
  };
};
const REMOVE_CARDS = gql`
 mutation RemoveCard($id: ID!) {
    removeCard(id: $id) {
        message
    }
}
`;

export default compose(connect(mapStateToProps), graphql(PRIMARY_CARDS, { name: 'PrimaryMutation' }), withRouter, withTranslation('account_onboarding'), withNotifications())(BillingCard);
