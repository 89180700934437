import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

const ScanModal = props => {
  const { content, title } = props;
  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'> {title} </h4>
      </div>
      <p className='query_text'> {content} </p>
      <div className='scanning-animation'></div>
      {/* <Loading /> */}
    </Modal>
  );
};

ScanModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default connect(null)(ScanModal);
