import React from 'react';
import { Field } from 'redux-form';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import DropdownItem from '../Onboarding/CompanyOnboarding/dropdown';
import { organization_employees, organization_sector } from '../Onboarding/CompanyOnboarding/onboarding_data.js';

const OrganizationForm = ({
  handleSubmit,
  handleChange,
  error,
  invalid,
  submitting,
  pristine,
  sector,
  employees,
  t,
}) => {
  return (
    <div>
      <div>
        <h4> {t('.vat')} </h4>
        <Field
          name='vat'
          type='text'
          label={t('.vat_number')}
          // onChange={handleChange}
          component={renderInputField}
          readOnly={true}
        />
      </div>
      <div>
        <h4> {t('.company_billing_info')} </h4>
        <Field
          name='company_name'
          type='text'
          label={t('.company_name')}
          onChange={handleChange}
          component={renderInputField}
        />
        <div className='flex_row address_form'>
          <Field
            name='street'
            type='text'
            label={t('.street_number')}
            onChange={handleChange}
            component={renderInputField}
          />
          <Field
            name='zip_code'
            type='text'
            label={t('.zip_code')}
            onChange={handleChange}
            component={renderInputField}
          />
          <Field
            name='city'
            type='text'
            label={t('.city')}
            onChange={handleChange}
            component={renderInputField}
          />
          <Field
            name='country'
            type='text'
            label={t('.country')}
            onChange={handleChange}
            component={renderInputField}
          />
        </div>
        <div>
          <h4> {t('.company_informations')} </h4>
          <DropdownItem
            label={t('.sector')}
            name='sector'
            placeholder={t('.sector_placeholder')}
            handleChange={handleChange}
            options={organization_sector}
            value={sector}
            t={t}
          />
          <DropdownItem
            label={t('.employee_rate')}
            name='employees'
            placeholder={t('.employee_rate_placeholder')}
            handleChange={handleChange}
            options={organization_employees}
            value={employees}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationForm;
