import React from 'react';

import DropDown from '../../../containers/Onboarding/CompanyOnboarding/dropdown';
import { withLocalesAndCountries } from '../../../providers/localesAndCountries';
import { I18nConsumer } from '../../../providers/I18n/provider';

const languages = [
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'en',
    label: 'English',
  },
];

class LanguageSelection extends React.Component {
  render() {
    return (
      <I18nConsumer>
        {({ setLanguage, language }) => (
          <DropDown
            options={languages}
            handleChange={e => setLanguage(e.target.value)}
            value={language}
            name={'language'}
            isPill={true}
          />
        )}
      </I18nConsumer>
    );
  }
}

export default withLocalesAndCountries(LanguageSelection);
