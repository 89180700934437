import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formattedFormElements } from '../../containers/Questions/formattedChoices';
import countriesList from '../../helpers/Countries/countries-list.js';
import { compose } from 'recompose';
import withApollo from 'react-apollo/withApollo';
import { withTranslation } from '../../providers/I18n';

const displayMultipleAnswer = (item, values, choices, type, locale, t) => {
  const parsedChoices = choices;
  const keys = Object.keys(parsedChoices);

  let title = null;

  for (let choice of parsedChoices[keys[0]]) {
    if (choice[item.name]) {
      title = choice[item.name].name;
    }
  }

  switch (type) {
    case 'duration':
      let answer = values[0][item.name];
      if (answer && answer.name === item.value) {
        return (
          <Fragment key={item.label}>
            {
              <div>
                {title && <div className='question_item-answer-title'> {title} </div>}
                <p className='question_item-answer'>
                  {answer.name === 'other' ? `${answer.other_text[0][locale]} :` : item.label}{' '}
                  {answer.time ? answer.time : ''}{' '}
                  {answer.period ? (answer.period === 'month' ? t('.month') : t('.years')) : ''}
                </p>
              </div>
            }
          </Fragment>
        );
      }
      break;
    case 'radio':
      if (values[0][item.name] === item.value) {
        return (
          <Fragment key={item.label}>
            {
              <div>
                {title && <div className='question_item-answer-title'> {title} </div>}
                <p className='question_item-answer'> {item.label} </p>
              </div>
            }
          </Fragment>
        );
      }
      break;
  }
};

const displayAnswer = (item, checkboxOrRadio, values, type, i, locale, t) => {
  let language = localStorage.getItem('locale') || 'fr';
  let itemValue = null;

  if (item.name === 'country' && typeof item.value !== 'string') {
    let country = countriesList[language].filter(
      country => country.value === item.value['country'],
    );
    item.value = country[0] ? country[0].label : item.value;
  }

  if (type === 'text area with default' || type === 'text area') {
    itemValue = item.value.filter(value => value[locale])[0];

    if (!itemValue) return null;
  }

  return type === 'text area with default' || type === 'text area' ? (
    i === 0 && (
      <p key={i} className='question_item-answer'>
        {' '}
        {itemValue[locale].replace(/<[^>]*>/g, '')}{' '}
      </p>
    )
  ) : (
    <Fragment key={item.label}>
      {checkboxOrRadio ? (
        values.includes(item.value) && <p className='question_item-answer'> {item.label}</p>
      ) : (
        <p className='question_item-answer'>
          {' '}
          {typeof item.value === 'string' ? item.value : item.value[item.name]}{' '}
        </p>
      )}
    </Fragment>
  );
};

const QuestionAnswers = ({ question, locales, t }) => {
  const formattedAnswers = formattedFormElements(question);
  const values = question.answer.value;
  const checkboxOrRadio = question.type === 'radio' || question.type === 'checkbox';
  return (
    <div className='question_answers'>
      {formattedAnswers.map((item, i) => {
        return item instanceof Array
          ? item.map((item, index) => {
              if (
                (question.type === 'radio' || question.type === 'duration') &&
                values[0] instanceof Object
              )
                return displayMultipleAnswer(
                  item,
                  values,
                  question.choices,
                  question.type,
                  locales[0].isoCode,
                  t,
                );
              else
                return displayAnswer(
                  item,
                  checkboxOrRadio,
                  values,
                  question.type,
                  index,
                  locales[0].isoCode,
                  t,
                );
            })
          : displayAnswer(item, checkboxOrRadio, values, question.type, i, locales[0].isoCode);
      })}
    </div>
  );
};

QuestionAnswers.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default compose(withRouter, withTranslation('question'))(QuestionAnswers);

//export default withRouter(QuestionAnswers);
