// @ts-nocheck
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import Loading from '../../components/Loading';
import { withTranslation } from '../../providers/I18n';
import './licenses.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
// @ts-ignore
import enLang from '../../assets/img/enLang.png';
// @ts-ignore
import nlLang from '../../assets/img/nl-lang.png'
// @ts-ignore
import frLang from '../../assets/img/fr-lang.png'

// @ts-ignore
import deLang from '../../assets/img/deLang.png';
// @ts-ignore
import esLang from '../../assets/img/esLang.png'
// @ts-ignore
import itLang from '../../assets/img/itLang.png'


// @ts-ignore
import enGray from '../../assets/img/enGray.png';
// @ts-ignore
import nlGray from '../../assets/img/nlGray.png'
// @ts-ignore
import frGray from '../../assets/img/frGray.png'

// @ts-ignore
import deGray from '../../assets/img/deGray.png';
// @ts-ignore
import esGray from '../../assets/img/esGray.png'
// @ts-ignore
import itGray from '../../assets/img/itGray.png'



// @ts-ignore
import frIcon from '../../assets/img/fr.png';
// @ts-ignore
import belIcon from '../../assets/img/bel.png';
// @ts-ignore
import paletteOrange from '../../assets/img/palette-orange.svg';
// @ts-ignore
import paletteBlue from '../../assets/img/palette-blue.svg';
// @ts-ignore
import scriptIcon from '../../assets/img/cookie-bite-script.svg';
// @ts-ignore
import pdfIcon from '../../assets/img/pdf-icon.svg';
// @ts-ignore
import iframeIcon from '../../assets/img/globe-solid.svg';
import { loadModal } from '../../redux/modal/action.js';

import Button from 'src/components/Button/index.jsx';
import { AiFillInfoCircle } from 'react-icons/ai';

class Licenses extends React.Component {
  state = {
    page: 1,
    rowsPerPage: 10,
  };

  getPolicyType = license => {
    return license.productTypeId === 1 ? 'privacy' : 'cookie';
  };
  formatDomain = input => {
    let output = input;
    output = output.replace('www.', '');
    output = output.replace('http://', '');
    output = output.replace('https://', '');
    return output;
  };
  render() {
    const { t, userId, loadModal } = this.props;
    const { page, rowsPerPage } = this.state;
    const loadUserModal = license => {
      const { id, maxNoOfUsers } = license;
      loadModal({
        modalType: 'all_users_modal',
        content: {
          licenseId: id,
          maxNoOfUsers,
          selectedLicense: license,
        },
        title: t('.all_users_title'),
        t,
      });
    };
    const viewDetailsModal = data => {
      loadModal({
        modalType: 'subscription_details_model',
        content: {
          id: data?.subscriptionId,
          liecenseId: data?.id,
        },
        title: '',
        t,
      });
    };
    const columns = [
      {
        name: 'ID',
        sortable: true,
        selector: row => row?.id,
        cell: license => {
          return (
            <>
              <div class={`circle ${license.productTypeId === 1 ? 'circle' : 'yellow-circle'}`}>
                {license?.id}
              </div>
            </>
          );
        },
      },
      {
        name: (
          <>
            <div>
              <p>{t('.license_domain_name')}</p>
            </div>
          </>
        ),
        selector: row => row['name'],
        sortable: true,
        width: "200px",
        cell: license => {
          if (license.product !== null && !license?.isExpired) {
            return (
              <>
                <div>
                  <Link
                    to={`/${this.getPolicyType(license)}-policies/${license.product.id}`}
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                  >
                    {license.name}
                  </Link>
                  <div
                    className='domain-name-wrapper'
                    style={{ marginBottom: '12px', marginTop: '12px' }}
                  >
                    <img src={iframeIcon} style={{ width: '15px', height: '15px' }} alt='' />
                    <span>{license.domainName ? this.formatDomain(license.domainName) : '-'}</span>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div>
                  <div style={{ marginBottom: '20px', marginTop: '20px' }}>{license.name}</div>
                  <div>{license.domainName ? this.formatDomain(license.domainName) : '-'}</div>
                </div>
              </>
            );
          }
        },
      },
      {
        name: (
          <>
            <div>
              <p>{t('.license_type')}</p>
            </div>
          </>
        ),

        selector: row => row['productTypeId'],
        sortable: true,
        wrap: true,
        cell: license => {
          if (license.productTypeId === 1) {
            return (
              <>
                <div
                  style={{ marginBottom: '20px', marginTop: '20px' }}
                  className='license-privacy-type-main'
                >
                  <span>{t('.privacy_policy')}</span>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  style={{ marginBottom: '20px', marginTop: '20px' }}
                  className='license-cookie-type-main'
                >
                  <span>{t('.cookie_policy')}</span>
                </div>
              </>
            );
          }
        },
      },
      // {
      //   name: <>
      //     <div>
      //       <p>{t('.domain')}</p>
      //     </div>
      //   </>,
      //   selector: 'domainName',
      //   sortable: true,
      //   wrap: true,
      //   cell: (license) => {
      //     return (
      //       <div style={{ marginBottom: "20px", marginTop: "20px" }}>
      //         {license.domainName ? this.formatDomain(license.domainName) : '-'}
      //       </div>
      //     )

      //   },
      // },
      {
        name: (
          <>
            <div>
              <p>
                {t(`.valid_from`)} - {t(`.valid_to`)}
              </p>
              {/* <p></p> */}
            </div>
          </>
        ),
        wrap: true,
        cell: license => {
          return (
            <>
              <div>
                <div style={{ marginBottom: '20px', marginTop: '20px' }} className='license-valid'>
                  <p>{license.validFrom}</p>
                </div>
                <div style={{ marginBottom: '20px', marginTop: '20px' }} className='license-valid'>
                  <p>{license.validTo}</p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        name: <>
          <div className='license-traffic'>
            <p>{t('.traffic_limit')} </p>
            <AiFillInfoCircle fontSize={15} />
            {/* {t('.traffic_limit')} */}
          </div>
        </>,
        sortable: true,
        wrap: true,
        selector: row => row?.trafficLimit,
        cell: license => {
          return (
            <>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>{license.trafficLimit}</div>
            </>
          );
        },
      },
      {
        name: <>
          <div>
            <p>Format (PDF/Web)</p>
          </div>
        </>,
        wrap: true,
        _cell: license => {
          return (
            <>
              <div style={{ marginBottom: '20px', marginTop: '20px' }} className='license-format'>
                {license.features.map(subitem => {
                  if (subitem.groupCode === 'format') {
                    if (subitem.name === 'PDF version')
                      return (
                        <span key={Math.random() * 10} data-title='PDF version'>
                          {/* <img src={pdfIcon} style={{ width: '20px', height: '20px' }} alt='' /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M18.3332 8.33329V12.5C18.3332 16.6666 16.6665 18.3333 12.4998 18.3333H7.49984C3.33317 18.3333 1.6665 16.6666 1.6665 12.5V7.49996C1.6665 3.33329 3.33317 1.66663 7.49984 1.66663H11.6665" stroke="#0028E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.3332 8.33329H14.9998C12.4998 8.33329 11.6665 7.49996 11.6665 4.99996V1.66663L18.3332 8.33329Z" stroke="#0028E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.5166 10.9166H6.06244C6.46688 10.9166 6.80521 11.0527 7.07744 11.325C7.35355 11.6011 7.4916 11.9316 7.4916 12.3166C7.4916 12.7016 7.35355 13.0322 7.07744 13.3083C6.80521 13.5805 6.46688 13.7166 6.06244 13.7166H5.44994V15H4.5166V10.9166ZM5.44994 12.8416H6.06244C6.20632 12.8416 6.32494 12.7911 6.41827 12.69C6.5116 12.5888 6.55827 12.4644 6.55827 12.3166C6.55827 12.1688 6.5116 12.0444 6.41827 11.9433C6.32494 11.8422 6.20632 11.7916 6.06244 11.7916H5.44994V12.8416Z" fill="#0028E6" />
                            <path d="M7.95736 10.9166H9.59069C10.1507 10.9166 10.6212 11.113 11.0024 11.5058C11.3835 11.8986 11.574 12.3827 11.574 12.9583C11.574 13.5377 11.3835 14.0238 11.0024 14.4166C10.6251 14.8055 10.1546 15 9.59069 15H7.95736V10.9166ZM8.89069 14.1016H9.59069C9.91347 14.1016 10.174 13.9986 10.3724 13.7925C10.5746 13.5863 10.6757 13.3083 10.6757 12.9583C10.6757 12.6083 10.5746 12.3322 10.3724 12.13C10.174 11.92 9.91347 11.815 9.59069 11.815H8.89069V14.1016Z" fill="#0028E6" />
                            <path d="M14.578 10.9166V11.815H13.0321V12.6025H14.5488V13.5008H13.0321V15H12.0988V10.9166H14.578Z" fill="#0028E6" />
                          </svg>
                        </span>
                      );
                    if (subitem.name === 'Web version')
                      return (
                        <span key={Math.random() * 10} data-title='Web version'>
                          <img src={iframeIcon} style={{ width: '20px', height: '20px' }} alt='' />
                        </span>
                      );
                  }
                })}
                {license.features.map(subitem => {
                  if (subitem.groupCode === 'personalisation') {
                    if (subitem.name === 'Cookie policy personalisation')
                      return (
                        <span
                          key={Math.random() * 10}
                          style={{ marginRight: '5px' }}
                          data-title='Cookie policy personalisation'
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_476_3516)">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 1.66663C14.6022 1.66663 18.3332 5.39758 18.3332 9.99996C18.3332 10.5406 18.2816 11.07 18.1828 11.5832C17.8725 13.1957 16.2994 13.9092 14.9274 13.7459L14.7813 13.725L13.3313 13.4834C12.989 13.4263 12.6402 13.5381 12.3948 13.7835C12.0866 14.0917 11.9393 14.5155 12.1467 14.9302C12.4989 15.6347 12.5038 16.4402 12.1925 17.0995C11.7754 17.9828 10.9245 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39758 5.39746 1.66663 9.99984 1.66663Z" stroke="#0028E6" stroke-width="1.5" />
                              <path d="M12.0786 7.08329H12.0861" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.91209 7.08329H7.91957" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M6.24559 10.4167H6.25307" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_476_3516">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {/* <img src={paletteBlue} style={{ width: '20px', height: '20px' }} alt='' /> */}
                        </span>
                      );
                    if (subitem.name === 'Banner personalisation')
                      return (
                        <span key={Math.random() * 10} data-title='Banner personalisation'>
                          <img
                            src={paletteOrange}
                            style={{ width: '20px', height: '20px' }}
                            alt='' />
                        </span>
                      );
                    if (subitem.name === 'Privacy policy personalisation')
                      return (
                        <span key={Math.random() * 10} data-title='Privacy policy personalisation'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_476_3516)">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 1.66663C14.6022 1.66663 18.3332 5.39758 18.3332 9.99996C18.3332 10.5406 18.2816 11.07 18.1828 11.5832C17.8725 13.1957 16.2994 13.9092 14.9274 13.7459L14.7813 13.725L13.3313 13.4834C12.989 13.4263 12.6402 13.5381 12.3948 13.7835C12.0866 14.0917 11.9393 14.5155 12.1467 14.9302C12.4989 15.6347 12.5038 16.4402 12.1925 17.0995C11.7754 17.9828 10.9245 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39758 5.39746 1.66663 9.99984 1.66663Z" stroke="#0028E6" stroke-width="1.5" />
                              <path d="M12.0786 7.08329H12.0861" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.91209 7.08329H7.91957" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M6.24559 10.4167H6.25307" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_476_3516">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {/* <img src={paletteBlue} style={{ width: '20px', height: '20px' }} alt='' /> */}
                        </span>
                      );
                  }
                })}
                {license?.allowVersion ? (
                  <>
                    <span
                      key={Math.random() * 10}
                      data-title='Versioning'
                      style={{ width: '20px', height: '20px' }}
                    >
                      <i
                        className='fas fa-file-export icon-color'
                        style={{ cursor: 'pointer', fontSize: 20 }}
                      ></i>
                    </span>
                  </>
                ) : null}
              </div>
            </>
          );
        },
        get cell() {
          return this._cell;
        },
        set cell(value) {
          this._cell = value;
        },
      },
      ,
      {
        name: <>
          <div>
            <p> {t('.legislation')} {t('.languages')}</p>
            {/* <p>{t('.legislation')}</p>
            <p>{t('.languages')}</p> */}
          </div>
        </>,
        wrap: true,
        cell: license => {
          return (
            <>
              <div style={{ marginBottom: '20px', marginTop: '20px' }} >
                <div style={{ marginBottom: '10px', display: 'flex', gap: 8 }}>
                  {license.features.map(item => {
                    if (item.groupCode === 'legislation') {
                      if (item.name === 'France')
                        return (
                          <>
                            <span
                              key={Math.random() * 10}
                              style={{ marginRight: '5px' }}
                              data-title='France'
                            >
                              <div className='div1-legislation'>
                                <img src={frIcon} alt='' className='div1-legislatione-image' />
                                {!item.active ? <div className='div1-shadow'></div> : null}
                                <div
                                  className={`div1-dot ${item.active ? 'div1-dot-green' : 'div1-dot-red'
                                    }`}
                                ></div>
                              </div>
                            </span>
                          </>
                        );
                      if (item.name === 'Belgium')
                        return (
                          <>
                            <span
                              key={Math.random() * 10}
                              style={{ marginRight: '5px' }}
                              data-title='Belgium'
                            >
                              <div className='div1-legislation'>
                                <img src={belIcon} alt='' className='div1-legislatione-image' />
                                {!item.active ? <div className='div1-shadow'></div> : null}
                                <div
                                  className={`div1-dot ${item.active ? 'div1-dot-green' : 'div1-dot-red'
                                    }`}
                                ></div>
                              </div>
                            </span>
                          </>
                        );
                    }
                  })}
                </div>
                <div className='columns padding-0' style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 5
                }}>
                  {license.features.map(item => {
                    if (item.groupCode === 'language') {
                      if (item.name === 'English')
                        return (

                          item.active ? <span
                            key={Math.random() * 10}

                            data-title='English'
                          >
                            <img src={enLang} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span> : <span
                            key={Math.random() * 10}

                            data-title='English'
                          >
                            <img src={enGray} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span>


                        )
                      if (item.name === 'French')
                        return (
                          item.active ? <span key={Math.random() * 10} data-title='French'>
                            <img src={frLang} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span> : <span key={Math.random() * 10} data-title='French'>
                            <img src={frGray} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span>
                        )
                      if (item.name === 'Dutch')
                        return (
                          item.active ? <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Dutch'>
                            <img src={nlLang} style={{ width: '24px' }} alt='' />
                          </span> : <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Dutch'>
                            <img src={nlGray} style={{ width: '24px' }} alt='' />
                          </span>
                        )
                      if (item.name === 'German')
                        return (
                          item.active ? <span
                            key={Math.random() * 10}

                            data-title='German'
                          >
                            <img src={deLang} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span> :
                            <span
                              key={Math.random() * 10}
                              data-title='German'
                            >
                              <img src={deGray} style={{ width: '24px', marginBottom: 10 }} alt='' />
                            </span>
                        )
                      if (item.name === 'Spanish')
                        return (
                          item.active ? <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Spanish'>
                            <img src={esLang} style={{ width: '24px' }} alt='' />
                          </span> : <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Spanish'>
                            <img src={esGray} style={{ width: '24px', marginBottom: 10 }} alt='' />
                          </span>
                        )
                      if (item.name === 'Italian')
                        return (
                          item.active ? <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Italian'>
                            <img src={itLang} style={{ width: '24px' }} alt='' />
                          </span> : <span key={Math.random() * 10} style={{ marginBottom: 10 }} data-title='Italian'>
                            <img src={itGray} style={{ width: '24px' }} alt='' />
                          </span>
                        )
                    }
                  })}
                </div>
              </div>
            </>
          );
        },
      },
      {
        name: t('.banner'),
        wrap: true,
        cell: license => {
          return (
            <>
              <div style={{ marginBottom: '20px', marginTop: '20px' }} className='license-banner'>
                {license.features.map(subitem => {
                  if (subitem.groupCode === 'banner') {
                    if (subitem.name === 'Script')
                      return (
                        <span
                          key={Math.random() * 10}
                          style={{ marginRight: '5px' }}
                          data-title='Script '
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_476_3532)">
                              <path d="M17.4998 9.16663C17.1349 9.16458 16.7807 9.0428 16.4917 8.82C16.2026 8.5972 15.9947 8.28569 15.8998 7.93329C15.8697 7.8187 15.8154 7.71185 15.7407 7.61988C15.666 7.52791 15.5725 7.45294 15.4665 7.39996C15.3583 7.35458 15.2422 7.33121 15.1248 7.33121C15.0075 7.33121 14.8914 7.35458 14.7832 7.39996C14.5862 7.47349 14.3766 7.50747 14.1665 7.49996C13.7245 7.49996 13.3006 7.32436 12.988 7.0118C12.6754 6.69924 12.4998 6.27532 12.4998 5.83329C12.5003 5.62149 12.5428 5.41188 12.6248 5.21663C12.6702 5.10843 12.6936 4.99228 12.6936 4.87496C12.6936 4.75763 12.6702 4.64149 12.6248 4.53329C12.5719 4.4273 12.4969 4.33382 12.4049 4.25909C12.313 4.18437 12.2061 4.13012 12.0915 4.09996C11.7345 4.00975 11.4174 3.80384 11.1897 3.51437C10.9621 3.22491 10.8367 2.8682 10.8332 2.49996C10.8332 2.27895 10.7454 2.06698 10.5891 1.9107C10.4328 1.75442 10.2209 1.66663 9.99984 1.66663C8.35166 1.66663 6.7405 2.15537 5.37009 3.07105C3.99968 3.98672 2.93158 5.28821 2.30084 6.81093C1.67011 8.33365 1.50509 10.0092 1.82663 11.6257C2.14817 13.2422 2.94185 14.7271 4.10728 15.8925C5.27272 17.058 6.75758 17.8516 8.37409 18.1732C9.9906 18.4947 11.6662 18.3297 13.1889 17.699C14.7116 17.0682 16.0131 16.0001 16.9288 14.6297C17.8444 13.2593 18.3332 11.6481 18.3332 9.99996C18.3332 9.77894 18.2454 9.56698 18.0891 9.4107C17.9328 9.25442 17.7209 9.16663 17.4998 9.16663Z" stroke="#0028E6" stroke-width="1.5" />
                              <path d="M13.7456 12.0833H13.7531" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.91209 14.5833H7.91957" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M6.24559 10.4167H6.25307" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.74559 5.41667H8.75307" stroke="#0028E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_476_3532">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {/* <img src={scriptIcon} style={{ width: '24px', height: '24px' }} alt='' /> */}
                        </span>
                      );
                    if (subitem.name === 'Consent proving database')
                      return (
                        <span
                          key={Math.random() * 10}
                          style={{ marginRight: '5px' }}
                          className='consent-tooltip exportTooltip'
                          data-title='Consent proving database'
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M6.6665 1.66663V4.16663" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.3335 1.66663V4.16663" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 10.1C10.8331 10.1 11.5084 9.42474 11.5084 8.59171C11.5084 7.75868 10.8331 7.08337 10 7.08337C9.167 7.08337 8.4917 7.75868 8.4917 8.59171C8.4917 9.42474 9.167 10.1 10 10.1Z" stroke="#0028E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.9168 14.3C12.8002 13.0917 11.8418 12.1417 10.6335 12.0333C10.2168 11.9917 9.79183 11.9917 9.36683 12.0333C8.1585 12.15 7.20016 13.0917 7.0835 14.3" stroke="#0028E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {/* <svg
                            stroke='currentColor'
                            fill='#0028e6'
                            strokeWidth='0'
                            viewBox='0 0 1024 1024'
                            className='mr-05'
                            width='24px'
                            height={'24px'}
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M594.3 601.5a111.8 111.8 0 0 0 29.1-75.5c0-61.9-49.9-112-111.4-112s-111.4 50.1-111.4 112c0 29.1 11 55.5 29.1 75.5a158.09 158.09 0 0 0-74.6 126.1 8 8 0 0 0 8 8.4H407c4.2 0 7.6-3.3 7.9-7.5 3.8-50.6 46-90.5 97.2-90.5s93.4 40 97.2 90.5c.3 4.2 3.7 7.5 7.9 7.5H661a8 8 0 0 0 8-8.4c-2.8-53.3-32-99.7-74.7-126.1zM512 578c-28.5 0-51.7-23.3-51.7-52s23.2-52 51.7-52 51.7 23.3 51.7 52-23.2 52-51.7 52zm416-354H768v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H548v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H328v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H96c-17.7 0-32 14.3-32 32v576c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32zm-40 568H136V296h120v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h120v496z'></path>
                          </svg> */}
                        </span>
                      );
                  }
                })}
                {license?.allowExport ? (
                  <>
                    <span
                      className='exportTooltip'
                      key={Math.random() * 10}
                      style={{ marginRight: '5px' }}
                      data-title='Export consent database'
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.7666 9.7334L9.89994 11.8667L12.0333 9.7334" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.8999 3.33337V11.8084" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.6668 10.15C16.6668 13.8334 14.1668 16.8167 10.0002 16.8167C5.8335 16.8167 3.3335 13.8334 3.3335 10.15" stroke="#0028E6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      {/* <i
                        className='fas fa-download icon-color'
                        style={{ cursor: 'pointer', fontSize: 24 }}
                      ></i> */}
                    </span>
                  </>
                ) : null}
              </div>
            </>
          );
        },
      },
      {
        name: (
          <>
            <div>
              <p>
                Action
                {/* {t('.license_view')} */}
              </p>
            </div>
          </>
        ),
        wrap: true,
        width: "150px",
        cell: license => {
          return (
            <>
              <div className='license-format'>
                {license?.subscriptionId && !license?.isExpired ? (
                  <>
                    <span className='licenseTooltip' onClick={() => viewDetailsModal(license)} key={Math.random() * 10}
                      style={{ marginRight: '5px' }}
                      data-title={t('.license_view')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z" fill="#0D0D0D" />
                        <path d="M11.9999 9.13989C10.4299 9.13989 9.1499 10.4199 9.1499 11.9999C9.1499 13.5699 10.4299 14.8499 11.9999 14.8499C13.5699 14.8499 14.8599 13.5699 14.8599 11.9999C14.8599 10.4299 13.5699 9.13989 11.9999 9.13989Z" fill="#0D0D0D" />
                      </svg>
                    </span>
                  </>
                ) : null}
                {!license?.isExpired ? (
                  <>
                    <span onClick={() => loadUserModal(license)} key={Math.random() * 10}
                      style={{ marginRight: '5px' }}
                      data-title={t('.users_list')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill="#0D0D0D" />
                        <path d="M17.08 14.1499C14.29 12.2899 9.73996 12.2899 6.92996 14.1499C5.65996 14.9999 4.95996 16.1499 4.95996 17.3799C4.95996 18.6099 5.65996 19.7499 6.91996 20.5899C8.31996 21.5299 10.16 21.9999 12 21.9999C13.84 21.9999 15.68 21.5299 17.08 20.5899C18.34 19.7399 19.04 18.5999 19.04 17.3599C19.03 16.1299 18.34 14.9899 17.08 14.1499Z" fill="#0D0D0D" />
                      </svg>
                    </span>
                  </>
                ) : null}
              </div>
            </>
          );
        },
      },
    ];

    return (
      <Query
        query={ALL_LICENSES}
        fetchPolicy={'network-only'}
        variables={{
          userId,
          perPage: rowsPerPage,
          page: page,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return `Error! ${error.message}`;
          const { pagyLicenses } = data;
          const totalCount = pagyLicenses[0].pagination.totalCount;
          const currentPage = pagyLicenses[0].pagination.currentPage;

          const dataWithIndex =
            pagyLicenses &&
            pagyLicenses[0].licenses.map((item, index) => ({
              ...item,
              index: index + 1, // Adding 1 to make the index 1-based instead of 0-based
            }));

          const tableData = {
            columns,
            data: dataWithIndex,
            exportHeaders: true,
            export: false,
            print: false,
            // filter: false
          };
          return (
            <>
              <div className='button-class'>
                <DataTableExtensions {...tableData}>
                  <DataTable
                    onChangePage={e => {
                      this.setState({ ...this.state, page: e });
                    }}
                    columns={columns}
                    data={dataWithIndex}
                    className='dataTable-license'
                    noHeader
                    pagination
                    highlightOnHover
                    defaultSortField='index' // Set the default sorting field
                    defaultSortAsc={true} // Set it to false for descending order
                    paginationPerPage={rowsPerPage}
                    paginationTotalRows={totalCount}
                    paginationServer
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={e => {
                      if (e * 0.7 > totalCount) {
                        return;
                      } else {
                        this.setState({ ...this.state, rowsPerPage: e });
                      }
                    }}
                  />
                </DataTableExtensions>
              </div>
              {/* <div className='row license-table-header' style={{ marginTop: '10px' }}>
                <div className='columns license-table-row'>{t('.license_name')}</div>
                <div className='columns license-table-row'>{t('.license_type')}</div>
                <div className='columns license-table-row'>{t('.domain')}</div>
                <div className='columns license-table-row'>
                  <p>{t(`.valid_from`)}</p>
                  <p>{t(`.valid_to`)}</p>
                </div>

                <div className='columns license-table-row'>{t('.traffic_limit')}</div>
                <div className='columns license-table-row'>
                  <p>Format</p>
                  <p>(PDF/Web) /</p>
                  <p>Details</p>
                </div>
                <div className='columns license-table-row'>
                  <p>{t('.legislation')}</p>
                  <p>{t('.languages')}</p>
                </div>
                <div className='columns license-table-row'>{t('.banner')}</div>
                {isSuperAdmin && (
                  <div className='columns license-table-row'>{t('.users_list')}</div>
                )}
                <div className='columns license-table-row'>{t('.license_view')}</div>

              </div> */}

              {/* {[...licenses].reverse().map(license => (
                <LicenseRow key={license.id} t={t} license={license} />
              ))} */}
            </>
          );
        }}
      </Query>
    );
  }
}

const ALL_LICENSES = gql`
  query allLicenses($userId: ID!, $page: Int, $perPage: Int) {
    pagyLicenses(userId: $userId, page: $page, perPage: $perPage) {
      pagination {
        totalCount
        prevPage
        nextPage
        currentPage
        totalPages
      }
      licenses {
        id
        subscriptionId
        name
        domainName
        productTypeId
        organizationId
        features {
          id
          name
          code
          preselect
          groupName
          groupCode
          active
        }
        trafficLimit
        productType {
          id
          name
          code
        }
        product {
          id
          title
        }
        validFrom
        validTo
        createdAt
        updatedAt
      }
    }
  }
`;

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin, userId } = auth;
  return {
    isSuperAdmin,
    userId,
  };
};
const mapDispatchToProps = {
  loadModal,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation('licenses'),
)(Licenses);
