import React, { useEffect } from 'react'
import { withNotifications, NotificationModel } from '../../providers/notifications'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { withTranslation } from '../../providers/I18n/'
import { withApollo } from 'react-apollo'

const AuthenticateProduct = props => {
  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const token = params.get('token')
    authenticateProduct(token)
  }, [])

  const { t, notificationsProvider } = props

  const authenticateProduct = token => {
    const { client, history } = props

    client
      .mutate({
        mutation: CONFIRM_ACCESS,
        variables: { confirmationCode: token },
      })
      .then(() => {
        const notification = new NotificationModel({
          title: t('.success_adding_license_title_modal'),
          message: t('.success_sign_in_message_modal'),
          type: 'success',
        })

        notificationsProvider.add(notification)
        history.replace('/')
      })
      .catch(error => {
        error.graphQLErrors.map(({ message, extensions }, i) => {})
      })
  }

  return null
}

const CONFIRM_ACCESS = gql`
  mutation ConfirmAccess($confirmationCode: String!) {
    confirmAccess(confirmationCode: $confirmationCode) {
      id
    }
  }
`

export default compose(
  withRouter,
  withApollo,
  withNotifications(),
  withTranslation('unAuthenticatedForm'),
)(AuthenticateProduct)
