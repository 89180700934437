import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withApollo from 'react-apollo/withApollo'
import gql from 'graphql-tag';

import Modal from '../../../components/Modal'
import { hideModal } from '../../../redux/modal/action'
import withTranslation from '../../../providers/I18n/HoC/t'
import Button from 'src/components/Button'
import withNotifications from 'src/providers/notifications/HoC/withNotifications';
import NotificationModel from 'src/providers/notifications/models/notification.model';
const LicensesDetailsCancelModel = ({
    title,
    closeExampleModal,
    content: { id },
    t,
    client,
    notificationsProvider,
    history,
}) => {
    return (
        <Modal>
            <div className='modal_bar'>
                <h4 className='modal_title'>{title}</h4>
                <button type='button' className='close' onClick={closeExampleModal} />
            </div>
            <div className='modal_content'>
                {t('.cancel_model_text')}
                <div className='list_buttons'>

                    <Button
                        className='btn btn_pill is-blue'
                        published
                        onClick={closeExampleModal}
                    >
                        {t('.back_to_application')}
                    </Button>
                    <Button
                        className='btn btn_pill is-error-sub'
                        published
                        onClick={() => {
                            client.mutate({
                                mutation: CANCEL_SUBSCRIPTION,
                                variables: {
                                    id,
                                },
                            }).then(({ data }) => {
                                closeExampleModal()
                                const notification = new NotificationModel({
                                    title: '',
                                    message: t('.cancel_message_success'),
                                    type: 'success',
                                })
                                notificationsProvider.add(notification);
                                history.go(0);
                            }).catch(({ graphQLErrors }) => {
                                var errorDisplay = '';
                                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.errors && graphQLErrors[0].extensions.errors.length > 0) {
                                    graphQLErrors[0].extensions.errors.map(item => {
                                        errorDisplay += ' ' + item.detail
                                    })
                                    const notification = new NotificationModel({
                                        title: '',
                                        message: errorDisplay,
                                        type: 'alert',
                                    })

                                    notificationsProvider.add(notification)
                                }
                                else {
                                    const notification = new NotificationModel({
                                        title: '',
                                        message: t('.server_error'),
                                        type: 'alert',
                                    })

                                    notificationsProvider.add(notification)
                                }
                                // throw error;
                            });
                        }}
                    >
                        {t('.yes_text')}
                    </Button>

                </div>
            </div>

        </Modal>
    )
}

LicensesDetailsCancelModel.propTypes = {
    title: PropTypes.string.isRequired,
    closeExampleModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    closeExampleModal: hideModal,
}
const CANCEL_SUBSCRIPTION = gql`
mutation CancelSubscription($id: ID!) {
    cancelSubscription(id: $id) {
        id
    }
}
`
export default compose(
    connect(null, mapDispatchToProps),
    withApollo,
    withRouter,
    withNotifications(),
    withTranslation('dashboard'),
)(LicensesDetailsCancelModel)
