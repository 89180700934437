import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { withApollo } from 'react-apollo'
import { Formik } from 'formik'

import CountryField from './Fields/country_field'
import LocaleField from './Fields/locale_field'
import ColorField from './Fields/color_field'
import PolicyField from './Fields/policy_field'
import EditModule from './edit_module'
import { withLocalesAndCountries } from '../../providers/localesAndCountries'
import mapInputs from '../../helpers/locales'
import Button from '../../components/Button'
import { withNotifications, NotificationModel } from '../../providers/notifications'
import { withTranslation } from '../../providers/I18n'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'
import { AiOutlineWarning, AiOutlineInfoCircle } from 'react-icons/ai'
import gql from 'graphql-tag'

class EditForm extends React.Component {
  constructor(props) {
    super(props)
    const { pp, title, domainName, locales } = props
    const formType = this.props.formType
    this.state = {
      title: title,
      domainName: domainName,
      userEmail: '',
      cpUrl: (pp && !pp.cookiePolicy && pp.customization && pp.customization.externalUrl) ? (pp && pp.customization && pp.customization.externalUrl) : null,
      cpId: pp && pp.cookiePolicy && pp.cookiePolicy.id,
      countries:
        pp &&
        pp.license.features.reduce(
          (arr, item) => (
            item.groupCode === 'legislation' && item.active && arr.push(item['code']), arr
          ),
          [],
        ),
      customization: (pp && pp.customization) || {},
      locales,
      policyType: this.isCookiePolicy(props.match.url) ? 'cookie' : 'privacy',
      invalid: formType === 'new',
      isCpLinked: false,
      titleAlert: false,
      domainNameAlert: false,
      differentDomainsAlert: false,
      countriesAlert: false,
      localesAlert: false,
      cpLinkAlert: false,
      cp_origin: true,
      availableLanguages: [],
      availableLegislation: []
    }

    this.onChange = this.onChange.bind(this)
    this.fetchlanguage();
    this.fetchLegislation();
  }
  fetchlanguage = () => {
    const { client } = this.props
    client
      .query({
        query: GET_AVAILABLE_LANGAGES,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const { availableLanguages } = data
        const languages = availableLanguages.reduce(
          (arr, item) => (item.active && arr.push(item['code'].split('language_')?.[1]), arr),
          [],
        );
        this.setState({ availableLanguages: languages });
      });
  };
  fetchLegislation = () => {
    const { client } = this.props
    client
      .query({
        query: GET_AVAILABLE_LEGISLATION,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const { availableLegislations } = data
        const legislations = availableLegislations.reduce(
          (arr, item) => (item.active && arr.push(item['code'].split('legislation_')?.[1]), arr),
          [],
        );
        this.setState({ availableLegislation: legislations });
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        title: this.props.title,
        domainName: this.props.domainName,
        countries: undefined,
        locales: this.props.locales,
        invalid: true
      })
    }
  }


  isCookiePolicy = url => {
    const arr = url.split(/[\/]/g)

    const type = arr.filter(item => {
      return item.match(/(?:^|\W)policies(?:$|\W)/g)
    })[0]

    return type === 'cookie-policies'
  }

  onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  onSubmit = () => {
    const { countries, locales, cpUrl, customization, title, domainName } = this.state
    const values = {}

    let valueToMap = {}

    countries &&
      countries.map(country => {
        valueToMap[`country_${country}`] = true
      })
    locales &&
      locales.map(locale => {
        valueToMap[`locale_${locale}`] = true
      })
    valueToMap.title = title
    valueToMap.cpUrl = cpUrl

    const groupedValues = mapInputs(valueToMap)

    const c = {}
    if (customization.colors) c.colors = customization.colors

    if (!_.isEmpty(c)) {
      groupedValues.customization = c
      values.customization = c
    }

    values.title = title
    values.domainName = domainName
    values.legislation = countries
    values.languages = locales
    values.cpUrl = cpUrl

    this.updatePolicy(values)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.validateFormInputs(e.target.name, e.target.value)
  }

  onLocaleAndCountryChange = (values, type) => {
    this.setState({ [type]: values })
    this.validateFormInputs(type, values)
  }

  handleLinkToPolicy = selectedPolicy => {
    this.setState({
      cpId: selectedPolicy.value,
      cpUrl: selectedPolicy.domainName,
    })
  }

  validateFormInputs = (type, value) => {
    setTimeout(() => {

      const formType = this.props.formType
      let invalid;
      let titleAlert
      let domainNameAlert
      let countriesAlert
      let localesAlert
      let productLinkAlert
      let differentDomainsAlert

      const { locales, title, domainName, countries, productUrl, cpUrl, cp_origin } = this.state;
      if ((!title && type !== 'title') || (type === 'title' && value === '')) {
        invalid = true
        titleAlert = true
      } else {
        titleAlert = false
      }
      if (
        ((!domainName || !this.validateUrl(domainName)) && type !== 'domainName') ||
        (type === 'domainName' && (value === '' || !this.validateUrl(value)))
      ) {
        invalid = true
        domainNameAlert = true
      } else {
        domainNameAlert = false
        differentDomainsAlert = false
      }

      // if (
      //   ((!countries || (Array.isArray(this.state.countries) && !countries.length)) &&
      //     type !== 'countries') ||
      //   (type === 'countries' && !value) ||
      //   (Array.isArray(value) && !value.length)
      // ) {
      //   invalid = true
      //   countriesAlert = true
      // } else {
      //   countriesAlert = false
      // }

      if (
        ((!locales || (Array.isArray(this.state.locales) && !locales.length)) &&
          type !== 'locales') ||
        (type === 'locales' && !value) ||
        (Array.isArray(value) && !value.length)
      ) {
        invalid = true
        localesAlert = true
      } else {
        localesAlert = false
      }

      // product specific - link PP <-> CP

      if (type === 'isCpLinked' && value) {
        invalid = true
        this.setState({ isCpLinked: true })
      }

      if (type === 'linkExistingPolicies') {
        if (!value) {
          invalid = true
        }
        else if (value && value !== domainName) {
          invalid = true
          productLinkAlert = true
          differentDomainsAlert = true
        } else {
          productLinkAlert = false
          differentDomainsAlert = false
          // invalid = false
        }
      }

      if (type === 'productUrl') {
        if (value === '' || value !== domainName) {
          invalid = true
          differentDomainsAlert = true
          productLinkAlert = true
        } else {
          differentDomainsAlert = false
          productLinkAlert = false
        }
      }
      if (type !== 'linkExistingPolicies' && this.state.isCpLinked && cp_origin === true) {
        if (cpUrl) {
          if (this.state.domainName !== cpUrl) {
            invalid = true;
          }
        }
      }
      if (cp_origin === true && this.state.isCpLinked) {
        if (this.state.domainName !== cpUrl || !this.state.domainName || !cpUrl) {
          invalid = true;
        }
      }
      if (cp_origin === false) {
        if (!cpUrl || !this.validateUrl(cpUrl)) {
          invalid = true;
          this.setState({ cpLinkAlert: true })

        }
        else {
          this.setState({ cpLinkAlert: false })

        }
      }
      else {
        this.setState({ cpLinkAlert: false })

      }
      this.setState({
        invalid,
        titleAlert,
        domainNameAlert,
        countriesAlert,
        localesAlert,
        productLinkAlert,
        differentDomainsAlert,
      })
    }, 100)
  }

  validateUrl = input => {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(
      input,
    )
  }

  onCustomizationChange = (key, value) => {
    this.setState({ customization: { ...this.state.customization, [key]: value } })
  }

  linkCpToPp = async id => {
    const { client, linkPolicyMutation } = this.props
    const { cpId } = this.state

    return await client.mutate({
      mutation: linkPolicyMutation,
      variables: {
        parentProductId: id,
        childProductId: cpId,
      },
    })
  }

  updatePolicy = values => {
    const {
      pp,
      client,
      graphqlMutation,
      history,
      notificationsProvider,
      selectedLicense,
      match: {
        params: { id },
      },
      t,
    } = this.props
    const { policyType, cpId } = this.state

    let customization = {}

    if (!cpId && values.cpUrl) {
      customization['externalUrl'] = values.cpUrl
    }

    if (values.customization && values.customization.colors !== undefined) {
      customization['colors'] = values.customization.colors
    }

    client
      .mutate({
        mutation: graphqlMutation,
        variables: {
          id: id,
          title: values.title,
          domainName: values.domainName,
          customization: customization,
          licenseId: selectedLicense.id,
          legislation: values.legislation ? values.legislation : [],
          languages: values.languages,
        },
      })
      .then(({ data }) => {
        const { createProduct, updateProduct } = data

        const policy = createProduct ? createProduct : updateProduct

        if (id) {
          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: t('.modification_success'),
            type: 'success',
          })
          notificationsProvider.add(notification)
        } else {
          const notification = new NotificationModel({
            title: t('.policy_creation'),
            message: t('.creation_success'),
            type: 'success',
          })
          notificationsProvider.add(notification)
        }

        if ((!pp && cpId) || (pp && !pp.cookiePolicy && cpId)) {
          this.linkCpToPp(policy.id)
            .then(({ mutation }) => {
              history.replace(`/${policyType}-policies/${policy.id}`)
            })
            .catch(e => {
              console.log(e)
            })
        } else {
          id
            ? history.replace(`/${policyType}-policies/${id}`)
            : history.replace(`/${policyType}-policies/${policy.id}`)
        }
      })
      .catch(error => {
        console.log(error)
        let errorMessage = t('.error_fill_all_required_fields')

        error.graphQLErrors.map(({ message, extensions }) => {
          if (extensions.errors) {
            errorMessage = extensions.errors[0].detail
          }
        })

        if (id) {
          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: errorMessage,
            type: 'alert',
          })
          notificationsProvider.add(notification)
        } else {
          const notification = new NotificationModel({
            title: t('.policy_creation'),
            message: errorMessage,
            type: 'alert',
          })
          notificationsProvider.add(notification)
        }
      })
  }

  render() {
    const {
      pp,
      formType,
      allCookiePolicyProducts,
      selectedLicense,
      match: {
        params: { id },
      },
      t,
      isSuperAdmin,
      packageData,
      notificationsProvider
    } = this.props
    const {
      title,
      domainName,
      customization,
      policyType,
      cpUrl,
      cpId,
      countries,
      locales,
      userEmail,
      locale,
      cpLinkAlert,
      availableLanguages,
      availableLegislation
    } = this.state

    const alertStyle = { border: `1px solid red` }
    const noAlertStyle = { border: `1px solid #adadad` }

    const titleStyle = this.state.titleAlert ? alertStyle : noAlertStyle
    const domainNameStyle = this.state.domainNameAlert ? alertStyle : noAlertStyle
    const cpUrlStyle = this.state.cpLinkAlert ? alertStyle : noAlertStyle
    console.log("selectedLicense=======461", selectedLicense)
    let policyPersonalizationFeature = selectedLicense.features.filter(obj => {
      return obj.code === 'privacy_policy' || obj.code === 'cookie_policy'
    })

    const policyPersonalization = policyPersonalizationFeature.length

    return (
      <Formik
        enableReinitialize
        initialValues={{
          title: title,
          domainName: domainName,
          customization,
          cpUrl: cpUrl,
          countries,
          locales: locale,
          userEmail,
        }}
        onSubmit={values => {
          this.onSubmit(values)
        }}
        key={selectedLicense.id}
      >
        {props => (
          <form onKeyDown={this.onKeyDown} onSubmit={props.handleSubmit}>
            <div className='pp__edit'>
              <div className='pp__edit__content'>
                <EditModule className={'field--title'}>
                  <h5>
                    {' '}
                    {t('.name')} <span className='error_color'>*</span>
                  </h5>
                  <input
                    type='text'
                    name='title'
                    value={title}
                    style={titleStyle}
                    onChange={this.onChange}
                  />
                </EditModule>

                <EditModule className={'field--title'}>
                  <h5>
                    {' '}
                    {t('.domain')} <span className='error_color'>*</span>
                  </h5>
                  <input
                    type='text'
                    name='domainName'
                    value={domainName}
                    style={domainNameStyle}
                    onChange={this.onChange}
                    placeholder={'http(s)://mydomain.com'}
                    readOnly={this.props.formType === 'edit' || this.props.domainName}
                  />
                  {this.state.differentDomainsAlert && (
                    <p className='error_color margin-top-10'>
                      <AiOutlineWarning size='1.5rem' className='mr-05' />
                      <span>{t('.linked_policies_different_domains')}</span>
                    </p>
                  )}
                  {this.props.formType === 'edit' && (
                    <span style={{ fontSize: '12px', marginTop: '10px' }}>{t('.domain_edit')}</span>
                  )}
                </EditModule>
                {availableLegislation && availableLegislation.length > 0 ?
                  <EditModule className={'field--countries'}>
                    <h5>
                      {' '}
                      {t('.countries')}
                    </h5>
                    <CountryField
                      availableCountries={availableLegislation}
                      {...props}
                      t={t}
                      handleChange={this.onLocaleAndCountryChange}
                      alerts={this.state.countriesAlert}
                      countries={this.state.countries}
                      packageData={packageData?.package ? packageData?.package : null}
                      selectedLicense={selectedLicense}
                      notificationsProvider={notificationsProvider}
                      policyType={policyType}

                    />
                    <p className='legislation_info margin-top-10'>
                      <AiOutlineInfoCircle size='1.5rem' className='mr-05' />
                      <span>{t('.legislation_info')}</span>
                    </p>
                  </EditModule>
                  : null}

                {availableLanguages && availableLanguages.length > 0 ?
                  <EditModule className={'field--locales'}>
                    <h5>
                      {t('.languages')}<span className='error_color'>*</span>
                    </h5>
                    <LocaleField
                      availableLocales={availableLanguages}
                      policyType={policyType}
                      t={t}
                      {...props}
                      locales={this.props.locales}
                      handleChange={this.onLocaleAndCountryChange}
                      packageData={packageData?.package ? packageData?.package : null}
                      selectedLicense={selectedLicense}
                      alerts={this.state.localesAlert}
                      notificationsProvider={notificationsProvider}
                    />
                    {/* localesValue={this.state.locales} */}

                    <p className='warning_message margin-top-10'>
                      <AiOutlineWarning size='1.5rem' className='mr-05' />
                      <span>{t('.language_warning')}</span>
                    </p>
                  </EditModule> : null}

              </div>

              <div className='pp__edit__content'>
                {/* {policyPersonalization !== 0 && ( */}
                <EditModule className={'field--color'}>
                  <h5> {t('.color')} </h5>
                  <ColorField
                    policyPersonalization={policyPersonalization !== 0}
                    colors={customization.colors}
                    handleChange={this.onCustomizationChange}
                    t={t}
                  />
                </EditModule>
                {/* )} */}
                {policyType === 'privacy' && (
                  <EditModule className={'field--policies'}>
                    <h5> {t('.link_policy')} </h5>
                    <PolicyField
                      allPrivacyPolicies={allCookiePolicyProducts}
                      policyType={policyType}
                      formType={formType}
                      cpUrl={cpUrl}
                      cpId={cpId}
                      linkedCpId={pp && pp.cookiePolicy && pp.cookiePolicy.id}
                      t={t}
                      {...props}
                      handleLinkToPolicy={this.handleLinkToPolicy}
                      handleChange={this.onChange}
                      pp={pp}
                      cpLinkAlert={cpLinkAlert}
                      style={cpUrlStyle}
                    />
                  </EditModule>
                )}
              </div>
            </div>
            <div className='columns flex_right'>
              <Link to={id ? `/privacy-policies/${id}` : `/privacy-policies`}>
                <Button rounded invert className='btn btn_pill margin-right-20'>
                  {t('.return')}
                </Button>
              </Link>

              <Button
                className='btn btn_pill is-blue'
                rounded
                published
                disabled={this.state.invalid}
                type='submit'
              >
                <i className='fas fa-check margin-right-10' />
                {t('.confirm')}
              </Button>
            </div>

            {typeof title !== 'undefined' ? (
              <TitleComponent title={title + ' | ' + t('.edit_policy')} />
            ) : (
              <TitleComponent title={t('.new_policy')} />
            )}
          </form>
        )}
      </Formik>
    )
  }
}
const GET_AVAILABLE_LANGAGES = gql`
  query AvailableLanguages {
    availableLanguages {
      name
      code
      active
    }
  }
`
const GET_AVAILABLE_LEGISLATION = gql`
  query AvailableLegislations {
    availableLegislations {
      name
      code
      active
    }
  }
`
const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin } = auth
  return {
    isSuperAdmin,
  }
}

export default compose(
  connect(mapStateToProps),
  withLocalesAndCountries,
  withApollo,
  withNotifications(),
  withRouter,
  withTranslation('privacyPolicyEdit'),
)(EditForm)
