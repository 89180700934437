import React, { useState } from 'react';
import PropTypes from 'prop-types';

class CountryDropdownItem extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
    }

    toggleDropdown = () => {
        if (!this.props.readOnly) this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    };

    render() {
        const {
            handleChange,
            name,
            options,
            value,
            label,
            placeholder,
            className,
            size,
            t,
            isPill,
            mandatory,
            arraowShow
        } = this.props;

        let valueSelected = options.find(function (option) {
            return option.value === value;
        });

        return (
            <div
                className={`dropdown ${className} ${size} ${this.props.readOnly ? 'readOnly' : ''} ${this.state.isOpen ? 'opened' : 'closed'
                    }`}
                onClick={this.toggleDropdown}
            >
                <div className={`${isPill ? 'dropdown_header is-pill' : 'dropdown_header'}  ${arraowShow ? 'dropdown_header-payment-mode' : ''}`}>
                    <div className='dropdown_label'>{label}
                        {mandatory && <span style={{ color: 'red' }}>&nbsp;* </span>}</div>
                    {valueSelected ? (t ? t(valueSelected.label) : valueSelected.label) : placeholder}
                    {arraowShow ? <i className={`dropdown_arrow fas ${this.state.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i> : null}
                </div>
                <div className='dropdown_items'>
                    {options.map(option => (
                        <div
                            key={option.value}
                            className={`dropdown_item ${value === option.value ? 'selected' : ''}`}
                            value={option.value}
                            name={name}
                            onClick={() => handleChange({ target: { value: option.value, name: name } })}
                        >
                            {t ? t(option.label) : option.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

CountryDropdownItem.propTypes = {
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
};

CountryDropdownItem.defaultProps = {
    value: '',
    label: null,
};

export default CountryDropdownItem;
