import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../../Button';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderField = this.renderField.bind(this);
    this.state = {
      value: null,
      invalid: true,
    };
  }

  handleChange(event) {
    const { onChange } = this.props;
    onChange([event.target.value], event.target.name);
    this.setState({ value: event.target.value, invalid: false });
  }

  componentWillMount() {
    const { value } = this.props;
    this.setState({ value: value ? value[0] : null });
  }

  componentDidMount() {
    if (this.props.answer !== null) {
      this.setState({ invalid: false });
    }
  }

  renderField() {
    const { options, readOnly, goBack, handleSubmit, t } = this.props;
    const { value } = this.state;

    return (
      <div className='radio-options'>
        <Field
          component={({ input, options }) =>
            options.map(option => (
              <div key={option.value} className='radio_input'>
                <input
                  id={option.id}
                  type='radio'
                  {...input}
                  value={option.value}
                  name={option.name}
                  checked={option.value === value}
                  onChange={this.handleChange}
                  disabled={readOnly}
                />
                <label htmlFor={option.id}>
                  {option.label}
                  {option.example && <p className='input_example'> {option.example} </p>}
                </label>
              </div>
            ))
          }
          name={'test'}
          options={options}
        />

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return <Fragment>{this.renderField()}</Fragment>;
  }
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  ).isRequired,
};

export default RadioGroup;
