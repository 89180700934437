import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import gql from 'graphql-tag';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import withTranslation from '../../../providers/I18n/HoC/t';

class DpoModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalLink = this.handleModalLink.bind(this);
  }

  handleModalLink(answer) {
    const {
      closeExampleModal,
      content: { policyType },
      match,
      history,
      client,
    } = this.props;
    const { id, categoryId } = match.params;
    client
      .mutate({
        mutation: ANSWER_QUESTION_MUTATION,
        variables: {
          questionReferenceNumber: 'B.1',
          productId: id,
          value: answer ? ['yes'] : ['no'],
        },
      })
      .then(({ data }) => {
        client
          .query({
            query: NEXT_UNANSWERED_QUESTION_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              referenceNumber: 'B.1',
              productId: id,
            },
          })
          .then(({ data }) => {
            const { nextQuestion } = data;
            answer
              ? history.replace(
                  `/${policyType}-policies/${id}/categories/${categoryId}/questions/${nextQuestion.id}`,
                )
              : history.replace(`/${policyType}-policies/${id}/categories/${categoryId}`);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
    closeExampleModal();
  }

  render() {
    const {
      content: { question },
      title,
      closeExampleModal,
      t,
    } = this.props;
    const { answer } = question.choices.data[0];
    return (
      <Modal>
        <div className='modal_bar'>
          <h4 className='modal_title'> {title} </h4>
          <button type='button' className='close' onClick={closeExampleModal} />
        </div>
        <div className='modal_content'>
          <div
            className='modal_statement'
            dangerouslySetInnerHTML={{ __html: question.statement }}
          />
          {answer ? (
            <div className='modal_buttons'>
              <Button
                onClick={this.handleModalLink.bind(this, true)}
                className='btn btn_pill is-blue'
                published
              >
                {t('.continue')}
              </Button>
            </div>
          ) : (
            <div className='modal_buttons'>
              <Button
                onClick={this.handleModalLink.bind(this, true)}
                className='btn btn_pill is-outlined is-blue margin-right-20'
              >
                {t('.choose_dpo')}
              </Button>
              <Button
                onClick={this.handleModalLink.bind(this, false)}
                className='btn btn_pill is-blue'
                published
              >
                {t('.continue')}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

DpoModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

const NEXT_UNANSWERED_QUESTION_QUERY = gql`
  query nextQuestion($productId: ID!, $referenceNumber: String!) {
    nextQuestion(productId: $productId, referenceNumber: $referenceNumber) {
      id
    }
  }
`;

const ANSWER_QUESTION_MUTATION = gql`
  mutation productAnswer($questionReferenceNumber: String!, $productId: ID!, $value: JSON!) {
    productAnswer(
      questionReferenceNumber: $questionReferenceNumber
      productId: $productId
      value: $value
    ) {
      id
    }
  }
`;

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withApollo,
  withTranslation('question'),
)(DpoModal);
