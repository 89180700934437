/* eslint react/jsx-indent-props: off */
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'

import { withTranslation } from '../../providers/I18n'
import validate from './validate'

import CheckboxGroup from '../../components/ProductForm/CheckboxGroup'
import RadioGroup from '../../components/ProductForm/RadioGroup'
import RadioMultipleGroup from '../../components/ProductForm/MultipleRadioGroup'
import TextFieldGroup from '../../components/ProductForm/TextFieldGroup'
import TextAreaWithDefault from '../../components/ProductForm/TextAreaWithDefault'
import DropDown from '../../components/ProductForm/Dropdown'
import Button from '../../components/Button'
import CookieForm from '../../components/ProductCookieForm'

const switchType = ({
  handleChange,
  answer,
  choices,
  question,
  pp,
  initialValues,
  name,
  error,
  t,
  handleSubmit,
  history,
}) => {
  let type = question.type.replace('free ', '')
  let selectedLanguage = localStorage.getItem('locale') || 'fr'
  const languages = pp.license.features.reduce(
    (arr, item) => (item.groupCode === 'language' && item.active && arr.push(item['code']), arr),
    [],
  )

  switch (type) {
    case 'text':
      return (
        <TextFieldGroup
          name={name}
          options={choices}
          onChange={handleChange}
          selectedLanguage={selectedLanguage}
          t={t}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          {...question}
        />
      )
    case 'radio':
      return choices[0] instanceof Array ? (
        <RadioMultipleGroup
          name={name}
          options={choices}
          value={initialValues}
          choices={question.choices}
          onChange={handleChange}
          t={t}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          {...question}
        />
      ) : (
        <RadioGroup
          name={name}
          options={choices}
          value={initialValues}
          onChange={handleChange}
          readOnly={question.readOnly}
          t={t}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          {...question}
        />
      )
    case 'checkbox':
      return (
        <CheckboxGroup
          question={question}
          name={name}
          options={choices}
          onFieldChange={handleChange}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          t={t}
          {...question}
        />
      )
    case 'text area':
    case 'text area with default':
      return (
        <TextAreaWithDefault
          name={name}
          options={choices}
          locales={languages}
          onChange={handleChange}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          t={t}
          selectedLanguage={selectedLanguage}
          {...question}
        />
      )
    case 'duration':
      return (
        <DropDown
          name={name}
          options={choices}
          locales={languages}
          value={question.answer ? question.answer.value[0] : {}}
          choices={question.choices}
          onChange={handleChange}
          selectedLanguage={selectedLanguage}
          t={t}
          goBack={() => history.goBack()}
          handleSubmit={handleSubmit}
          {...question}
        />
      )
    case 'form':
      return (
        <CookieForm
          question={question}
          locales={languages}
          onChange={handleChange}
          cookies_scanned={pp.cookies}
          cookies_validate={question.answer ? question.answer.value : []}
        />
      )
    default:
      return null
  }
}

const Form = props => {
  if (props.question.type === 'form') {
    const languages = props.pp.license.features.reduce(
      (arr, item) => (item.groupCode === 'language' && item.active && arr.push(item['code']), arr),
      [],
    )

    return (
      <div className='question_form'>
        <form>
          <CookieForm
            question={props.question}
            locales={languages}
            onChange={props.handleChange}
            cookies_scanned={props.pp.cookies}
            cookies_validate={props.question.answer ? props.question.answer.value : []}
            goBack={() => props.history.goBack()}
            handleSubmit={props.handleSubmit}
          />
        </form>
      </div>
    )
  } else {
    return (
      <div className='question_form'>
        <form>{switchType(props)}</form>
      </div>
    )
  }
}

Form.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]))
    .isRequired,
}

Form.defaultProps = {
  handleSubmit: () => {},
  handleChange: () => {},
}

export default compose(
  reduxForm({ form: 'answer_form', validate }),
  withTranslation('question'),
  withRouter,
)(Form)
