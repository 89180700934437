import React from 'react'
import { Link } from 'react-router-dom'

import { formattedDate } from '../../helpers/date'


const PolicyCardDisplay = ({ t, policyType, policy }) => (
  <div className='policy'>
    <div className='policy_content'>
      <div className='title-wrapper'>
        <span className='title block'>{policy.title}</span>
      </div>

      <div className='is-flex'>
        <div className=' policy_card-version'>
          <span className='text'>{t('.version')}</span>
          <span className='number'>{policy.version}</span>
        </div>
        <div>
          <span className={`status right ${policy.status}`}>{t(`.${policy.status}`)}</span>
        </div>
      </div>
      <div className='policy-desc'>
        <div>
          <span className='block'>
            {t('.creation')}: {formattedDate(policy.createdAt)}
          </span>
          <span className='block'>
            {t('.modification')}: {formattedDate(policy.updatedAt)}
          </span>
          <span className='block'>
            {t('.publication')}:{' '}
            {policy.publishedAt ? formattedDate(policy.publishedAt) : ' - '}
          </span>
          <span className='block'>
            {t('.product_owner')}: {policy.owner.organization.name}
          </span>
        </div>
      </div>
    </div>
  </div>
)

const PolicyCard = ({ t, policyType, policy }) => (
  <li className='privacy-policy-card' key={policy.id}>
    {!policy?.license?.isExpired ? (
      <Link to={`/${policyType}-policies/${policy.id}`} >
        <PolicyCardDisplay t={t} policyType={policyType} policy={policy} />
      </Link>
    ) : (
      <div style={{ opacity: 0.4 }}>
        <PolicyCardDisplay t={t} policyType={policyType} policy={policy} />
      </div>
    )}
  </li>
)

export default PolicyCard