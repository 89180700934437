import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNotifications } from 'src/providers/notifications';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'src/providers/I18n';
import { IoIosCloseCircleOutline, IoMdCheckmarkCircleOutline } from 'react-icons/io';

class PackageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLan: 'fr',
        };
    }

    componentWillMount() {
        var lan = localStorage.getItem('locale') || 'en';
        if (lan && lan.length > 0) {
            this.setState({ currentLan: lan })
        }
    }
    pageViewCount = (item) => {
        var count = (item.isCookie && item.isPolicy) ? Math.min(item.cTrafficBucket, item.pTrafficBucket) : item.isCookie ? item.cTrafficBucket : item.isPolicy ? item.pTrafficBucket : '';
        if (count) {
            // count = new Intl.NumberFormat('fr-FR').format(count)
        }

        return count >= 10000 ? `${(count / 1000)}k` : count.toString();
    }
    languageCount = (item, value) => {
        const { t } = this.props;

        // console.log("---4050s---item", item)
        if (value === 'langage') {
            var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLang, item.pNoOfLang) : item.isCookie ? item.cNoOfLang : item.isPolicy ? item.pNoOfLang : '';
            count = count >= 2 ? `${t('.up_to')} ${(count)}` : count.toString();;
            return count;
        }
        if (value === 'legislations') {
            var count = (item.isCookie && item.isPolicy) ? Math.min(item.cNoOfLegis, item.pNoOfLegis) : item.isCookie ? item.cNoOfLegis : item.isPolicy ? item.pNoOfLegis : '';
            return count;
        }
        if (value === "users") {
            var count;

            if (!item.cNoOfUsers && item.cNoOfUsers !== 0 && !item.pNoOfUsers && item.pNoOfUsers !== 0) {
                count = 'Unlimited'
            }
            else if (!item.cNoOfUsers && item.cNoOfUsers === 0 && !item.pNoOfUsers && item.pNoOfUsers === 0) {
                count = '1'
            }
            else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers !== item.cNoOfUsers)) {
                count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
            }
            else if (((item.cNoOfUsers || item.cNoOfUsers === 0) && (item.pNoOfUsers || item.pNoOfUsers === 0)) && (item.pNoOfUsers === item.cNoOfUsers)) {
                count = (Math.min(item.cNoOfUsers, item.pNoOfUsers)) + 1
            }
            else if (item.cNoOfUsers || item.pNoOfUsers || item.pNoOfUsers === 0 || item.cNoOfUsers === 0) {
                if (item.cNoOfUsers || item.cNoOfUsers === 0) {
                    count = item.cNoOfUsers + 1
                }
                if (item.pNoOfUsers || item.pNoOfUsers === 0) {
                    count = item.pNoOfUsers + 1
                }
            }
            else {
                count = "1"
            }



            return count;
        }
    }

    Totalpersonalisation = (item, code) => {

        var total = false;
        if (code === 'Personalisation') {
            item.cookieFeatures.forEach(element => {
                if (element.feature_group.code === 'personalisation') {
                    total = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.feature_group.code === 'personalisation') {
                    total = true
                }
            });
        }
        else if (code === 'format_web_version') {
            var webCookieFormat = false;
            var webPrivacyFormat = false;
            item.cookieFeatures.forEach(element => {
                if (element.code === 'format_web_version') {
                    webCookieFormat = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.code === 'format_web_version') {
                    webPrivacyFormat = true
                }
            });
            total = webCookieFormat || webPrivacyFormat
        }
        else if (code === 'format_pdf') {
            var pdfCookieFormat = false;
            var pdfPrivacyFormat = false;
            item.cookieFeatures.forEach(element => {
                if (element.code === 'format_pdf') {
                    pdfCookieFormat = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.code === 'format_pdf') {
                    pdfPrivacyFormat = true
                }
            });
            total = pdfCookieFormat && pdfPrivacyFormat
        }
        else if (code === 'banner_script') {
            item.cookieFeatures.forEach(element => {
                if (element.code === 'banner_script') {
                    total = true
                }
            });
        }
        else if (code === 'banner_consents_db') {
            item.cookieFeatures.forEach(element => {
                if (element.code === 'banner_consents_db') {
                    total = true
                }
            });
        }
        else if (code === 'personalisation_banner') {
            item.cookieFeatures.forEach(element => {
                if (element.code === 'personalisation_banner') {
                    total = true
                }
            });

        }
        else if (code === 'Graphic customisation') {
            var personalisationCookie = false;
            var personalisationPolicy = false;

            item.cookieFeatures.forEach(element => {
                if (element.code === 'personalisation_cookie_policy') {
                    personalisationCookie = true
                }
            });
            item.policyFeatures.forEach(element => {
                if (element.code === 'personalisation_privacy_policy') {
                    personalisationPolicy = true
                }
            });
            total = personalisationCookie && personalisationPolicy;

        }
        else if (code === "versioning") {
            if (item.isCookie && item.isPolicy) {
                if (item.cIsVersioning && item.pIsVersioning) {
                    total = true;
                }
                else {
                    total = false;
                }
            }
            else if (item.isCookie) {
                if (item.cIsVersioning) {
                    total = true;
                }
                else {
                    total = false;
                }
            }
            else if (item.isPolicy) {
                if (item.pIsVersioning) {
                    total = true;
                }
                else {
                    total = false;
                }
            }
            else {
                total = false
            }
        }

        return total;
    }
    render() {
        const { activePackage, t } = this.props;
        const { currentLan } = this.state;


        return (
            <>
                <thead>
                    <tr>
                        <th >{t('.feature')}</th>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <th>{currentLan === 'en' ? item.name[0].en : item.name[1].fr} </th>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t('.page_view')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{t('.up_to')} {this.pageViewCount(item)} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.if_beyond_the_limit')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.ifBeyondTheLimit ? (item.ifBeyondTheLimit + ' / 500 logs') : <IoIosCloseCircleOutline className='icon-close' />} </td >
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.privacy_policie')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.isPolicy ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>

                    <tr>
                        <td>{t('.cookie_policies')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.isCookie ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.legal_design_web_policies')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.legalDesignWebPolicy ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>

                        <td>{t('.cookie_banner')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <td>{
                                                this.Totalpersonalisation(item, 'banner_script') ?
                                                    <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.cookie_consent_management_storage')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <td>{
                                                this.Totalpersonalisation(item, 'banner_consents_db') ?
                                                    <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.graphic_customisation')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <td>{
                                                this.Totalpersonalisation(item, 'Graphic customisation') ?
                                                    <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.number_of_language_available')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{this.languageCount(item, "langage")} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.extra_users')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td> {this.languageCount(item, "users")}</td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.versioning_arching')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{
                                                    this.Totalpersonalisation(item, 'versioning') ?
                                                        <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.PDF_export')}</td>

                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{
                                                    this.Totalpersonalisation(item, 'format_pdf') ?
                                                        <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.consent-database-export')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.cIsExport ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.banner_consent_interaction_statistics')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{item.isBannerConsentInteractionStatistics ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>
                    <tr>
                        <td>{t('.specific_national_eu')}</td>
                        {activePackage && activePackage.length > 0 ? (
                            <>
                                {
                                    activePackage.map(item => {
                                        return (
                                            <>
                                                <td>{this.languageCount(item, "legislations")} </td>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : null}
                    </tr>



                    {/* <tr>
                                    <td>{t('.legislations')}</td>
                                    {activePackage && activePackage.length > 0 ? (
                                      <>
                                        {
                                          activePackage.map(item => {
                                            return (
                                              <>
                                                <td> <IoMdCheckmarkCircleOutline className='icon-add' /> </td>
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    ) : null}
                                  </tr> */}


                    {/* <tr>
                                  <td>{t('.personalisation')}</td>
                                  {activePackage && activePackage.length > 0 ? (
                                    <>
                                      {
                                        activePackage.map(item => {
                                          return (
                                            <>
                                              <td>{
                                                this.Totalpersonalisation(item, 'Personalisation') ?
                                                  <IoMdCheckmarkCircleOutline className='icon-add' /> : "-"} </td>
                                            </>
                                          )
                                        })
                                      }
                                    </>
                                  ) : null}
                                </tr> */}
                    {/* <tr>
                                    <td>{t('.web_format')}</td>
                                    {activePackage && activePackage.length > 0 ? (
                                      <>
                                        {
                                          activePackage.map(item => {
                                            return (
                                              <>
                                                <td>{
                                                  this.Totalpersonalisation(item, 'format_web_version') ?
                                                    <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    ) : null}
                                  </tr> */}
                    <tr>
                        <td>{t('.support')}</td>
                        {
                            activePackage.map(item => {
                                return (
                                    <>
                                        <td>{(currentLan === 'en' && item?.support && item?.support[0] && item?.support[0].en !== "-") ? <IoMdCheckmarkCircleOutline className='icon-add' /> : (currentLan === 'fr' && item?.support && item?.support[1] && item?.support[1].fr !== "-") ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                    </>
                                )
                            })
                        }
                    </tr>
                    <tr>
                        <td>{t('.dedicated_csm')}</td>
                        {
                            activePackage.map(item => {
                                return (
                                    <>
                                        <td>{item.dedicatedCsm ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                    </>
                                )
                            })
                        }
                    </tr>
                    <tr>
                        <td>{t('.legal_updates')}</td>
                        {
                            activePackage.map(item => {
                                return (
                                    <>
                                        <td>{(currentLan === 'en' && item?.legalUpdate && item?.legalUpdate[0] && item?.legalUpdate && item?.legalUpdate[0]?.en !== '-') ? <IoMdCheckmarkCircleOutline className='icon-add' /> : (currentLan === 'fr' && item?.legalUpdate && item?.legalUpdate[1] && item?.legalUpdate && item?.legalUpdate[1]?.fr !== '-') ? <IoMdCheckmarkCircleOutline className='icon-add' /> : <IoIosCloseCircleOutline className='icon-close' />} </td>
                                    </>
                                )
                            })
                        }
                    </tr>


                </tbody>
            </>
        );
    }
}


export default compose(connect(null, null), withApollo, withRouter, withTranslation('account_onboarding'), withNotifications())(PackageList);