import React from 'react';

import Tooltip from '../../Tooltip/tooltipInline';

import HorizontalLanguageSelector from '../LanguageSelection/horizontalLanguageSelector';

const renderInputFieldWithLangSelector = ({
  option,
  className,
  input,
  label,
  placeholder,
  type,
  example,
  readOnly,
  tooltip,
  tooltipText,
  mandatory,
  t,
  handleLanguageChange,
  languageOptions,
  selectedLanguage,
  meta: { touched, error, active },
  noTooltip,
}) => {
  return (
    <div className={`text_input ${className || ''} ${touched && error ? 'error' : ''}`}>
      <input
        {...input}
        type={type}
        placeholder={placeholder ? placeholder : label}
        readOnly={readOnly}
      />
      {label && (
        <div className='text__label' style={{ width: '100%' }}>
          <label>
            <div style={{ display: 'inline-block', width: '50%' }}>
              {label}
              {mandatory && <span style={{ color: 'red' }}> * </span>}
              {tooltip && <Tooltip> {tooltipText} </Tooltip>}
            </div>
            <HorizontalLanguageSelector
              handleLanguageChange={handleLanguageChange}
              languageOptions={languageOptions}
              selectedLanguage={selectedLanguage}
            />
          </label>
        </div>
      )}
      {touched && error && <div className='input_error'>{t(error)}</div>}
      {example && <p className='input_example'> {option.example} </p>}
      {active && touched && input.name === 'password' && !noTooltip && (
        <div className='tooltip__box'>
          <p> {t('.password_must_contain')} </p>
          <ul>
            <li> {t('.min_characters')} </li>
            <li> {t('.upper_lower_case')} </li>
            <li> {t('.a_number')} </li>
            <li> {t('.special_character')} </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export { renderInputFieldWithLangSelector };
