const INITIAL_STATE = {
  pdfLink: null,
  htmlPreview: null,
  id: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SET_PREVIEW':
      return payload;
    default:
      return state;
  }
};
