import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { hideModal, loadModal } from 'src/redux/modal/action';
import { connect } from 'react-redux'
import { compose } from 'recompose'

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderField = this.renderField.bind(this);
    this.state = {
      value: null,
    };
  }


  handleChange(event) {
    const { onChange, bannerPersonalization, loadModal, selectedLicense } = this.props;
    if (bannerPersonalization) {
      onChange([event.target.value], event.target.name);
      this.setState({ value: event.target.value });
    }
    else {
      loadModal({
        modalType: 'access_limit_user_model',
        content: {
          selectedLicense: selectedLicense
        },
        title: '',
      })
    }

  }

  componentWillMount() {
    const { value } = this.props;
    this.setState({ value: value ? value[0] : null });
  }

  renderField() {
    const { options, readOnly } = this.props;
    const { value } = this.state;
    return (
      <div className='radio-options'>
        <Field
          component={({ input, options }) =>
            options.map(option => (
              <div key={option.value} className='radio_input'>
                <input
                  id={option.id}
                  type='radio'
                  {...input}
                  value={option.value}
                  name={option.name}
                  checked={option.value === value}
                  onChange={this.handleChange}
                  disabled={readOnly}
                />
                <label htmlFor={option.id}>
                  {option.label}
                  {option.example && <p className='input_example'> {option.example} </p>}
                </label>
              </div>
            ))
          }
          name={'test'}
          options={options}
        />
      </div>
    );
  }

  render() {
    return <Fragment>{this.renderField()}</Fragment>;
  }
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  ).isRequired,
};

// export default RadioGroup;
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}

export default compose(
  connect(null, mapDispatchToProps),
)(RadioGroup)
