import React from 'react';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';

import CountryField from './Fields/country_field';
import LocaleField from './Fields/locale_field';
import ColorField from './Fields/color_field';
import PolicyField from './Fields/policy_field';
import EditModule from './edit_module';
import { withLocalesAndCountries } from '../../providers/localesAndCountries';
import mapInputs from '../../helpers/locales';
import Button from '../../components/Button';
import { withNotifications, NotificationModel } from '../../providers/notifications';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';
import { AiOutlineWarning } from 'react-icons/ai';

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    const { pp } = props;
    const formType = this.props.formType;
    this.state = {
      title: pp && pp.title,
      cpUrl: pp && pp.externalCpUrl,
      cpId: pp && pp.cookiePolicy && pp.cookiePolicy.id,
      countries: pp && pp.countries.map(country => country.isoCode),
      locales: pp && pp.locales.map(locale => locale.isoCode),
      customization: (pp && pp.customization) || {},
      policyType: this.isCookiePolicy(props.match.url) ? 'cookie' : 'privacy',
      invalid: formType === 'new',
      isCpLinked: false,
      titleAlert: false,
      countriesAlert: false,
      localesAlert: false,
      cpLinkAlert: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  isCookiePolicy = url => {
    const arr = url.split(/[\/]/g);

    const type = arr.filter(item => {
      return item.match(/(?:^|\W)policies(?:$|\W)/g);
    })[0];

    return type === 'cookie-policies';
  };

  onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  onSubmit = () => {
    const { countries, locales, title, cpUrl, customization } = this.state;
    let valueToMap = {};

    countries &&
      countries.map(country => {
        valueToMap[`country_${country}`] = true;
      });
    locales &&
      locales.map(locale => {
        valueToMap[`locale_${locale}`] = true;
      });
    valueToMap.title = title;
    valueToMap.cpUrl = cpUrl;

    const groupedValues = mapInputs(valueToMap);

    const c = {};
    if (customization.colors) c.colors = customization.colors;
    if (customization.logoBase64) {
      c.logo = customization.logoBase64;
    } else if (customization.logoBase64 === null) {
      c.logo = customization.logoBase64;
    }
    if (!_.isEmpty(c)) groupedValues.customization = c;
    this.updatePolicy(groupedValues);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.validateFormInputs(e.target.name, e.target.value);
  };

  handleLinkToPolicy = data => {
    this.setState({
      cpId: data.value,
      cpUrl: data?.domainName,
    });
  };

  onLocaleAndCountryChange = (values, type) => {
    this.setState({ [type]: values });
    this.validateFormInputs(type, values);
  };

  validateFormInputs = (type, value) => {
    const formType = this.props.formType;

    let invalid = false;
    let titleAlert;
    let countriesAlert;
    let localesAlert;
    let cpLinkAlert;

    if (
      ((typeof this.state.title === 'undefined' || this.state.title === '') && type !== 'title') ||
      (type === 'title' && value === '')
    ) {
      invalid = true;
      titleAlert = true;
    } else {
      titleAlert = false;
    }

    if (
      ((typeof this.state.countries === 'undefined' ||
        this.state.countries === null ||
        (Array.isArray(this.state.countries) && !this.state.countries.length)) &&
        type !== 'countries') ||
      (type === 'countries' && value === null) ||
      (Array.isArray(value) && !value.length)
    ) {
      invalid = true;
      countriesAlert = true;
    } else {
      countriesAlert = false;
    }

    if (
      ((typeof this.state.locales === 'undefined' ||
        this.state.locales === null ||
        (Array.isArray(this.state.locales) && !this.state.locales.length)) &&
        type !== 'locales') ||
      (type === 'locales' && value === null) ||
      (Array.isArray(value) && !value.length)
    ) {
      invalid = true;
      localesAlert = true;
    } else {
      localesAlert = false;
    }

    // PP specific - link to CP
    if (this.state.policyType === 'privacy') {
      if (type === 'title' || type === 'countries' || type === 'locales') {
        if (
          this.state.isCpLinked &&
          (typeof this.state.cpUrl === 'undefined' ||
            this.state.cpUrl === null ||
            !this.validateUrl(this.state.cpUrl)) &&
          (typeof this.state.cpId === 'undefined' || this.state.cpId === null)
        ) {
          invalid = true;
          cpLinkAlert = true;
        } else {
          cpLinkAlert = false;
        }
      }

      if (type === 'isCpLinked' && value) {
        if (
          (typeof this.state.cpUrl === 'undefined' || this.state.cpUrl === null) &&
          (typeof this.state.cpId === 'undefined' || this.state.cpId === null)
        ) {
          invalid = true;
          cpLinkAlert = true;
        }
      }

      if (
        type === 'cpId' &&
        value === null &&
        (typeof this.state.cpUrl === 'undefined' || this.state.cpUrl === null)
      ) {
        invalid = true;
        cpLinkAlert = true;
      }

      if (
        type === 'cpUrl' &&
        value === null &&
        (typeof this.state.cpId === 'undefined' || this.state.cpId === null)
      ) {
        invalid = true;
        cpLinkAlert = true;
      } else {
        if (type === 'cpUrl') cpLinkAlert = false;
      }

      if (type === 'cpUrl' && (value === '' || !this.validateUrl(value))) {
        invalid = true;
        cpLinkAlert = true;
      } else {
        if (type === 'cpUrl') cpLinkAlert = false;
      }

      if (
        this.state.isCpLinked &&
        type !== 'invalid' &&
        type !== 'isCpLinked' &&
        type !== 'cpUrl'
      ) {
        if (
          (typeof this.state.cpUrl === 'undefined' || this.state.cpUrl === null) &&
          (typeof this.state.cpId === 'undefined' || this.state.cpId === null)
        ) {
          invalid = true;
          cpLinkAlert = true;
        }
      }
    }

    this.setState({
      invalid: invalid,
      titleAlert: titleAlert,
      countriesAlert: countriesAlert,
      localesAlert: localesAlert,
      cpLinkAlert: cpLinkAlert,
    });
  };

  validateUrl = input => {
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(
      input,
    );
  };

  onCustomizationChange = (key, value) => {
    this.setState({ customization: { ...this.state.customization, [key]: value } });
  };

  linkCpToPp = async id => {
    const { client, linkPolicyMutation } = this.props;
    const { cpId } = this.state;

    return await client.mutate({
      mutation: linkPolicyMutation,
      variables: {
        ppId: id,
        cpId: cpId,
      },
    });
  };

  updatePolicy = values => {
    const {
      pp,
      client,
      graphqlMutation,
      history,
      notificationsProvider,
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const { policyType, cpId } = this.state;
    client
      .mutate({
        mutation: graphqlMutation,
        variables: {
          id: id,
          title: values.title,
          countries: values.countries,
          locales: values.locales,
          customization: values.customization,
          policyType: policyType,
          externalCpUrl: !cpId ? values.cpUrl : null,
        },
      })
      .then(({ data }) => {
        const { createPrivacyPolicy, updatePrivacyPolicy } = data;

        const policy = createPrivacyPolicy ? createPrivacyPolicy : updatePrivacyPolicy;

        if (id) {
          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: t('.modification_success'),
            type: 'success',
          });
          notificationsProvider.add(notification);
        } else {
          const notification = new NotificationModel({
            title: t('.policy_creation'),
            message: t('.creation_success'),
            type: 'success',
          });
          notificationsProvider.add(notification);
        }

        if ((!pp && cpId) || (pp && !pp.cookiePolicy && cpId)) {
          this.linkCpToPp(policy.id)
            .then(({ mutation }) => {
              history.replace(`/${policyType}-policies/${policy.id}`);
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          id
            ? history.replace(`/${policyType}-policies/${id}`)
            : history.replace(`/${policyType}-policies/${policy.id}`);
        }
      })
      .catch(error => {
        let errorMessage = t('.error_fill_all_required_fields');

        error.graphQLErrors.map(({ message, extensions }) => {
          if (extensions.errors) {
            errorMessage = extensions.errors[0].detail;
          }
        });

        if (id) {
          const notification = new NotificationModel({
            title: t('.policy_modification'),
            message: errorMessage,
            type: 'alert',
          });
          notificationsProvider.add(notification);
        } else {
          const notification = new NotificationModel({
            title: t('.policy_creation'),
            message: errorMessage,
            type: 'alert',
          });
          notificationsProvider.add(notification);
        }
      });
  };

  render() {
    const {
      pp,
      formType,
      allPrivacyPolicies,
      availableCountries,
      availablePolicyLocales,
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const { title, customization, policyType, cpUrl, cpId } = this.state;
    const countriesIso = pp && pp.countries.map(country => country.isoCode);
    const availableCountriesIso = availableCountries.map(country => country.isoCode);

    const localesIso = pp && pp.locales.map(locale => locale.isoCode);
    const availablePolicyLocalesIso = availablePolicyLocales.map(locale => locale.isoCode);

    const alertStyle = { border: `1px solid red` };
    const noAlertStyle = { border: `1px solid #adadad` };

    const titleStyle = this.state.titleAlert ? alertStyle : noAlertStyle;
    const cpUrlStyle = this.state.cpLinkAlert ? alertStyle : noAlertStyle;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          title: title,
          countries: countriesIso,
          locales: localesIso,
          customization,
          cpUrl: cpUrl,
        }}
        onSubmit={values => {
          this.onSubmit(values);
        }}
      >
        {props => (
          <form onKeyDown={this.onKeyDown} onSubmit={props.handleSubmit}>
            <div className='pp__edit'>
              <div className='pp__edit__content'>
                <EditModule className={'field--title'}>
                  <h5>
                    {' '}
                    {t('.name')} <span className='error_color'>*</span>
                  </h5>
                  <input
                    type='text'
                    name='title'
                    value={title}
                    onChange={this.onChange}
                    style={titleStyle}
                  />
                </EditModule>
                <EditModule className={'field--countries'}>
                  <h5>
                    {' '}
                    {t('.countries')} <span className='error_color'>*</span>
                  </h5>
                  <CountryField
                    availableCountries={availableCountriesIso}
                    {...props}
                    t={t}
                    handleChange={this.onLocaleAndCountryChange}
                    alerts={this.state.countriesAlert}
                  />
                </EditModule>
                <EditModule className={'field--locales'}>
                  <h5>
                    {t('.languages')} <span className='error_color'>*</span>
                  </h5>
                  <LocaleField
                    availableLocales={availablePolicyLocalesIso}
                    policyType={policyType}
                    t={t}
                    {...props}
                    handleChange={this.onLocaleAndCountryChange}
                    alerts={this.state.localesAlert}
                  />
                  <p className='warning_message margin-top-10'>
                    <AiOutlineWarning size='1.5rem' className='mr-05' />
                    <span>{t('.language_warning')}</span>
                  </p>
                </EditModule>
              </div>
              <div className='pp__edit__content'>
                <EditModule className={'field--color'}>
                  <h5> {t('.color')} </h5>
                  <ColorField
                    colors={customization.colors}
                    handleChange={this.onCustomizationChange}
                    t={t}
                  />
                </EditModule>
                {policyType === 'privacy' && (
                  <EditModule className={'field--policies'}>
                    <h5> {t('.link_policy')} </h5>
                    <PolicyField
                      allPrivacyPolicies={allPrivacyPolicies}
                      policyType={policyType}
                      formType={formType}
                      cpUrl={cpUrl}
                      cpId={cpId}
                      linkedCpId={pp && pp.cookiePolicy && pp.cookiePolicy.id}
                      t={t}
                      {...props}
                      handleLinkToPolicy={this.handleLinkToPolicy}
                      handleChange={this.onChange}
                      pp={pp}
                      style={cpUrlStyle}
                    />
                  </EditModule>
                )}
              </div>
            </div>
            <div className='columns flex_right'>
              <Link to={id ? `/${policyType}-policies/${id}` : `/${policyType}-policies`}>
                <Button className='btn btn_pill is-borderless has-text-blue margin-right-20'>
                  {t('.return')}
                </Button>
              </Link>

              <Button
                className='btn btn_pill is-blue'
                published
                disabled={this.state.invalid}
                type='submit'
              >
                <i className='fas fa-check margin-right-10' />
                {t('.confirm')}
              </Button>
            </div>

            {typeof title !== 'undefined' ? (
              <TitleComponent title={title + ' | ' + t('.edit_policy')} />
            ) : (
              <TitleComponent title={t('.new_policy')} />
            )}
          </form>
        )}
      </Formik>
    );
  }
}

export default compose(
  withLocalesAndCountries,
  withApollo,
  withNotifications(),
  withRouter,
  withTranslation('privacyPolicyEdit'),
)(EditForm);
