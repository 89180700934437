import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import ReactQuill from 'react-quill';

import { renderInputField } from '../../../../components/Form/TextFieldGroup/fieldInput';
import { renderInputFieldWithLangSelector } from '../../../../components/Form/TextFieldGroup/fieldInputWithLangSelector';
import { modules, formats } from '../../../../components/Form/TextAreaWithDefault/quill_options';
import Tooltip from '../../../../components/Tooltip/tooltipInline';
import DropdownItem from '../../../Onboarding/CompanyOnboarding/dropdown';

import HorizontalLanguageSelector from '../../../../components/Form/LanguageSelection/horizontalLanguageSelector';

const BannerText = ({
  handleChange,
  cp_name,
  bannerText,
  bannerTextInvalid,
  bannerSubText,
  t,
  pp,
  selectedLanguage,
  language_options,
  onLanguageChange,
}) => {
  return (
    <div className='customisation__text'>
      <h4>{t('.banner_text')}</h4>
      {pp.locales.map(locale => (
        <div
          style={{
            marginTop: '2em',
            display: `${locale.isoCode === selectedLanguage ? 'block' : 'none'}`,
          }}
          key={locale.isoCode}
        >
          <Field
            name={`banner_title--${locale.isoCode}`}
            type='text'
            label={t('.banner_title')}
            onChange={e => handleChange(e.target.value, 'banner_title', selectedLanguage)}
            component={renderInputFieldWithLangSelector}
            className='outside-label'
            t={t}
            handleLanguageChange={onLanguageChange}
            languageOptions={language_options}
            selectedLanguage={selectedLanguage}
            mandatory={true}
          />

          <div className='flex_row'>
            <h5 className='quill__label' style={{ width: '100%' }}>
              <div style={{ display: 'inline-block', width: '50%' }}>
                {t('.cookie_text')} <span className='error_color'>*</span>
                <Tooltip>
                  <p> {t('.cookies_tip_text-main')} </p>
                  <ul>
                    <li> {t('.cookies_tip_text-necessary')} </li>
                    <li> {t('.cookies_tip_text-statistic')} </li>
                    <li> {t('.cookies_tip_text-functional')} </li>
                    <li> {t('.cookies_tip_text-marketing')} </li>
                  </ul>
                </Tooltip>
              </div>
              <HorizontalLanguageSelector
                handleLanguageChange={onLanguageChange}
                languageOptions={language_options}
                selectedLanguage={selectedLanguage}
              />
            </h5>
          </div>
          <ReactQuill
            value={bannerText}
            onChange={text => handleChange(text, 'banner_text', selectedLanguage)}
            modules={modules}
            formats={formats}
            className={`quill-editor notranslate`}
          />

          {bannerTextInvalid && (
            <div className='error_color' style={{ marginLeft: '12px', marginTop: '5px' }}>
              {t('.banner_text_missing')}
            </div>
          )}

          <Field
            name={`banner_text_url--${locale.isoCode}`}
            type='text'
            label={t('.banner_policy_text_link')}
            onChange={e => handleChange(e.target.value, 'banner_text_url', selectedLanguage)}
            className='outside-label'
            component={renderInputFieldWithLangSelector}
            tooltip
            tooltipText={t('.text_url_tip')}
            t={t}
            handleLanguageChange={onLanguageChange}
            languageOptions={language_options}
            selectedLanguage={selectedLanguage}
            mandatory={true}
          />
          <Field
            name={`banner_url--${locale.isoCode}`}
            type='text'
            label={t('.banner_policy_link')}
            onChange={e => handleChange(e.target.value, 'banner_url', selectedLanguage)}
            className='outside-label'
            component={renderInputFieldWithLangSelector}
            tooltip
            tooltipText={t('.url_tip')}
            t={t}
            handleLanguageChange={onLanguageChange}
            languageOptions={language_options}
            selectedLanguage={selectedLanguage}
            mandatory={true}
          />
        </div>
      ))}
    </div>
  );
};

export default BannerText;
