/* eslint react/jsx-indent-props: off */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'src/providers/I18n';

import { renderInputField } from '../../../components/Form/TextFieldGroup/fieldInput';
import Button from '../../../components/Button';
import DropdownItem from './dropdown';
import { organization_sector, organization_employees } from './onboarding_data';
import validate from './validate';
import { connect } from 'react-redux';
import { countries, lookup, regions, continents } from 'country-data'
import CountryDropdownItem from './countrydropdown';
import countriesList from 'src/helpers/Countries/countries-list';


const OnBoardingForm = ({
  handleSubmit,
  handleChange,
  handleVATChange,
  toggleVAT,
  error,
  invalid,
  submitting,
  pristine,
  sector,
  employees,
  hasVat,
  isVatValid,
  vatInfo,
  initialValues,
  t,
  vatVerfication,
  countryhandleChange,
  backToPreviousPage
}) => {
  function listOfCountries() {
    var currentLanguage = localStorage.getItem('locale')
    let listOfCountries = countriesList[currentLanguage ? currentLanguage : 'fr'];
    var coutrydata = ["at", "be", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hu", "ie", "it", "lv", "lt", "lu", "mt", "nl", "pl", "pt", "ro", "sk", "si", "es", "se"]
    var newList = [{
      name: '',
      label: t('.please_select_country'),
      value: ''
    }];
    listOfCountries.forEach(item => {
      if (coutrydata.includes(item.value)) {
        newList.push({
          name: item.label,
          label: item.label,
          value: item.value
        })
      }

    })
    newList = newList.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return newList;
  }
  return (
    <div className='onboarding_form_data'>
      <form className='text-form auth_form' onSubmit={handleSubmit}>
        <div>
          <h5>{t('.has_vat')}</h5>
          <div className='radio-options'>
            <Field
              name={'hasVat'}
              component={({ input }) => (
                <Fragment>
                  <div className='radio_input'>
                    <input
                      id='yes'
                      type='radio'
                      {...input}
                      value='yes'
                      name='yes'
                      checked={hasVat === 'yes'}
                      onChange={toggleVAT}
                    />
                    <label htmlFor='yes'>
                      {t('.yes')}
                      <span>{t('.yes_i_have_vat')}</span>
                    </label>

                  </div>
                  <div className='radio_input'>
                    <input
                      id='no'
                      type='radio'
                      {...input}
                      value='no'
                      name='no'
                      checked={hasVat === 'no'}
                      onChange={toggleVAT}
                    />
                    <label htmlFor='no'>
                      {t('.no')}
                      <span> {t('.no_i_have_vat')}</span>
                    </label>
                  </div>
                </Fragment>
              )}
            />
          </div>
        </div>
        <div></div>
        {hasVat === 'yes' && (
          <div className='vat-section'>
            <h5> {t('.vat')} </h5>
            <Field
              name='vat'
              type='text'
              label={t('.vat_number')}
              onChange={handleVATChange}
              mandatory
              component={renderInputField}
              t={t}
            />
            {(!isVatValid) && (
              <Button
                type='submit'
                disabled={invalid || submitting}
                className='btn btn_pill is-blue'
                onClick={vatVerfication}
              >
                {t('.check_vat_number')}
              </Button>
            )}

          </div>
        )}

        {(hasVat === 'no' || isVatValid) && (
          <div>
            <h5> {t('.company_billing_info')} </h5>
            <Field
              name='organization_name'
              type='text'
              label={t('.company_name')}
              onChange={handleChange}
              mandatory
              component={renderInputField}
              t={t}
            />
            <div className='flex_row address_form'>
              <Field
                name='street'
                type='text'
                label={t('.street_number')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='zip_code'
                type='text'
                label={t('.zip_code')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='city'
                type='text'
                label={t('.city')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              {hasVat === 'yes' ? (

                <Field
                  name='country'
                  type='text'
                  label={t('.country')}
                  input={{ disabled: hasVat === 'yes', defaultValue: initialValues?.country, value: initialValues?.country }}
                  mandatory
                  component={renderInputField}
                  t={t}
                />
              ) : null}
              {hasVat === 'no' ? (
                <div className='flex-div newmargin'>
                  <CountryDropdownItem
                    label={t('.country')}
                    name='country'
                    handleChange={countryhandleChange}
                    options={listOfCountries()}
                    value={initialValues?.country}
                    mandatory
                    arraowShow={true}
                  />

                  {/* // <Field
                //   name='country'
                //   type='text'
                //   label={t('.country')}
                //   onChange={handleChange}
                //   mandatory
                //   component={renderInputField}
                //   t={t}
                // /> */}
                </div>

              ) : null}

            </div>
            <Field
              name='phone_number'
              type='text'
              label={t('.phone_number')}
              onChange={handleChange}
              component={renderInputField}
              t={t}
            />
            <h5> {t('.account_tenant')} </h5>
            <div className='flex_row account-wrapper'>
              <Field
                name='firstname'
                type='text'
                label={t('.firstname')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
              <Field
                name='name'
                type='text'
                label={t('.lastname')}
                onChange={handleChange}
                mandatory
                component={renderInputField}
                t={t}
              />
            </div>
            <h5> {t('.company_informations')} </h5>
            <DropdownItem
              className={'company_informations'}
              label={t('.sector')}
              name='sector'
              placeholder={t('.sector_placeholder')}
              handleChange={handleChange}
              options={organization_sector}
              value={sector}
              t={t}
            />
            <DropdownItem
              className={'employee_rate'}
              label={t('.employee_rate')}
              name='employees'
              placeholder={t('.employee_rate_placeholder')}
              handleChange={handleChange}
              options={organization_employees}
              value={employees}
              t={t}
            />
          </div>
        )}
        {error && <p className='validation_error'>{error}</p>}

        <div className='bottom-wrapper'>

          <div className='row'>
            <Button
              className='btn btn_pill back-ground-remove'
              type='button'
              onClick={async () => {
                backToPreviousPage();
              }}
            >
              <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.5 12H3.66998" stroke="#0D0D0D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg></span>
              <span>{t('.back_to_previous_page')}</span>

            </Button>
            {hasVat === 'yes' && isVatValid ? (
              <>
                <Button
                  type='submit'
                  className='btn btn_pill is-blue text-align-end-button'
                  disabled={invalid || submitting || !hasVat || (hasVat === 'yes' && !initialValues?.vat) && (hasVat === 'yes' && !isVatValid) || !initialValues?.country}
                >
                  {t('.nextstep')}
                </Button>
              </>
            ) : null}
            {hasVat === 'no' ? (
              <>
                <Button
                  type='submit'
                  className='btn btn_pill is-blue '
                  disabled={invalid || submitting || !initialValues?.country}
                >
                  {t('.nextstep')}
                  {/* {t('.overview_payment')} */}
                </Button>
              </>
            ) : null}
          </div>

        </div>



        {/* <div className='button-end'>
          {hasVat === 'yes' && isVatValid ? (
            <>
              <Button
                type='submit'
                className='btn btn_pill is-blue text-align-end-button'
                disabled={invalid || submitting || !hasVat || (hasVat === 'yes' && !initialValues?.vat) && (hasVat === 'yes' && !isVatValid) || !initialValues?.country}
              >
                {t('.nextstep')}
              </Button>
            </>
          ) : null}
          {hasVat === 'no' ? (
            <>
              <Button
                type='submit'
                className='btn btn_pill is-blue '
                disabled={invalid || submitting || !initialValues?.country}
              >
                {t('.nextstep')}
              </Button>
            </>
          ) : null}
        </div> */}
      </form>
    </div>
  );
};

OnBoardingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: 'onboarding_form_data',
    enableReinitialize: true,
    validate
  }),
  withTranslation('account_onboarding'),
  withRouter,
)(OnBoardingForm);
