/* eslint max-len: off */
import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { formattedDate } from '../../helpers/date';
import add from '../../assets/img/add.svg';
import blocked from '../../assets/img/blocked.svg';
import AuthWrapper from '../../components/Auth';
import Loading from '../../components/Loading';
import { withTranslation } from '../../providers/I18n';
import PolicyCard from '../../components/PolicyCard';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent.jsx';
import { AiOutlineCloseCircle, AiOutlineFileAdd } from 'react-icons/ai';

const PrivacyPolicies = ({ t }) => {
  return (
    <AuthWrapper>
      <TitleComponent title={t('.my_privacy_policies')} />
      <Query query={ALL_PRIVACY_POLICIES_QUERY} fetchPolicy={'network-only'}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return `Error! ${error.message}`;
          const {
            allPrivacyPolicies,
            organization: { settings },
          } = data;

          const limit = settings.policies_limit ? settings.policies_limit.privacy : 1000;
          const canCreateMore = limit > allPrivacyPolicies.length;

          const ConditionalLink = canCreateMore ? Link : Fragment;

          return (
            <div className='row privacy-policies'>
              <div className='columns'>
                <div className='row'>
                  <div className='column'>
                    <h1>{t('.my_privacy_policies')}</h1>
                  </div>
                </div>
                <ul className='privacy-policies-list'>
                  <li className='privacy-policy-card'>
                    <ConditionalLink to={`/privacy-policies/new`}>
                      <div className={`add-policy ${canCreateMore ? '' : 'blocked'}`}>
                        <div className='add-policy-container'>
                          {canCreateMore ? (
                            <AiOutlineFileAdd size='3rem' />
                          ) : (
                            <AiOutlineCloseCircle size='3rem' />
                          )}
                          <span className='text-center'> {t('.add_new_policy')}</span>
                          {!canCreateMore && <p> {t('.limited_message')} </p>}
                        </div>
                      </div>
                    </ConditionalLink>
                  </li>
                  {/*.reverse().map for reverse order*/}
                  {[...allPrivacyPolicies].map(pp => (
                    <PolicyCard key={pp.id} policyType='privacy' t={t} policy={pp} />
                  ))}
                </ul>
              </div>
            </div>
          );
        }}
      </Query>
    </AuthWrapper>
  );
};

const ALL_PRIVACY_POLICIES_QUERY = gql`
  query allPrivacyPolicies {
    organization {
      id
      settings
    }
    allPrivacyPolicies {
      id
      title
      createdAt
      version
      updatedAt
      publishedAt
      status
      data {
        version
      }
      owner {
        id
        email
      }
    }
  }
`;

PrivacyPolicies.propTypes = {};

PrivacyPolicies.defaultProps = {};

export default compose(withTranslation('privacyPolicies'))(PrivacyPolicies);
