import { validatePhone, validateEmail, validateUrl, validateHTTPUrl } from './validateFormats';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = '.required';
  } else if (!validateEmail(values.email) && values.email) {
    errors.email = '.mail_not_valid';
  }

  if (!validatePhone(values.phone_number) && values.phone_number) {
    errors.phone_number = '.phone_error';
  }

  if (!values.id_number) {
    errors.id_number = '.required';
  }

  if (!values.company_name) {
    errors.company_name = '.required';
  }

  if (!values.street) {
    errors.street = '.required';
  }

  if (!values.number) {
    errors.number = '.required';
  }

  if (!values.city) {
    errors.city = '.required';
  }

  if (!values.country) {
    errors.country = '.required';
  }

  if (!values.zip_code) {
    errors.zip_code = '.required';
  }

  if (!values.website) {
    errors.website = '.required';
  }

  if (!values.name) {
    errors.name = '.required';
  }

  if (!values.link) {
    errors.link = '.required';
  } else if (!validateUrl(values.link) && values.link) {
    errors.link = '.url_not_valid';
  }

  if (!values.url) {
    errors.url = '.required';
  } else if (!validateUrl(values.url) && values.url) {
    errors.url = '.url_not_valid';
  }

  if (!values.data) {
    errors.data = '.required';
  } else if (!validateHTTPUrl(values.data) && values.data) {
    errors.data = '.url_http_not_valid';
  }

  return errors;
};

export default validate;
