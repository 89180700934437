/* eslint max-len: off */
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { formattedDate } from '../../helpers/date';
import AuthWrapper from '../../components/Auth';
import Loading from '../../components/Loading';
import VersionsList from './versions_list';
import Button from '../../components/Button';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

const PolicyVersions = ({
  match: {
    params: { id },
  },
  t,
}) => {
  return (
    <AuthWrapper>
      <Query query={POLICY_VERSIONS_QUERY} variables={{ id: id }} fetchPolicy={'network-only'}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return `Error! ${error.message}`;
          const { privacyPolicyVersions, privacyPolicy } = data;
          return (
            <div className='row privacy-policies'>
              <TitleComponent title={privacyPolicy.title + ' | ' + t('.archives')} />
              <div className='columns'>
                <div className='row'>
                  <div className='column'>
                    <div className='section__heading'>
                      <h1>{t('.archives')}</h1>
                      <Link to={`/privacy-policies/${id}`}>
                        <Button className='btn btn_pill is-outlined is-blue'>
                          <i className='fas fa-arrow-left margin-right-10' />
                          {t('.dashboard')}
                        </Button>
                      </Link>
                    </div>
                    <VersionsList
                      privacyPolicyVersions={privacyPolicyVersions}
                      policyType={privacyPolicy.type}
                      ppId={id}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </AuthWrapper>
  );
};

const POLICY_VERSIONS_QUERY = gql`
  query policyVersions($id: ID!) {
    privacyPolicyVersions(policyId: $id) {
      archivedAt
      html
      id
      pdf
      publishedAt
      status
      uid
      version
    }
    privacyPolicy(id: $id) {
      id
      type
      title
    }
  }
`;

PolicyVersions.propTypes = {};

PolicyVersions.defaultProps = {};

export default compose(withTranslation('dashboard'))(PolicyVersions);
