import React from 'react';
import PropTypes from 'prop-types';

const FieldInput = ({ id, input, type, label, meta: { touched, error, active }, prefix }) => (
  <div>
    <div className={`text_input ${prefix && 'prefix_input'}`}>
      <label>{label}</label>
      {prefix && <span className='prefix'>{prefix}</span>}{' '}
      <input {...input} type={type} placeholder={label} />
    </div>
    {touched && error && !active && <span className='error_message'>{error}</span>}
  </div>
);

FieldInput.defaultProps = {
  label: '',
  type: 'text',
};

FieldInput.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.any,
    anyTouched: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.bool,
  }).isRequired,
};

export default FieldInput;
