import React, { Fragment, useState } from 'react';
import { Field } from 'redux-form';
import ReactQuill from 'react-quill';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import update from 'immutability-helper';

import { renderInputField } from '../../../../components/Form/TextFieldGroup/fieldInput';
import { renderField } from '../../../../components/Form/TextArea';
import RadioGroup from '../../../../components/Form/RadioGroupSimple';
import { modules, formats } from '../../../../components/Form/TextAreaWithDefault/quill_options';
import Button from '../../../../components/Button';
import { loadModal } from '../../../../redux/modal/action';

import HorizontalLanguageSelector from '../../../../components/Form/LanguageSelection/horizontalLanguageSelector';
import { AiOutlineWarning } from 'react-icons/ai';

const BannerCookiesForm = ({
  handleChange,
  cookies,
  cookies_consent,
  cookies_available,
  loadExplanationModal,
  selectedLanguage,
  language_options,
  onLanguageChange,
  t,
  pp,
}) => {
  const [cookieSelected, selectCookieTab] = useState('necessary');

  const onOptInChange = (val, name) => {
    let newCookiesConsent = update(cookies_consent, {
      [name]: { $set: val[0] },
    });

    handleChange(newCookiesConsent, 'cookies_consent');
  };

  const handleCookieChange = (val, name, key) => {
    let valueToReturn = [];

    cookies.map((cookie, i) => {
      if (cookie.name === name) {
        valueToReturn = update(cookies, {
          [i]: {
            [key]: {
              $set: val,
            },
          },
        });
      }
    });

    handleChange(valueToReturn, 'banner_cookies', selectedLanguage);
  };

  const toggleExplanation = text => {
    loadExplanationModal({
      modalType: 'cookie_explanation',
      content: text,
      title: t('.explanations'),
    });
  };

  return (
    <div className='customisation__cookies'>
      <h4>
        <div style={{ display: 'inline-block', width: '50%' }}>{t('.cookies')} :</div>
        <HorizontalLanguageSelector
          handleLanguageChange={onLanguageChange}
          languageOptions={language_options}
          selectedLanguage={selectedLanguage}
        />
      </h4>

      <ul className='cst__cookies__nav'>
        {cookies.map(cookie => (
          <li
            key={cookie.name}
            className={`cst__cookies__nav--item ${
              cookieSelected === cookie.name ? 'selected' : ''
            }`}
            onClick={() => selectCookieTab(cookie.name)}
          >
            {t(`.${cookie.name}`)}
          </li>
        ))}
      </ul>
      {cookies.map(cookie => (
        <div
          key={cookie.name}
          className={`cst__cookies__content ${cookieSelected !== cookie.name ? 'hidden' : ''}`}
        >
          {cookies_available.includes(cookie.name) ? (
            <Fragment>
              {pp.locales.map(locale => (
                <div
                  className='cst__cookies__content--left'
                  style={{
                    display: `${locale.isoCode === selectedLanguage ? 'block' : 'none'}`,
                  }}
                  key={locale.isoCode}
                >
                  <Field
                    name={`cookie_title_${cookie.name}--${locale.isoCode}`}
                    type='text'
                    label={t('.cookie_title')}
                    onChange={e => handleCookieChange(e.target.value, cookie.name, 'title')}
                    component={renderInputField}
                    t={t}
                    mandatory={true}
                  />
                  <ReactQuill
                    value={cookie.text}
                    onChange={text => handleCookieChange(text, cookie.name, 'text')}
                    modules={modules}
                    formats={formats}
                    className={`quill-editor notranslate`}
                  />
                </div>
              ))}
              <div className='cst__cookies__content--right'>
                <div className='left_radio'>
                  <p> {t('.default_opt-in')} </p>
                  <RadioGroup
                    value={[cookies_consent[cookie.name]]}
                    options={[
                      {
                        id: `opt_in_${cookie.name}_true`,
                        name: cookie.name,
                        value: 'yes',
                        label: t('.opt_in_default'),
                      },
                      {
                        id: `opt_in_${cookie.name}_false`,
                        name: cookie.name,
                        value: 'no',
                        label: t('.opt_out_default'),
                      },
                    ]}
                    readOnly={cookie.name === 'necessary'}
                    onChange={onOptInChange}
                  />
                </div>
                <div className='warning_message'>
                  <AiOutlineWarning size='1.5rem' className='mr-05' />
                  <span> {cookie.warning} </span>
                </div>
                {cookie.explanation && (
                  <Button
                    className='btn btn_pill is-outlined is-blue'
                    invert
                    onClick={() => toggleExplanation(cookie.explanation)}
                  >
                    {' '}
                    {t('.more_info')}{' '}
                  </Button>
                )}
              </div>
            </Fragment>
          ) : (
            <div>{t('.no_cookies')}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = {
  loadExplanationModal: loadModal,
};

export default compose(connect(null, mapDispatchToProps))(BannerCookiesForm);
