const INITIAL_STATE = {
  modalType: null,
  content: null,
  title: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SHOW_MODAL':
      return payload;
    case 'HIDE_MODAL':
      return INITIAL_STATE;
    default:
      return state;
  }
};
