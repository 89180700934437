export const reader = () => {
  const reader = {
    currentTab: null,
    previousTab: null,
    nextTab: null,

    currentCard: null,
    currentNav: null,

    container: document.getElementsByClassName('preview')[0],
    nav: document.getElementsByClassName('navbar')[0],
    sectionElements: document.getElementsByClassName('main_section'),
    sublistElements: document.getElementsByClassName('sublist__item'),
    subSectionElements: document.getElementsByClassName('sub_section'),
    cardElements: document.getElementsByClassName('maintitle_card'),
    navElement: document.getElementsByClassName('navbar__item--link'),

    init: function() {
      this.navigateSection();
      this.initTab();
    },

    initTab: function() {
      let that = this;

      for (let el of this.navElement) {
        el.addEventListener('click', function() {
          if (that.currentNav && that.currentNav !== this) {
            that.currentNav.classList.remove('active');
            that.currentCard.classList.remove('active');
          }
          that.currentNav = this;
          that.toggle(this);
        });
      }

      for (let el of this.cardElements) {
        el.addEventListener('click', function() {
          if (that.currentCard) {
            that.currentNav.classList.remove('active');
            that.currentCard.classList.remove('active');
          }

          if (that.currentCard === this) {
            that.currentNav = null;
            that.currentCard = null;

            that.previousTab = that.currentTab;
            that.currentTab.classList.remove('visible');
            that.currentTab = null;
          } else {
            that.currentCard = this;
            that.toggle(this);
          }
        });
      }
    },

    toggle: function(item) {
      let idElements = this.container.querySelectorAll(`[data-id='${item.dataset.id}']`);

      this.currentNav = idElements[0];
      this.currentCard = idElements[1];

      this.currentNav.classList.add('active');
      this.currentCard.classList.add('active');

      this.nextTab = document.getElementById(item.dataset.id);

      if (this.currentTab && this.nextTab !== this.currentTab) {
        this.currentTab.classList.remove('visible');
      }

      this.previousTab = this.currentTab;
      this.currentTab = this.nextTab;
      this.currentTab.classList.add('visible');

      let topPos = this.currentCard.offsetTop;

      this.scrollToElem(topPos);
    },

    nativeSmoothScrollTo: function(to) {
      this.container.scroll({
        behavior: 'smooth',
        left: 0,
        top: to,
      });
    },

    smoothScrollTo: function(to, duration) {
      const element = this.container,
        start = element.scrollTop,
        change = to - start,
        startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = _ => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };
      animateScroll();
    },

    supportsNativeSmoothScroll: 'scrollBehavior' in document.documentElement.style,

    scrollToElem: function(top) {
      if (top) {
        if (this.supportsNativeSmoothScroll) {
          this.nativeSmoothScrollTo(top);
        } else {
          this.smoothScrollTo(top, 600);
        }
      }
    },

    navigateSection: function() {
      let that = this;

      for (let el of this.sublistElements) {
        el.addEventListener('click', function() {
          let data = el.dataset.section;
          let section = document.getElementById(data);

          let topPos = section.offsetTop;

          that.scrollToElem(topPos);
        });
      }

      this.container.onscroll = function() {
        let top =
          (that.container.pageYOffset || that.container.scrollTop) -
          (that.container.clientTop || 0);

        for (let el of that.subSectionElements) {
          if (top + 100 >= el.offsetTop && top - 300 <= el.offsetTop) {
            let id = el.id;

            for (let el of that.sublistElements) {
              let section = el.dataset.section;

              if (section === id) {
                el.classList.add('active');
              } else {
                el.classList.remove('active');
              }
            }
          }
        }
      };
    },

    toggleTitleTile: function() {
      const element = document.getElementsByClassName('expandable_titles')[0];

      let selectedElement = null;

      let that = this;

      let selectableElements = element.getElementsByClassName('title_div');

      for (let el of selectableElements) {
        el.addEventListener('click', function() {
          if (selectedElement && selectedElement !== this) {
            selectedElement.classList.remove('active');
          }

          el.classList.add('active');

          let topPos = el.offsetTop;

          that.container.scroll({
            top: topPos,
            left: 0,
            behavior: 'smooth',
          });

          if (selectedElement === this) {
            selectedElement.classList.remove('active');
            selectedElement = null;
          } else {
            selectedElement = this;
          }
        });
      }
    },
  };

  reader.init();
};
