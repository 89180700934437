import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';

import EditForm from './form';
import AuthWrapper from '../../components/Auth';
import Loading from '../../components/Loading';
import GraphqlCalls from './GraphQl';
import { withTranslation } from '../../providers/I18n';

const PrivacyPolicyEdit = ({
  match: {
    params: { id },
    url,
  },
  t,
}) => {
  const isCookiePolicy = () => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  const policyTypeQuery = !isCookiePolicy() ? 'cookie' : 'privacy';
  const policyType = isCookiePolicy() ? 'cookie' : 'privacy';

  return (
    <AuthWrapper>
      <div className='row'>
        <div className='columns'>
          <h1> {t(`.my_${policyType}_policies`)} </h1>
          <Query
            fetchPolicy={'network-only'}
            query={GraphqlCalls.GET_PRIVACY_POLICY_QUERY}
            variables={{ ppId: id, type: policyTypeQuery }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return `Error! ${error.message}`;
              const { privacyPolicy, allPrivacyPolicies } = data;
              return (
                <EditForm
                  pp={privacyPolicy}
                  allPrivacyPolicies={allPrivacyPolicies}
                  formType={'edit'}
                  linkPolicyMutation={GraphqlCalls.LINK_POLICIES_MUTATION}
                  graphqlMutation={GraphqlCalls.UPDATE_PRIVACY_POLICY_MUTATION}
                />
              );
            }}
          </Query>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default compose(withRouter, withTranslation('privacyPolicyEdit'))(PrivacyPolicyEdit);
