import Axios from 'axios';

Axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('token');
    const locale = localStorage.getItem('locale') || 'fr_BE';
    const authorizationHeader = token ? `Bearer ${token}` : null;

    config.headers['Accept-Language'] = locale;
    config.headers['Authorization'] = authorizationHeader;
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);
