import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Header from '../Header';
import ModalContainer from '../Modal/container';
import Footer from '../Footer';
import withBreadcrumb from '../../providers/breadcrumbs/hoc/breadcrumbs';

const AuthWrapper = ({ children, authenticated, Breadcrumb }) => {
  return (
    <Fragment>
      <Header authenticate={authenticated} />
      <Breadcrumb />
      <main>
        <div className='main_content'>{children}</div>
      </main>
      <Footer />
      <ModalContainer />
    </Fragment>
  );
};

AuthWrapper.defaultProps = {};

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { authenticated } = auth;
  return { authenticated };
};

export default compose(withBreadcrumb, connect(mapStateToProps))(AuthWrapper);
