import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { withNotifications, NotificationModel } from '../../../providers/notifications';
import { withTranslation } from '../../../providers/I18n';

const PublishModal = props => {
  const { title, closeExampleModal, content, t } = props;
  const { handleReturn, publishPolicy, previews, policyType } = content;
  const [isLoading, setLoadingTo] = useState(false);

  const handlePublish = () => {
    const {
      match: {
        params: { id },
      },
      history,
      notificationsProvider,
    } = props;

    setLoadingTo(true);

    const publishPoliciesMutation = [];

    for (let key in previews) {
      previews[key].status !== 'published' &&
        publishPoliciesMutation.push(publishPolicy(previews[key].id));
    }

    Promise.all(publishPoliciesMutation)
      .then(data => {
        closeExampleModal();
        const notification = new NotificationModel({
          title: t('.policy_publication'),
          message: t('.policy_has_updated'),
          type: 'success',
          timer: 6000,
        });

        notificationsProvider.add(notification);

        if (parseInt(data?.[0]?.data?.publishPrivacyPolicy?.version, 10) > 1) {
          history.push(`/${policyType}-policies/${id}`);
        } else {
          history.push(`/${policyType}-policies/${id}/integration_process`);
        }
      })
      .catch(() => {
        const notification = new NotificationModel({
          title: t('.policy_publication'),
          message: t('.policy_has_not_updated'),
          type: 'error',
          timer: 6000,
        });
        notificationsProvider.add(notification);
      });
  };

  const handleReturnToPolicy = (id, type) => {
    handleReturn(id, type).then(closeExampleModal);
  };

  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'> {title} </h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='modal_content'>
        <p>{t('.modal_text')}</p>
        <div className='list_buttons'>
          {previews['cookie'] && (
            <Button
              className='btn btn_pill is-outlined is-blue'
              onClick={() => handleReturnToPolicy(previews['cookie'].id, 'cookie')}
            >
              {previews['cookie'].status === 'published' ? t('.return_to_cp') : t('.modify_cp')}
            </Button>
          )}

          {previews['privacy'] && (
            <Button
              className='btn btn_pill is-outlined is-blue'
              onClick={() => handleReturnToPolicy(previews['privacy'].id, 'privacy')}
            >
              {previews['privacy'].status === 'published' ? t('.return_to_pp') : t('.modify_pp')}
            </Button>
          )}

          <Button className='btn btn_pill is-blue' published onClick={handlePublish}>
            {!isLoading ? t('.publish_my_policies') : t('.publishing')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

PublishModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withNotifications(),
  withTranslation('privacyPolicyPreview'),
)(PublishModal);
