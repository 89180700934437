import React from 'react';

const Tooltip = ({ children }) => {
  return (
    <div className='tooltip__container'>
      <div className='tooltip__icon'>?</div>
      <div className='tooltip__box'>{children}</div>
    </div>
  );
};

export default Tooltip;
