/* eslint no-console: off */
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';
import gql from 'graphql-tag';
import { filter } from 'lodash';
import update from 'immutability-helper';
import Prompt from 'react-router-dom/es/Prompt';

import { withTranslation } from '../../providers/I18n';
import { formattedDate } from '../../helpers/date';
import { loadModal } from '../../redux/modal/action';
import { setPreview } from '../../redux/preview/action';
import AuthWrapper from '../../components/Auth';
import Loading from '../../components/Loading';
import PreviewContainer from './preview_container';
import PreviewActions from './preview_actions';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class PrivacyPolicyPreview extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isLoading: true,
      canLeave: true,
      ppStatus: null,
      previewLoaded: false,
      policySelected: '',
      cookieAccessible: false,
      privacyAccessible: false,
      previews: {},
      title: '',
    };
  }

  componentWillMount() {
    const { match, history, location } = this.props;
    const {
      params: { id },
    } = match;

    const type = this.isCookiePolicy(match.url) ? 'cookie' : 'privacy';

    this.setState({ policyType: type, policySelected: type }, () => this.fetchPolicy(id));

    this.historyUnlisten = history.listen(newLocation => {
      const { canLeave } = this.state;

      if (location !== newLocation) {
        localStorage.setItem('ppStatus', 'unblocked');
        if (!canLeave) {
          this.unblockPolicy(id);
        }
      }
    });
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }

  fetchPolicy = async id => {
    const { client, history, match } = this.props;
    const { policyType } = this.state;
    const {
      params: { version },
    } = match;

    await client
      .query({
        query: PRIVACY_POLICY_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      })
      .then(({ data }) => {
        const {
          privacyPolicy: { locales },
          organization,
        } = data;
        const title = data.privacyPolicy.title;

        this.setState({
          locales: locales,
          organization: organization,
          title: title,
        });

        if (version) {
          let versionPreview = filter(data.privacyPolicyVersions, ['version', parseInt(version)]);
          this.setPreview(versionPreview[0]);
          this.setState({
            versionPreview: versionPreview[0],
            previewLoaded: true,
            ppStatus: data.privacyPolicy.status,
            hasBothPolicies: false,
          });

          if (versionPreview[0].status === 'published') {
            this.canAccessRelatedPolicy(data);
            this.storePreview(policyType, {
              ...data.privacyPolicy.data,
              status: 'published',
              id: id,
            });
          } else {
            this.setState({ isLoading: false });
          }
        } else {
          if (
            localStorage.getItem('ppStatus') !== 'blocked' &&
            data.privacyPolicy.status !== 'blocked'
          ) {
            history.push(`/privacy-policies/`);
          } else {
            this.blockPolicy(id).then(result => {
              let {
                data: { blockPrivacyPolicy },
              } = result;

              this.setPreview(blockPrivacyPolicy.data);
              this.storePreview(policyType, {
                ...blockPrivacyPolicy.data,
                status: 'blocked',
                id: id,
              });

              this.setState({
                previewLoaded: true,
                ppStatus: 'blocked',
                canLeave: false,
              });

              this.canAccessRelatedPolicy(data);
            });
          }
        }
      });
  };

  blockPolicy = async id => {
    const { client } = this.props;
    return await client.mutate({
      mutation: BLOCK_PRIVACY_POLICY_MUTATION,
      variables: {
        id,
      },
    });
  };

  unblockPolicy = async id => {
    const { client } = this.props;
    return await client.mutate({
      mutation: UNBLOCK_PRIVACY_POLICY_MUTATION,
      variables: {
        id,
      },
    });
  };

  publishPolicy = async id => {
    const { client } = this.props;
    return await client.mutate({
      mutation: PUBLISH_PRIVACY_POLICY_MUTATION,
      variables: {
        id,
      },
    });
  };

  getPolicyAndCategories = async (id, type) => {
    const { client } = this.props;

    return await client.query({
      query: POLICY_CATEGORIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: id,
        type: type,
      },
    });
  };

  canAccessRelatedPolicy = async item => {
    const { policyType } = this.state;
    const {
      privacyPolicy: { parentId, cookiePolicy },
    } = item;

    if (cookiePolicy || parentId) {
      const relatedPolicy =
        policyType === 'cookie'
          ? await this.checkRelatedPolicy(parentId, 'privacy')
          : await this.checkRelatedPolicy(cookiePolicy.id, 'cookie');

      this.setState({
        cookieAccessible: policyType === 'cookie' || relatedPolicy,
        privacyAccessible: policyType === 'privacy' || relatedPolicy,
        hasBothPolicies: true,
      });
    } else {
      this.setState({
        hasBothPolicies: false,
      });
    }

    this.setState({ isLoading: false });
  };

  checkRelatedPolicy = async (id, type) => {
    let result = true;

    const { data } = await this.getPolicyAndCategories(id, type);

    if (data.privacyPolicy.status !== 'published') {
      if (this.isCategoriesCompleted(data.categories)) {
        const resultBlockPolicy = await this.blockPolicy(id);
        const {
          data: { blockPrivacyPolicy },
        } = resultBlockPolicy;
        this.storePreview(type, { ...blockPrivacyPolicy.data, status: 'blocked', id: id });

        result = true;
      } else {
        this.storePreview(type, { status: 'uncompleted', id: id });
        result = false;
      }
    } else {
      this.storePreview(type, { ...data.privacyPolicy.data, status: 'published', id: id });
      result = true;
    }

    return result;
  };

  storePreview = (type, data) => {
    let previews = update(this.state.previews, {
      [type]: {
        $set: data,
      },
    });

    const locales = Object.keys(previews).reduce((result, type) => {
      previews[type].status !== 'uncompleted' &&
        Object.keys(previews[type].html).forEach(isoCode => {
          if (result.find(locale => locale.isoCode === isoCode) === undefined) {
            result.push({ isoCode });
          }
        });
      return result;
    }, []);

    this.setState({ previews, locales });
  };

  setPreview = preview => {
    const { setPreview } = this.props;

    setPreview({
      htmlPreview: preview.html,
      pdfLink: preview.pdf,
    });
  };

  isCategoriesCompleted(categories) {
    let categoriesCompleted = true;
    for (let cat of categories) {
      let state = cat.status.key;
      if (state === 'in_progress') categoriesCompleted = false;
    }

    return categoriesCompleted;
  }

  isCookiePolicy = url => {
    let arr = url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  toggleModal() {
    this.setState({ canLeave: true });

    const { loadModal, t } = this.props;
    loadModal({
      modalType: 'publish',
      title: t('.modal_title'),
      content: {
        handleReturn: this.handleReturn,
        publishPolicy: this.publishPolicy,
        previews: this.state.previews,
        policyType: this.state.policyType,
      },
    });
  }

  handlePolicyChange = type => {
    this.setPreview(this.state.previews[type]);
    this.setState({ policySelected: type });
  };

  handleReturn = async (id, type) => {
    const {
      match: { params },
      history,
    } = this.props;
    const { previews, policyType } = this.state;

    const unblockPoliciesMutation = [];

    for (let key in previews) {
      previews[key].status === 'blocked' &&
        unblockPoliciesMutation.push(this.unblockPolicy(previews[key].id));
    }

    this.setState({ canLeave: true });

    try {
      await Promise.all(unblockPoliciesMutation);
    } catch (e) {
      // Error handler
    }
    id
      ? history.push(`/${type}-policies/${id}`)
      : history.push(`/${policyType}-policies/${params.id}`);
  };

  render() {
    const {
      canLeave,
      ppStatus,
      previews,
      previewLoaded,
      versionPreview,
      locales,
      organization,
      isLoading,
      hasBothPolicies,
      cookieAccessible,
      privacyAccessible,
      policyType,
      policySelected,
      title,
    } = this.state;
    const {
      match: {
        params: { version },
      },
      preview,
      t,
    } = this.props;

    return (
      <AuthWrapper>
        <TitleComponent title={title + ' | ' + t('.preview')} />
        <Prompt when={!canLeave} message={t('.leaving_message')} />
        {isLoading && (
          <div className='row'>
            <div className='column'>
              <Loading />
            </div>
          </div>
        )}
        {ppStatus && !isLoading && (
          <div className='row'>
            <div className='column'>
              {version || ppStatus === 'published' ? (
                <Fragment>
                  {versionPreview && versionPreview.status === 'published' ? (
                    <div>
                      <h1>
                        {' '}
                        {t('.policy_preview')} <b> {t('.online')} </b>{' '}
                      </h1>
                      <p>
                        {' '}
                        {t('.version')} : {version}{' '}
                      </p>
                      <span> {t('.published')} </span>
                    </div>
                  ) : (
                    <div>
                      <h1>
                        {' '}
                        {t('.policy_preview')} <b> {t('.old')} </b>{' '}
                      </h1>
                      <p>
                        {' '}
                        {t('.version')} : {version}{' '}
                      </p>
                      <span> {t('.archived')} </span>
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <h1>
                      {' '}
                      {policyType === 'privacy'
                        ? t('.privacy_policy_preview')
                        : t('.cookies_policy_preview')}{' '}
                    </h1>
                    <p className='important_text'>
                      {' '}
                      {policyType === 'privacy'
                        ? t('.privacy_draft_text_first')
                        : t('.cookies_draft_text_first')}{' '}
                    </p>
                    <p className='important_text'> {t('.draft_text_second')} </p>
                  </div>
                </Fragment>
              )}

              <PreviewContainer
                locales={locales}
                preview={preview}
                previews={previews}
                previewLoaded={previewLoaded}
                policyType={policyType}
                hasBothPolicies={hasBothPolicies}
                cookieAccessible={cookieAccessible}
                privacyAccessible={privacyAccessible}
                hasPDFAccess={
                  organization && organization.settings.access_limit
                    ? !organization.settings.access_limit.preview
                    : true
                }
                policySelected={policySelected}
                handlePolicyChange={this.handlePolicyChange}
                t={t}
              />

              <PreviewActions
                previews={previews}
                policyType={policyType}
                hasBothPolicies={hasBothPolicies}
                cookieAccessible={cookieAccessible}
                privacyAccessible={privacyAccessible}
                hasPublishAccess={
                  organization && organization.settings.access_limit
                    ? !organization.settings.access_limit.publish
                    : true
                }
                toggleModal={this.toggleModal}
                handleReturn={this.handleReturn}
                t={t}
              />
            </div>
          </div>
        )}
      </AuthWrapper>
    );
  }
}

const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      id
      title
      status
      parentId
      cookiePolicy {
        id
      }
      locales {
        isoCode
      }
      data {
        html
        pdf
      }
      customization {
        colors
        logo {
          type
          path
        }
      }
    }
    organization {
      id
      settings
    }
    privacyPolicyVersions(policyId: $id) {
      archivedAt
      html
      id
      pdf
      publishedAt
      status
      uid
      version
    }
  }
`;

const POLICY_CATEGORIES_QUERY = gql`
  query categories($type: String, $id: ID!) {
    privacyPolicy(id: $id) {
      id
      status
      data {
        html
        pdf
      }
    }
    categories(type: $type) {
      name
      status(policyId: $id, policyType: $type)
    }
  }
`;

const UNBLOCK_PRIVACY_POLICY_MUTATION = gql`
  mutation unblockPrivacyPolicy($id: ID!) {
    unblockPrivacyPolicy(id: $id) {
      id
      status
    }
  }
`;

const BLOCK_PRIVACY_POLICY_MUTATION = gql`
  mutation blockPrivacyPolicy($id: ID!) {
    blockPrivacyPolicy(id: $id) {
      id
      status
      locales {
        isoCode
      }
      data {
        html
        pdf
      }
    }
  }
`;

const PUBLISH_PRIVACY_POLICY_MUTATION = gql`
  mutation publishPrivacyPolicy($id: ID!) {
    publishPrivacyPolicy(id: $id) {
      id
      status
      version
    }
  }
`;

const mapStateToProps = state => {
  const { preview } = state;
  return {
    preview,
  };
};

const mapDispatchToProps = {
  loadModal,
  setPreview,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApollo,
  withRouter,
  withTranslation('privacyPolicyPreview'),
)(PrivacyPolicyPreview);
