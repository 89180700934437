import React from 'react';

const renderCheckboxField = ({ renderLabel, input, label, checked, value, meta: { error } }) => {
  return (
    <label>
      <input {...input} type='checkbox' checked={input.value === true} />
      <div className={`checkbox_label ${error && !input.value ? 'error' : ''}`}>
        <span>{label}</span>
        {renderLabel && renderLabel()}
      </div>
    </label>
  );
};

export { renderCheckboxField };
