import React from 'react'
import Select from 'react-select'
import { NotificationModel, NotificationsProvider } from 'src/providers/notifications'

import { withTranslation } from '../../../providers/I18n'
import { hideModal, loadModal } from 'src/redux/modal/action'
import { connect } from 'react-redux'
import { compose } from 'recompose'

class LocaleField extends React.Component {
  constructor(props) {
    super(props)
    const { t, values, availableLocales, locales, selectedLicense } = props
    this.state = {
      selectedOption: (locales || []).map(locale => {
        return {
          value: locale,
          label: t(`.${locale}`),
        }
      }),
      availableOptions: availableLocales.map(locale => {
        return {
          value: locale,
          label: t(`.${locale}`),
        }
      }),
      values: values.locales,
    }
  }

  handleChange = selectedOptionsNew => {

    const { packageData, notificationsProvider, policyType } = this.props;
    const { selectedLicense } = this.props
    const { selectedOptions } = this.state;
    if ((policyType === 'privacy' ? (packageData?.pNoOfLang === 0) : (packageData?.cNoOfLang === 0))) {
      this.setState({ selectedOptions: selectedOptions ? selectedOptions : [] })
      this.showUpgradeModel();
      return;

    }
    if (packageData && selectedOptionsNew?.length > (policyType === 'privacy' ? (packageData?.pNoOfLang) : (packageData?.cNoOfLang))) {
      this.setState({ selectedOptions: selectedOptions ? selectedOptions : [] })
      this.showUpgradeModel();
      return;
    }

    const languages = selectedLicense?.features?.reduce(
      (arr, item) => (item.groupCode === 'language' && arr.push(item['code']), arr),
      [],
    );



    var selectValueOption = selectedOptionsNew?.map(opt => opt.value)
    // || selectedOption?.length > selectedLicense?.package?.cNoOfLang
    if (selectedOptionsNew && selectedOptionsNew.length > 0) {
      let isFounded = selectValueOption?.every(ai => languages.includes(ai));
      if (!isFounded) {
        this.showUpgradeModel();
        this.setState({ selectedOptions: selectedOptions ? selectedOptions : [] })
        return;

      }
      else {
        this.setState(
          {
            selectedOption: selectedOptionsNew,
            values: selectedOptionsNew && selectedOptionsNew.map(opt => opt.value),
          },
          () => this.props.handleChange(this.state.values, 'locales'),
        )
      }
    }
    else {
      this.setState(
        {
          selectedOption: selectedOptionsNew,
          values: selectedOptionsNew && selectedOptionsNew.map(opt => opt.value),
        },
        () => this.props.handleChange(this.state.values, 'locales'),
      )
    }
    this.setState(
      {
        selectedOption: selectedOptionsNew,
        values: selectedOptionsNew && selectedOptionsNew.map(opt => opt.value),
      },
      () => this.props.handleChange(this.state.values, 'locales'),
    )
    // if (packageData) {


    // }
    // else {

    // }
    // if (policyType === 'privacy') {
    //   var lan = localStorage.getItem('locale');
    //   if (packageData && selectedOption?.length > (policyType === 'privacy' ? (packageData?.pNoOfLegis) : (packageData?.cNoOfLegis))) {

    //     const notification = new NotificationModel({
    //       title: '',
    //       message: (!lan || lan === 'en') ? `Your package allow only ${(policyType === 'privacy' ? (packageData?.pNoOfLang) : (packageData?.cNoOfLang))} language` : (lan === 'fr') ? `Votre forfait n'autorise que la langue ${(policyType === 'privacy' ? (packageData?.pNoOfLang) : (packageData?.cNoOfLang))}` : '',//`Your package allow only ${packageData?.cNoOfLang} language`,
    //       type: 'alert',
    //     })

    //     notificationsProvider.add(notification)
    //     return;
    //     // if (localesValue && localesValue.length >= (policyType === 'privacy' ? (packageData?.pNoOfLang) : (packageData?.cNoOfLang))) {
    //     // }
    //   }
    //   this.setState(
    //     {
    //       selectedOption: selectedOption,
    //       values: selectedOption && selectedOption.map(opt => opt.value),
    //     },
    //     () => this.props.handleChange(this.state.values, 'locales'),
    //   )
    // }
    // else {


    //   // if (selectedLicense?.package?.cNoOfLang) {
    //   // }
    //   // else {
    //   //   this.showUpgradeModel();
    //   // }
    // }

  }

  showUpgradeModel = () => {
    const { loadModal, selectedLicense } = this.props
    loadModal({
      modalType: 'access_limit_user_model',
      content: {
        selectedLicense: selectedLicense

      },
      title: '',
    })
  }

  render() {
    const { selectedOption, availableOptions } = this.state
    const { alerts } = this.props
    const alertStyle = alerts
      ? {
        control: styles => ({ ...styles, borderColor: 'red !important' }),
      }
      : {
        control: styles => ({ ...styles, borderColor: '#adadad !important' }),
      }
    return (
      <Select
        value={selectedOption}
        classNamePrefix={'locale'}
        options={availableOptions}
        onChange={this.handleChange}
        isMulti={true}
        styles={alertStyle}
      />
    )
  }
}

const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}
export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('privacyPolicyEdit.localelist'),
)(LocaleField)
