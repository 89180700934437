import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledButton = styled.button`
    &[type="button"],
    &[type="submit"]{
      // padding: 12px 20px;
      // width: auto;
      // -webkit-appearance: none;
      // margin-bottom: 0;
      // background-color: ${({ color }) => color};
      // -webkit-box-shadow: 0px 5px 15px 1px rgba(173,173,173,1);
      // -moz-box-shadow: 0px 5px 15px 1px rgba(173,173,173,1);
      // box-shadow: 0px 5px 15px 1px rgba(173,173,173,1);
      // border: none;
      // color: white;
      // font-size: 14px;
      // cursor: pointer;
      // transition: all .3s ease-in-out;

      &:disabled {
          opacity: .3;
          pointer-events: none;
      }

      ${({ expanded }) => expanded && 'width: 100%;'}
      ${({ circle }) => circle && 'border-radius: 0.3em;'}
      ${({ rounded }) => rounded && 'border-radius: 0.3em;'}
      ${({ invert, color }) =>
        invert &&
        `
          background: white;
          border:1px solid ${color};
          color: ${color};
          box-shadow: none;
          i {
             color: ${color};
             font-size: 14px;
          }
      `}
      ${({ small }) => {
        return (
          small &&
          `
            font-size: 12px;
            padding: 8px 12px;
          `
        );
      }}
      ${({ buttonIcon, buttonSize }) => {
        return (
          buttonIcon &&
          `
              width: ${buttonSize}px;
              height: ${buttonSize}px;
              padding: 8px;
          `
        );
      }}
      ${({ draft }) => {
        return (
          draft &&
          `
            background: #939393;
            box-shadow: none;
            a {
              color: inherit;
            }
        `
        );
      }}
      ${({ published }) => {
        return (
          published &&
          `
            margin-bottom: 1px;
            a,
            i {
              color: inherit;
              font-size: 14px;
            }
        `
        );
      }}
      ${({ remove }) => {
        return (
          remove &&
          `
            background-color: #FFB900;
            margin-bottom: 1px;
            &:hover{
                box-shadow: none;
            }
            a,
            i {
              color: inherit;
              font-size: 14px;
            }
        `
        );
      }}
      ${({ hidden }) => {
        return (
          hidden &&
          `
                visibility: hidden;
                &:hover{
                    visibility: visible;
                }
                a,
                i {
                  visibility: visible;
                }
            `
        );
      }}
`;

const Button = ({ children, type, disabled, isLoading, ...rest }) => (
  <StyledButton type={type} disabled={disabled || isLoading} {...rest}>
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  rounded: PropTypes.bool,
  buttonIcon: PropTypes.bool,
  circle: PropTypes.bool,
  isLoading: PropTypes.bool,
  buttonSize: PropTypes.number,
  hidden: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  color: '#0028E6',
  disabled: false,
  expanded: false,
  rounded: false,
  buttonIcon: false,
  circle: false,
  isLoading: false,
  hidden: false,
  buttonSize: 40,
};

export default Button;
