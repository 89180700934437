import { useEffect, useState } from 'react';

function usePagination({ page, query, variables, fetchPolicy, client }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [paginationData, setPaginationData] = useState(null);

  useEffect(() => {
    setError(null);
    async function getQuery() {
      try {
        const res = await client.query({
          query,
          variables,
          fetchPolicy,
        });
        setData(res.data.pagyProducts[0].products);
        setPaginationData(res.data.pagyProducts[0].pagination);
        setLoading(false);
      } catch (e) {
        if (!Array.isArray(e)) {
          setError(e.message);
        }
      } finally {
        setLoading(false);
      }
    }

    getQuery();
  }, [page, query]);

  return [data, loading, error, paginationData];
}

export default usePagination;
