import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import BannerForm from './form';
import Loading from '../../../components/Loading';

const BannerCustomisation = ({ question, pp, client }) => {
  const [selectedLanguage, switchLanguage] = useState(pp.locales[0].isoCode);
  const [initValues, setInitValues] = useState({});
  const [choices, setChoices] = useState();

  const setInitialValues = (data, locale) => {
    const { title, cookies, url, text_url } = data[0];
    let initialValues = initValues;
    if (question.answer && question.answer.value[0][locale]) {
      let { banner_url } = question.answer.value[0];
      if (typeof banner_url !== 'undefined') {
        initialValues[`banner_url--${locale}`] = banner_url;
      }

      banner_url = question.answer.value[0][locale]['banner_url'];
      if (typeof banner_url !== 'undefined') {
        initialValues[`banner_url--${locale}`] = banner_url;
      }

      let { banner_title, banner_cookies, banner_text_url } = question.answer.value[0][locale];

      initialValues[`banner_title--${locale}`] = banner_title;
      initialValues[`banner_text_url--${locale}`] = banner_text_url;

      banner_cookies.map(cookie => {
        initialValues[`cookie_title_${cookie.name}--${locale}`] = cookie.title;
      });

      setInitValues(initialValues);
    } else {
      initialValues[`banner_title--${locale}`] = title;
      initialValues[`banner_url--${locale}`] = url;
      initialValues[`banner_text_url--${locale}`] = text_url;

      cookies.map(cookie => {
        initialValues[`cookie_title_${cookie.name}--${locale}`] = cookie.title;
      });

      setInitValues(initialValues);
    }
  };

  useEffect(() => {
    async function fetchData() {
      return await Promise.all(
        pp.locales.map(locale => {
          return client.query({
            query: FETCH_QUESTION_BY_LOCALE_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              ref: 'S.1',
              locale: locale.isoCode,
            },
          });
        }),
      );
    }

    fetchData().then(data => {
      let choices = {};
      data.map(({ data }, i) => {
        setInitialValues(data.fetchQuestionByLocale.choices.data, pp.locales[i].isoCode);
        choices[pp.locales[i].isoCode] = data.fetchQuestionByLocale.choices.data[0];
      });
      setChoices(choices);
    });
  }, []);

  return choices ? (
    <BannerForm
      initialValues={initValues}
      selectedLanguage={selectedLanguage}
      switchLanguage={switchLanguage}
      question={question}
      choices={choices}
      pp={pp}
    />
  ) : (
    <Loading />
  );
};

const FETCH_QUESTION_BY_LOCALE_QUERY = gql`
  query fetchQuestionByLocale($ref: String!, $locale: String!) {
    fetchQuestionByLocale(referenceNumber: $ref, locale: $locale) {
      id
      choices
    }
  }
`;

export default withApollo(BannerCustomisation);
