import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import { clone, difference, concat } from 'lodash';
import Select from 'react-select';

import { withTranslation } from '../../../providers/I18n';
import { NotificationModel } from 'src/providers/notifications';
import { hideModal, loadModal } from 'src/redux/modal/action'
import { connect } from 'react-redux'
import { compose } from 'recompose'

class CountryField extends React.Component {
  constructor(props) {
    super(props);
    const { t, values, availableCountries, packageData } = props;
    this.state = {
      selectedOptions:
        values.countries &&
        values.countries.map(country => {
          return {
            value: country,
            label: t(`.${country}`),
          };
        }),
      availableCountries: availableCountries.map(country => {
        return {
          value: country,
          label: t(`.${country}`),
        };
      }),
      values: props.values.countries,
    };
  }

  onChange = country => {
    const { countries } = this.state;

    let countriesCopy = clone(countries) ? clone(countries) : [];
    countriesCopy = countriesCopy.includes(country)
      ? difference(countriesCopy, [country])
      : concat(countriesCopy, country);

    this.setState({ countries: countriesCopy }, () =>
      this.props.handleChange(this.state.countries, 'countries'),
    );
  };

  handleChange = selectedOptionsNew => {
    const { packageData, notificationsProvider, policyType } = this.props;
    const { selectedOptions } = this.state;
    const { selectedLicense } = this.props
    const languages = selectedLicense?.features?.reduce(
      (arr, item) => (item.groupCode === 'legislation' && arr.push(item['code']), arr),
      [],
    );

    // var lan = localStorage.getItem('locale');

    if ((policyType === 'privacy' ? (packageData?.pNoOfLegis === 0) : (packageData?.cNoOfLegis === 0))) {
      this.setState({ selectedOptions: [] })
      this.showUpgradeModel();
      return;

    }
    if (packageData && selectedOptionsNew?.length > (policyType === 'privacy' ? (packageData?.pNoOfLegis) : (packageData?.cNoOfLegis))) {
      this.setState({ selectedOptions: selectedOptions ? selectedOptions : [] })
      this.showUpgradeModel();
      return;
    }

    var selectValueOption = selectedOptionsNew?.map(opt => opt.value)
    // || selectedOption?.length > selectedLicense?.package?.cNoOfLang
    if (selectValueOption && selectValueOption.length > 0) {

      let isFounded = selectValueOption?.every(ai => languages.includes(ai));
      if (!isFounded) {
        this.showUpgradeModel();
        return;
      }
      else {
        this.setState(
          {
            selectedOptions: selectedOptionsNew,
            values: selectedOptionsNew && selectedOptionsNew.map(opt => opt.value),
          },
          () => this.props.handleChange(this.state.values, 'countries'),
        );
      }
    }
    else {
      this.setState(
        {
          selectedOptions: selectedOptionsNew,
          values: selectedOptionsNew && selectedOptionsNew.map(opt => opt.value),
        },
        () => this.props.handleChange(this.state.values, 'countries'),
      );
    }
  };
  showUpgradeModel = () => {
    const { loadModal, selectedLicense } = this.props

    loadModal({
      modalType: 'access_limit_user_model',
      content: {
        selectedLicense: selectedLicense
      },
      title: '',
    })
  }
  render() {
    const { availableCountries, selectedOptions } = this.state;
    const { alerts } = this.props;
    const alertStyle = alerts
      ? {
        control: styles => ({ ...styles, borderColor: 'red !important' }),
      }
      : {
        control: styles => ({ ...styles, borderColor: '#adadad !important' }),
      };


    return (
      <Select
        value={selectedOptions}
        classNamePrefix={'locale'}
        options={availableCountries}
        onChange={this.handleChange}
        isMulti={true}
      // styles={alertStyle}
      />
    );
  }
}
const mapDispatchToProps = {
  closeExampleModal: hideModal,
  loadModal
}
export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('privacyPolicyEdit.countrylist'),
)(CountryField)

// export default withTranslation('privacyPolicyEdit.countrylist')(CountryField);
