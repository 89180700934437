import styled from 'styled-components';

const StyledCopiedMessageBanner = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  opacity: ${props => (props.showCopiedMessage ? '1' : '0')};
  transition: opacity 0.3s ease-in;
  padding: 15px;
  border-radius: 10px;
  background-color: #50d3c4;
  min-width: 70px;
  color: white;
  font-weight: bold;
`;

const CopyMessage = ({ showCopiedMessage, setShowCopiedMessage }) => {
  return (
    <StyledCopiedMessageBanner showCopiedMessage={showCopiedMessage}>
      Copied
    </StyledCopiedMessageBanner>
  );
};

export default CopyMessage;
