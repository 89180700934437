/* eslint no-console: off */
/* eslint no-shadow: off */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from '../../providers/I18n'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import AuthenticateAccess from '../../components/Header/authenticateAccess'
import UnAuthenticateAccess from '../../components/Header/unAuthenticateAccess'

const Header = ({ authenticate, t, isSuperAdmin }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [showMessage, setShowMessage] = useState(true)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const viewportIsMobile = () => {
    return width < 1024
  }

  return (
    <header className='row expanded shadow-wrapper'>
      {viewportIsMobile() && (
        <div className='user_message_box' style={{ display: `${showMessage ? 'flex' : 'none'}` }}>
          <p>{t('.responsive_message')}</p>
          <button
            className='btn_pill profil_btn is-white is-outlined'
            type='button'
            onClick={() => setShowMessage(!showMessage)}
          >
            Ok, I understand.
          </button>
        </div>
      )}
      <div className='columns'>
        <div className='main-header-wrapper'>
          {authenticate && <AuthenticateAccess isSuperAdmin={isSuperAdmin} />}
          {!authenticate && <UnAuthenticateAccess />}
        </div>
      </div>
    </header>
  )
}

// PropTypes
Header.propTypes = {
  authenticate: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth }) => {
  const { isSuperAdmin } = auth
  return {
    isSuperAdmin,
  }
}

export default compose(connect(mapStateToProps), withTranslation('header'))(Header)
