import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { compose } from 'recompose';
import "./App.css";
import { reduxForm } from 'redux-form';
import { withTranslation } from "src/providers/I18n";
import { withRouter } from 'react-router-dom';
import CheckoutForm from "./CheckoutForm";


function PaymentBuyNewForm({ paysuccess, productName, email, monthlyPrice, payButton, inprogressButton, priceDisplay, cardShow, includeTaxShow, validateCompanyData }) {
    const lan = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en';
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        locale: lan
    });

    return (
        <div className="App">
            {/* <form> */}
            <Elements stripe={stripePromise} >
                <CheckoutForm paysuccess={paysuccess} productName={productName} email={email} monthlyPrice={priceDisplay ? priceDisplay : monthlyPrice} payButton={payButton} inprogressButton={inprogressButton} cardShow={cardShow} includeTaxShow={includeTaxShow} validateCompanyData={validateCompanyData} />
            </Elements>
            {/* </form> */}
        </div>
    );
}

export default compose(
    reduxForm({
        form: 'onboarding_form',
        enableReinitialize: true,
    }),
    withTranslation('account_onboarding'),
    withRouter,
)(PaymentBuyNewForm);


