import { withRouter, Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import React from 'react'
import withApollo from 'react-apollo/withApollo'
import withTranslation from '../../providers/I18n/HoC/t'
import { withNotifications, NotificationModel } from '../../providers/notifications/'
import gql from 'graphql-tag'
import { compose } from 'recompose'
import { isEmpty } from 'lodash'
import connect from 'react-redux/es/connect/connect'

import AuthWrapper from '../../components/ProductAuth'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import ProgressBar from './progressbar'
import Categories from './categories'
import { loadModal } from '../../redux/modal/action'
import DashboardProfile from './profile'
import DashboardVersions from './versions'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

class PrivacyPolicyDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.deletePrivacyPolicy = this.deletePrivacyPolicy.bind(this)
    this.state = {
      previewIsLoading: false,
      policyType: this.isCookiePolicy(props.match.url) ? 'cookie' : 'privacy',
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      policyType: this.isCookiePolicy(nextProps.match.url) ? 'cookie' : 'privacy',
    })
  }

  componentWillUnmount() {
    localStorage.setItem('isNewVersion', null)
  }

  isCookiePolicy = url => {
    return url.includes('cookie-policies')
  }

  unpublishMutation = async id => {
    const { client } = this.props
    return await client.mutate({
      mutation: UNPUBLISH_PRIVACY_POLICY_MUTATION,
      variables: {
        id,
      },
    })
  }

  fetchPolicyStatus = async id => {
    const { client } = this.props
    return await client.query({
      query: PRIVACY_POLICY_STATUS_QUERY,
      variables: {
        id,
        userId: this.props.userId,
      },
    })
  }

  isCategoriesCompleted(categories) {
    let categoriesCompleted = true
    for (let cat of categories) {
      let state = cat.status.key
      if (state === 'in_progress') {
        categoriesCompleted = false
        return categoriesCompleted;
      }

      cat?.questionGroups?.forEach(group => {
        group?.questions?.forEach(question => {
          if (question.referenceNumber === 'M.1') {
            categoriesCompleted =
              question.answer &&
              question.answer.value[0].email !== undefined &&
              question.answer.value[0].email !== ''
          } else if (question.referenceNumber === 'R.1' && question.answer) {
            question.answer.value.forEach(answer => {
              if (answer.purpose === 'other') {
                categoriesCompleted = answer?.other?.every(
                  item => Object.values(item)[0].length !== 0,
                )
              }
            })
          }
        })
      })
    }
    return categoriesCompleted
  }

  createNewPolicyVersion = async privacyPolicy => {
    if (privacyPolicy?.license?.allowVersion) {
      const {
        loadModal,
        t,
        match: {
          params: { id },
        },
      } = this.props

      let otherId = null

      const hasCookiePolicy = privacyPolicy.cookiePolicy
      const hasPrivacyPolicy = privacyPolicy.parentId

      if (hasCookiePolicy) {
        const { status, id } = privacyPolicy.cookiePolicy
        otherId = status === 'published' && id
      } else if (hasPrivacyPolicy) {
        try {
          await this.fetchPolicyStatus(privacyPolicy.parentId).then(({ data }) => {
            const {
              privacyPolicy: { status, id },
            } = data

            otherId = status === 'published' && id
          })
        } catch (e) {
          //error handler
        }
      }

      loadModal({
        modalType: 'unpublish',
        content: {
          unpublishMutation: this.unpublishMutation,
          reload: this.forceReload,
          currentId: id,
          otherPolicyId: otherId,
        },
        title: t('.create_new_version_title'),
      })
    }
    else {
      const {
        loadModal,
        t
      } = this.props
      loadModal({
        modalType: 'access_limit_user_model',
        content: {
          selectedLicense: privacyPolicy?.license
        },
        title: '',
      })
    }

  }

  deletePrivacyPolicy() {
    const { loadModal, t } = this.props
    loadModal({
      modalType: 'delete',
      content: '',
      title: t('.delete_policy_title'),
      t: t,
    })
  }

  handleClick = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props
    const { policyType } = this.state

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`)
    } else {
      localStorage.setItem('ppStatus', 'blocked')
      history.push(`/${policyType}-policies/${id}/preview`)
    }
  }

  render() {
    const { match, history, t, userId, notificationsProvider } = this.props
    const {
      params: { id },
    } = match
    const { previewIsLoading, policyType } = this.state

    if (isNaN(Number(id))) {
      history.push('/404');
    }
    return (
      <AuthWrapper>
        <div className='row'>
          {!isNaN(Number(id)) ? (
            <div className='columns'>
              <Query
                query={PRODUCT_QUERY}
                fetchPolicy={'network-only'}
                variables={{
                  id: id,
                  categoryType: this.state.policyType,
                  productId: id,
                  userId,
                }}
              >
                {({ loading, error, data, refetch }) => {
                  if (loading) return <Loading />
                  if (error) return `Error! ${error.message}`
                  if (isEmpty(data)) return <Loading />
                  const { product, productCategories, productVersions } = data

                  if (!product) {
                    const notification = new NotificationModel({
                      title: t('.no_access_to_policy_title'),
                      message: t('.no_access_to_policy'),
                      type: 'alert',
                    })
                    notificationsProvider.add(notification)

                    return <Redirect to={`/${this.state.policyType}-policies`} />
                  }
                  const {
                    status,
                    title,
                    publishedAt,
                    cookies,
                    license,
                    customization,
                    owner,
                  } = product

                  const isNewVersion = localStorage.getItem('isNewVersion')
                  localStorage.setItem('ppName', title)

                  status === 'blocked' && history.push(`/${policyType}-policies/${id}/preview`)

                  const legislation = license.features.reduce(
                    (arr, item) => (
                      item.groupCode === 'legislation' && item.active && arr.push(item['code']), arr
                    ),
                    [],
                  )
                  const languages = license.features.reduce(
                    (arr, item) => (
                      item.groupCode === 'language' && item.active && arr.push(item['code']), arr
                    ),
                    [],
                  )

                  return (
                    <div className='dashboard'>
                      {isNewVersion === 'true' && (
                        <div className='onboarding_message'>
                          {policyType === 'privacy' ? (
                            <p> {t('.pp_question_based')} </p>
                          ) : (
                            <p> {t('.cp_question_based')} </p>
                          )}
                          <p> {t('.making_changes')} </p>
                        </div>
                      )}

                      <TitleComponent title={product.title} />

                      <DashboardProfile
                        status
                        title
                        countries={legislation}
                        locales={languages}
                        createdAt
                        updatedAt
                        publishedAt
                        customization
                        policyType={policyType}
                        categories={productCategories}
                        deletePrivacyPolicy={this.deletePrivacyPolicy}
                        {...product}
                      />
                      <div className='dashboard_element el_right'>
                        <div className='row collapse align-bottom align-justify'>
                          <h3>
                            {' '}
                            {policyType === 'privacy'
                              ? t('.my_privacy_policy')
                              : t('.my_cookies_policy')}{' '}
                          </h3>

                          <div>
                            {status === 'published' && (
                              <Button
                                className='btn btn_pill is-borderless'
                                onClick={() => this.createNewPolicyVersion(product)}
                              >
                                <i className='fas fa-file-export margin-right-10' />
                                {t('.new_version')}
                              </Button>
                            )}

                            <Button
                              disabled={
                                !this.isCategoriesCompleted(productCategories) &&
                                status !== 'published'
                              }
                              onClick={() => this.handleClick(status, productVersions.length)}
                              className='btn btn_pill is-outlined is-blue margin-left-20'
                            >
                              <i className='far fa-file-alt margin-right-10' />
                              {t('.preview')}
                            </Button>
                          </div>
                        </div>
                        <ProgressBar
                          status={status}
                          categories={productCategories}
                          previewIsLoading={previewIsLoading}
                          t={t}
                        />
                        <Categories
                          categories={productCategories}
                          ppID={id}
                          ppStatus={status}
                          publishedAt={publishedAt}
                          policyType={policyType}
                          t={t}
                          locales={languages}
                        />
                      </div>

                      <DashboardVersions
                        privacyPolicyVersions={productVersions}
                        ppId={id}
                        policyType={policyType}
                      />
                    </div>
                  )
                }}
              </Query>
            </div>
          ) : null}

        </div>
      </AuthWrapper>
    )
  }
}

const PRODUCT_QUERY = gql`
  query($id: ID!, $categoryType: String, $productId: ID!, $userId: ID!) {
    product(id: $id, userId: $userId) {
      id
      parentId
      licenseId
      uid
      title
      status
      version
      owner {
        id
        email
        organization {
          name
        }
      }
      type
      anyAnswers
      customization {
        externalUrl
        cookies
        colors
        logo {
          type
          path
        }
      }
      data {
        id
        version
        html
        pdf
        publishedAt
      }
      license {
        id
        name
        domainName
        productTypeId
        maxNoOfUsers
        organizationId
        subscriptionId
        features {
          id
          name
          code
          preselect
          groupName
          groupCode
          groupDescription
          active
        }
        package{
          cNoOfLang
          cNoOfLegis
          pNoOfLang
          pNoOfLegis
        }
        trafficLimit
        productType {
          id
          name
          code
        }
        validFrom
        validTo
        createdAt
        updatedAt
        allowVersion
      }
      linkedPolicy {
        id
        uid
        title
      }
      cookiePolicy {
        id
        title
      }
      cookieUrl
      cookies
      cookiesType
      cookiesByType
      createdAt
      updatedAt
      publishedAt
    }
    productCategories(type: $categoryType) {
      id
      position
      name
      avatar
      type
      questionGroups {
        id
        name
        questions(productId: $productId) {
          id
          referenceNumber
          type
          localeKey
          statement
          explanation
          explanationExt
          choices
          mandatoryChoices
          example
          exampleExt
          warningMessage
          warningMessageExt
          version
          validationWarningMessage
          readOnly
          answer(productId: $productId) {
            id
            value
          }
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      status(productId: $productId)
    }
    productVersions(productId: $productId) {
      id
      uid
      version
      html
      pdf
      publishedAt
      archivedAt
      status
    }
  }
`

const PRIVACY_POLICY_STATUS_QUERY = gql`
  query privacyPolicy($id: ID!) {
    privacyPolicy(id: $id) {
      status
      id
    }
  }
`

const UNPUBLISH_PRIVACY_POLICY_MUTATION = gql`
  mutation unpublishPrivacyPolicy($id: ID!) {
    unpublishProduct(id: $id) {
      id
    }
  }
`

const mapDispatchToProps = {
  loadModal,
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withApollo,
  withNotifications(),
  withTranslation('dashboard'),
)(PrivacyPolicyDashboard)