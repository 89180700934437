import React, { Fragment } from 'react';

import Button from '../../components/Button';

const PreviewActions = ({
  previews,
  policyType,
  hasBothPolicies,
  hasPublishAccess,
  cookieAccessible,
  privacyAccessible,
  handleReturn,
  toggleModal,
  t,
  hasWebVersion
}) => {
  const publishedPrivacy = previews['privacy'] && previews['privacy'].status === 'published';
  const publishedCookie = previews['cookie'] && previews['cookie'].status === 'published';

  const uncompletedCookie = previews['cookie'] && previews['cookie'].status === 'uncompleted';
  const uncompletedPrivacy = previews['privacy'] && previews['privacy'].status === 'uncompleted';

  const onePolicyToPublish =
    (!publishedPrivacy && publishedCookie) || (!publishedCookie && publishedPrivacy);

  const allPoliciesAccessible = cookieAccessible && privacyAccessible;
  const unfinishedPolicy = hasBothPolicies && !allPoliciesAccessible;

  return (
    <Fragment>
      <div className='list_buttons'>
        {(cookieAccessible || uncompletedCookie) && (
          <Button
            className='btn btn_pill is-outlined is-blue'
            onClick={() => handleReturn(previews['cookie'].id, 'cookie')}
          >
            {publishedCookie ? t('.return_to_cp') : t('.modify_cp')}
          </Button>
        )}
        {(privacyAccessible || uncompletedPrivacy) && (
          <Button
            className='btn btn_pill is-outlined is-blue'
            onClick={() => handleReturn(previews['privacy'].id, 'privacy')}
          >
            {publishedPrivacy ? t('.return_to_pp') : t('.modify_pp')}
          </Button>
        )}
        {!hasBothPolicies &&
          (!previews[policyType] || previews[policyType].status === 'published' ? (
            <Button className='btn btn_pill is-borderless' onClick={() => handleReturn(null)}>
              {t('.return_to_policy')}
            </Button>
          ) : (
            <Button className='btn btn_pill is-outlined is-blue' onClick={() => handleReturn(null)}>
              {t('.modify_policy')}
            </Button>
          ))}

        {hasBothPolicies && !publishedPrivacy && !publishedCookie && (
          <Fragment>
            {unfinishedPolicy && <p className='annotation'> {t('.one_policy_uncompleted')} * </p>}
            <Button
              className='btn btn_pill is-blue'
              published
              disabled={unfinishedPolicy || !hasPublishAccess}
              onClick={toggleModal}
            >
              {t('.publish_my_policies')}
            </Button>
          </Fragment>
        )}

        {((!hasBothPolicies && previews[policyType]) || onePolicyToPublish) && (
          <Button
            className='btn btn_pill is-blue'
            published
            disabled={!hasPublishAccess}
            onClick={toggleModal}
          >
            {t('.publish_my_policy')}
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default PreviewActions;
