/* eslint no-console: off */
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withApollo from 'react-apollo/withApollo'

import AuthWrapper from '../../components/ProductAuth'
import CategoryQuestionExplanation from './explanation'
import PrivacyPolicyQuestionEdit from './edit'
import Loading from '../../components/Loading'
import { withTranslation } from '../../providers/I18n'
import BannerCustomisation from '../ProductCookiePolicies/BannerCustomisation'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

class PrivacyPolicyCategoryQuestion extends React.Component {
  constructor() {
    super()
    this.state = {
      policyType: 'privacy',
      loaded: false,
    }
  }

  componentDidMount() {
    const { match, history } = this.props
    const {
      params: { id, categoryId },
    } = match
    const locale = localStorage.getItem('locale')

    this.setState({ policyType: this.isCookiePolicy(match.url) ? 'cookie' : 'privacy' }, () => {
      this.fetchQuestionById().then(({ data }) => {
        const {
          question: { localeKey, referenceNumber },
        } = data
        if (locale !== localeKey) {
          this.fetchQuestionByLocale(referenceNumber, locale).then(({ data }) => {
            const { questionByLocale } = data
            history.replace(
              `/${this.state.policyType}-policies/${id}/categories/${categoryId}/questions/${questionByLocale.id}`,
            )
          })
        }
        this.setState({
          loaded: true,
        })
      })
    })
  }

  fetchQuestionById = () => {
    const {
      client,
      userId,
      match: {
        params: { questionId, id },
      },
    } = this.props
    const { policyType } = this.state

    return client.query({
      query: QUESTION_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: questionId,
        product_id: id,
        type: policyType,
        userId,
      },
    })
  }

  fetchQuestionByLocale = (ref, locale) => {
    const { client } = this.props
    return client.query({
      query: QUESTION_BY_LOCALE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        ref,
        locale,
      },
    })
  }

  isCookiePolicy = url => {
    return url.includes('cookie-policies')
  }

  render() {
    const {
      match: {
        params: { questionId, categoryId, id },
      },
      t,
      userId
    } = this.props
    const { policyType, loaded } = this.state
    return (
      <AuthWrapper>
        {loaded && (
          <Query
            query={QUESTION_BY_ID_QUERY}
            fetchPolicy={'no-cache'}
            variables={{ id: questionId, product_id: id, type: policyType, userId }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return `Error! ${error.message}`
              const { question, product } = data
              const { license } = product
              const { mandatoryChoices } = question
              const ppName = localStorage.getItem('ppName')

              const legislation = license.features.reduce(
                (arr, item) => (
                  item.groupCode === 'legislation' && item.active && arr.push(item['code']), arr
                ),
                [],
              )

              let url = `/${policyType}-policies/${id}`
              url =
                question.type === 'cookie_configurator' ? url : `${url}/categories/${categoryId}`

              return (
                <div className='question_main row'>
                  <TitleComponent title={ppName + ' | ' + question.statement} />
                  <div className='columns category'>
                    <div>
                      <h6> {t(`.${policyType}_policy`)}</h6>
                      <h1 className='policy_title'> {ppName} </h1>
                    </div>
                    <div className='arrow_title'>
                      <Link to={url}>
                        <i className='fas fa-arrow-left margin-right-20' />
                      </Link>
                      <h2>
                        {question.statement}{' '}
                        {mandatoryChoices && <span style={{ color: 'red' }}> * </span>}
                      </h2>
                    </div>
                    <CategoryQuestionExplanation
                      question={question}
                      availableCountries={legislation}
                    />
                    {question.type === 'cookie_configurator' ? (
                      <BannerCustomisation question={question} pp={product} />
                    ) : (
                      <PrivacyPolicyQuestionEdit
                        question={question}
                        pp={product}
                        policyType={policyType}
                      />
                    )}
                  </div>
                </div>
              )
            }}
          </Query>
        )}
      </AuthWrapper>
    )
  }
}

const QUESTION_BY_ID_QUERY = gql`
  query fetchQuestionById($id: ID!, $product_id: ID!, $userId: ID!) {
    question(id: $id, productId: $product_id) {
      id
      referenceNumber
      type
      localeKey
      statement
      explanation
      explanationExt
      choices
      mandatoryChoices
      example
      exampleExt
      warningMessage
      warningMessageExt
      version
      validationWarningMessage
      readOnly
      answer(productId: $product_id) {
        id
        value
        autocompleted
      }
    }
    product(id: $product_id, userId: $userId) {
      id
      title
      cookies
      cookieUrl
      cookiesType
      cookiesByType
      parentId
      cookiePolicy {
        id
      }
      license {
        id
        features {
          id
          name
          code
          preselect
          groupName
          groupCode
          groupDescription
          active
        }
      }
    }
  }
`

const QUESTION_BY_LOCALE_QUERY = gql`
  query fetchQuestion($ref: String!, $locale: String!) {
    questionByLocale(referenceNumber: $ref, locale: $locale) {
      id
    }
  }
`

PrivacyPolicyCategoryQuestion.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}),
}

PrivacyPolicyCategoryQuestion.defaultProps = {
  history: {},
}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withApollo,
  withTranslation('question'),
)(PrivacyPolicyCategoryQuestion)
