import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';

class DropdownItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.dropdownRef = createRef();

  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  toggleDropdown = () => {
    if (!this.props.readOnly) this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const {
      handleChange,
      name,
      options,
      value,
      label,
      placeholder,
      className,
      size,
      t,
      isPill,
    } = this.props;

    let valueSelected = options.find(function (option) {
      return option.value === value;
    });

    return (
      <div
        className={`dropdown ${className} ${size} ${this.props.readOnly ? 'readOnly' : ''} ${this.state.isOpen ? 'opened' : 'closed'
          }`}
        ref={this.dropdownRef}
        onClick={this.toggleDropdown}
      >
        <div className={isPill ? 'dropdown_header is-pill' : 'dropdown_header'}>
          <div className='dropdown_label'>{label}</div>
          {valueSelected ? (t ? t(valueSelected.label) : valueSelected.label) : placeholder}
        </div>
        <div className='dropdown_items'>
          {options.map(option => (
            <div
              key={option.value}
              className={`dropdown_item ${value === option.value ? 'selected' : ''}`}
              value={option.value}
              name={name}
              onClick={() => handleChange({ target: { value: option.value, name: name } })}
            >
              {t ? t(option.label) : option.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

DropdownItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

DropdownItem.defaultProps = {
  value: '',
  label: null,
};

export default DropdownItem;
