import React from 'react';
import { compose } from 'recompose';
import withApollo from 'react-apollo/withApollo';
import { withRouter } from 'react-router-dom';

import PrivacyPolicyCategory from '../../components/ProductPrivacyPolicyCategory';
import withTranslation from '../../providers/I18n/HoC/t';

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesStatus: 'completed',
    };
  }

  componentWillMount() {
    const { categories, ppStatus } = this.props;

    categories.forEach(cat => {
      if (cat.status.key === 'in_progress') {
        this.setState({ categoriesStatus: 'uncompleted' });
      }
    });

    if (ppStatus === 'published') this.setState({ categoriesStatus: 'published' });
  }

  render() {
    const { categories, ppID, ppStatus, publishedAt, policyType, t, locales } = this.props;
    return (
      <div className='categories align-center'>
        {categories &&
          categories.map(category => {
            let completedExceptionCheck = true;

            category.questionGroups.forEach(group => {
              group?.questions?.forEach(question => {
                if (question.referenceNumber === 'M.1') {
                  completedExceptionCheck =
                    question.answer &&
                    question.answer.value[0].email !== undefined &&
                    question.answer.value[0].email !== '';
                } else if (question.referenceNumber === 'R.1' && question.answer) {
                  question.answer.value.forEach(answer => {
                    if (answer.purpose === 'other') {
                      completedExceptionCheck = answer?.other?.every(
                        item => Object.values(item)[0].length !== 0,
                      );
                    }
                  });
                }
              });
            });

            return (
              <PrivacyPolicyCategory
                key={category.id}
                id={category.id}
                ppId={ppID}
                avatar={category.avatar}
                name={category.name}
                status={category.status.key}
                completedExceptionCheck={completedExceptionCheck}
                position={category.position}
                ppStatus={ppStatus}
                date={publishedAt}
                policyType={policyType}
                t={t}
                category={category}
                locales={locales}
              />
            );
          })}
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(Categories);
