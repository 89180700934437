/* eslint max-len: off */
import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { formattedDate } from '../../helpers/date'
import AuthWrapper from '../../components/Auth'
import Loading from '../../components/Loading'
import VersionsList from './versions_list'
import Button from '../../components/Button'
import { withTranslation } from '../../providers/I18n'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'
import { withNotifications, NotificationModel } from '../../providers/notifications/'
import { withRouter, Redirect } from 'react-router-dom'

const PolicyVersions = ({
  match: {
    params: { id },
  },
  t,
  userId,
  notificationsProvider,
}) => {
  return (
    <AuthWrapper>
      <Query query={POLICY_VERSIONS_QUERY} variables={{ id, userId }} fetchPolicy={'network-only'}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return `Error! ${error.message}`
          const { productVersions, product } = data

          if (!product) {
            const notification = new NotificationModel({
              title: t('.no_access_to_policy_title'),
              message: t('.no_access_to_policy'),
              type: 'alert',
            })
            notificationsProvider.add(notification)
            return <Redirect to={`/cookie-policies`} />
          }

          let productType = product.type.includes('cookie') ? 'cookie-policies' : 'privacy-policies'

          return (
            <div className='row privacy-policies'>
              <TitleComponent title={product.title + ' | ' + t('.archives')} />
              <div className='columns'>
                <div className='row'>
                  <div className='column'>
                    <div className='section__heading'>
                      <h1>{t('.archives')}</h1>
                      <Link to={`/${productType}/${id}`}>
                        <Button className='btn btn_pill is-outlined is-blue'>
                          <i className='fas fa-arrow-left margin-right-10' />
                          {t('.dashboard')}
                        </Button>
                      </Link>
                    </div>
                    <VersionsList
                      privacyPolicyVersions={productVersions}
                      policyType={productType}
                      ppId={id}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    </AuthWrapper>
  )
}

const POLICY_VERSIONS_QUERY = gql`
  query policyVersions($id: ID!, $userId: ID!) {
    productVersions(productId: $id) {
      archivedAt
      html
      id
      pdf
      publishedAt
      status
      uid
      version
    }
    product(id: $id, userId: $userId) {
      id
      type
      title
    }
  }
`

PolicyVersions.propTypes = {}

PolicyVersions.defaultProps = {}

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId }
}

export default compose(
  connect(mapStateToProps),
  withTranslation('dashboard'),
  withNotifications(),
  withRouter,
)(PolicyVersions)
