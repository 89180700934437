import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import questionItem from '../../../containers/Category/questionItem';
import Button from '../../Button';

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: true,
    };
  }

  field = ({ input, meta, options, question, goBack, handleSubmit, t }) => {
    const { onFocus, onChange } = input;

    const { touched, error } = meta;
    const inputValue = input.value;
    const checkboxes = options.map(({ label, example, value, checked, name }, index) => {
      const handleChange = event => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.splice(index, 0, value);
        } else {
          arr.splice(arr.indexOf(value), 1);
        }

        onChange(arr);
        this.props.onFieldChange(arr);

        this.setState({ invalid: !(arr !== undefined && arr.length > 0) });
      };
      const fieldChecked = inputValue.includes(value);

      if (fieldChecked) this.setState({ invalid: false });

      let checkboxInfoClass =
        question.referenceNumber === 'C.8' && name === 'internet'
          ? 'checkbox_info checkbox_info_highlighted'
          : 'checkbox_info';
      let disabledStyle = question.referenceNumber === 'K.2' && index === 1 ? true : false;

      return (
        <label
          className='checkbox_input'
          key={`checkbox-${index}`}
        // style={disabledStyle ? { background: '#eee' } : {}}
        >
          <input
            type='checkbox'
            name={`${name}[${index}]`}
            value={value}
            checked={fieldChecked}
            onChange={handleChange}
            onFocus={onFocus}
          />
          <div className={checkboxInfoClass}>
            <span className='checkbox_label'>{label}</span>
            {example && <p className='input_example'> {example} </p>}
            <span className='slider' />
          </div>
        </label>
      );
    });

    return (
      <Fragment>
        <div className='checkbox_options'>{checkboxes}</div>
        {touched && error && <p className='error'>{error}</p>}

        <div className='cookie-form__break'>&nbsp;</div>

        <div className='text-right cookie-form__list_buttons'>
          <Button
            className='btn btn_pill is-borderless has-text-blue margin-right-20'
            onClick={goBack}
          >
            {t('.previous')}
          </Button>
          <Button
            className='btn btn_pill is-blue'
            published
            onClick={handleSubmit}
            disabled={this.state.invalid}
          >
            {t('.validate')}
          </Button>
        </div>
      </Fragment>
    );
  };

  render() {
    return <Field {...this.props} type='checkbox' name={this.props.name} component={this.field} />;
  }
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CheckboxGroup;
