import { validateUrl } from '../../Questions/validateFormats';

const validate = (values, language_options) => {
  const errors = {};

  let languages = [];
  language_options.pp.license.features.forEach(item => {
    if (item.groupCode === 'language'){
      languages.push(item['code'].slice(-2))
    }
  });

  languages.map(locale => {
    if (!values['banner_title--' + locale]) {
      errors['banner_title--' + locale] = '.banner_title_missing';
    }
    if (!values['banner_text_url--' + locale]) {
      errors['banner_text_url--' + locale] = '.banner_policy_text_link_missing';
    }
    if (!values['banner_url--' + locale]) {
      errors['banner_url--' + locale] = '.link_missing_error';
    }
    if (
      values['banner_url--' + locale] &&
      !validateUrl(values['banner_url--' + locale])
    ) {
      errors['banner_url--' + locale] = '.url_not_valid';
    }
    if (!values['cookie_title_functional--' + locale]) {
      errors['cookie_title_functional--' + locale] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_marketing--' + locale]) {
      errors['cookie_title_marketing--' + locale] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_necessary--' + locale]) {
      errors['cookie_title_necessary--' + locale] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_statistic--' + locale]) {
      errors['cookie_title_statistic--' + locale] = '.cookie_category_title_missing';
    }
  });

  if (!values.banner_url) {
    errors.banner_url = '.link_missing_error';
  }

  return errors;
};

export default validate;

//url_http_not_valid
