/* eslint no-console: off */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formattedDate } from '../../helpers/date';

const PublishedCategory = ({ id, ppId, avatar, name, status, date, policyType, t, locales }) => (
  <div className='columns policy_category-card'>
    <Link to={`/${policyType}-policies/${ppId}/categories/${id}`}>
      <div className={`question_item`}>
        <div className='question_item-link'>
          <div
            className='icon-part'
            style={{ WebkitMaskImage: `url(${avatar}`, maskImage: `url(${avatar}` }}
          >
            {/* <img src={avatar} alt={avatar} className='policy-icon' /> */}
          </div>
        </div>
        <div className='question_item-text'>
          <div className='title-part'>
            <p>{name}</p>
          </div>
          <div className='privacy-policy-edit clearfix'>
            <p className='pp-edit-text left fill'>
              {t('.publication')} : <strong> {date ? formattedDate(date) : ' - '} </strong>
            </p>
          </div>
        </div>
        <div className='question_item-reference'>
          <i className='far fa-eye'></i>
        </div>
      </div>
    </Link>
  </div>
);

PublishedCategory.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string,
};

PublishedCategory.defaultProps = {
  avatar: '',
  name: '',
};

export default withRouter(PublishedCategory);
