import * as React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import { renderInputField } from '../../components/Form/TextFieldGroup/fieldInput';
import { renderCheckboxField } from '../../components/Form/Checkbox';
import UnAuthWrapper from '../../components/UnAuth';
import { resetPassword } from './submit_validation';
import validate from './validate';
import { loadModal } from '../../redux/modal/action';
import { withTranslation } from '../../providers/I18n';
import { TitleComponent } from '../../providers/pageTitles/TitleComponent';

class ResetPassword extends React.Component {
  onSubmit = values => {
    const { loadResetModal, t } = this.props;
    return resetPassword(values)
      .then(({ data }) => {
        loadResetModal({
          modalType: 'reset',
          content: values,
          title: t('.reset_password'),
        });
      })
      .catch(error => {
        if (error) {
          throw new SubmissionError({
            email: '.no_match',
            _error: '.no_match_email',
          });
        }
      });
  };

  render() {
    const { submitting, handleSubmit, error, t } = this.props;
    return (
      <UnAuthWrapper title={t('.password_forgotten')}>
        <TitleComponent title={t('.password_forgotten')} />
        <form className='text-form auth_form' onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name='email'
            type='text'
            label={t('.your_email')}
            component={renderInputField}
            t={t}
          />
          {error && <p className='validation_error'>{t(error)}</p>}
          <button type='submit' disabled={submitting} className='btn btn_pill is-blue'>
            {submitting ? t('.waiting') : t('.send_email')}
          </button>
        </form>
      </UnAuthWrapper>
    );
  }
}

ResetPassword.propTypes = {
  loadResetModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  loadResetModal: loadModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({ form: 'new_password_form', validate }),
  withRouter,
  withTranslation('unAuthenticatedForm'),
)(ResetPassword);
