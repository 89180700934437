import { validateUrl } from '../../Questions/validateFormats';

const validate = (values, language_options) => {
  const errors = {};

  language_options.pp.locales.map(locale => {
    if (!values['banner_title--' + locale.isoCode]) {
      errors['banner_title--' + locale.isoCode] = '.banner_title_missing';
    }
    if (!values['banner_text_url--' + locale.isoCode]) {
      errors['banner_text_url--' + locale.isoCode] = '.banner_policy_text_link_missing';
    }
    if (!values['banner_url--' + locale.isoCode]) {
      errors['banner_url--' + locale.isoCode] = '.link_missing_error';
    }
    if (
      values['banner_url--' + locale.isoCode] &&
      !validateUrl(values['banner_url--' + locale.isoCode])
    ) {
      errors['banner_url--' + locale.isoCode] = '.url_not_valid';
    }
    if (!values['cookie_title_functional--' + locale.isoCode]) {
      errors['cookie_title_functional--' + locale.isoCode] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_marketing--' + locale.isoCode]) {
      errors['cookie_title_marketing--' + locale.isoCode] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_necessary--' + locale.isoCode]) {
      errors['cookie_title_necessary--' + locale.isoCode] = '.cookie_category_title_missing';
    }
    if (!values['cookie_title_statistic--' + locale.isoCode]) {
      errors['cookie_title_statistic--' + locale.isoCode] = '.cookie_category_title_missing';
    }
  });

  if (!values.banner_url) {
    errors.banner_url = '.link_missing_error';
  }

  return errors;
};

export default validate;

//url_http_not_valid
