// HELPER
// =====================

const filterCountriesAndLocales = (key, value) => {
  if (key.includes('country_') && value) {
    const splittedValue = key.split('_');
    return { outputKey: 'countries', value: splittedValue[1] };
  }

  if (key.includes('locale_') && value) {
    const splittedKey = key.split('_');
    const mappedValue = `${splittedKey[1]}`;
    return { outputKey: 'locales', value: mappedValue };
  }

  return {};
};

const mapCountriesAndLocales = inputs => {
  const mappedValues = { countries: [], locales: [] };
  const results = Object.keys(inputs).reduce((result, key) => {
    const mappedOutput = filterCountriesAndLocales(key, inputs[key]);
    if (mappedOutput.outputKey !== undefined) {
      result[mappedOutput.outputKey].push({ isoCode: mappedOutput.value });
    }
    return result;
  }, mappedValues);
  return results;
};

const mapInputs = inputs => {
  const output = mapCountriesAndLocales(inputs);
  output.title = inputs.title;
  output.cpUrl = inputs.cpUrl;
  return output;
};

export default mapInputs;
