/* eslint react/jsx-indent-props: off */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnAuthenticatedRoute from './components/UnAuthenticatedRoute'

import LostPassword from './components/LostPassword'
import CreatePassword from './containers/UnAuthenticatedForm/new_password'
import ResetPassword from './containers/UnAuthenticatedForm/reset_password'
import ChangePassword from './containers/UnAuthenticatedForm/change_password'
import AccountVerification from './containers/UnAuthenticatedForm/account_verification'
import SignIn from './containers/UnAuthenticatedForm/sign_in'
import SignUp from './containers/UnAuthenticatedForm/sign_up'
import AuthenticateProduct from './containers/AuthenticateProduct'

import PrivacyPolicies from './containers/PrivacyPolicies'

import CookiePolicies from './containers/CookiePolicies'
import PrivacyPolicyIntegrationProcess from './containers/PrivacyPolicies/integration_process.jsx'
import CookieBannerIntegrationProcess from './containers/CookiePolicies/cookie_banner_integration'
import CompanyOnboarding from './containers/Onboarding/CompanyOnboarding/'
import NewUserCompanyOnboarding from './containers/NewOnboarding/CompanyOnboarding'

import UserOnboarding from './containers/Onboarding/UserOnboarding/index.jsx'
import PrivacyPolicyDashboard from './containers/Dashboard'
import PrivacyPolicyPreview from './containers/Preview'
import PrivacyPolicyNew from './containers/PrivacyPolicyEdit/new_policy'
import PrivacyPolicyEdit from './containers/PrivacyPolicyEdit'
import PolicyVersions from './containers/PolicyVersions'
import PrivacyPolicyCategory from './containers/Category'
import PrivacyPolicyCategoryQuestion from './containers/Questions/'

import ProductsPrivacyPolicies from './containers/ProductPrivacyPolicies'
import ProductsPrivacyPolicyIntegrationProcess from './containers/ProductPrivacyPolicies/integration_process.jsx'
import ProductsPrivacyPolicyDashboard from './containers/ProductDashboard'
import ProductsPrivacyPolicyPreview from './containers/ProductPreview'
import ProductsPrivacyPolicyNew from './containers/ProductPrivacyPolicyEdit/new_policy'
import ProductsPrivacyPolicyEdit from './containers/ProductPrivacyPolicyEdit'
import ProductsPolicyVersions from './containers/ProductPolicyVersions'
import ProductsPrivacyPolicyCategory from './containers/ProductCategory'
import ProductsPrivacyPolicyCategoryQuestion from './containers/ProductQuestions/'

import ProductsCookiePolicies from './containers/ProductCookiePolicies'
import ProductsCookieBannerIntegrationProcess from './containers/ProductCookiePolicies/cookie_banner_integration'

import NoMatch from './containers/NoMatch'
import AccountPage from './containers/Account'
import ConsentDatabasePage from './containers/ConsentDatabase'
import ProductConsentDatabasePage from './containers/ProductConsentDatabase'
import ButExtra from './containers/ButExtra'
import ChangeLicense from './containers/changeLicense'


const Root = ({ authenticated }) => {

  return (
    <Router>
      <Switch>
        <UnAuthenticatedRoute path='/' exact component={SignIn} authenticate={authenticated} />
        <UnAuthenticatedRoute
          path='/newuser' exact component={NewUserCompanyOnboarding} authenticate={authenticated} />
        <UnAuthenticatedRoute
          path='/en/newuser' exact component={NewUserCompanyOnboarding} authenticate={authenticated} />
        <UnAuthenticatedRoute
          path='/fr/newuser' exact component={NewUserCompanyOnboarding} authenticate={authenticated} />



        <UnAuthenticatedRoute
          path='/:lanurl/:uid/newuser' exact component={NewUserCompanyOnboarding} authenticate={authenticated} />

        <UnAuthenticatedRoute path='/:uid/newuser' exact component={NewUserCompanyOnboarding} authenticate={authenticated} />


        <UnAuthenticatedRoute
          path='/lost-password'
          exact
          component={LostPassword}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/en/lost-password'
          exact
          component={LostPassword}
          authenticate={authenticated}
        /> <UnAuthenticatedRoute
          path='/fr/lost-password'
          exact
          component={LostPassword}
          authenticate={authenticated}
        />



        <UnAuthenticatedRoute
          path='/new-password'
          exact={false}
          component={CreatePassword}
          authenticate={authenticated}
        />

        <UnAuthenticatedRoute
          path='/en/new-password'
          exact={false}
          component={CreatePassword}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/fr/new-password'
          exact={false}
          component={CreatePassword}
          authenticate={authenticated}
        />



        <UnAuthenticatedRoute
          path='/reset-password'
          exact
          component={ResetPassword}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/en/reset-password'
          exact
          component={ResetPassword}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/fr/reset-password'
          exact
          component={ResetPassword}
          authenticate={authenticated}
        />





        <UnAuthenticatedRoute
          path='/change-password'
          exact={false}
          component={ChangePassword}
          authenticate={authenticated}
        />

        <UnAuthenticatedRoute
          path='/en/change-password'
          exact={false}
          component={ChangePassword}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/fr/change-password'
          exact={false}
          component={ChangePassword}
          authenticate={authenticated}
        />



        <UnAuthenticatedRoute
          path='/account-verification'
          exact
          component={AccountVerification}
          authenticate={authenticated}
        />

        <UnAuthenticatedRoute
          path='/en/account-verification'
          exact
          component={AccountVerification}
          authenticate={authenticated}
        /> <UnAuthenticatedRoute
          path='/fr/account-verification'
          exact
          component={AccountVerification}
          authenticate={authenticated}
        />



        <UnAuthenticatedRoute path='/sign-up' exact component={SignUp} authenticate={authenticated} />
        <UnAuthenticatedRoute path='/en/sign-up' exact component={SignUp} authenticate={authenticated} />

        <UnAuthenticatedRoute path='/fr/sign-up' exact component={SignUp} authenticate={authenticated} />



        <UnAuthenticatedRoute
          path='/register'
          exact
          component={UserOnboarding}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/en/register'
          exact
          component={UserOnboarding}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/fr/register'
          exact
          component={UserOnboarding}
          authenticate={authenticated}
        />



        <UnAuthenticatedRoute
          path='/policy'
          exact={false}
          component={AuthenticateProduct}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/en/policy'
          exact={false}
          component={AuthenticateProduct}
          authenticate={authenticated}
        />
        <UnAuthenticatedRoute
          path='/fr/policy'
          exact={false}
          component={AuthenticateProduct}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/onboarding'
          exact
          component={CompanyOnboarding}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/onboarding'
          exact
          component={CompanyOnboarding}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/onboarding'
          exact
          component={CompanyOnboarding}
          authenticate={authenticated}
        />
        {/* <AuthenticatedRoute
        path='/newuser'
        exact
        component={NewUserCompanyOnboarding}
        authenticate={authenticated}
      /> */}
        <AuthenticatedRoute
          path='/account'
          exact
          component={AccountPage}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/account'
          exact
          component={AccountPage}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/account'
          exact
          component={AccountPage}
          authenticate={authenticated}
        />


      /* PRODUCTS */
        <AuthenticatedRoute
          path='/consent-database'
          exact
          component={ProductConsentDatabasePage}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/consent-database'
          exact
          component={ProductConsentDatabasePage}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/consent-database'
          exact
          component={ProductConsentDatabasePage}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/privacy-policies'
          exact
          component={ProductsPrivacyPolicies}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies'
          exact
          component={ProductsPrivacyPolicies}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies'
          exact
          component={ProductsPrivacyPolicies}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/privacy-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/cookie-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/archive'
          exact
          component={ProductsPolicyVersions}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/:policy_type/:id/preview/:version'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/:policy_type/:id/preview/:version'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/:policy_type/:id/preview/:version'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/en/:policy_type/:id/preview/:version'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/:policy_type/:id/preview/:version'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/privacy-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/privacy-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/privacy-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies'
          exact
          component={ProductsCookiePolicies}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies'
          exact
          component={ProductsCookiePolicies}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies'
          exact
          component={ProductsCookiePolicies}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/new'
          exact
          component={ProductsPrivacyPolicyNew}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/cookie-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id'
          exact
          component={ProductsPrivacyPolicyDashboard}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/edit'
          exact
          component={ProductsPrivacyPolicyEdit}
          authenticate={authenticated}
        />










        <AuthenticatedRoute
          path='/cookie-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/integration_process'
          exact
          component={ProductsPrivacyPolicyIntegrationProcess}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/:id/banner_integration'
          exact
          component={ProductsCookieBannerIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/banner_integration'
          exact
          component={ProductsCookieBannerIntegrationProcess}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/banner_integration'
          exact
          component={ProductsCookieBannerIntegrationProcess}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />

        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/categories/:categoryId'
          exact
          component={ProductsPrivacyPolicyCategory}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/categories/:categoryId/questions/:questionId'
          exact
          component={ProductsPrivacyPolicyCategoryQuestion}
          authenticate={authenticated}
        />



        <AuthenticatedRoute
          path='/cookie-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/cookie-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/cookie-policies/:id/preview'
          exact
          component={ProductsPrivacyPolicyPreview}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/change-licenses/:id/:licenseId'
          exact
          component={ChangeLicense}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/change-licenses/:id/:licenseId'
          exact
          component={ChangeLicense}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/change-licenses/:id/:licenseId'
          exact
          component={ChangeLicense}
          authenticate={authenticated}
        />


        <AuthenticatedRoute
          path='/buylicenses'
          exact
          component={ButExtra}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/en/buylicenses'
          exact
          component={ButExtra}
          authenticate={authenticated}
        />
        <AuthenticatedRoute
          path='/fr/buylicenses'
          exact
          component={ButExtra}
          authenticate={authenticated}
        />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  )
}

Root.propTypes = {
  authenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth }) => {
  const { authenticated } = auth
  return { authenticated }
}

export default connect(mapStateToProps, null)(Root)
