/* eslint no-console: off */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import withApollo from 'react-apollo/withApollo'
import AuthWrapper from '../../components/ProductAuth'
import CategoryQuestionsList from './list'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import WizardForm from '../../components/ProductWizardForm'
import { withTranslation } from '../../providers/I18n'
import { withNotifications, NotificationModel } from '../../providers/notifications/'
import { TitleComponent } from '../../providers/pageTitles/TitleComponent'

class PrivacyPolicyCategory extends React.Component {
  constructor() {
    super()
    this.state = { policyType: 'privacy' }
  }

  componentDidMount() {
    const { match } = this.props
    this.setState({ policyType: this.isCookiePolicy(match.url) ? 'cookie' : 'privacy' })
  }

  isCookiePolicy = url => {
    return url.includes('cookie-policies')
  }

  previewPolicy = (status, version) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props
    const { policyType } = this.state

    if (status === 'published') {
      history.push(`/${policyType}-policies/${id}/preview/${version}`)
    } else {
      localStorage.setItem('ppStatus', 'blocked')
      history.push(`/${policyType}-policies/${id}/preview`)
    }
  }

  isCategoriesCompleted(categories) {
    let categoriesCompleted = true
    for (let cat of categories) {
      let state = cat.status.key
      if (state === 'in_progress') categoriesCompleted = false

      cat?.questionGroups?.forEach(group => {
        group?.questions?.forEach(question => {
          if (question.referenceNumber === 'M.1') {
            categoriesCompleted =
              question.answer &&
              question.answer.value[0].email !== undefined &&
              question.answer.value[0].email !== ''
          } else if (question.referenceNumber === 'R.1' && question.answer) {
            question.answer.value.forEach(answer => {
              if (answer.purpose === 'other') {
                categoriesCompleted = answer?.other?.every(
                  item => Object.values(item)[0].length !== 0,
                )
              }
            })
          }
        })
      })
    }
    return categoriesCompleted
  }

  handleCategoryChange = (category, categories, next) => {
    const { position, type } = category
    const { policyType } = this.state
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props

    const nextCategory = categories.filter(cat => {
      if (next) {
        return cat.position === position + 1 && type === policyType
      } else {
        return cat.position === position - 1 && type === policyType
      }
    })[0]

    nextCategory
      ? history.push(`/${policyType}-policies/${id}/categories/${nextCategory.id}`)
      : history.push(`/${policyType}-policies/${id}`)
  }

  categoriesProgression = categories =>
    categories.filter(category => category.status.key === 'completed').length

  render() {
    const {
      match: {
        params: { id, categoryId },
      },
      t,
      history,
      userId,
      notificationsProvider,
    } = this.props
    const { policyType } = this.state

    return (
      <AuthWrapper>
        <Query
          query={ALL_CATEGORIES_QUESTIONS_QUERY}
          fetchPolicy={'no-cache'}
          variables={{
            id: categoryId,
            ppId: id,
            type: policyType,
            userId,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return `Error! ${error.message}`
            const { productCategory, product, productCategories, productVersions } = data
            if (!product) {
              const notification = new NotificationModel({
                title: t('.no_access_to_policy_title'),
                message: t('.no_access_to_policy'),
                type: 'alert',
              })
              notificationsProvider.add(notification)

              return <Redirect to={`/${policyType}-policies`} />
            }
            const { license } = product
            const wizard_option = productCategories.map(category => {
              let completedExceptionCheck = true

              category.questionGroups.forEach(group => {
                group?.questions?.forEach(question => {
                  if (question.referenceNumber === 'M.1') {
                    completedExceptionCheck =
                      question.answer &&
                      question.answer.value[0].email !== undefined &&
                      question.answer.value[0].email !== ''
                  }
                })
              })

              return {
                isComplete: category.status.key === 'completed' && completedExceptionCheck,
                icon: category.avatar,
                id: parseInt(category.id),
              }
            })
            const ppName = localStorage.getItem('ppName')

            let legislations = []
            license.features.forEach(item => {
              if (item.groupCode === 'legislation') {
                legislations.push(item['code'].slice(-2))
              }
            })

            let languages = []
            license.features.forEach(item => {
              if (item.groupCode === 'language' && item.active) {
                languages.push(item['code'].slice(-2))
              }
            })

            if (productCategory.position === 8) {
              history.push(
                `/${policyType}-policies/${id}/categories/${categoryId}/questions/${productCategory.questionGroups?.[0]?.questions?.[0]?.id}`,
              )
              return null
            } else {
              return (
                <div className='row'>
                  <TitleComponent title={ppName + ' | ' + productCategory.name} />
                  <div className='columns'>
                    <div className='category'>
                      <div className='section__heading'>
                        <div className='header__title'>
                          <h6> {t(`.${policyType}_policy`)} </h6>
                          <h1 className='policy_title'> {ppName} </h1>
                        </div>
                        <div>
                          <Link to={`/${policyType}-policies/${id}`}>
                            <Button className='btn btn_pill has-text-blue is-borderless'>
                              <i className='fas fa-arrow-left margin-right-10' />
                              {t('.dashboard')}
                            </Button>
                          </Link>
                          <Button
                            className='btn btn_pill is-outlined margin-left-20'
                            disabled={!this.isCategoriesCompleted(productCategories)}
                            onClick={() =>
                              this.previewPolicy(product.status, productVersions.length)
                            }
                          >
                            <i className='far fa-file-alt margin-right-10' />
                            {t('.preview')}
                          </Button>
                        </div>
                      </div>

                      <WizardForm options={wizard_option} policyType={policyType} />

                      <div className='arrow_title'>
                        <div
                          className='icon-part margin-right-20'
                          style={{
                            WebkitMaskImage: `url(${productCategory.avatar}`,
                            maskImage: `url(${productCategory.avatar}`,
                          }}
                        ></div>
                        <h2>{productCategory.name}</h2>
                      </div>
                      <CategoryQuestionsList
                        category={productCategory}
                        ppStatus={product.status}
                        getRef={this.getRef}
                        policyType={policyType}
                        pp={product}
                        locales={languages}
                      />

                      {product.status !== 'published' && (
                        <div className='row'>
                          <div className='columns flex_right'>
                            <Button
                              className='btn btn_pill is-borderless has-text-blue margin-right-20'
                              onClick={() =>
                                this.handleCategoryChange(productCategory, productCategories, false)
                              }
                            >
                              <i className='fas fa-arrow-left margin-right-10' />
                              {t('.return')}
                            </Button>
                            {productCategory.position !== 5 ? (
                              <Button
                                className='btn btn_pill is-blue'
                                published
                                onClick={() =>
                                  this.handleCategoryChange(
                                    productCategory,
                                    productCategories,
                                    true,
                                  )
                                }
                              >
                                {t('.continue_encoding')}
                                <i className='fas fa-arrow-right margin-left-10' />
                              </Button>
                            ) : this.categoriesProgression(productCategories) === 5 ? (
                              <a onClick={() => this.previewPolicy(product.status)}>
                                <Button published className='btn btn_pill is-blue'>
                                  {t('.preview_your_policy')}
                                  <i className='fas fa-file-alt margin-left-10' />
                                </Button>
                              </a>
                            ) : (
                              <div className='flex_cl' style={{ position: 'relative' }}>
                                <p className='annotation'> * {t('.questions_unanswered')} </p>
                                <Button className='btn btn_pill is-blue' published disabled={true}>
                                  {t('.preview_your_policy')}
                                  <i className='fas fa-file-alt margin-left-10' />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            }
          }}
        </Query>
      </AuthWrapper>
    )
  }
}

const ALL_CATEGORIES_QUESTIONS_QUERY = gql`
  query category($id: ID!, $ppId: ID!, $type: String, $userId: ID!) {
    productCategory(id: $id) {
      id
      position
      name
      avatar
      type
      questionGroups {
        id
        name
        questions(productId: $ppId) {
          id
          referenceNumber
          type
          localeKey
          statement
          explanation
          explanationExt
          choices
          mandatoryChoices
          example
          exampleExt
          warningMessage
          warningMessageExt
          version
          validationWarningMessage
          readOnly
          answer(productId: $ppId) {
            id
            value
            autocompleted
          }
        }
        createdAt
        updatedAt
      }
      status(productId: $ppId)
      createdAt
      updatedAt
    }
    product(id: $ppId, userId: $userId) {
      id
      status
      license {
        id
        features {
          id
          name
          code
          preselect
          groupName
          groupCode
          groupDescription
          active
        }
      }
    }
    productVersions(productId: $ppId) {
      id
    }
    productCategories(type: $type) {
      id
      position
      name
      avatar
      type
      questionGroups {
        id
        name
        questions(productId: $ppId) {
          id
          referenceNumber
          type
          localeKey
          statement
          explanation
          explanationExt
          choices
          mandatoryChoices
          example
          exampleExt
          warningMessage
          warningMessageExt
          version
          validationWarningMessage
          readOnly
          answer(productId: $ppId) {
            id
            value
          }
        }
      }
      status(productId: $ppId)
    }
  }
`

PrivacyPolicyCategory.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

PrivacyPolicyCategory.defaultProps = {}

const mapStateToProps = ({ auth }) => {
  const { userId } = auth
  return { userId }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withApollo,
  withTranslation('category'),
)(PrivacyPolicyCategory)