import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withApollo from 'react-apollo/withApollo';

import { hideModal } from '../../../redux/modal/action';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { withNotifications, NotificationModel } from '../../../providers/notifications';
import withTranslation from '../../../providers/I18n/HoC/t';

const UnpublishModal = props => {
  const { title, closeExampleModal, content, match, t } = props;
  const { unpublishMutation, currentId, otherPolicyId } = content;

  const [isLoading, setLoadingTo] = useState(false);

  const isCookiePolicy = () => {
    let arr = match.url.split(/\/([\d]+)/);

    arr = arr.filter(item => {
      return item.match(/[\/]/g);
    });

    return arr[0] === '/cookie-policies';
  };

  const handlePublish = async updateAll => {
    setLoadingTo(true);

    let policiesToUnpublish = [];

    policiesToUnpublish.push(unpublishMutation(currentId));
    updateAll && policiesToUnpublish.push(unpublishMutation(otherPolicyId));

    try {
      await Promise.all(policiesToUnpublish);
    } catch ({ error }) {
      console.log(error);
    }

    localStorage.setItem('isNewVersion', 'true');
    window.location.reload();
  };

  return (
    <Modal>
      <div className='modal_bar'>
        <h4 className='modal_title'>{title}</h4>
        <button type='button' className='close' onClick={closeExampleModal} />
      </div>
      <div className='modal_content'>
        <div className='modal_statement'>
          {isCookiePolicy() ? t('.cp_modal_version_text') : t('.pp_modal_version_text')}
        </div>
        <p>{t('.modal_version_warning')}</p>
        <div className='list_buttons'>
          <Button className='btn btn_pill is-outlined is-blue' onClick={closeExampleModal}>
            {t('.changed_mind')}
          </Button>
          {otherPolicyId && (
            <Button
              className='btn btn_pill is-outlined is-blue'
              onClick={() => handlePublish(true)}
            >
              {t('.update_both')}
            </Button>
          )}
          <Button className='btn btn_pill is-blue' published onClick={() => handlePublish(false)}>
            {!isLoading
              ? otherPolicyId
                ? t('.update_this_policy')
                : t('.update_my_policy')
              : t('.updating')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UnpublishModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeExampleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeExampleModal: hideModal,
};

export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  withRouter,
  withNotifications(),
  withTranslation('dashboard'),
)(UnpublishModal);
