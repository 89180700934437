export const INFO = 'info';
export const SUCCESS = 'success';
export const ALERT = 'alert';
export const WARNING = 'warning';
export const NOTIFICATION_TIMER = 3000;

export const notificationsIcons = {
  info: 'info',
  success: 'check',
  alert: 'times',
  warning: 'exclamation',
};
